import { useEffect, useState } from "react";
import "@styles/common.scss";
import { AuthProvider } from "@providers/AuthContext";
import AccountCreationView from "./onboarding/AccountCreationView";
import LoginView from "./onboarding/LoginView";
import MagicLinkView from "./onboarding/MagicLinkView";
import GmailIntegration from "./gmail-integration/GmailIntegration";
import ProtectedRoute from "@routes/ProtectedRoute";
import DeepLinkRoute from "@routes/DeepLinkRoute";
import { OnboardingProvider } from "@providers/OnboardingContext";
import NewsletterPicker from "./onboarding/NewsletterPicker/NewsletterPicker";
import OnboardingRoute from "@routes/OnboardingRoute";
import CategorySelection from "./onboarding/CategorySelection/CategorySelection";
import OnboardingDiscover from "./onboarding/Discover/OnboardingDiscover";
import MecoInboxIntegration from "./meco-integration/MecoInboxIntegration";
import ScrollToTop from "@utils/ScrollToTop";
import Welcome from "./onboarding/Welcome";
import Digest from "./digest/Digest";
import ReconnectMailboxes from "./reconnect-mailboxes/ReconnectMailboxes";
import Discover from "./discover/Discover";
import { LayoutProvider } from "@providers/LayoutContext";
import Senders from "./senders/Senders";
import Settings from "./settings/Settings";
import { Toaster } from "react-hot-toast";
import OutlookIntegration from "./outlook-integration/OutlookIntegration";
import OauthCallback from "./common/integration-common/OauthCallback";
import Bookmarks from "./bookmarks/Bookmarks";
import HomeLayout from "@layouts/HomeLayout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SuccessPaywall, { StripeSessionState } from "./paywall/SuccessPaywall";
import { PremiumProvider } from "@providers/PremiumContext";
import OnboardingPaywall from "./onboarding/OnboardingPaywall";
import UTMCollector from "@utils/UTMCollector";
import Search from "./search/Search";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { postAnalyticsEvents } from "@utils/managers/networking/NetworkManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import NotFound from "./common/NotFound";
import { isMobile } from "react-device-detect";
import Audio from "./audio/Audio";
import { AudioOnboardingProvider } from "@providers/AudioOnboardingContext";
import AudioIntro from "./audio/onboarding/AudioIntro";
import AudioPickSenders from "./audio/onboarding/AudioPickSenders";
import AudioSchedulePicker from "./audio/onboarding/AudioSchedulePicker";

const App = () => {
	const [stripeSessionState, setStripeSessionState] = useState<StripeSessionState | undefined>(undefined);

	useEffect(() => {
		recordEvent(kAnalyticsConstants.App.sessionStarted);
		postAnalyticsEvents(kAnalyticsConstants.App.sessionStarted);
	}, []);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const sessionIdParam = params.get("session_id");
		const productIdParam = params.get("product_id");
		if (sessionIdParam && productIdParam) {
			setStripeSessionState({ sessionId: sessionIdParam, productId: productIdParam });
		}
	}, [window.location.search]);

	if (isMobile) {
		window.location.href = "https://www.meco.app/mobile-redirect";
		return null;
	}

	return (
		<Router>
			<Toaster />
			<UTMCollector />
			<ScrollToTop />
			<Routes>
				<Route path="*" element={<NotFound />} />
				<Route element={<AuthProvider />}>
					<Route element={<PremiumProvider />}>
						<Route
							path="/"
							element={
								<>
									<ProtectedRoute /> {stripeSessionState && <SuccessPaywall sessionState={stripeSessionState} setSessionState={setStripeSessionState} />}
								</>
							}>
							<Route element={<LayoutProvider />}>
								<Route element={<HomeLayout />}>
									<Route path="/" element={<Digest />} />
									<Route path="/mail/:mail_id" element={<Digest />} />
									<Route path="/audio" element={<Audio />} />
									<Route path="/search" element={<Search />} />
									<Route path="/discover" element={<Discover />} />
									<Route path="/bookmarks" element={<Bookmarks />} />
									<Route path="/senders" element={<Senders />} />
									<Route path="/settings" element={<Settings />} />
									
								</Route>
							</Route>
							<Route element={<AudioOnboardingProvider />}>
								<Route path="/audio/get-started" element={<AudioIntro />} />
								<Route path="/audio/pick-senders" element={<AudioPickSenders />} />
								<Route path="/audio/schedule" element={<AudioSchedulePicker />} />
							</Route>
							<Route path="/reconnect-mailboxes" element={<ReconnectMailboxes />} />
							<Route element={<OnboardingProvider />}>
								<Route path="/get-started/connect-gmail" element={<GmailIntegration />} />
								<Route path="/get-started/connect-outlook" element={<OutlookIntegration />} />
								<Route path="/get-started/meco-mailbox" element={<MecoInboxIntegration />} />
								<Route element={<OnboardingRoute />}>
									<Route path="/get-started/newsletter-picker" element={<NewsletterPicker />} />
									<Route path="/get-started/interests" element={<CategorySelection />} />
									<Route path="/get-started/discover" element={<OnboardingDiscover />} />
									<Route path="/get-started/meco-pro" element={<OnboardingPaywall />} />
									<Route path="/get-started/welcome" element={<Welcome />} />
								</Route>
							</Route>
						</Route>
						<Route path="/magic-link" element={<MagicLinkView />} />
						<Route path="/get-started/connect-gmail/callback" element={<OauthCallback />} />
						<Route path="/get-started/connect-outlook/callback" element={<OauthCallback />} />
						<Route element={<DeepLinkRoute />}>
							<Route path="/auth/:id" element={<MagicLinkView />} />
						</Route>
					</Route>
				</Route>
				<Route path="/get-started" element={<AccountCreationView />} />
				<Route path="/login" element={<LoginView />} />
			</Routes>
		</Router>
	);
};

export default App;
