import { useState, useEffect } from "react";
import { ReactComponent as PaywallWandIcon } from "@images/paywall_benefit_wand_icon.svg";
import { ReactComponent as PaywallMoonIcon } from "@images/paywall_benefit_moon_icon.svg";
import { ReactComponent as PaywallTextIcon } from "@images/paywall_benefit_text_icon.svg";
import { ReactComponent as PaywallBookmarkIcon } from "@images/bookmark_on_icon.svg";
import { ReactComponent as PaywallOfflineIcon } from "@images/paywall_benefit_offline_icon.svg";
import Alert, { AlertState } from "@components/common/Alert";
import { NLCreateCheckout, NLPurchaseProduct } from "@models/Models";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import ParticlesBackground from "@components/common/ParticlesBackground";
import { Link, useNavigate } from "react-router-dom";
import { getCheckoutUrl } from "@utils/managers/networking/NetworkManager";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import { kAppConstants } from "@utils/constants/AppConstants";
import { yearlyPlan, monthlyPlan } from "@utils/constants/PaywallConstants";

const OnboardingPaywall = () => {
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [selectedProduct, setSelectedProduct] = useState<NLPurchaseProduct>(yearlyPlan);
	const [isCreatingCheckout, setIsCreatingCheckout] = useState<boolean>(false);

	useEffect(() => {
		recordEvent(kAnalyticsConstants.Paywall.paywallPresented, { reference: kAnalyticsConstants.Reference.onboarding });
	}, []);

	const purchaseProduct: NLPurchaseProduct[] = [yearlyPlan, monthlyPlan];
	const ProBenefit = ({ icon, title, isAsterisk = false }) => {
		return (
			<div className="flex flex-row transition ease-in-out gap-2 items-center">
				<div className="pr-2">{icon}</div>
				<div className="flex-col text-left">
					<div className="text-white font-regular font-primary text-base">
						{title}
						{isAsterisk && <span>&#42;</span>}
					</div>
				</div>
			</div>
		);
	};

	const productOnSelect = (product: NLPurchaseProduct) => {
		setSelectedProduct(product);
	};

	const subscribeOnClick = () => {
		recordEvent(kAnalyticsConstants.Paywall.subscribeButtonTapped, { reference: kAnalyticsConstants.Reference.onboarding, price_id: selectedProduct.price_id });
		setIsCreatingCheckout(true);
		const createCheckout = new NLCreateCheckout(selectedProduct.price_id, window.location.origin + "/get-started/welcome", window.location.href);
		getCheckoutUrl(createCheckout)
			.then((checkout) => {
				window.location.href = checkout.checkout_url;
			})
			.catch((error) => {
				recordEvent(kAnalyticsConstants.Paywall.checkoutGenerationError, { reference: kAnalyticsConstants.Reference.onboarding, price_id: selectedProduct.price_id, error: error.message });
				setIsCreatingCheckout(false);
				setAlertState({ isShow: true, title: "Uh oh! Something went wrong", message: "Please try again and contact us if the problem persists", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
			});
	};

	return (
		<div>
			<ParticlesBackground />
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<div className="flex min-h-[100dvh] flex-col md:justify-center justify-start overflow-hidden py-2 md:py-12">
				<div className="flex flex-col gap-10 items-center justify-center p-3">
					<div className="meco_container text-center max-w-[1080px] py-[40px] md:p-[60px] md:pb-[40px]">
						<div className="flex flex-col md:flex-row gap-5 max-w-[400px] md:max-w-none">
							<div className="w-full md:w-1/2 flex flex-col gap-[50px]">
								<div className="text-white font-bold font-primary text-3xl md:text-left md:leading-10 leading-9">Unlock the smartest version of you with PRO</div>
								<div className="flex flex-col gap-3 m-auto md:m-0">
									<ProBenefit icon={<PaywallWandIcon className="fill-white w-[20px]" />} title="Connect your Gmail & Outlook mailboxes" />
									<ProBenefit icon={<PaywallMoonIcon className="fill-white w-[20px]" />} title="Four color themes including Dark Mode" />
									<ProBenefit icon={<PaywallTextIcon className="fill-white w-[20px]" />} title="Tailor font & text size for consistent reading" />
									<ProBenefit icon={<PaywallBookmarkIcon className="fill-white w-[14px] ml-[3px]" />} title="Save links, highlight learnings & add notes" isAsterisk={true} />
									<ProBenefit icon={<PaywallOfflineIcon className="fill-white w-[20px]" />} title="Read anywhere with offline access" isAsterisk={true} />
									<div className="font-regular font-primary text-2xs text-white/50 text-left">
										<span>&#42;</span> Features are currently iOS only and coming to web soon.
									</div>
								</div>
							</div>
							<div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-4 pt-3">
								<div className="flex flex-col gap-2 w-full">
									{purchaseProduct.map((product) => {
										return (
											<div key={product.price_id} className={`relative flex flex-col ${product.price_id === selectedProduct.price_id ? "border-success-green" : "border-white/20"} bg-white/10 hover:bg-white/20 transition ease-in-out border duration-300 rounded-xl px-3 py-4 w-full gap-2 items-start mb-[12px] shadow-md cursor-pointer`} onClick={() => productOnSelect(product)}>
												{product.badge && <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-success-green text-white font-medium font-primary text-xs rounded-full">{product.badge}</div>}
												<div className="flex flex-row items-center justify-between w-full gap-3">
													<div className="flex flex-col items-start text-left flex-grow font-medium font-primary text-lg text-white">{product.product_name}</div>
													<div className="flex flex-row gap-2 items-center">
														<div className="font-medium font-primary text-base text-white">
															{product.monthly_price}
															<div className="inline font-regular text-xs pl-1">/MO</div>
														</div>
														<input readOnly className={`checkbox-round green`} type="checkbox" name="selectable-sender" checked={product.price_id === selectedProduct.price_id} value={product.price_id} />
													</div>
												</div>
											</div>
										);
									})}
								</div>
								<div className="flex flex-col gap-2 w-full items-center">
									<button onClick={() => subscribeOnClick()} className="flex cta-button w-full shadow-2xl">
										{isCreatingCheckout ? <LoadingSpinner className="h-[26px] text-white/20 animate-spin fill-white" /> : selectedProduct.trial_days ? "TRY " + selectedProduct.trial_days + " DAYS FOR FREE" : "Subscribe to unlock"}
									</button>
									<div className={`font-regular font-primary text-sm text-white/50 ${selectedProduct.trial_days ? "opacity-100" : "opacity-0"}`}>
										Free for {selectedProduct.trial_days} days, then {selectedProduct.total_price}/{selectedProduct.term}. Cancel anytime.
									</div>
									<Link to="/get-started/welcome" className="md:hidden">
										<button className="text-force-primary-light font-medium font-primary text-base focus:outline-none whitespace-nowrap mt-3">Not now</button>
									</Link>
								</div>
							</div>
						</div>
						<div className="w-full hidden md:block">
							<div className="h-[1px] w-full bg-force-primary-light/20 mt-5"></div>
							<div className="flex justify-end">
								<Link to="/get-started/welcome">
									<button className="text-force-primary-light font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-white/20 hover:bg-brand-blue/80 transition ease-in-out whitespace-nowrap mt-3">Not now</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OnboardingPaywall;
