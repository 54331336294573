import { useState, useEffect } from "react";
import gmailMecoLogoDark from "@images/gmail_integration_dark.png";
import { ReactComponent as BenefitBolt } from "@images/gmail_benefit_bolt.svg";
import { ReactComponent as BenefitWand } from "@images/gmail_benefit_wand.svg";
import { ReactComponent as BenefitPrivacy } from "@images/gmail_benefit_privacy.svg";
import { kStringConstants } from "@utils/constants/StringConstants";
import { kErrorConstants } from "@utils/constants/ErrorConstants";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import BenefitView, { BenefitViewState } from "../common/integration-common/BenefitView";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import Alert, { AlertState } from "@components/common/Alert";
import OauthPrivacyPopup, { OauthPrivacyPopupState } from "../common/integration-common/OauthPrivacyPopup";
import { NLMailboxProfile, OnboardingProfile } from "@models/Models";
import { GetUserMailStatesService } from "@utils/managers/backendMailManager/mailboxFunctions/GetUserMailStatesService";
import * as Date from "@utils/Date+GetDateFor";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import ParticlesBackground from "@components/common/ParticlesBackground";
import { useAuth } from "@providers/AuthContext";
import { useOnboardingProfile } from "@providers/OnboardingContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { usePremium } from "@providers/PremiumContext";
import PaywallModal from "@components/common/PaywallModal";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const GmailIntegration = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const isAccount: boolean = (location.state?.isAccount || location.search.includes("is_account")) ?? false;
	const [privacyPopupState, setPrivacyPopupState] = useState(OauthPrivacyPopupState.hidden);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const { authUser, setAuthUser } = useAuth();
	const { setOnboardingProfile } = useOnboardingProfile();
	const { isPremium } = usePremium();
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});
	const [showPaywall, setShowPaywall] = useState<boolean>(false);

	const benefits: BenefitViewState[] = [
		{
			icon: <BenefitBolt className="fill-force-primary-light w-[30px]" />,
			title: "Instantly add existing newsletters",
			description: "Add your existing newsletters to Meco in seconds, no manual work needed.",
		},
		{
			icon: <BenefitWand className="fill-force-primary-light w-[30px]" />,
			title: "Magically declutter your inbox",
			description: "Newsletters added to Meco are magically hidden in your Gmail, giving your inbox space to breathe!",
		},
		{
			icon: <BenefitPrivacy className="fill-force-primary-light w-[30px]" />,
			title: "Built with your privacy in mind",
			description: "Emails are stored locally and never leave your device. We never process or sell your email data.",
		},
	];

	useEffect(() => {
		analyticsManager.recordEvent("GMI - Presented", { is_onboarding: !isAccount });
	}, []);

	const integrateGmailOnClick = () => {
		analyticsManager.recordEvent("GMI - Integrate Tapped", { is_onboarding: !isAccount });
		setPrivacyPopupState(OauthPrivacyPopupState.visible);
	};

	const integrateWithGmail = async () => {

		if (!isPremium()) {
			setPrivacyPopupState(OauthPrivacyPopupState.hidden);
			setShowPaywall(true);
			return;
		}

		analyticsManager.recordEvent("GMI - Integration - Started", { is_onboarding: !isAccount });
		setPrivacyPopupState(OauthPrivacyPopupState.hidden);
		OauthSessionManager.sharedInstance()
			.integrateWithGmail()
			.then(([profile, mailboxProfileId]) => {
				setAuthUser(profile);
				const mailboxProfile = profile.mailbox_profiles.find((x) => x.id === mailboxProfileId);
				if (mailboxProfile) {
					return getStates(mailboxProfile);
				}
				throw kErrorConstants.commonBackendError;
			})
			.then((onboardingProfile) => {
				setLoadingState({ isLoading: false });

				analyticsManager.recordEvent("GMI - Integration - Completed", { is_onboarding: !isAccount, mailbox_profile_id: onboardingProfile.persistence.mailboxProfile.id, mailbox_profile_email: onboardingProfile.persistence.mailboxProfile.email_address });
				setOnboardingProfile(onboardingProfile);

				if (onboardingProfile.persistence.statesArray.length > 0 && !onboardingProfile.persistence.mailboxProfile.is_profile_setup) {
					navigate("/get-started/newsletter-picker", { replace: true, state: { isAccount: isAccount }});
				} else {
					if (isAccount) {
						navigate("/settings", { replace: true, state: { isAccount: isAccount }});
					} else {
						navigate("/get-started/interests", { replace: true });
					}
				}
			})
			.catch((error) => {
				setLoadingState({ isLoading: false });
				handleSignInError(error);
			});
	};

	const getStates = (mailboxProfile: NLMailboxProfile): Promise<OnboardingProfile> => {
		return new Promise((resolve, reject) => {
			setLoadingState({ isLoading: true, message: "Finding newsletters sent to you in the last 7 days" });

			const userMailStateRequests = new GetUserMailStatesService.QueryBuilder(mailboxProfile, null, false, null);
			const getUserMailStatesService = new GetUserMailStatesService([userMailStateRequests]);

			getUserMailStatesService.getUserMailStates((userMailStates, _mailboxProfileIds, error) => {
				if (!userMailStates) {
					reject(kErrorConstants.commonBackendError);
					return;
				}

				if (error) {
					reject(error);
					return;
				}

				var onboardingProfile = new OnboardingProfile(mailboxProfile);
				onboardingProfile.persistence.statesArray = userMailStates;
				onboardingProfile.persistence.lastGetUserMailStatesDateString = Date.backendDateString();
				resolve(onboardingProfile);
			});
		});
	};

	const handleSignInError = (error) => {
		var alertTitle = kStringConstants.Common.errorAlertTitle;
		var alertMessage = kStringConstants.Common.errorAlertMessage;

		if (error == kErrorConstants.oauthIntegration.userCancelled) {
			analyticsManager.recordEvent("GMI - Integration - User Cancelled Flow", { is_onboarding: !isAccount, error: error.message ?? "" });
		} else {
			analyticsManager.recordEvent("GMI - Integration - Error", { is_onboarding: !isAccount, error: error.message ?? "" });
		}

		if (error === kErrorConstants.oauthIntegration.permissionError) {
			alertTitle = "Please allow Gmail permissions";
			alertMessage = "We require these permissions to add and manage newsletters with Meco. We designed Meco with your privacy in mind. Your emails remain private and are only accessible by you.";
		}

		if (error === kErrorConstants.oauthIntegration.alreadyIntegratedAccount) {
			alertTitle = "Gmail already in use";
			alertMessage = "This Gmail address is already associated with another Meco account. A Gmail can only be connected to one Meco account at a time.";
		}

		if (error === kErrorConstants.oauthIntegration.accountMismatch) {
			alertTitle = "Incorrect Gmail address";
			alertMessage = "The address you selected did not match the email above. Please retry with the correct Gmail.";
		}

		setAlertState({ isShow: true, title: alertTitle, message: alertMessage, actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
	};

	return (
		<div>
			<LoadingView loadingState={loadingState} />
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<OauthPrivacyPopup setIsShow={() => setPrivacyPopupState(OauthPrivacyPopupState.hidden)} integrationType={"Gmail"} popupState={privacyPopupState} isOnboarding={!isAccount} agreeOnClick={() => integrateWithGmail()} />
			<PaywallModal isShow={showPaywall} onClose={() => setShowPaywall(false)} analyticsReference={kAnalyticsConstants.Reference.Paywall.gmailIntegration} isOnboarding={!isAccount}/>
			<ParticlesBackground />
			<div className="flex min-h-[100dvh] flex-col md:justify-center justify-start overflow-hidden py-2 md:py-12">
				<div className="flex flex-col gap-10 items-center justify-center p-3">
					<div className="meco_container text-center max-w-[1080px] py-[40px] md:p-[60px] md:pb-[40px]">
						<div className="flex flex-col-reverse md:flex-row gap-5 max-w-[400px] md:max-w-none">
							<div className="w-full md:w-1/2 flex flex-col">
								<div className="flex flex-col gap-3">
									{benefits.map((benefit) => (
										<BenefitView key={benefit.title} benefit={benefit} />
									))}
									<div className="flex flex-col gap-0 md:hidden">
										<button className="flex m-auto cta-button w-full shadow-2xl mt-4" onClick={integrateGmailOnClick}>
											Connect with Gmail
										</button>
										{isAccount ? (
											<Link to="/settings">
												<button className="text-force-primary-light font-medium font-primary text-base focus:outline-none whitespace-nowrap mt-3">Back</button>
											</Link>
										) : (
											<Link to="/get-started/meco-mailbox">
												<button className="text-force-primary-light font-medium font-primary text-base focus:outline-none whitespace-nowrap mt-3">Not now</button>
											</Link>
										)}
									</div>
								</div>
							</div>
							<div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-4">
								<div className="text-white font-medium font-primary text-3xl text-center md:leading-10 leading-9">
									Meco works seamlessly&nbsp;
									<br className="hidden md:block" />
									with Gmail
								</div>

								<img src={gmailMecoLogoDark} className="w-[250px]" />
								<button className="hidden md:flex cta-button w-full shadow-2xl" onClick={integrateGmailOnClick}>
									Connect with Gmail
								</button>
							</div>
						</div>
						<div className="w-full hidden md:block">
							<div className="h-[1px] w-full bg-force-primary-light/20 mt-5"></div>
							<div className={`flex ${isAccount ? "justify-start" : "justify-end"}`}>
								{isAccount ? (
									<Link to="/settings">
										<button className="text-force-primary-light font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-white/20 hover:bg-brand-blue/80 transition ease-in-out whitespace-nowrap mt-3">Back</button>
									</Link>
								) : (
									<Link to="/get-started/meco-mailbox">
										<button className="text-force-primary-light font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-white/20 hover:bg-brand-blue/80 transition ease-in-out whitespace-nowrap mt-3" onClick={() => analyticsManager.recordEvent("GMI - Not now tapped", { is_onboarding: !isAccount })}>
											Not now
										</button>
									</Link>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GmailIntegration;
