import { useState, FC, Fragment } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { useHotkeys } from "react-hotkeys-hook";
import IconButton from "@components/common/IconButton";
import Alert, { AlertState } from "@components/common/Alert";
import { Dialog, Transition } from "@headlessui/react";
import collageNotifications from "@images/collage_notifications.png";
import { setAsArrivedNotification } from "@utils/managers/NotificationsManager";
import { NLNotificationAsArrivedType } from "@models/Models";
import { NLNotificationPreferenceType } from "@models/Enums";
import { useAuth } from "@providers/AuthContext";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

interface NotificationsModalProps {
	isShow: boolean;
	onClose: () => void;
}

const NotificationsModal: FC<NotificationsModalProps> = ({ isShow, onClose }) => {
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
    const { authUser, setAuthUser } = useAuth();
    
	useHotkeys("Escape", () => onModalClose());

	const onModalClose = () => {
		recordEvent(kAnalyticsConstants.App.notifications, { notifications_enabled: false })
		onClose();
	};

	const getNotifiedOnClick = () => {
		const newNotifications = new NLNotificationAsArrivedType({ id: "0", type: NLNotificationPreferenceType.asArrived });
		recordEvent(kAnalyticsConstants.App.notifications, { notifications_enabled: true })
		setAuthUser({ ...authUser!, notifications: { as_arrived: newNotifications } });
		setAsArrivedNotification(authUser!, newNotifications);
		onClose();
    };

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="max-w-[400px] transform overflow-hidden rounded-2xl bg-surface border border-white/10 text-left align-middle shadow-xl transition-all p-4">
								<div className="flex flex-col">
									<div className="w-full flex flex-row justify-end items-center">
										<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onModalClose()} />
									</div>
									<div className="flex flex-col gap-3 text-center items-center h-full w-full">
										<img src={collageNotifications} className="max-w-[120px] object-contain" />
										<div className="text-primary font-medium font-primary text-xl text-center">Never miss a newsletter</div>
										<div className="text-primary font-regular font-primary text-sm">Readers who have notifications turned on tend to read 3x more newsletters!</div>
										<button className={`bg-success-green hover:bg-success-green/80 transition duration-300 ease-in-out py-2 font-primary text-lg text-white rounded-xl shadow-sm w-full h-[44px]`} onClick={() => getNotifiedOnClick()}>
											Get notified
										</button>
										<button className={`py-2 font-primary text-md text-primary hover:text-primary/80 transition duration-300 ease-in-out w-full`} onClick={() => onModalClose()}>
											Not now
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default NotificationsModal;
