import { ReactNode, useEffect, useRef, useState } from "react";
import EmailViewer from "../email-viewer/EmailViewer";
import { useData } from "@providers/DataContext";
import { useAuth } from "@providers/AuthContext";
import { searchMessages } from "@utils/managers/MailManager";
import MailCard from "@components/common/MailCard";
import { LayoutGroup } from "framer-motion";
import EmptyStateView from "@components/common/EmptyStateView";
import collageNoResult from "@images/collage_search_no_results.png";
import collageBinoculars from "@images/collage_binoculars.png";
import { useLayout } from "@providers/LayoutContext";
import { NLMail } from "@models/Models";
import { useBookmarks } from "@providers/BookmarkContext";
import "@utils/String+MessageIdType";
import SearchHeader from "./SearchHeader";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import { recordEvent } from "@utils/managers/AnalyticsManager";

const Search = () => {
	const { mailListWidth, isMailListHidden, setSettingsActive } = useLayout();
	const { activeMail, setActiveMail, setStartingPointMail } = useData();
	const [searchResults, setSearchResults] = useState<NLMail[] | undefined>(undefined);
	const [isNoResult, setNoResult] = useState<boolean>(false);

	const { bookmarks } = useBookmarks();
	const feedRef = useRef<HTMLDivElement>(null);
	const mailListRef = useRef<HTMLDivElement>(null);

	const [searchQuery, setSearchQuery] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const minimumSearchCharacter = 3;
	const { authUser } = useAuth();

	useEffect(() => {
		setSettingsActive(false);
	}, []);

	useEffect(() => {
		if (mailListRef.current && activeMail) {
			const activeMailNode = mailListRef.current.querySelector(`#mail-${activeMail.id.safeId()}`);
			if (activeMailNode && !isElementInViewport(activeMailNode)) {
				activeMailNode.scrollIntoView({ behavior: "smooth", block: "center" });
			}
		}
	}, [activeMail]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (!searchQuery || searchQuery.length < minimumSearchCharacter) {
				resetSearch();
				return;
			}

			performSearch(searchQuery);
		}, 750);
		return () => {
			clearTimeout(delayDebounceFn);
			if (!searchQuery) {
				resetSearch();
			}
		};
	}, [searchQuery]);

	const resetSearch = () => {
		setSearchResults(undefined);
		setNoResult(false);
	};

	const performSearch = (query: string) => {
		setIsLoading(true);
		const mailboxProfiles = authUser!.mailbox_profiles;
		if (mailboxProfiles.length > 0) {
			searchMessages(query, mailboxProfiles, (messages, _error) => {
				recordEvent("LBR - Search Performed", { search_term: query, search_result_count: messages?.length ?? 0});
				setIsLoading(false);
				setNoResult(!(messages && messages.length > 0));
				setSearchResults(messages);
			});
		} else {
			setIsLoading(false);
			setNoResult(true);
		}
	};

	const renderEmptyView = (): ReactNode => {
		if (searchQuery && isNoResult) {
			return <EmptyStateView title="Oops! No matches found!" description="Try searching keywords or sender name" alertImage={collageNoResult} centerInParent={true} />;
		}

		return <EmptyStateView title={"Looking for a specific post?"} description={"Search Meco to find the newsletter you are looking for"} alertImage={collageBinoculars} centerInParent={true} />;
	};

	const isElementInViewport = (el: Element) => {
		const rect = el.getBoundingClientRect();
		return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
	};

	const mailCardOnClick = (mail: NLMail) => {
		if (activeMail && mail.id === activeMail.id) {
			setActiveMail(undefined);
			return;
		}
		setStartingPointMail(mail);
		setActiveMail(mail);
	};

	return (
		<div className="flex flex-row w-full items-stretch">
			<div className={`${isMailListHidden ? "hidden" : "flex"} h-screen border-r border-primary-100 z-20 transition-[width] ease-in-out duration-300 overflow-scroll flex flex-col pb-4`} style={{ width: mailListWidth + "px", minWidth: mailListWidth + "px" }} ref={feedRef} id="root-container">
				<SearchHeader searchQuery={searchQuery} setSearchQuery={setSearchQuery} isLoading={isLoading} setIsLoading={setIsLoading} />
				{searchResults && searchResults.length > 0 ? (
					<div className="flex flex-col p-3 gap-3" ref={mailListRef}>
						<LayoutGroup>
							{searchResults &&
								searchResults.map((mail, i) => {
									return (
										<div key={mail.id}>
											<MailCard mail={mail} bookmark={bookmarks?.find((x) => x.mail_id === mail.id)} isActive={mail.id === activeMail?.id} onClick={() => mailCardOnClick(mail)} />
										</div>
									);
								})}
						</LayoutGroup>
					</div>
				) : (
					renderEmptyView()
				)}
			</div>
			<EmailViewer reference={kAnalyticsConstants.Reference.search} />
		</div>
	);
};

export default Search;
