import { DiscoverSubscriptionState } from "./Discover";
import DiscoverCard from "./DiscoverCard";

export const DiscoverRow = (props) => {
	
  const determineSubscriptionState = (newsletter) => {
		if (newsletter.one_click_subscribe_method != null) {
			if (newsletter.is_user_subscribed) {
				return DiscoverSubscriptionState.subscribed;
			} else {
				return DiscoverSubscriptionState.oneClickSubscribe;
			}
		} else {
			return DiscoverSubscriptionState.web;
		}
	};

	return (
		<div key={props.category.id} id={props.category.name}>
			<div className="font-primary font-black text-xl text-primary">
				{props.category.emoji} {props.category.name}
			</div>
			<div key={props.key} className="grid grid-flow-col auto-cols-max gap-4 pt-3 pb-5 overflow-auto min-h-[300px]">
				{props.recommendedNewsletters &&
					props.recommendedNewsletters.map((newsletter, i) => {
						return <DiscoverCard category={props.category} newsletter={newsletter} key={i} subscriptionState={determineSubscriptionState(newsletter)} alreadySubscribedOnClick={props.alreadySubscribedOnClick()} oneClickSubscribeOnClick={props.oneClickSubscribeOnClick()}/>;
					})}
			</div>
		</div>
	);
};

export default DiscoverRow;
