import React, { useState, useEffect, FC, Fragment } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { useHotkeys } from "react-hotkeys-hook";
import IconButton from "@components/common/IconButton";
import { ReactComponent as PaywallWandIcon } from "@images/paywall_benefit_wand_icon.svg";
import { ReactComponent as PaywallAudioIcon } from "@images/tabbar_audio.svg";
import { ReactComponent as PaywallMoonIcon } from "@images/paywall_benefit_moon_icon.svg";
import { ReactComponent as PaywallTextIcon } from "@images/paywall_benefit_text_icon.svg";
import { ReactComponent as PaywallBookmarkIcon } from "@images/bookmark_on_icon.svg";
import { ReactComponent as PaywallOfflineIcon } from "@images/paywall_benefit_offline_icon.svg";
import { NLCreateCheckout, NLPurchaseProduct } from "@models/Models";
import Alert, { AlertState } from "@components/common/Alert";
import { Dialog, Transition } from "@headlessui/react";
import { getCheckoutUrl, activateInternalTrial } from "@utils/managers/networking/NetworkManager";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { monthlyPlan, yearlyPlan } from "@utils/constants/PaywallConstants";
import { useAuth } from "@providers/AuthContext";
import InternalTrialModal from "./InternalTrialModal";

interface PaywallModalProps {
	isShow: boolean;
	onClose: () => void;
	analyticsReference: string;
	isOnboarding?: boolean;
}

const PaywallModal: FC<PaywallModalProps> = ({ isShow, onClose, analyticsReference, isOnboarding }) => {
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [selectedProduct, setSelectedProduct] = useState<NLPurchaseProduct>(yearlyPlan);
	const [isCreatingCheckout, setIsCreatingCheckout] = useState<boolean>(false);
	const [isShowInternalTrialModal, setInternalTrialModal] = useState<boolean>(false);

	const { setAuthUser } = useAuth();
	const [titleString, setTitleString] = useState<string>("Unlock the smartest version of you with PRO");
	useHotkeys("Escape", () => onModalClose());

	useEffect(() => {
		if (!isShow) {
			return;
		}

		recordEvent(kAnalyticsConstants.Paywall.paywallPresented, { reference: analyticsReference });

		var titleLabel = "Unlock the smartest version of you with PRO";

		if (analyticsReference === kAnalyticsConstants.Reference.Paywall.gmailIntegration) {
			titleLabel = "Add newsletters from Gmail & declutter your inbox";
		}

		if (analyticsReference === kAnalyticsConstants.Reference.Paywall.outlookIntegration) {
			titleLabel = "Add newsletters from Outlook & declutter your inbox";
		}

		if (analyticsReference === kAnalyticsConstants.Reference.Paywall.readerMode) {
			titleLabel = "Enable Dark Mode, fonts, themes & more";
		}

		if (analyticsReference === kAnalyticsConstants.Reference.Paywall.audio) {
			titleLabel = "Daily personalized podcasts with PRO";
		}

		if (analyticsReference === kAnalyticsConstants.Reference.Paywall.aiSummaru) {
			titleLabel = "Newsletter AI Summaries with PRO";
		}

		setTitleString(titleLabel);
	}, [isShow]);

	const purchaseProduct: NLPurchaseProduct[] = [yearlyPlan, monthlyPlan];

	const ProBenefit = ({ icon, title, isAsterisk = false }) => {
		return (
			<div className="flex flex-row transition ease-in-out gap-2 items-center">
				<div className="pr-2">{icon}</div>
				<div className="flex-col text-left">
					<div className="text-white font-regular font-primary text-base">
						{title}
						{isAsterisk && <span>&#42;</span>}
					</div>
				</div>
			</div>
		);
	};

	const productOnSelect = (product: NLPurchaseProduct) => {
		setSelectedProduct(product);
	};

	const subscribeOnClick = () => {
		recordEvent(kAnalyticsConstants.Paywall.subscribeButtonTapped, { reference: analyticsReference, price_id: selectedProduct.price_id });
		setIsCreatingCheckout(true);
		const createCheckout = new NLCreateCheckout(selectedProduct.price_id, window.location.href, window.location.href);
		getCheckoutUrl(createCheckout)
			.then((checkout) => {
				window.location.href = checkout.checkout_url;
			})
			.catch((error) => {
				recordEvent(kAnalyticsConstants.Paywall.checkoutGenerationError, { reference: analyticsReference, price_id: selectedProduct.price_id, error: error.message });
				setIsCreatingCheckout(false);
				setAlertState({ isShow: true, title: "Uh oh! Something went wrong", message: "Please try again and contact us if the problem persists", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
			});
	};

	const onModalClose = () => {
		let internalTrialEligibleRefs = [kAnalyticsConstants.Reference.Paywall.gmailIntegration, kAnalyticsConstants.Reference.Paywall.outlookIntegration];

		if (internalTrialEligibleRefs.includes(analyticsReference) && isOnboarding) {
			activateInternalTrial().then((profile) => {
				setAuthUser(profile);
				recordEvent(kAnalyticsConstants.Paywall.internalTrialStarted, { reference: analyticsReference });
				setInternalTrialModal(true);
			})
			.catch((_error) => {
				onClose();
			})

			return;
		}

		recordEvent(kAnalyticsConstants.Paywall.paywallDismissed, { reference: analyticsReference });
		onClose();
	};

	const onInternalTrialModalClose = () => {
		setInternalTrialModal(false);
		recordEvent(kAnalyticsConstants.Paywall.paywallDismissed, { reference: analyticsReference });
		onClose();
	}

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<InternalTrialModal isShow={isShowInternalTrialModal} onClose={() => onInternalTrialModalClose()} />
			<Transition as={Fragment} show={isShow}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="w-full h-full transform overflow-hidden rounded-2xl bg-black border border-white/20 text-left align-middle shadow-xl transition-all">
								<video className="absolute w-full h-full object-cover -z-10" autoPlay loop muted>
									<source src="https://meco-static-1337.s3.us-east-2.amazonaws.com/media/sunset.mp4" type="video/mp4" />
								</video>
								<div className="absolute w-full h-full bg-black/80 top-0 left-0 flex flex-row justify-between items-center -z-10" />
								<div className="absolute w-full top-0 left-0 flex flex-row justify-between items-center p-4 pb-3 z-20">
									<div className="text-primary font-medium font-primary text-xl"></div>
									<IconButton Icon={ExitIcon} iconClassName="fill-white" className="-mr-2.5 hover:bg-white/10" onClick={() => onModalClose()} />
								</div>
								<div className="flex flex-col md:flex-row gap-5 max-w-none items-center h-full p-16">
									<div className="w-full md:w-1/2 flex flex-col gap-[50px]">
										<div className="text-white font-bold font-primary text-3xl md:text-left md:leading-10 leading-9">{titleString}</div>
										<div className="flex flex-col gap-3 m-auto md:m-0">
											<ProBenefit icon={<PaywallWandIcon className="fill-white w-[20px]" />} title="Connect your Gmail & Outlook mailboxes" />
											<ProBenefit icon={<PaywallAudioIcon className="fill-white w-[20px]" />} title="Personalized podcasts made for you" />
											<ProBenefit icon={<PaywallMoonIcon className="fill-white w-[20px]" />} title="Dark Mode, custom themes & fonts" />
											<ProBenefit icon={<PaywallBookmarkIcon className="fill-white w-[14px] ml-[3px]" />} title="Highlight, take notes & save links" isAsterisk={true} />
											<ProBenefit icon={<PaywallOfflineIcon className="fill-white w-[20px]" />} title="Read anywhere with offline access" isAsterisk={true} />
											<div className="font-regular font-primary text-2xs text-white/50 text-left">
												<span>&#42;</span> Features are currently iOS only and coming to web soon.
											</div>
										</div>
									</div>
									<div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-4 pt-3">
										<div className="flex flex-col gap-2 w-full">
											{purchaseProduct.map((product) => {
												return (
													<div key={product.price_id} className={`relative flex flex-col ${product.price_id === selectedProduct.price_id ? "border-success-green" : "border-white/20"} bg-white/10 hover:bg-white/20 transition ease-in-out border duration-300 rounded-xl px-3 py-4 w-full gap-2 items-start mb-[12px] shadow-md cursor-pointer`} onClick={() => productOnSelect(product)}>
														{product.badge && <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-success-green text-white font-medium font-primary text-xs rounded-full">{product.badge}</div>}
														<div className="flex flex-row items-center justify-between w-full gap-3">
															<div className="flex flex-col items-start text-left flex-grow font-medium font-primary text-lg text-white">{product.product_name}</div>
															<div className="flex flex-row gap-2 items-center">
																<div className="font-medium font-primary text-base text-white">
																	{product.monthly_price}
																	<div className="inline font-regular text-xs pl-1">/MO</div>
																</div>
																<input readOnly className={`checkbox-round green`} type="checkbox" name="selectable-sender" checked={product.price_id === selectedProduct.price_id} value={product.price_id} />
															</div>
														</div>
													</div>
												);
											})}
										</div>
										<div className="flex flex-col gap-2 w-full items-center">
											<button onClick={() => subscribeOnClick()} className="flex cta-button w-full shadow-2xl">
												{isCreatingCheckout ? <LoadingSpinner className="h-[26px] text-white/20 animate-spin fill-white" /> : selectedProduct.trial_days ? "TRY " + selectedProduct.trial_days + " DAYS FOR FREE" : "Subscribe to unlock"}
											</button>
											<div className={`font-regular font-primary text-sm text-white/50 ${selectedProduct.trial_days ? "opacity-100" : "opacity-0"}`}>
												Free for {selectedProduct.trial_days} days, then {selectedProduct.total_price}/{selectedProduct.term}. Cancel anytime.
											</div>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default PaywallModal;
