import React, { MouseEventHandler, useState } from "react";
import { AudioRowType } from "@models/Models";
import { ReactComponent as WaveIcon } from "@images/tabbar_audio.svg";
import { ReactComponent as GiftIcon } from "@images/audio_first_episode_icon.svg";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import PaywallModal from "@components/common/PaywallModal";
import { convertHourToLocalTime } from "@utils/Date+GetDateFor";

interface AudioEmptyCardProps {
	audioRowType: AudioRowType;
	scheduledHour: number;
}

const AudioEmptyCard: React.FC<AudioEmptyCardProps> = ({ audioRowType, scheduledHour }) => {
	const [showPaywall, setShowPaywall] = useState<boolean>(false);

	const generateTitle = () => {
		switch (audioRowType) {
			case AudioRowType.UserCheckBack:
				return "Your Daily Brief will be ready soon…";
			case AudioRowType.UserFirstEpisodeLoading:
				return "We’re preparing your first episode!";
			default:
				break;
		}
	};

	const generateDescription = () => {
		switch (audioRowType) {
			case AudioRowType.UserCheckBack:
				let hour = scheduledHour ?? 9;
				return `Your personalized news briefing will be available here at ${convertHourToLocalTime(hour)}`;
			case AudioRowType.UserFirstEpisodeLoading:
				return "Your first Daily Brief will be here soon, please refresh in a few minutes.";
			default:
				break;
		}
	};

	const generateImage = () => {
		switch (audioRowType) {
			case AudioRowType.UserFirstEpisodeLoading:
				return <GiftIcon className="w-[30px] h-[30px] fill-primary" />
			default:
				return <WaveIcon className="w-[30px] h-[30px] fill-primary" />
		}
	}

	return (
		<>
			<PaywallModal isShow={showPaywall} onClose={() => setShowPaywall(false)} analyticsReference={kAnalyticsConstants.Reference.Paywall.audio} />
			<div className="w-full px-4">
				<div className={`w-full border border-primary-200 rounded-[16px] p-4 flex flex-col items-center justify-center gap-1 ${audioRowType === AudioRowType.UserFirstEpisodeLoading ? "bg-success-green/10" : ""}`}>
					{generateImage()}
					<div className="flex flex-col items-center">
						<div className="font-primary font-medium text-lg text-primary">{generateTitle()}</div>
						<div className="font-primary font-regular text-base text-primary">{generateDescription()}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AudioEmptyCard;
