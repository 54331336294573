import { ReactNode, useEffect, useRef, useState } from "react";
import EmailViewer from "../email-viewer/EmailViewer";
import useMailState from "@hooks/UseMailState";
import { useData } from "@providers/DataContext";
import { useAuth } from "@providers/AuthContext";
import { LayoutGroup } from "framer-motion";
import EmptyStateView from "@components/common/EmptyStateView";
import collageHourglass from "@images/collage_hourglass.png";
import collagePlate from "@images/collage_plate.png";
import collageLink from "@images/collage_link.png";
import collagePen from "@images/collage_pen.png";
import { UnreadState } from "@models/Enums";
import { useLayout } from "@providers/LayoutContext";
import { NLBookmark, NLMail } from "@models/Models";
import { useBookmarks } from "@providers/BookmarkContext";
import BookmarksStarCard from "./bookmark-rows/BookmarksStarCard";
import moment from "moment";
import { getIndividualMail } from "@utils/managers/MailManager";
import BookmarksHeader from "./BookmarksHeader";
import { Tab } from "@headlessui/react";
import BookmarksLinkCard from "./bookmark-rows/BookmarksLinkCard";
import BookmarksHighlightCard from "./bookmark-rows/BookmarksHighlightCard";
import { useMailsDispatch } from "@providers/MailContext";
import "@utils/String+MessageIdType";
import Alert, { AlertState } from "@components/common/Alert";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const Bookmarks = () => {
	const { mailListWidth, isMailListHidden } = useLayout();
	const { activeMail, setActiveMail, setStartingPointMail } = useData();
	const { authUser } = useAuth();
	const mailState = useMailState({ isUnread: UnreadState.off, groupId: undefined, userMailStateId: undefined, isBookmark: true, mails: [], queryResponses: [] });
	const mailDispatch = useMailsDispatch();
	const { bookmarks } = useBookmarks();
	const feedRef = useRef<HTMLDivElement>(null);
	const mailListRef = useRef<HTMLDivElement>(null);
	const bookmarkSegments = ["Bookmarks", "Links", "Highlights"];
	const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});

	useEffect(() => {
		if (feedRef?.current) {
			feedRef.current.scrollTop = 0;
		}
	}, [mailState?.userMailStateId, mailState?.groupId]);

	useEffect(() => {
		if (mailListRef.current && activeMail) {
			const activeMailNode = mailListRef.current.querySelector(`#mail-${activeMail.id.safeId()}`);
			if (activeMailNode && !isElementInViewport(activeMailNode)) {
				activeMailNode.scrollIntoView({ behavior: "smooth", block: "center" });
			}
		}
	}, [activeMail]);

	const isElementInViewport = (el: Element) => {
		const rect = el.getBoundingClientRect();
		return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
	};

	const bookmarkOnClick = (bookmark: NLBookmark) => {
		if (!mailState || !bookmark.mail_id) return;
		const mail = mailState.mails.find((x) => x.id === bookmark.mail_id);

		if (!mail) {
			getIndividualMail(authUser!.mailbox_profiles, bookmark.mail_id, (mail, error) => {
				if (error) {
					setAlertState({ isShow: true, title: "Newsletter not found", message: "It looks like the bookmarked newsletter has been deleted.", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
					return;
				}
				if (mail) {
					var existingMailState = { ...mailState };
					existingMailState.mails = [...existingMailState.mails, mail];
					mailDispatch({ type: "SET_LOAD_MORE", payload: existingMailState });
					activateMail(mail);
				}
			});
			return;
		}

		activateMail(mail);
	};

	const activateMail = (mail: NLMail) => {
		if (activeMail && mail.id === activeMail.id) {
			setActiveMail(undefined);
			return;
		}
		setStartingPointMail(mail);
		setActiveMail(mail);
	};

	const renderRelevantCard = (bookmark: NLBookmark): ReactNode => {
		if (bookmark.bookmark_star !== undefined) {
			return (
				<>
					<BookmarksStarCard bookmark={bookmark} mail={mailState?.mails.find((x) => x.id === bookmark.mail_id)} isActive={bookmark.mail_id === activeMail?.id} onClick={() => bookmarkOnClick(bookmark)} />
				</>
			);
		}

		if (bookmark.bookmark_link !== undefined) {
			return (
				<>
					<BookmarksLinkCard bookmark={bookmark} mail={mailState?.mails.find((x) => x.id === bookmark.mail_id)} isActive={bookmark.mail_id === activeMail?.id} onClick={() => bookmarkOnClick(bookmark)} />
				</>
			);
		}

		if (bookmark.bookmark_highlight !== undefined) {
			return (
				<>
					<BookmarksHighlightCard bookmark={bookmark} mail={mailState?.mails.find((x) => x.id === bookmark.mail_id)} isActive={bookmark.mail_id === activeMail?.id} onClick={() => bookmarkOnClick(bookmark)} />
				</>
			);
		}
	};

	const renderBookmarks = (): ReactNode => {
		if (!bookmarks) {
			return <EmptyStateView title="Fetching your bookmarks..." description="They will appear here when ready" alertImage={collageHourglass} />;
		}

		if (selectedSegmentIndex === 0 && bookmarks.filter((bookmark) => bookmark.bookmark_star !== undefined).length === 0) {
			return <EmptyStateView title="Nothing to see here..." description="There are no saved newsletters" alertImage={collagePlate} />;
		}

		if (selectedSegmentIndex === 1 && bookmarks.filter((bookmark) => bookmark.bookmark_link !== undefined).length === 0) {
			return <EmptyStateView title="Nothing to see here..." description="There are no saved links" alertImage={collageLink} />;
		}

		if (selectedSegmentIndex === 2 && bookmarks.filter((bookmark) => bookmark.bookmark_highlight !== undefined).length === 0) {
			return <EmptyStateView title="Nothing to see here..." description="There are no saved highlights" alertImage={collagePen} />;
		}

		return (
			<div className="flex flex-col p-3 gap-3" ref={mailListRef}>
				<LayoutGroup>
					{selectedSegmentIndex !== 0 && <div className="text-primary-500 font-regular font-primary text-xs text-center">Saving new links & highlights currently only available on iOS. These features will come to the web very soon.</div>}
					{bookmarks &&
						bookmarks
							.sort((a, b) => +moment(b.created_at) - +moment(a.created_at))
							.filter((bookmark) => {
								if (selectedSegmentIndex === 0) {
									return bookmark.bookmark_star !== undefined;
								} else if (selectedSegmentIndex === 1) {
									return bookmark.bookmark_link !== undefined;
								} else {
									return bookmark.bookmark_highlight !== undefined;
								}
							})
							.map((bookmark, i) => {
								return <div key={bookmark.id}>{renderRelevantCard(bookmark)}</div>;
							})}
				</LayoutGroup>
			</div>
		);
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<div className="flex flex-row w-full items-stretch">
				<div className={`${isMailListHidden ? "hidden" : "flex"} h-screen border-r border-primary-100 z-20 transition-[width] ease-in-out duration-300 overflow-scroll flex flex-col pb-4`} style={{ width: mailListWidth + "px", minWidth: mailListWidth + "px" }} ref={feedRef} id="root-container">
					<BookmarksHeader />
					<div className="p-3 pb-0">
						<Tab.Group onChange={(index) => setSelectedSegmentIndex(index)}>
							<Tab.List className="flex space-x-1 rounded-xl bg-surface-200 p-2 border border-primary-100">
								{bookmarkSegments.map((category, index) => (
									<Tab key={category} className={`w-full font-primary text-sm font-medium rounded-lg py-2.5 leading-5", "ring-transparent ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-2" ${selectedSegmentIndex == index ? "bg-success-green text-white shadow" : "text-primary hover:bg-primary-100 hover:text-primary"}`}>
										{category}
									</Tab>
								))}
							</Tab.List>
						</Tab.Group>
					</div>
					{renderBookmarks()}
				</div>
				<EmailViewer mailState={mailState} reference={kAnalyticsConstants.Reference.bookmarks} />
			</div>
		</>
	);
};

export default Bookmarks;
