import { NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";

export class LabelHealthService {
	private mailboxProfile: NLMailboxProfile;

	constructor(mailboxProfile: NLMailboxProfile) {
		this.mailboxProfile = mailboxProfile;
	}

	checkLabelHealth(completion: (error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();
		let request: LabelHealthService.RequestModel | undefined = undefined;

		const promise = new Promise<void>((innerResolve, innerReject) => {
			if (this.mailboxProfile.type !== NLMailboxProfileType.Internal) {
				oauthSessionManager
					.fetchAccessTokenForMailboxProfile(this.mailboxProfile.id, this.mailboxProfile.type)
					.then((accessToken) => {
						request = new LabelHealthService.RequestModel(this.mailboxProfile.id, accessToken);
						innerResolve();
					})
					.catch((error) => {
						innerReject(error);
						return;
					});
			} else {
				innerReject();
			}
		});

		Promise.all([promise])
			.then(() => {
				if (!request) {
					completion(undefined);
					return;
				}
				this.makeRequests(request, completion);
			})
			.catch((error) => {
				completion(error);
			});
	}

	private makeRequests(request: LabelHealthService.RequestModel, completion: (error: Error | undefined) => void) {
		networkManager
			.checkLabelHealth(request)
			.then((_jsonData) => {
				completion(undefined);
			})
			.catch((error) => {
				completion(error);
			});
	}
}

export namespace LabelHealthService {
	export class RequestModel {
		access_token: string;
		mailbox_profile_id!: string;

		constructor(mailboxProfileId: string, accessToken: string) {
			this.mailbox_profile_id = mailboxProfileId;
			this.access_token = accessToken;
		}
	}
}
