import SectionLayout from "../../../layouts/SectionLayout";

const GiveFeedback = () => {
	return (
		<SectionLayout id="feedback" sectionTitle="Give Feedback">
			<div className="flex flex-row gap-4 flex-wrap">
				<div className="font-primary font-regular text-base text-primary">
					Reach us at{" "}
					<a href="mailto:team@meco.app" className="text-success-green font-medium">
						team@meco.app
					</a>{" "}
					or{" "}
					<a href="https://www.x.com/meco_app" target="_blank" className="text-success-green font-medium">
						DM us on X
					</a>
					. Our team will get back to you as soon as possible.
				</div>
			</div>
		</SectionLayout>
	);
};

export default GiveFeedback;
