import { kErrorConstants } from "@utils/constants/ErrorConstants";
import { NLMail, NLMailboxProfile, SyncMessagesProfile } from "@models/Models";
import { GetMessagesService } from "./mailboxFunctions/GetMessagesService";
import { PartialSyncMessagesService } from "./mailboxFunctions/PartialSyncMessagesService";
import { SyncMessagesService } from "./mailboxFunctions/SyncMessagesService";

export const syncMessages = (mailboxProfiles: NLMailboxProfile[], baseMails: NLMail[] | undefined, historyIdDictionary: Record<string, string> | undefined, cutOffMail: NLMail | undefined, completion: (mails: NLMail[] | undefined, historyIdDictionary: Record<string, string> | undefined, error: Error | undefined) => void) => {
	var syncMessagesProfile = new SyncMessagesProfile(mailboxProfiles, baseMails, historyIdDictionary);

	let partialSyncMailboxProfileIds = Object.keys(syncMessagesProfile.historyIds).map((key) => key);
	let fullSyncProfiles = mailboxProfiles.filter((x) => !partialSyncMailboxProfileIds.includes(x.id));
	syncMessagesProfile.fullSyncProfiles = fullSyncProfiles;

	partialSyncMessages(syncMessagesProfile)
		.then((updatedSyncMessagesProfile) => {
			return processPartialSyncResponses(updatedSyncMessagesProfile);
		})
		.then((updatedSyncMessagesProfile) => {
            return fullSyncMessages(updatedSyncMessagesProfile);
        })
        .then((updatedSyncMessagesProfile) => {
            return generateSyncMessagesResponse(updatedSyncMessagesProfile, cutOffMail);
        })
        .then(([processedMessages, historyIds]) => {
            completion(processedMessages, historyIds, undefined);
        }).catch((error) => {
			completion(undefined, undefined, error);
		});
};

const partialSyncMessages = (syncMessagesProfile: SyncMessagesProfile): Promise<SyncMessagesProfile> => {
	return new Promise((resolve, reject) => {
		if (Object.keys(syncMessagesProfile.historyIds).length == 0) {
			resolve(syncMessagesProfile);
			return;
		}

		var partialSyncMessagesRequests: PartialSyncMessagesService.QueryBuilder[] = [];

		for (let mailboxProfileId in syncMessagesProfile.historyIds) {
			const mailboxProfile = syncMessagesProfile.allMailboxProfiles.find((x) => x.id === mailboxProfileId);
			if (mailboxProfile) {
				const partialSyncMessagesRequest = new PartialSyncMessagesService.QueryBuilder(mailboxProfile, syncMessagesProfile.historyIds[mailboxProfileId]);
				partialSyncMessagesRequests.push(partialSyncMessagesRequest);
			}
		}

		if (partialSyncMessagesRequests.length === 0) {
			reject(kErrorConstants.dataProcessingError);
			return;
		}

		var updatedSyncMessagesProfile = syncMessagesProfile;

		const partialSyncService = new PartialSyncMessagesService(partialSyncMessagesRequests);
		partialSyncService.partialSyncMessages((responseArray, error) => {
			if (error) {
				reject(error);
				return;
			}

			if (!responseArray) {
				reject(kErrorConstants.commonBackendError);
				return;
			}

			updatedSyncMessagesProfile.partialSyncMessageResponses = responseArray;
			resolve(updatedSyncMessagesProfile);
		});
	});
};

const processPartialSyncResponses = (syncMessagesProfile: SyncMessagesProfile): Promise<SyncMessagesProfile> => {
	return new Promise((resolve, reject) => {
		var updatedSyncMessagesProfile = syncMessagesProfile;

		//We couldn't partial sync or there is no partial sync
		if (!updatedSyncMessagesProfile.partialSyncMessageResponses) {
			for (let item in updatedSyncMessagesProfile.historyIds) {
				let mailboxProfile = syncMessagesProfile.allMailboxProfiles.find((x) => x.id === item);
				if (mailboxProfile) {
					updatedSyncMessagesProfile.fullSyncProfiles.push(mailboxProfile);
				}
			}
			resolve(updatedSyncMessagesProfile);
			return;
		}

		var getMessagesRequests: GetMessagesService.QueryBuilder[] = [];

		for (let responseArray of updatedSyncMessagesProfile.partialSyncMessageResponses) {
			let mailboxProfile = updatedSyncMessagesProfile.allMailboxProfiles.find((x) => x.id === responseArray.mailboxProfileId);

			if (responseArray.error) {
				//If we spot an unknown history id, we do a full sync for that mailbox - removing history id from dict
				if (responseArray.error === kErrorConstants.mailboxProfiles.unknownHistoryId && mailboxProfile) {
					updatedSyncMessagesProfile.fullSyncProfiles.push(mailboxProfile);
				}
				continue;
			}

			if (responseArray.messages_deleted && responseArray.messages_deleted.length > 0) {
				let deletedMessages = responseArray.messages_deleted;
				updatedSyncMessagesProfile.partialSyncDeletedMessageIds.push(...deletedMessages);
			}

			if (responseArray.history_id && mailboxProfile) {
				updatedSyncMessagesProfile.historyIds[mailboxProfile.id] = responseArray.history_id;
			}

			if (responseArray.messages_added && responseArray.messages_added.length > 0 && mailboxProfile) {
				const getMessagesRequest = new GetMessagesService.QueryBuilder(mailboxProfile, responseArray.messages_added);
				getMessagesRequests.push(getMessagesRequest);
			}
		}

		if (getMessagesRequests.length === 0) {
			resolve(updatedSyncMessagesProfile);
			return;
		}

		const getMessagesService = new GetMessagesService(getMessagesRequests);
		getMessagesService.getMessages((getMessagesResponses, error) => {
			if (error) {
				reject(error);
				return;
			}

			if (!getMessagesResponses) {
				reject(kErrorConstants.commonBackendError);
				return;
			}

			var messages: NLMail[] | undefined = undefined;

			for (let messageResponse of getMessagesResponses) {
				if (!messageResponse.messages) {
					continue;
				}

				messages = messages ?? [];
				messages.push(...messageResponse.messages);
			}

			updatedSyncMessagesProfile.partialSyncAddedMessages = messages;
			resolve(updatedSyncMessagesProfile);
		});
	});
};

const fullSyncMessages = (syncMessagesProfile: SyncMessagesProfile): Promise<SyncMessagesProfile> => {
	return new Promise((resolve, reject) => {
		if (syncMessagesProfile.fullSyncProfiles.length === 0) {
			resolve(syncMessagesProfile);
			return;
		}

		var syncMessagesRequests: SyncMessagesService.QueryBuilder[] = [];

		for (let mailboxProfile of syncMessagesProfile.fullSyncProfiles) {
			const syncMessagesRequest = new SyncMessagesService.QueryBuilder(mailboxProfile);
			syncMessagesRequests.push(syncMessagesRequest);
		}

		if (syncMessagesRequests.length === 0) {
			resolve(syncMessagesProfile);
			return;
		}

		var updatedSyncMessagesProfile = syncMessagesProfile;

        var syncMessagesService = new SyncMessagesService(syncMessagesRequests);

        syncMessagesService.fullSyncMessages((responseArray, error) => {

			if (error) {
				reject(error);
				return;
			}

			if (!responseArray) {
				reject(kErrorConstants.commonBackendError);
				return;
			}

            var messages: NLMail[] | undefined = undefined;

            for (let response of responseArray) {

                if (error) {
                    updatedSyncMessagesProfile.fullSyncProfiles = updatedSyncMessagesProfile.fullSyncProfiles.filter((x) => x.id !== response.mailboxProfileId);
                    continue;
                }

                if (!response.message_list) {
                    updatedSyncMessagesProfile.fullSyncProfiles = updatedSyncMessagesProfile.fullSyncProfiles.filter((x) => x.id !== response.mailboxProfileId);
                    continue;
                }

                let mailboxProfile = updatedSyncMessagesProfile.allMailboxProfiles.find((x) => x.id == response.mailboxProfileId)
				messages = messages ?? [];
				messages.push(...response.message_list);

                if (response.history_id && mailboxProfile) {
                    updatedSyncMessagesProfile.historyIds[mailboxProfile.id] = response.history_id;
                }

            }

            updatedSyncMessagesProfile.fullSyncMails = messages;
            resolve(updatedSyncMessagesProfile);

        })

	});
};

const generateSyncMessagesResponse = (syncMessagesProfile: SyncMessagesProfile, cutOffMail: NLMail | undefined): Promise<[NLMail[] | undefined, Record<string, string>]> => {

    return new Promise((resolve, _reject) => {
        
        var processedMails: NLMail[] = [];
        var addedMailIds: string[] = [];

		if (syncMessagesProfile.baseMails.length > 0) {

			var newMails: NLMail[] = [];

			const fullSyncedMailboxIds = syncMessagesProfile.fullSyncProfiles.map((x) => x.id);
			const deletedMailIdArray = syncMessagesProfile.partialSyncDeletedMessageIds;

			if (syncMessagesProfile.partialSyncAddedMessages) {
				addedMailIds = syncMessagesProfile.partialSyncAddedMessages.map((x) => x.id);
			}

			for (let cachedMail of syncMessagesProfile.baseMails) {

				var isAddMail = true
                    
				//Don't add this email if the mailbox profile was fully synced
				if (fullSyncedMailboxIds.includes(cachedMail.mailbox_profile_id)) {
					isAddMail = false
				}
				
				//Don't add this email if it's been deleted
				if (deletedMailIdArray.includes(cachedMail.id)) {
					isAddMail = false
				}
				
				//Don't add this email if it's added/modified
				if (addedMailIds.includes(cachedMail.id)) {
					isAddMail = false
				}
				
				if (isAddMail) {
					processedMails.push(cachedMail);
				}

			}

		}

		//Add all emails that we fetched with full sync
        if (syncMessagesProfile.fullSyncMails) {
            processedMails.push(...syncMessagesProfile.fullSyncMails);
        }

		//Add all emails that's added with partial sync only if earlier than cut off date
        if (syncMessagesProfile.partialSyncAddedMessages) {
            
			const cutOffMailDate = cutOffMail?.receive_date;

			for (let addedMail of syncMessagesProfile.partialSyncAddedMessages) {
				if (cutOffMailDate && addedMail.receive_date > cutOffMailDate) {
					processedMails.push(addedMail);
				}
			}

        }

        resolve([processedMails, syncMessagesProfile.historyIds]);

    })

}
