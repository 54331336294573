import { NLMail, NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";

export class SyncMessagesService {
	private queries: SyncMessagesService.QueryBuilder[];

	constructor(queries: SyncMessagesService.QueryBuilder[]) {
		this.queries = queries;
	}

	fullSyncMessages(completion: (responseModel: SyncMessagesService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();

		let requestArray: SyncMessagesService.RequestModel[] = [];
		let responseArray: SyncMessagesService.ResponseModel[] = [];
		const promises: Promise<void>[] = [];

		for (const requestModel of this.queries) {
			const promise = new Promise<void>((innerResolve, innerReject) => {
				if (requestModel.mailboxProfile.type !== NLMailboxProfileType.Internal) {
					oauthSessionManager
						.fetchAccessTokenForMailboxProfile(requestModel.mailboxProfile.id, requestModel.mailboxProfile.type)
						.then((accessToken) => {
							const request = new SyncMessagesService.RequestModel(requestModel.mailboxProfile.id, accessToken);
							requestArray.push(request);
							innerResolve();
						})
						.catch((error) => {
							if (error instanceof Error && error.message === "noInternetError") {
								innerReject(error);
							} else {
								var responseModel = new SyncMessagesService.ResponseModel(requestModel.mailboxProfile.id);
								responseModel.error = oauthSessionManager.generateSignInRequiredError(requestModel.mailboxProfile.id, error);
								responseArray.push(responseModel);
								innerResolve();
							}
						});
				} else {
					const request = new SyncMessagesService.RequestModel(requestModel.mailboxProfile.id, null);
					requestArray.push(request);
					innerResolve();
				}
			});
			promises.push(promise);
		}

		Promise.all(promises)
			.then(() => {
				if (requestArray.length === 0) {
					completion(responseArray, undefined);
					return;
				}

				this.makeRequests(requestArray, responseArray, completion);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}

	private makeRequests(requestArray: SyncMessagesService.RequestModel[], responseArray: SyncMessagesService.ResponseModel[], completion: (responseArray: SyncMessagesService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		networkManager
			.syncMessages(requestArray)
			.then((jsonData) => {
				var responseModels = responseArray;

				for (let mailboxProfileId in jsonData) {
					let responseData = jsonData[mailboxProfileId];
					var responseModel = new SyncMessagesService.ResponseModel(mailboxProfileId);

					if (Array.isArray(responseData["message_list"])) {
						let mailsList = responseData["message_list"].map((mail: NLMail) => new NLMail(mail));
						responseModel.message_list = mailsList;
					}

					if (responseData["history_id"]) {
						responseModel.history_id = responseData["history_id"];
					}

					if (responseData["error"]) {
						responseModel.error = MailboxError.returnError(responseData["error"], mailboxProfileId);
					}

					responseModels.push(responseModel);
				}

				completion(responseModels, undefined);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}
}

export namespace SyncMessagesService {
	export class RequestModel {
		access_token: string | null;
		mailbox_profile_id!: string;

		constructor(mailboxProfileId: string, accessToken: string | null) {
			this.mailbox_profile_id = mailboxProfileId;
			this.access_token = accessToken;
		}
	}

	export class ResponseModel {
		mailboxProfileId!: string;
		message_list?: NLMail[] | null;
		history_id?: string | null;
		error?: Error | null;

		constructor(mailboxProfileId: string) {
			this.mailboxProfileId = mailboxProfileId;
		}
	}

	export class QueryBuilder {
		mailboxProfile: NLMailboxProfile;

		constructor(mailboxProfile: NLMailboxProfile) {
			this.mailboxProfile = mailboxProfile;
		}
	}
}
