import { NLMail, NLUserMailState } from "@models/Models";
import { MailState, QueryResponse, useMailsDispatch, useMailsState } from "@providers/MailContext";
import { useData } from "@providers/DataContext";
import { TimeState, UnreadState } from "@models/Enums";
import moment from "moment";

const useMailState = (criteria: MailState): MailState | undefined => {
	const { mailStates, baseMails } = useMailsState();
	const { userSenderGroups, userMailStates, activeTimeFilter } = useData();

	const compareMailStates = (state1: MailState, state2: MailState): boolean => {
		return state1.groupId === state2.groupId && state1.isUnread === state2.isUnread && state1.userMailStateId === state2.userMailStateId && state1.isBookmark === state2.isBookmark;
	};

	const generateCutOffMail = (mergedMails: NLMail[], baseMails: NLMail[], queryResponses: QueryResponse[]): NLMail | undefined => {
		var mails = mergedMails;

		if (mails.length === 0) {
			mails = baseMails;
		}

		const groupByMailboxId: { [mailboxProfileId: string]: NLMail[] } = {};
		mails.forEach((mail) => {
			if (!groupByMailboxId[mail.mailbox_profile_id]) {
				groupByMailboxId[mail.mailbox_profile_id] = [];
			}
			groupByMailboxId[mail.mailbox_profile_id].push(mail);
		});

		var oldestMailsArray: NLMail[] | undefined = undefined;
		for (let mailboxProfileId in groupByMailboxId) {
			var tempMails = groupByMailboxId[mailboxProfileId];
			if (tempMails.length > 0) {
				tempMails = tempMails.sort((a, b) => +a.receive_date - +b.receive_date);
				const oldestMail = tempMails[0];
				oldestMailsArray = oldestMailsArray ?? [];
				oldestMailsArray.push(oldestMail);
			}
		}

		if (!oldestMailsArray) {
			return undefined;
		}

		const cachedCutOffs = queryResponses.map((x) => x.cut_off_mail_id);

		var oldestMailsMinusCached: NLMail[] = [];

		for (let oldestReceiveDateMail of oldestMailsArray) {
			if (cachedCutOffs.includes(oldestReceiveDateMail.id)) {
				continue;
			}
			oldestMailsMinusCached.push(oldestReceiveDateMail);
		}

		const newestOfOldest = oldestMailsMinusCached.sort((a, b) => +b.receive_date - +a.receive_date);
		return newestOfOldest[0];
	};

	const applyTimeFilter = (messages: NLMail[], activeTimeFilter: TimeState): NLMail[] => {
		const currentDay = moment.utc();
		const dateTo = moment.utc().add(1, "day");
		switch (activeTimeFilter) {
			case TimeState.last24hrs:
				const dateFromToday = moment(currentDay).subtract(24, "hours");
				return messages.filter((mail) => {
					const receiveDate = moment.unix(mail.receive_date);
					return receiveDate.isBetween(dateFromToday, currentDay, undefined, "[]");
				});
			case TimeState.last7days:
				const dateFromWeek = moment(currentDay).subtract(7, "days");
				const startOfLastWeek = moment(dateFromWeek).startOf("day");
				return messages.filter((mail) => {
					const receiveDate = moment.unix(mail.receive_date);
					return receiveDate.isBetween(startOfLastWeek, dateTo, undefined, "[]");
				});
			case TimeState.last30days:
				const dateFromMonth = moment(currentDay).subtract(30, "days");
				const startOf30Days = moment(dateFromMonth).startOf("day");
				return messages.filter((mail) => {
					const receiveDate = moment.unix(mail.receive_date);
					return receiveDate.isBetween(startOf30Days, dateTo, undefined, "[]");
				});
			default:
				return messages;
		}
	};

	const filterUniqueById = (objects: NLMail[]): NLMail[] => {
		const uniqueIds: Set<string> = new Set();
		return objects.filter((obj) => {
			if (uniqueIds.has(obj.id)) {
				return false;
			}
			uniqueIds.add(obj.id);
			return true;
		});
	};

	if (!baseMails) {
		return undefined;
	}

	var matchingMailState = mailStates.find((state) => compareMailStates(state, criteria));

	var mergedMails: NLMail[] = [];

	if (matchingMailState) {
		mergedMails = [...matchingMailState.mails, ...baseMails];
	} else {
		mergedMails = baseMails;
	}

	matchingMailState = { ...criteria, queryResponses: matchingMailState?.queryResponses ?? [], mails: mergedMails };

	const unreadStatus = UnreadState.getUnreadBoolean(matchingMailState.isUnread);
	if (unreadStatus) {
		mergedMails = mergedMails.filter((x) => x.is_unread === unreadStatus);
	}

	if (matchingMailState.groupId && userSenderGroups) {
		const senderAddresses = userSenderGroups.filter((x) => x.user_group === matchingMailState?.groupId).map((x) => x.sender_address);
		mergedMails = mergedMails.filter((x) => senderAddresses.includes(x.sender_address!));
	}

	if (matchingMailState.userMailStateId && userMailStates) {
		const userMailState = userMailStates.find((x) => x.id === matchingMailState?.userMailStateId);
		mergedMails = mergedMails.filter((x) => x.sender_address === userMailState?.sender_address);
	}

	if (!matchingMailState.isBookmark) {
		const cutOffMail = generateCutOffMail(mergedMails, baseMails, matchingMailState.queryResponses);
		criteria.cutOffMail = cutOffMail;

		if (cutOffMail) {
			mergedMails = mergedMails.filter((x) => x.receive_date >= cutOffMail.receive_date);
		}

		mergedMails = applyTimeFilter(mergedMails, activeTimeFilter);
	}


	mergedMails = filterUniqueById(mergedMails);

	var mailState = {
		...criteria,
		mails: mergedMails.sort((a, b) => +b.receive_date - +a.receive_date),
	};

	return mailState;
};

export default useMailState;
