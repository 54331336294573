import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Qonversion, { Environment, LogLevel, QonversionConfigBuilder } from "@qonversion/web-sdk";
import mixpanel from "mixpanel-browser";
import Hotjar from "@hotjar/browser";
import { kAppConstants } from "@utils/constants/AppConstants";

if ("serviceWorker" in navigator) {
	navigator.serviceWorker
		.register(`service-worker.js`)
		.then((registration) => {
			console.log("Service Worker registered with scope:", registration.scope);
		})
		.catch((error) => {
			console.log("Service Worker registration failed:", error);
		});
}

const isProd = kAppConstants.appStage === "production";

mixpanel.init(kAppConstants.mixpanelToken, { debug: !isProd });

Sentry.init({
	dsn: "https://5fe256010b3647fab82f15549cff9aa5@o1210212.ingest.sentry.io/6345859",
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 0.5, // Should not use 1.0 in production
	enabled: isProd,
	environment: kAppConstants.appStage,
});

Hotjar.init(kAppConstants.hotjarSiteId, 6, {
	debug: !isProd,
});

const config = new QonversionConfigBuilder(process.env.REACT_APP_QONVERSION_TOKEN)
	.setEnvironment(isProd ? Environment.Production : Environment.Sandbox)
	.setLogLevel(isProd ? LogLevel.Error : LogLevel.Verbose)
	.build();
Qonversion.initialize(config);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
