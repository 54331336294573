export const kLocalStorageKeys = {
	App: {
		bannedSenderAddresses: "bannedSenderAddresses",
		newSenderLastView: "newSenderLastView",
		readerMode: "reader_mode",
	},
	AppSettings: {
		theme: "theme",
		activeWallpaper: "active_wallpaper",
	},
	Session: {
		utmCampaign: "utm_campaign",
		nextPath: "next_path",
		onboardingProfile: "onboarding_profile",
		audioOnboardingProfile: "audio_onboarding_profile",
		isFirstEpisodeEligible: "is_first_episode_eligible",
	},
	DigestFilters: {
		timeFilter: "time_filter",
		unreadFilter: "unread_filter",
		groupFilter: "group_filter",
	},
	ShowOnce: {
		notificationsReminderShown: "notificationsReminderShown",
		audioShareDisclaimer: "audio_share_disclaimer"
	},
	Audio: {
		playbackSpeed: "playback_speed"
	},
};
