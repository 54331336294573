export const findHeroImage = (htmlContent: string): string | undefined => {
	type ImageInfo = {
		src: string;
		width: number;
		height: number;
	};

	const tempDiv = document.createElement("div");
	tempDiv.innerHTML = htmlContent;
	const images = tempDiv.querySelectorAll("img");
	let largestImage: ImageInfo | undefined;
	let imageScrapeWidthThreshold = 100;
	let imageScrapeHeightThreshold = 100;
	let fineWidthThreshold = 500;
	let fineHeightThreshold = 500;
	for (let img of images) {
		const src = img.getAttribute("src") || "";
		var width = img.width;
		var height = img.height;
		if (width && !height) {
			height = width;
		}
		if (height && !width) {
			width = height;
		}
		// If no hero image is found yet, or if this image is larger than the current largest image
		if ((!largestImage || width * height > largestImage.width * largestImage.height) && width * height > imageScrapeWidthThreshold * imageScrapeHeightThreshold) {
			largestImage = {
				src,
				width,
				height,
			};
			if (width * height > fineWidthThreshold * fineHeightThreshold) {
				break;
			}
		}
	}
	// Clean up
	tempDiv.remove();
	return largestImage?.src;
};

export const findUnsubscribeLink = (htmlContent: string): string | undefined => {
	// Create a temporary div element to parse HTML content
	const tempDiv = document.createElement("div");
	tempDiv.innerHTML = htmlContent;

	// Search for anchor elements containing specific keywords
	const anchors = tempDiv.querySelectorAll("a");
	let subscriptionLink: string | undefined;

	// List of keywords to search for
	const keywords = ["unsubscribe", "subscription", "opt"];

	// Loop through each anchor element
	for (let anchor of anchors) {
		const href = anchor.getAttribute("href") || "";
		const text = anchor.textContent?.toLowerCase() || "";

		// Check if the anchor text contains any of the keywords
		if (keywords.some((keyword) => text.includes(keyword))) {
			subscriptionLink = href;
			break; // Stop searching after finding the first matching link
		}
	}

	// Clean up
	tempDiv.remove();

	return subscriptionLink;
};
