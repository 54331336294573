import { FC, MouseEventHandler } from "react";
import { ReactComponent as UnreadIcon } from "@images/unread_icon.svg";
import { ReactComponent as UnreadDisabledIcon } from "@images/unread_disabled_icon.svg";
import IconButton from "./IconButton";

export interface CheckMarkState {
	state: "disabled" | "read" | "unread"
    onClick: MouseEventHandler<HTMLButtonElement>;
}

const EmailCheckMark: FC<CheckMarkState> = ({ state, onClick }) => {
    switch (state) {
        case "unread":
            return (
                <IconButton forcedIconSize={23} Icon={UnreadIcon} onClick={onClick} />
            );
        case "read":
            return (
                <IconButton forcedIconSize={23} Icon={UnreadIcon} className="bg-success-green" iconClassName="fill-white group-hover:fill-primary" onClick={onClick} />
            );
        case "disabled":
            return (
                <IconButton forcedIconSize={23} Icon={UnreadDisabledIcon} onClick={onClick} />
            );       
    }
};

export default EmailCheckMark;
