import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ParticlesBackground from "@components/common/ParticlesBackground";
import { ReactComponent as CheckMark } from "@images/check_mark_selected.svg";
import { ReactComponent as CautionIcon } from "@images/caution_icon.svg";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { kStringConstants } from "@utils/constants/StringConstants";
import { useOnboardingProfile } from "@providers/OnboardingContext";
import { kAppConfig } from "@utils/configurations/AppConfig";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { NLMailboxProfile, NLMailboxProfileType, NLProfile, OnboardingProfile } from "@models/Models";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import { useAuth } from "@providers/AuthContext";
import { generateToast } from "@utils/managers/ToastManager";

export enum VerifyMecoStatusCode {
	Available = 200,
	Taken = 409,
	NotAccepted = 422,
}

const MecoInboxIntegration = () => {
	const { setOnboardingProfile } = useOnboardingProfile();
	const { setAuthUser } = useAuth();
	const location = useLocation();
	const isAccount: boolean = location.state?.isAccount ?? false;
	const [fullName, setFullName] = useState<string | null>(null);
	const [username, setUsername] = useState<string | null>(null);
	const [isValidName, setValidName] = useState(false);
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});
	const mecoInboxDomain = kAppConfig.mecoInboxDomain;
	const minimumUsernameCharacter = 3;
	const navigate = useNavigate();

	enum VerificationStatus {
		checking,
		available,
		taken,
		notAccepted,
		idle,
		error,
	}

	const [verifyStatusCode, setVerifyStatusCode] = useState<VerificationStatus | null>(VerificationStatus.idle);

	useEffect(() => {
		analyticsManager.recordEvent("MCI - Presented", { is_onboarding: !isAccount });
	}, []);

	const continueButtonOnClick = (e) => {
		e.preventDefault();

		if (!isFormValid) {
			return;
		}

		setLoadingState({ isLoading: true, message: "Creating your Meco email" });

		var title = kStringConstants.Common.errorAlertTitle;
		var message = kStringConstants.Common.errorAlertMessage;

		networkManager
			.createMecoMailboxProfile(username!, fullName)
			.then((profile) => {
				const emailAddress = username + kAppConfig.mecoInboxDomain;
				const createdMailboxProfile = profile.mailbox_profiles.filter((x) => x.email_address == emailAddress)[0];
				if (!createdMailboxProfile) {
					generateToast({ status: "error", title: title, message: message });
					return;
				}

				analyticsManager.recordEvent("MCI - Mailbox Created", { is_onboarding: !isAccount, username: username });
				setAuthUser(profile);

				if (isAccount) {
					return navigateToSettings();
				}

				return updateUserProfile(profile, createdMailboxProfile);
			})
			.catch((error) => {
				if (error.statusCode === (VerifyMecoStatusCode.NotAccepted || VerifyMecoStatusCode.Taken)) {
					title = "Username not available";
					message = "Please pick another username to proceed";
				}
				analyticsManager.recordEvent("MCI - Mailbox Creation Error", { is_onboarding: !isAccount, error: error.message ?? "" });
				generateToast({ status: "error", title: title, message: message });
			})
			.finally(() => {
				setLoadingState({ isLoading: false });
			});
	};

	const updateUserProfile = (profile: NLProfile, mailboxProfile: NLMailboxProfile): Promise<void> => {
		return new Promise((resolve, _reject) => {
			networkManager
				.updateUserProfile({ is_profile_setup: true })
				.then((updatedProfile) => {
					setAuthUser(profile);
				})
				.finally(() => {
					var onboardingProfile = new OnboardingProfile(mailboxProfile);
					setOnboardingProfile(onboardingProfile);
					navigate("/get-started/interests", { replace: true });
				});
		});
	};

	const navigateToSettings = (): Promise<void> => {
		return new Promise((resolve, _reject) => {
			navigate("/settings", { replace: true, state: { mailboxIntegrated: NLMailboxProfileType.Internal } });
			resolve();
		});
	};

	const nameInputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
		const nameValue: string = e.currentTarget.value;
		setFullName(nameValue);
	};

	const usernameInputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
		const usernameValue: string = e.currentTarget.value;
		const sanitisedUsername = convertToValidUsername(usernameValue, 64);
		setUsername(sanitisedUsername);
	};

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if ((username?.length ?? 0) < minimumUsernameCharacter) {
				setVerifyStatusCode(VerificationStatus.idle);
				return;
			}
			validateUsername(username);
		}, 750);
		return () => clearTimeout(delayDebounceFn);
	}, [username]);

	useEffect(() => {
		setValidName(validateName(fullName));
	}, [fullName]);

	const validateUsername = (username) => {
		setVerifyStatusCode(VerificationStatus.checking);
		networkManager
			.verifyMecoMailboxUsername(username)
			.then((statusCode) => {
				switch (statusCode) {
					case VerifyMecoStatusCode.Available:
						setVerifyStatusCode(VerificationStatus.available);
						break;
					case VerifyMecoStatusCode.Taken:
						setVerifyStatusCode(VerificationStatus.taken);
						break;
					case VerifyMecoStatusCode.NotAccepted:
						setVerifyStatusCode(VerificationStatus.notAccepted);
						break;
					default:
						setVerifyStatusCode(VerificationStatus.error);
						break;
				}
			})
			.catch((_error) => {
				setVerifyStatusCode(VerificationStatus.error);
			});
	};

	const validateName = (input: string | null | undefined): boolean => {
		if (input === null || input === undefined) {
			return false;
		}
		const regex = /^\s*$/;
		return !regex.test(input);
	};

	const convertToValidUsername = (input: string, characterLimit: number): string => {
		const usernameRegex = /^[a-zA-Z0-9._-]+$/;
		const isMatching = usernameRegex.test(input);

		if (input.length <= characterLimit && isMatching) {
			return input.toLowerCase();
		} else {
			const illegalCharactersRegex = /[^a-zA-Z0-9._-]/g;
			let filteredText = input.replace(illegalCharactersRegex, "");

			if (filteredText.length > characterLimit) {
				filteredText = filteredText.slice(0, characterLimit);
			}

			return filteredText.toLowerCase();
		}
	};

	const renderStatusText = (): React.ReactNode => {
		switch (verifyStatusCode) {
			case VerificationStatus.checking:
				return (
					<div className="flex flex-row gap-2 w-full items-center justify-start">
						<LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white" />
						<div className="text-white/80 font-regular font-primary text-base">Checking availability...</div>
					</div>
				);
			case VerificationStatus.available:
				return (
					<div className="flex flex-row gap-2 w-full items-center justify-start">
						<CheckMark className="fill-success-green w-[20px] h-[20px]" />
						<div className="text-success-green font-regular font-primary text-base">Username available</div>
					</div>
				);
			case VerificationStatus.taken:
			case VerificationStatus.notAccepted:
				return (
					<div className="flex flex-row gap-2 w-full items-center justify-start">
						<CautionIcon className="fill-[#E8B502] w-[16px] h-[16px]" />
						<div className="text-[#E8B502] font-regular font-primary text-sm">Username already taken</div>
					</div>
				);
			case VerificationStatus.error:
				return (
					<div className="flex flex-row gap-2 w-full items-center justify-start">
						<CautionIcon className="fill-[#E8B502] w-[16px] h-[16px]" />
						<div className="text-[#E8B502] font-regular font-primary text-sm">An error occured. Please try again.</div>
					</div>
				);
			case VerificationStatus.idle:
				return null;
		}
	};

	const isFormValid = () => {
		if (loadingState.isLoading) {
			return false;
		}

		if (isAccount) {
			return verifyStatusCode === VerificationStatus.available;
		}

		return verifyStatusCode === VerificationStatus.available && isValidName;
	};

	return (
		<div>
			<LoadingView loadingState={loadingState} />
			<ParticlesBackground />
			<div className="flex min-h-[100dvh] flex-col md:justify-center justify-start overflow-hidden py-2 md:py-12">
				<div className="flex flex-col gap-10 items-center justify-center p-3">
					<div className="meco_container max-w-[1080px] py-[40px] md:p-[60px] md:pb-[40px]">
						<div className="flex flex-col md:flex-row gap-5 items-center">
							<div className="w-full md:w-1/2 flex flex-col gap-3 items-start text-center md:text-left">
								<div className="text-white font-bold font-primary text-4xl leading-10">Your email address just for newsletters</div>
								<div className="text-force-primary-light font-medium font-primary text-xl">Create your dedicated Meco address for newsletters. Subscribe to newsletters with this address to read them in Meco.</div>
							</div>
							<div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-3 max-w-[400px]">
								<form id="mecoInbox" className="flex flex-col mt-4 gap-3 w-full" autoCapitalize="sentences" onSubmit={continueButtonOnClick}>
									{!isAccount && <input type="text" disabled={loadingState.isLoading === true} autoFocus={!isAccount} className={`flex-grow bg-black/30 focus:ring-1 focus:border-white focus:ring-white border h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${loadingState.isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Full Name" onChange={nameInputOnChange} />}
									<div className="relative w-full">
										<input type="text" value={username ?? ""} disabled={loadingState.isLoading === true} autoFocus={isAccount} className={`flex-grow bg-black/30 focus:ring-1 focus:border-white focus:ring-white border h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 w-full pr-[150px] ${loadingState.isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Username" onChange={usernameInputOnChange} />
										<span className="no-select absolute inset-y-0 right-3 pl-3 flex items-center font-regular font-primary font-fs-heading-small text-white">{mecoInboxDomain}</span>
									</div>
								</form>
								<div className="min-h-[30px] w-full">{renderStatusText()}</div>
								<button type="submit" form="mecoInbox" disabled={!isFormValid()} className={`md:hidden flex m-auto cta-button ${isFormValid() ? null : "disabled"} w-full shadow-2xl mt-2`}>
									{isAccount ? "Done" : "Next"}
								</button>
							</div>
						</div>
						<div className="w-full hidden md:block">
							<div className="h-[1px] w-full bg-white/20 mt-5"></div>
							<div className={`flex ${isAccount ? "justify-between" : "justify-end"} items-center`}>
								{isAccount && (
									<Link to="/settings">
										<button className="text-force-primary-light font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-white/20 hover:bg-brand-blue/80 transition ease-in-out whitespace-nowrap mt-3">Back</button>
									</Link>
								)}
								<button type="submit" form="mecoInbox" disabled={!isFormValid()} className={`font-medium font-primary text-lg focus:outline-none rounded-xl p-2 px-3 py-2 items-center ${isFormValid() ? "bg-success-green/80 hover:bg-success-green/100 text-white" : "bg-white/20 text-white/50"} transition ease-in-out whitespace-nowrap mt-3`}>
									{isAccount ? "Done" : "Next"}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MecoInboxIntegration;
