import { NLSearchResult} from "@models/Models";
import { FC, useState } from "react";
import { Textfit } from "react-textfit";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";

interface AddSenderSearchResultRowProps {
	searchResult: NLSearchResult;
    isAdding: boolean
	onSelectedItem: (selectedItem: NLSearchResult) => void;
	onAddClick: (searchResult: NLSearchResult) => void;
}

const AddSenderSearchResultRow: FC<AddSenderSearchResultRowProps> = ({ searchResult, onSelectedItem, isAdding, onAddClick }) => {
	return (
		<div key={searchResult.senderAddress + "_" + searchResult.mailboxProfile.id} className={`flex flex-col bg-surface hover:bg-surface-100 border transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 w-full gap-2 items-start mb-[12px] shadow-md`} onClick={(e) => onSelectedItem(searchResult)}>
			<div className="flex flex-row items-center justify-between w-full gap-3">
				<div className="flex flex-col items-start text-left min-w-0 flex-grow">
					<Textfit mode="single" max={12} className="text-primary font-regular font-primary w-full">
						{searchResult.mailboxProfile.name}
					</Textfit>
					<Textfit mode="single" max={16} className="text-primary font-medium text-base font-primary w-full">
						{searchResult.senderName}
					</Textfit>
					<Textfit mode="single" max={12} className="text-primary-500 font-regular text-xs font-primary w-full">
						{searchResult.senderAddress}
					</Textfit>
				</div>
				<div className="flex-shrink-0">
					<button
						disabled={searchResult.isAdded || isAdding}
						className={`relative font-medium font-primary text-base focus:outline-none rounded-xl px-4 py-1 items-center transition ease-in-out whitespace-nowrap ${searchResult.isAdded ? "opacity-50 bg-primary text-secondary" : "opacity-100 bg-success-green/80 hover:bg-success-green/100 text-white"}`}
						onClick={() => {
							onAddClick(searchResult);
						}}>
						{isAdding && <LoadingSpinner className="absolute top-0 left-0 right-0 bottom-0 m-auto h-[20px] text-primary-200 animate-spin fill-primary" />}
						<span style={{ visibility: isAdding ? "hidden" : "visible" }}>{searchResult.isAdded ? "Added" : "Add"}</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddSenderSearchResultRow;
