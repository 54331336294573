import React, { useState, useEffect, useCallback, FC, ReactNode, useRef, Fragment } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { ReactComponent as SearchIcon } from "@images/search_icon.svg";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { ReactComponent as FilterIcon } from "@images/filter_icon.svg";
import IconButton from "../../common/IconButton";
import { useData } from "@providers/DataContext";
import { NLMailboxProfileType, NLSearchResult as NLSearchResult, NLUserMailState } from "@models/Models";
import { useAuth } from "@providers/AuthContext";
import { searchSenders } from "@utils/managers/MailManager";
import EmptyStateView from "@components/common/EmptyStateView";
import { useHotkeys } from "react-hotkeys-hook";
import collageBinoculars from "@images/collage_binoculars.png";
import AddSenderSearchResultRow from "./AddSenderSearchResultRow";
import Dropdown, { DropdownItem } from "@components/common/Dropdown";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import Alert, { AlertState } from "@components/common/Alert";
import { CreateSenderService } from "@utils/managers/backendMailManager/mailboxFunctions/CreateSenderService";
import { Dialog, Transition } from "@headlessui/react";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

interface AddSenderSearchModalProps {
	isShow: boolean;
	onClose: () => void;
}

const AddSenderSearchModal: FC<AddSenderSearchModalProps> = ({ isShow, onClose }) => {
	const { userMailStates, setUserMailStates, setDigestNeedsReloading } = useData();
	const { authUser } = useAuth();
	const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
	const [searchResults, setSearchResults] = useState<NLSearchResult[] | undefined>(undefined);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isNoResult, setNoResult] = useState<boolean>(false);
	const minimumSearchCharacter = 3;
	const searchBoxRef = useRef<HTMLInputElement>(null);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [senderBeingAdded, setSenderBeingAdded] = useState<string | undefined>(undefined);

	useHotkeys("Escape", () => onModalClose());

	useEffect(() => {
		if (isShow) {
			setTimeout(() => {
				searchBoxRef?.current?.focus();
			}, 100);
			return;
		} else {
			setSearchQuery("");
			setSearchResults(undefined);
			setSenderBeingAdded(undefined);
		}
	}, [isShow]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (!searchQuery || searchQuery.length < minimumSearchCharacter) {
				resetSearch();
				return;
			}

			performSearch(searchQuery);
		}, 750);
		return () => {
			clearTimeout(delayDebounceFn);
			if (!searchQuery) {
				resetSearch();
			}
		};
	}, [searchQuery]);

	const performSearch = (query: string) => {
		setLoading(true);
		const qualifiedMailboxProfiles = authUser!.mailbox_profiles.filter((x) => x.type !== NLMailboxProfileType.Internal);
		if (qualifiedMailboxProfiles.length > 0) {
			searchSenders(qualifiedMailboxProfiles, query, (searchModelArray, error) => {
				setLoading(false);
				setNoResult(!(searchModelArray && searchModelArray.length > 0));
				setSearchResults(searchModelArray);
			});
		} else {
			setLoading(false);
			setNoResult(true);
		}
	};

	const onModalClose = () => {
		onClose();
	};

	const resetSearch = () => {
		setSearchResults(undefined);
		setNoResult(false);
	};

	const renderEmptyView = (): ReactNode => {
		if (searchQuery && isNoResult) {
			return <EmptyStateView title="Oops! No matches found!" description="Try searching the sender address or name" alertImage={collageBinoculars} centerInParent={true} />;
		}

		var mailboxTypeName = "Gmail";
		const gmailMailbox = authUser!.mailbox_profiles.find((x) => x.type === NLMailboxProfileType.Gmail);
		const outlookMailbox = authUser!.mailbox_profiles.find((x) => x.type === NLMailboxProfileType.Outlook);
		
		if (gmailMailbox && outlookMailbox) {
			mailboxTypeName = "Gmail/Outlook";
		} else if (outlookMailbox) {
			mailboxTypeName = "Outlook";
		}

		return <EmptyStateView title={"Search your " +  mailboxTypeName + " inbox for newsletters"} description={"Add newsletters to Meco from your existing senders in " + mailboxTypeName} alertImage={collageBinoculars} centerInParent={true} />;
	};

	const onAddClick = (searchResult: NLSearchResult) => {
		setSenderBeingAdded(searchResult.senderAddress);

		const bannedSenderAddresses = localStorage.getItem(kLocalStorageKeys.App.bannedSenderAddresses);
		if (bannedSenderAddresses && bannedSenderAddresses.includes(searchResult.senderAddress)) {
			setAlertState({ isShow: true, title: "Uh oh! Something went wrong", message: "This sender can't be added to Meco", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
			setSenderBeingAdded(undefined);
			return;
		}

		const createSenderService = new CreateSenderService(searchResult.mailboxProfile, searchResult.senderName, searchResult.senderAddress);

		createSenderService.createSender((newUserMailState, error) => {
			if (!newUserMailState || error) {
				setAlertState({ isShow: true, title: "Uh oh! Something went wrong", message: "Please try again and contact us if the problem persists", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
				setSenderBeingAdded(undefined);
				return;
			}

			recordEvent(kAnalyticsConstants.App.newsletterAdded, {[kAnalyticsConstants.App.NewsletterKeys.newsletterSenderAddress] : newUserMailState.sender_address, [kAnalyticsConstants.App.NewsletterKeys.newsletterSenderName] : newUserMailState.sender_name, [kAnalyticsConstants.App.NewsletterAddedVia.newsletterAddedViaKey] : kAnalyticsConstants.App.NewsletterAddedVia.gmailSearch});

			//Update states
			var tempUserMailStates: NLUserMailState[] = [];
			if (userMailStates && userMailStates.length > 0) {
				for (const userMailState of userMailStates ?? []) {
					if (userMailState.id === newUserMailState.id) {
						tempUserMailStates.push(newUserMailState);
						continue;
					}
					tempUserMailStates.push(userMailState);
				}
			} else {
				tempUserMailStates.push(newUserMailState);
			}
			setUserMailStates(tempUserMailStates);

			//Update results
			var tempSearchResults: NLSearchResult[] = [];
			for (const tempSearchResult of searchResults ?? []) {
				if (searchResult.senderAddress === tempSearchResult.senderAddress && searchResult.mailboxProfile.id === tempSearchResult.mailboxProfile.id) {
					tempSearchResult.isAdded = true;
				}
				tempSearchResults.push(tempSearchResult);
			}

			setDigestNeedsReloading(true);
			setSearchResults(tempSearchResults);
			setSenderBeingAdded(undefined);
		});
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child className="w-full h-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="m-auto w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
								<div className="absolute w-full bg-surface top-0 left-0 flex flex-col p-4 border-b border-primary-200 z-20 gap-3">
									<div className="flex flex-row justify-between items-center">
										<div className="text-primary font-medium font-primary text-xl">Add senders by search</div>
										<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onModalClose()} />
									</div>
									<div className="flex flex-row items-center gap-3">
										<div className="relative w-full">
											<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
												<SearchIcon className="w-[20px] h-[20px] fill-primary" />
											</div>
											<input type="text" value={searchQuery} className={`!ps-12 flex-grow bg-secondary focus:ring-1 focus:border-primary focus:ring-primary border h-[55px] border-primary-200 rounded-[12px] font-regular font-primary placeholder-primary-500 w-full pr-[150px] text-primary`} placeholder="Search senders..." onChange={(e) => setSearchQuery(e.currentTarget.value)} ref={searchBoxRef} />
											{isLoading && <LoadingSpinner className="absolute end-3 bottom-3 m-auto h-[25px] text-primary-200 animate-spin fill-primary" />}
										</div>
										{/* <Dropdown buttonIcon={FilterIcon} buttonOnEdge={true} items={mailboxProfileOptions()} /> */}
									</div>
								</div>
								{searchResults && searchResults.length > 0 ? (
									<div className="flex flex-col gap-4 text-left h-full p-4 pt-[180px] bg-secondary overflow-auto">
										<div>
											{searchResults.map((searchResult) => {
												return <AddSenderSearchResultRow searchResult={searchResult} onSelectedItem={(test) => test} onAddClick={(searchResult) => onAddClick(searchResult)} isAdding={senderBeingAdded === searchResult.senderAddress} />;
											})}
										</div>
									</div>
								) : (
									renderEmptyView()
								)}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default AddSenderSearchModal;
