import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "@providers/AuthContext";
import LoadingView from "@components/common/LoadingView";
import { useOnboardingProfile } from "@providers/OnboardingContext";

const OnboardingRoute = ({...rest}) => {
	const { authUser } = useAuth();
	const { onboardingProfile } = useOnboardingProfile();

	if (authUser === undefined || onboardingProfile === undefined) {
		return <LoadingView loadingState={{ isLoading: true }} />;
	}

	if (!authUser || !onboardingProfile) {
		return <Navigate to="/get-started" />;
	}

	return <Outlet {...rest} />;
};

export default OnboardingRoute;
