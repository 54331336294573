import React, { MouseEventHandler, useState } from "react";
import { NLBookmark, NLMail } from "@models/Models";
import { motion } from "framer-motion";
import * as ElapsedDate from "@utils/Date+ElapsedInterval";
import { ReactComponent as BookmarkOnIcon } from "@images/bookmark_on_icon.svg";
import { ReactComponent as TagIcon } from "@images/tag_icon.svg";
// import { useData } from "@providers/DataContext";

interface BookmarksStarCardProps {
	bookmark: NLBookmark;
	mail: NLMail | undefined;
	isActive: boolean;
	onClick: MouseEventHandler<HTMLDivElement>;
}

const BookmarksStarCard: React.FC<BookmarksStarCardProps> = ({ bookmark, mail, isActive, onClick }) => {
	const [tagsExpanded, setTagsExpanded] = useState<boolean>(false);
	const [noteExpanded, setNoteExpanded] = useState<boolean>(false);

	return (
		<motion.div key={bookmark.id} id={`bookmark-${bookmark.id}`} layout layoutScroll={true} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ ease: "easeInOut", duration: 0.3, type: "spring" }} onClick={onClick}>
			<div className={`w-full flex flex-row bg-surface rounded-xl px-3 py-3 hover:bg-surface-100 transition ease-in-out duration-[250] cursor-pointer shadow-md ${isActive ? "!bg-success-green/30 hover:bg-white/30 scale-[1.01]" : null}`}>
				<div className="flex flex-col gap-3 justify-center items-center w-full">
					<div className="flex flex-row gap-2 justify-between items-center w-full">
						<div className={`flex flex-col gap-2 ${mail?.is_unread ?? false ? "opacity-1" : isActive ? "opacity-1" : "opacity-30"}`}>
							<div className="flex flex-col gap-0">
								<div className="text-xs text-left font-primary font-medium text-primary transition ease-in-out">{bookmark.mail_sender_name}</div>
								<div className="text-base  font-primary font-bold text-primary leading-6">{bookmark.mail_subject}</div>
							</div>
							<div className="flex flex-row items-center gap-1">
								{bookmark && bookmark.bookmark_star && <BookmarkOnIcon className="fill-success-green h-[12px]" />}
								{mail && <div className="text-2xs font-primary font-regular text-primary-500">{ElapsedDate.elapsedInterval(mail.receive_date)}</div>}
							</div>
						</div>
						{mail && mail.image_url && (
							<div className="rounded-lg bg-black/20 w-full max-w-[80px] overflow-hidden">
								<img src={mail.image_url} className="w-full h-full aspect-video object-cover" />
							</div>
						)}
					</div>
					{bookmark.note && (
						<div className="text-sm font-primary font-regular text-primary w-full">
							{noteExpanded ? bookmark.note : bookmark.note.split(" ").slice(0, 20).join(" ")}
							{!noteExpanded && bookmark.note.split(" ").length > 20 && (
								<span
									onClick={(e) => {
										e.stopPropagation();
										setNoteExpanded(true);
									}}
									className="text-primary-500 font-medium cursor-pointer">
									... show more
								</span>
							)}
						</div>
					)}
					{bookmark.tags && bookmark.tags.length > 0 && (
						<div className="w-full flex flex-row gap-2 flex-wrap">
							{(tagsExpanded ? bookmark.tags : bookmark.tags.slice(0, 2)).map((tag, index) => (
								<div key={tag} className="bg-primary-100 rounded-md px-2 py-1 whitespace-nowrap flex flex-row gap-1 items-center">
									<TagIcon className="fill-primary h-[12px]" />
									<div className="text-xs font-primary font-regular text-primary">{tag}</div>
								</div>
							))}
							{!tagsExpanded && bookmark.tags.length > 2 && (
								<div
									onClick={(e) => {
										e.stopPropagation();
										setTagsExpanded(true);
									}}
									className="bg-transpartent border border-primary-200 rounded-md px-2 py-1 whitespace-nowrap flex flex-row gap-1 items-center">
									<div className="text-xs font-primary font-regular text-primary">{bookmark.tags.length - 2} more tags</div>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</motion.div>
	);
};

export default BookmarksStarCard;
