import { kErrorConstants } from "@utils/constants/ErrorConstants";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";

export class MailboxError {
	static returnError(errorString: string, mailboxProfileId: string): any {
		switch (errorString) {
			case "unauthorised_user":
				return OauthSessionManager.sharedInstance().generateSignInRequiredError(mailboxProfileId, kErrorConstants.mailboxProfiles.oauthTokenRefresh.backendIsError);
			case "no_label_id":
				return kErrorConstants.mailboxProfiles.Gmail.labelIdMissing;
			case "unknown_error":
				return kErrorConstants.mailboxProfiles.unknownError;
			case "unknown_history_id":
				return kErrorConstants.mailboxProfiles.unknownHistoryId;
			case "not_pro_user":
				return OauthSessionManager.sharedInstance().generateNotProUserError(mailboxProfileId, kErrorConstants.mailboxProfiles.oauth.notProUser);
			default:
				return kErrorConstants.mailboxProfiles.unknownError;
		}
	}
}
