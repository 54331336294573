import React, { MouseEventHandler } from "react";
import { NLBookmark, NLMail } from "@models/Models";
import { motion } from "framer-motion";
import * as ElapsedDate from "@utils/Date+ElapsedInterval";
import { ReactComponent as BookmarkOnIcon } from "@images/bookmark_on_icon.svg";
import "@utils/String+MessageIdType";
// import { useData } from "@providers/DataContext";

interface MailCardProps {
	mail: NLMail;
	bookmark: NLBookmark | undefined;
	isActive: boolean;
	onClick: MouseEventHandler<HTMLDivElement>;
}

const MailCard: React.FC<MailCardProps> = ({ mail, bookmark, isActive, onClick }) => {
	// const { userMailStates, setActiveUserMailState } = useData();

	// const senderOnClick = () => {
	// 	if (!userMailStates) {
	// 		return;
	// 	}

	// 	const selectedSender = userMailStates.find((x) => x.sender_address === mail.sender_address && x.mailbox_profile_id === mail.mailbox_profile_id);
	// 	if (selectedSender) {
	// 		setActiveUserMailState(selectedSender);
	// 	}
	// };

	return (
		<motion.div key={mail.id} id={`mail-${mail.id.safeId()}`} layout layoutScroll={true} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ ease: "easeInOut", duration: 0.3, type: "spring" }} onClick={onClick}>
			<div className={`w-full flex flex-row bg-surface rounded-xl px-3 py-3 hover:bg-surface-100 transition ease-in-out duration-[250] cursor-pointer shadow-md ${isActive ? "!bg-success-green/30 hover:bg-white/30 scale-[1.01]" : null}`}>
				<div className="flex flex-row gap-2 justify-between items-center w-full">
					<div className={`flex flex-col gap-2 ${mail.is_unread ? "opacity-1" : isActive ? "opacity-1" : "opacity-30"}`}>
						<div className="flex flex-col gap-0">
								<div className="text-xs text-left font-primary font-medium text-primary transition ease-in-out">
									{mail.sender_name}
								</div>
							<div className="text-base  font-primary font-bold text-primary leading-6">{mail.subject}</div>
						</div>
						<div className="flex flex-row items-center gap-1">
							{bookmark && bookmark.bookmark_star && <BookmarkOnIcon className="fill-success-green h-[12px]" />}
							<div className="text-2xs font-primary font-regular text-primary-500">{ElapsedDate.elapsedInterval(mail.receive_date)}</div>
						</div>
					</div>
					{mail.image_url && (
						<div className="rounded-lg bg-black/20 w-full max-w-[80px] overflow-hidden">
							<img src={mail.image_url} className="w-full h-full aspect-video object-cover" />
						</div>
					)}
				</div>
			</div>
		</motion.div>
	);
};

export default MailCard;
