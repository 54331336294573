import React, { createContext, Dispatch, useReducer, useContext, ReactNode } from "react";
import { NLBookmark } from "@models/Models";

type Action = { type: "SET_BOOKMARKS"; payload: { bookmarks: NLBookmark[] | undefined } } | { type: "MODIFY_BOOKMARK"; payload: { updatedBookmark: NLBookmark } } | { type: "ADD_BOOKMARKS"; payload: { bookmarks: NLBookmark[] } };

interface BookmarksState {
	bookmarks: NLBookmark[] | undefined;
	bookmarkTags: string[];
}

// Create context
const BookmarksContext = createContext<BookmarksState | undefined>(undefined);
const BookmarksDispatchContext = createContext<Dispatch<Action> | undefined>(undefined);

// MailsProvider component
const BookmarksProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

	const getUniqueTags = (bookmarks: NLBookmark[] | undefined): string[] => {
		var tempUniqueTags: string[] = [];
		if (!bookmarks) {
			return tempUniqueTags;
		}
		
		for (const bookmark of bookmarks) {
			for (const tag of bookmark.tags) {
				if (!tempUniqueTags.includes(tag)) {
					tempUniqueTags.push(tag);
				}
			}
		}
		return tempUniqueTags;
	}

	const bookmarksReducer = (state: BookmarksState, action: Action): BookmarksState => {
		switch (action.type) {
			case "SET_BOOKMARKS":
				return {
					bookmarks: action.payload.bookmarks,
					bookmarkTags: getUniqueTags(action.payload.bookmarks)
				};
			case "MODIFY_BOOKMARK":
				var newBookmarkState = { ...state };
				let newBookmarks: NLBookmark[] = [];
				for (let bookmark of newBookmarkState.bookmarks ?? []) {
					if (bookmark.id === action.payload.updatedBookmark.id) {
						if (!action.payload.updatedBookmark.is_deleted) {
							newBookmarks.push(action.payload.updatedBookmark);
						}
						continue;
					}
					newBookmarks.push(bookmark);
				}
				newBookmarkState.bookmarks = newBookmarks;
				newBookmarkState.bookmarkTags = getUniqueTags(newBookmarks);
				return newBookmarkState;
			case "ADD_BOOKMARKS":
				var newBookmarkState = { ...state };
				var bookmarkIds = action.payload.bookmarks.map((x) => x.id);
				var existingBookmarks = newBookmarkState.bookmarks ?? [];
				existingBookmarks = existingBookmarks.filter((x) => !bookmarkIds.includes(x.id));
				existingBookmarks.push(...action.payload.bookmarks);
				newBookmarkState.bookmarks = existingBookmarks;
				newBookmarkState.bookmarkTags = getUniqueTags(existingBookmarks);
				return newBookmarkState;
			default:
				return state;
		}
	};

	const [state, dispatch] = useReducer(bookmarksReducer, { bookmarks: undefined, bookmarkTags: [] });

	return (
		<BookmarksContext.Provider value={state}>
			<BookmarksDispatchContext.Provider value={dispatch}>{children}</BookmarksDispatchContext.Provider>
		</BookmarksContext.Provider>
	);
};

// Custom hooks to use the context
const useBookmarks = () => {
	const context = useContext(BookmarksContext);
	if (context === undefined) {
		throw new Error("useBookmarks must be used within a BookmarksProvider");
	}
	return context;
};

const useBookmarksDispatch = () => {
	const context = useContext(BookmarksDispatchContext);
	if (context === undefined) {
		throw new Error("useBookmarks must be used within a BookmarksProvider");
	}
	return context;
};

export { BookmarksProvider, useBookmarks, useBookmarksDispatch };
