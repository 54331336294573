import { useEffect, useState, useRef } from "react";
import Alert, { AlertState } from "@components/common/Alert";
import { NLNewsletterScore, NLRecommendedNewsletters, NLNewsletter, NLNewsletterCategory } from "@models/Models";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import ParticlesBackground from "@components/common/ParticlesBackground";
import { useOnboardingProfile } from "@providers/OnboardingContext";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import loadingAnimation from "@images/spark.json";
import * as ArrayUtils from "@utils/Array+Chunk";
import OnboardingDiscoverRow from "./OnboardingDiscoverRow";
import { Link, useNavigate } from "react-router-dom";
import { usePremium } from "@providers/PremiumContext";

var recommendationEngineVersion: string | null;
var newsletterCategories: NLNewsletterCategory[] | undefined;
const recommendationPageSize = 4;
const maxRecommendation = 12;
var arrayOfNewsletterScores: NLNewsletterScore[][] | null;
var arrayOfNewsletters: NLNewsletter[][] | null;
var newsletterArrayIndex = 0;
var hasOneClickSubscribe = true;
var recommendationsLoaded = false;

const OnboardingDiscover = (props) => {
	const navigate = useNavigate();
	const { onboardingProfile, setOnboardingProfile } = useOnboardingProfile();
	const [selectedNewsletters, setSelectedNewsletters] = useState<NLNewsletter[]>([]);
	const [newsletters, setNewsletters] = useState<NLNewsletter[] | null>(null);
	const [headerDescription, setHeaderDescription] = useState<string | null>(null);
	const [isShowNext, setIsShowNext] = useState<boolean>(false);
	const playerRef = useRef<LottieRefCurrentProps | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const { isPremium } = usePremium();
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});

	useEffect(() => {
		resetVariables();
		postCategoriesAndFetchRecommendations();
		playerRef.current?.setSpeed(0.5);
		playerRef.current?.goToAndPlay(60, true);
	}, []);

	useEffect(() => {
		var tempOnboardingProfile = { ...onboardingProfile! };
		tempOnboardingProfile.persistence.oneClickSubscribeArray = selectedNewsletters;
		setOnboardingProfile(tempOnboardingProfile);
	}, [selectedNewsletters]);

	const resetVariables = () => {
		recommendationEngineVersion = null;
		arrayOfNewsletterScores = null;
		arrayOfNewsletters = null;
		newsletterArrayIndex = 0;
		hasOneClickSubscribe = true;
		recommendationsLoaded = false;
		setNewsletters(null);
	};

	const postCategoriesAndFetchRecommendations = async () => {
		try {
			const categories = onboardingProfile!.persistence.categoryArray;
			const allNewsletterCategories = await postUserCategories(categories);
			newsletterCategories = allNewsletterCategories;

			const newsletterArray = await getRecommendedNewsletters();

			recommendationEngineVersion = newsletterArray.recommendation_engine_version;

			let sortedNewsletterIds = newsletterArray.newsletter_scores.sort((a, b) => b.combined_score - a.combined_score).map((x) => x.newsletter_id);
			let newsletterResponse = [...newsletterArray.newsletters];
			newsletterResponse.sort(function (a, b) {
				return sortedNewsletterIds.indexOf(a.id) - sortedNewsletterIds.indexOf(b.id);
			});

			arrayOfNewsletterScores = ArrayUtils.chunkArray(
				newsletterArray.newsletter_scores.sort((a, b) => b.combined_score - a.combined_score),
				recommendationPageSize
			);

			arrayOfNewsletters = ArrayUtils.chunkArray(newsletterResponse, recommendationPageSize);

			let tempNewsletters = arrayOfNewsletters[newsletterArrayIndex];
			hasOneClickSubscribe = tempNewsletters.filter((x) => x.one_click_subscribe_method != null) != null;

			if (hasOneClickSubscribe && tempNewsletters?.length > 0) {
				var autoSelectNewsletters: NLNewsletter[] = [];

				for (let newsletter of tempNewsletters) {
					if (newsletter.one_click_subscribe_method == null) {
						continue;
					}
					autoSelectNewsletters.push(newsletter);
				}

				setSelectedNewsletters(autoSelectNewsletters);
			}

			setNewsletters(tempNewsletters);
			//Stop animation here
			//Set footer visibility
			updateLabelsAndButtons(newsletterResponse);

			const properties = await generateAnalyticsProperties(newsletterArray.newsletters, recommendationEngineVersion);
			analyticsManager.recordEvent(kAnalyticsConstants.Onboarding.personalisedDiscoverPresented, properties);
			trackNewsletterDisplayedEvent(newsletterArrayIndex);
			recommendationsLoaded = true;
		} catch (error) {
			const errorProperties = error ? { error: error } : { error: "empty_results" };
			analyticsManager.recordEvent(kAnalyticsConstants.Onboarding.personalisedDiscoverError, errorProperties);
			playerRef.current?.stop();
			setAlertState({ isShow: true, title: "No recommendations found", message: "Looks like we couldn’t find any recommendations just yet.", actionButton: "OK", dismissButton: null, actionButtonAction: () => onNext() });
		}
	};

	const postUserCategories = (categoryIds): Promise<NLNewsletterCategory[]> => {
		return new Promise(function (resolve, reject) {
			networkManager
				.postNewsletterCategories(categoryIds)
				.then((categories) => {
					resolve(categories);
				})
				.catch((_error) => {
					reject(null);
				});
		});
	};

	const getRecommendedNewsletters = (): Promise<NLRecommendedNewsletters> => {
		return new Promise(function (resolve, reject) {
			networkManager
				.getOnboardingRecommendedNewsletters(maxRecommendation)
				.then((recommendedNewsletters) => {
					if (!recommendedNewsletters || recommendedNewsletters.newsletters.length == 0) {
						reject();
						return;
					}

					resolve(recommendedNewsletters);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const updateLabelsAndButtons = (newsletterArray) => {
		var headerDescription;

		let nonOneClickSubscribe = newsletterArray.filter((x) => x.one_click_subscribe_method == null);

		if (nonOneClickSubscribe.length > 0) {
			if (nonOneClickSubscribe.length == recommendationPageSize) {
				headerDescription = "Here are some newsletters loved by other Meco readers that match your interests. Subscribe as normal by using your email address.";
			} else {
				headerDescription = "Here are some newsletters loved by other Meco readers that match your interests.";
			}
		}

		if (hasOneClickSubscribe) {
			headerDescription = "Here are some newsletters loved by other Meco readers that match your interests. Add them to Meco in 1-tap.";
		}

		setHeaderDescription(headerDescription);
	};

	const generateAnalyticsProperties = async (newsletterArray, recommendationEngineVersion) => {
		let personalisedDiscoverKeys = kAnalyticsConstants.Onboarding.personalisedDiscoverKeys;
		let userCategories = newsletterCategories?.filter((x) => x.is_user_picked == true) ?? [];
		var categoryNames = userCategories.map((x) => x.name ?? "");
		var categoryIds = userCategories?.map((x) => x.id) ?? [];
		let nonOneClickSubscribe = newsletterArray?.filter((x) => x.one_click_subscribe_method == null) ?? [];
		let oneClickSubscribe = newsletterArray?.filter((x) => x.one_click_subscribe_method != null) ?? [];
		newsletterArray = newsletterArray == null ? [] : newsletterArray;

		let properties = {
			[personalisedDiscoverKeys.selectedCategoriesIds]: categoryIds,
			[personalisedDiscoverKeys.selectedCategoriesNames]: categoryNames,
			[personalisedDiscoverKeys.returnNewsletterCount]: newsletterArray.length,
			[personalisedDiscoverKeys.returnNewsletterLimit]: maxRecommendation,
			[personalisedDiscoverKeys.returnedNewsletterIds]: newsletterArray.map((x) => x.id),
			[personalisedDiscoverKeys.returnedNewsletterNames]: newsletterArray.map((x) => x.name),
			[personalisedDiscoverKeys.returnedNOCSCount]: nonOneClickSubscribe.length,
			[personalisedDiscoverKeys.returnedNOCSNewsletterIds]: nonOneClickSubscribe.map((x) => x.id),
			[personalisedDiscoverKeys.returnedNOCSNewsletterNames]: nonOneClickSubscribe.map((x) => x.name),
			[personalisedDiscoverKeys.returnedOCSCount]: oneClickSubscribe.length,
			[personalisedDiscoverKeys.returnedOCSNewsletterIds]: oneClickSubscribe.map((x) => x.id),
			[personalisedDiscoverKeys.returnedOCSNewsletterNames]: oneClickSubscribe.map((x) => x.name),
			[personalisedDiscoverKeys.recommendationEngineVersion]: recommendationEngineVersion,
		};

		var analytics = onboardingProfile!.analytics;
		analytics.selectedCategoriesIds = categoryIds;
		analytics.selectedCategoriesNames = categoryNames;
		analytics.disReturnNewsletterCount = newsletterArray.length;
		analytics.disReturnNewsletterLimit = maxRecommendation;
		analytics.disReturnedNewsletterIds = newsletterArray.map((x) => x.id);
		analytics.disReturnedNewsletterNames = newsletterArray.map((x) => x.name);

		analytics.disReturnedOCSCount = oneClickSubscribe.length;
		analytics.disReturnedOCSNewsletterIds = oneClickSubscribe.map((x) => x.id);
		analytics.disReturnedOCSNewsletterNames = oneClickSubscribe.map((x) => x.name);

		analytics.disReturnedNOCSCount = nonOneClickSubscribe.length;
		analytics.disReturnedNOCSNewsletterIds = nonOneClickSubscribe.map((x) => x.id);
		analytics.disReturnedNOCSNewsletterNames = nonOneClickSubscribe.map((x) => x.name);

		analytics.disRecommendationEngineVersion = recommendationEngineVersion;

		var tempOnboardingProfile = { ...onboardingProfile! };
		tempOnboardingProfile.analytics = analytics;
		setOnboardingProfile(tempOnboardingProfile);

		return properties;
	};

	const generateNewsletterScoreAnalyticsProperties = (newsletterScore, recommendationEngineVersion) => {
		let personalisedDiscoverKeys = kAnalyticsConstants.Onboarding.personalisedDiscoverKeys;

		let properties = {
			[personalisedDiscoverKeys.rec_newsletter_id]: newsletterScore.newsletter_id,
			[personalisedDiscoverKeys.rec_newsletter_name]: newsletterScore.newsletter_name,
			[personalisedDiscoverKeys.rec_engine_cat]: newsletterScore.cat,
			[personalisedDiscoverKeys.rec_engine_cat_score]: newsletterScore.cat_score,
			[personalisedDiscoverKeys.rec_engine_subcat]: newsletterScore.sub_cat,
			[personalisedDiscoverKeys.rec_engine_subcat_w_score]: newsletterScore.sub_cat_weighted_score,
			[personalisedDiscoverKeys.rec_eng_score]: newsletterScore.eng_score,
			[personalisedDiscoverKeys.rec_eng_w_score]: newsletterScore.eng_weighted_score,
			[personalisedDiscoverKeys.rec_campaign_priority_level]: newsletterScore.campaign_priority_level,
			[personalisedDiscoverKeys.rec_combined_score]: newsletterScore.combined_score,
			[personalisedDiscoverKeys.recommendationEngineVersion]: recommendationEngineVersion,
			[personalisedDiscoverKeys.returnNewsletterLimit]: maxRecommendation,
		};

		return properties;
	};

	const trackNewsletterDisplayedEvent = (index) => {
		if (!arrayOfNewsletterScores) {
			return;
		}

		if (index > arrayOfNewsletterScores?.length - 1) {
			return;
		}

		for (let newsletterScore of arrayOfNewsletterScores[index]) {
			analyticsManager.recordEvent(kAnalyticsConstants.Onboarding.recommendationDisplayed, generateNewsletterScoreAnalyticsProperties(newsletterScore, recommendationEngineVersion));
		}
	};

	const trackDiscoverCompletionEvent = () => {
		var initialProperties = generateAnalyticsProperties(newsletters, recommendationEngineVersion);
		let personalisedDiscoverKeys = kAnalyticsConstants.Onboarding.personalisedDiscoverKeys;
		initialProperties[personalisedDiscoverKeys.subscribedNewsletterCount] = onboardingProfile!.persistence.oneClickSubscribeArray.length;
		initialProperties[personalisedDiscoverKeys.subscribedNewsletterIds] = onboardingProfile!.persistence.oneClickSubscribeArray.map((x) => x.id);
		initialProperties[personalisedDiscoverKeys.subscribedNewsletterNames] = onboardingProfile!.persistence.oneClickSubscribeArray.map((x) => x.name);
		initialProperties[kAnalyticsConstants.Onboarding.OnboardingProfileKeys.discoveredItems] = onboardingProfile!.analytics.discoveredItems;
		analyticsManager.recordEvent(kAnalyticsConstants.Onboarding.personalisedDiscoverCompleted, initialProperties);
		var analytics = onboardingProfile!.analytics;
		analytics.disSubscribedNewsletterCount = onboardingProfile!.persistence.oneClickSubscribeArray.length;
		analytics.disSubscribedNewsletterIds = onboardingProfile!.persistence.oneClickSubscribeArray.map((x) => x.id);
		var ocsIdArray = onboardingProfile!.persistence.oneClickSubscribeArray.map((x) => x.id);
		let subscribedNewsletters = newsletters?.filter((x) => ocsIdArray.includes(x.id)) ?? [];
		analytics.disSubscribedNewsletterNames = subscribedNewsletters.map((x) => x.name ?? "");

		var tempOnboardingProfile = { ...onboardingProfile! };
		tempOnboardingProfile.analytics = analytics;
		setOnboardingProfile(tempOnboardingProfile);
	};

	const updateFooterTitle = () => {
		let oneClickSubscribeCount = selectedNewsletters.length;

		if (oneClickSubscribeCount == 0) {
			return "Skip";
		}

		var newsletterOrNewsletters = " newsletters";

		if (oneClickSubscribeCount == 1) {
			newsletterOrNewsletters = " newsletter";
		}

		return "Add " + oneClickSubscribeCount + newsletterOrNewsletters;
	};

	const listItemClicked = (e, selectedNewsletter) => {
		var subscribedNewsletters = [...selectedNewsletters];

		if (subscribedNewsletters.map((x) => x.id).includes(selectedNewsletter.id)) {
			subscribedNewsletters = subscribedNewsletters.filter((newsletter) => {
				return newsletter.id !== selectedNewsletter.id;
			});
		} else {
			if (!hasOneClickSubscribe) {
				analyticsManager.recordEvent(kAnalyticsConstants.Onboarding.personalisedDiscoverOCSTapped, { newsletter_id: selectedNewsletter.id, newsletter_title: selectedNewsletter.name, newsletter_index: selectedNewsletter.onboarding_sort_index, reference: "onboarding" });
			}

			subscribedNewsletters.push(selectedNewsletter);
		}

		setSelectedNewsletters(subscribedNewsletters);
	};

	const itemVisited = () => {
		var tempOnboardingProfile = { ...onboardingProfile! };
		if (tempOnboardingProfile.analytics.discoveredItems == undefined) {
			tempOnboardingProfile.analytics.discoveredItems = 0;
		}
		tempOnboardingProfile.analytics.discoveredItems += 1;
		setOnboardingProfile(tempOnboardingProfile);
	};

	const showLoadMore = () => {
		const nextIndex = newsletterArrayIndex + 1;

		if (arrayOfNewsletters && nextIndex <= arrayOfNewsletters?.length - 1) {
			return (
				<button className="flex mt-4 w-full border justify-center h-[50px] items-center rounded-[10px] border-force-primary-light/20 text-base  font-primary font-black" onClick={() => showMoreOnClick()}>
					✨ Show more recommendations
				</button>
			);
		}

		return null;
	};

	const showMoreOnClick = () => {
		const nextIndex = newsletterArrayIndex + 1;

		if (arrayOfNewsletters && nextIndex > arrayOfNewsletters?.length - 1) {
			return;
		}

		var mutableNewsletters = [...newsletters!];
		mutableNewsletters.push(...arrayOfNewsletters![nextIndex]);
		newsletterArrayIndex = nextIndex;
		setNewsletters(mutableNewsletters);

		trackNewsletterDisplayedEvent(newsletterArrayIndex);
		analyticsManager.recordEvent(kAnalyticsConstants.Onboarding.personalisedDiscoverShowMore, ["recommendation_index", newsletterArrayIndex]);
	};

	const renderLoadingView = () => {
		return (
			<div className="m-auto max-w-[500px] mb-5 md:py-[60px] md:px-[60px] flex items-center">
				<div>
					<Lottie className="max-w-[280px] m-auto" animationData={loadingAnimation} lottieRef={playerRef} onLoopComplete={aniamtionLoopComplete} />
					<div className="text-xl text-center font-primary font-black text-white">Tailoring newsletter recommendations just for you</div>
				</div>
			</div>
		);
	};

	const aniamtionLoopComplete = () => {
		if (recommendationsLoaded) {
			setIsLoading(false);
		}
	};

	const onNext = () => {
		trackDiscoverCompletionEvent();
		if (isPremium()) {
			navigate("/get-started/welcome");
			return;
		}
		
		navigate("/get-started/meco-pro");
	};

	return (
		<div>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<ParticlesBackground />
			<div className={`flex min-h-[100dvh] flex-col ${isLoading ? "justify-center " : "justify-start"} py-2 md:py-12`}>
				{!isLoading && (
					<div className="fixed top-0 bg-black/50 w-full p-4 z-30 backdrop-blur-lg border-b border-white/10 shadow-2xl">
						<div className="max-w-[1080px] w-full z-30 m-auto">
							<div className="flex justify-end items-center">
								<button className={`text-white font-medium font-primary text-lg focus:outline-none rounded-xl p-2 px-3 py-2 items-center transition ease-in-out whitespace-nowrap ${selectedNewsletters.length > 0 ? "bg-success-green/80 hover:bg-success-green/100" : "bg-brand-red/80 hover:bg-brand-red/100"}`} onClick={() => onNext()}>
									{updateFooterTitle()}
								</button>
							</div>
						</div>
					</div>
				)}
				<div className={`flex flex-col gap-10 items-center justify-center p-3 ${isLoading ? null : "mt-[50px] md:mt-[80px]"}`}>
					<div className="meco_container max-w-[1080px] py-[40px] md:p-[60px] md:pb-[40px]">
						{isLoading ? (
							renderLoadingView()
						) : (
							<div>
								<div className="flex flex-col md:flex-row gap-5 items-start">
									<div className="sticky md:top-[110px] w-full md:w-1/2 flex flex-col gap-2">
										<div className="text-white font-bold font-primary text-4xl leading-10">Top picks just for you</div>
										<div className="text-force-primary-light font-medium font-primary text-base  md:text-xl">{headerDescription}</div>
									</div>
									<div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-3 max-w-[450px] md:max-w-none m-auto md:min-w-[400px]">
										<div className="text-white font-medium font-primary text-3xl text-center w-full max-w-[600px] mb-5  no-select">
											{newsletters != null
												? newsletters.map((newsletter, i) => {
														return <OnboardingDiscoverRow {...newsletter} key={i} isChecked={selectedNewsletters.map((x) => x.id).includes(newsletter.id)} checkOnChange={listItemClicked} itemVisited={itemVisited} />;
												  })
												: null}
											{showLoadMore()}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OnboardingDiscover;
