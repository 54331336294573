export const defaultStyles = `
body {
    background-color: #ffffff;
    color: #000000;
    font-family: inherit;
    padding: 0 2rem;
    font-size: inherit;
    line-height: inherit;
}
p {
    color: #000000;
    font-size: inherit;
    line-height: inherit;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
    margin: revert;
}

a {
    color: '#0000ee';
    text-decoration: 'underline';
}

a[href] {
    color: #15c;
    text-decoration: underline;
}

h1 {
    color: '#000000';
    fontSize: '24px';
    fontWeight: 'bold';
}
h2 {
    color: '#000000';
    fontSize: '22px';
    fontWeight: 'bold';
}
`;

export const readerModeStyles = `

:root {
    --grey-90-a10: rgba(12, 12, 13, 0.1);
    --grey-90-a20: rgba(12, 12, 13, 0.2);
    --grey-30: #d7d7db;
    --light-theme-background: #fff;
    --light-theme-foreground: rgb(21, 20, 26);
    --dark-theme-background: rgb(28, 27, 34);
    --dark-theme-foreground: rgb(251, 251, 254);
    --body-padding: 20px;
    --font-size: 20px;
    --content-width: 100%;
    --line-height: 1.6em;
    --font-family: serif;
}

html[m3c0_reader_mode="true"] body {
    --main-background: var(--light-theme-background);
    --main-foreground: var(--light-theme-foreground);
    --primary-color: rgb(0, 97, 224);
    --selected-background: rgba(0, 97, 224, 0.3);
    --selected-border: var(--primary-color);
    --outline-focus-color: var(--primary-color);
    --font-value-background: rgb(240, 240, 244);
    --font-value-border: var(--grey-30);
    --text-selected-background: var(--selected-background);
    --text-selected-foreground: inherit;
    --link-foreground: var(--primary-color);
    --link-selected-background: var(--selected-background);
    --link-selected-foreground: #333;
    --visited-link-foreground: #b5007f;
    /* light colours */
}

html[m3c0_reader_mode="true"] body {
    margin: 0;
    padding: var(--body-padding);
    background-color: var(--main-background);
    color: var(--main-foreground);
    font-family: var(--font-family), serif;
    margin: 0 auto;
    font-size: var(--font-size);
    max-width: var(--content-width);
    line-height: var(--line-height);
    text-align: start;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
}

html[m3c0_reader_mode="true"] body.sepia {
    --main-background: rgb(244, 236, 216);
    --main-foreground: rgb(91, 70, 54);
}

html[m3c0_reader_mode="true"] body.gray {
    --main-background: var(--dark-theme-background);
    --main-foreground: var(--dark-theme-foreground);
    --primary-color: rgb(0, 221, 255);
    --selected-background: rgba(0, 221, 255, 0.3);
    --font-value-background: rgba(249, 249, 250, 0.15);
    --font-value-border: #656468;
    --link-selected-foreground: #fff;
    --visited-link-foreground: #e675fd;
}

html[m3c0_reader_mode="true"] body.dark {
    --main-background: rgb(0,0,0);
    --main-foreground: rgb(223,223,223);
    --primary-color: rgb(0, 172, 198);
    --selected-background: rgba(0, 172, 198, 0.3);
    --font-value-background: rgba(249, 249, 250, 0.15);
    --font-value-border: #656468;
    --link-selected-foreground: #fff;
    --visited-link-foreground: #e675fd;
}

/* Article content */
html[m3c0_reader_mode="true"] .container {
    margin: 0 auto;
    font-size: var(--font-size);
    max-width: var(--content-width);
    line-height: var(--line-height);
}

html[m3c0_reader_mode="true"] .container {
    margin: 0 auto;
    font-size: var(--font-size);
    max-width: var(--content-width);
    line-height: var(--line-height);
}

@media only screen and (min-width: 780px) {
    html[m3c0_reader_mode="true"] .container {
        max-width: 800px;
        margin: 0 auto;
    }
    
    html[m3c0_reader_mode="true"] .meco-reader-content {
        margin-left: 70px;
        margin-right: 70px;
    }
}

html[m3c0_reader_mode="true"] .meco-reader-content pre {
    font-family: inherit;
}

html[m3c0_reader_mode="true"] .meco-reader-content {
    font-size: 1em;
}

@media print {
    html[m3c0_reader_mode="true"] .meco-reader-content p,
    html[m3c0_reader_mode="true"] .meco-reader-content code,
    html[m3c0_reader_mode="true"] .meco-reader-content pre,
    html[m3c0_reader_mode="true"] .meco-reader-content blockquote,
    html[m3c0_reader_mode="true"] .meco-reader-content ul,
    html[m3c0_reader_mode="true"] .meco-reader-content ol,
    html[m3c0_reader_mode="true"] .meco-reader-content li,
    html[m3c0_reader_mode="true"] .meco-reader-content figure,
    html[m3c0_reader_mode="true"] .meco-reader-content .wp-caption {
        margin: 0 0 10px !important;
        padding: 0 !important;
    }
}

html[m3c0_reader_mode="true"] .meco-reader-content h1,
html[m3c0_reader_mode="true"] .meco-reader-content h2,
html[m3c0_reader_mode="true"] .meco-reader-content h3 {
    font-weight: bold;
}

html[m3c0_reader_mode="true"] .meco-reader-content h1 {
    font-size: 1.6em;
    line-height: 1.25em;
}

html[m3c0_reader_mode="true"] .meco-reader-content h2 {
    font-size: 1.2em;
    line-height: 1.51em;
}

html[m3c0_reader_mode="true"] .meco-reader-content h3 {
    font-size: 1em;
    line-height: 1.66em;
}

html[m3c0_reader_mode="true"] .meco-reader-content a:link {
    text-decoration: underline;
    font-weight: normal;
}

html[m3c0_reader_mode="true"] .meco-reader-content a:link,
html[m3c0_reader_mode="true"] .meco-reader-content a:link:hover,
html[m3c0_reader_mode="true"] .meco-reader-content a:link:active,
html[m3c0_reader_mode="true"] .meco-reader-content a:link:visited {
    color: var(--link-foreground);
}

html[m3c0_reader_mode="true"] .meco-reader-content a:visited {
    color: var(--visited-link-foreground);
}

html[m3c0_reader_mode="true"] .meco-reader-content * {
    max-width: 100%;
    height: auto;
}

html[m3c0_reader_mode="true"] .meco-reader-content p,
html[m3c0_reader_mode="true"] .meco-reader-content p,
html[m3c0_reader_mode="true"] .meco-reader-content code,
html[m3c0_reader_mode="true"] .meco-reader-content pre,
html[m3c0_reader_mode="true"] .meco-reader-content blockquote,
html[m3c0_reader_mode="true"] .meco-reader-content ul,
html[m3c0_reader_mode="true"] .meco-reader-content ol,
html[m3c0_reader_mode="true"] .meco-reader-content li,
html[m3c0_reader_mode="true"] .meco-reader-content figure,
html[m3c0_reader_mode="true"] .meco-reader-content .wp-caption {
    margin: -10px -10px 20px;
    padding: 10px;
    border-radius: 5px;
}

html[m3c0_reader_mode="true"] .meco-reader-content li > * {
    margin: 0;
    padding: 10px 0;
}

html[m3c0_reader_mode="true"] .meco-reader-content li {
    padding-left: 5px;
    margin-bottom: 0;
}

html[m3c0_reader_mode="true"] .meco-reader-content li > ul,
html[m3c0_reader_mode="true"] .meco-reader-content li > ol {
    margin-bottom: -10px;
}

html[m3c0_reader_mode="true"] .meco-reader-content p > img:only-child,
html[m3c0_reader_mode="true"] .meco-reader-content p > a:only-child > img:only-child,
html[m3c0_reader_mode="true"] .meco-reader-content .wp-caption img,
html[m3c0_reader_mode="true"] .meco-reader-content figure img {
    display: block;
}

html[m3c0_reader_mode="true"] .meco-reader-content img[meco-reader-center] {
    margin-inline: auto;
}

html[m3c0_reader_mode="true"] .meco-reader-content .caption,
html[m3c0_reader_mode="true"] .meco-reader-content .wp-caption-text .meco-reader-content figcaption {
    font-size: 0.9em;
    line-height: 1.48em;
    font-style: italic;
}

html[m3c0_reader_mode="true"] .meco-reader-content pre {
    white-space: pre-wrap;
}

html[m3c0_reader_mode="true"] .meco-reader-content blockquote {
    padding: 0;
    padding-inline-start: 16px;
}

html[m3c0_reader_mode="true"] .meco-reader-content ul,
html[m3c0_reader_mode="true"] .meco-reader-content ol {
    margin: -10px 20px 20px;
    padding: 0;
}

html[m3c0_reader_mode="true"] .meco-reader-content ul {
    padding-inline-start: 30px;
    list-style: disc;
}

html[m3c0_reader_mode="true"] .meco-reader-content ol {
    padding-inline-start: 30px;
}

html[m3c0_reader_mode="true"] .meco-reader-content table,
html[m3c0_reader_mode="true"] .meco-reader-content th,
html[m3c0_reader_mode="true"] .meco-reader-content td {
    border: 0px solid currentColor;
    border-collapse: collapse;
    padding: 0px;
    vertical-align: top;
    font-size: inherit;
    line-height: inherit;
    text-align: inherit;
}

html[m3c0_reader_mode="true"] .meco-reader-content table {
    width: 100%;
    margin: 5px;
}

html[m3c0_reader_mode="true"] .meco-reader-content img {
    margin: 0 auto;
}

/* Visually hide (but don't display: none) screen reader elements */
html[m3c0_reader_mode="true"] .meco-reader-content .visually-hidden,
html[m3c0_reader_mode="true"] .meco-reader-content .visuallyhidden,
html[m3c0_reader_mode="true"] .meco-reader-content .sr-only {
    display: inline-block;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    border-width: 0;
}

/* Hide elements with common "hidden" class names */
html[m3c0_reader_mode="true"] .meco-reader-content .hidden,
html[m3c0_reader_mode="true"] .meco-reader-content .invisible {
    display: none;
}

/* Enforce wordpress and similar emoji/smileys aren't sized to be full-width,
 * see bug 1399616 for context. */
html[m3c0_reader_mode="true"] .meco-reader-content img.wp-smiley,
html[m3c0_reader_mode="true"] .meco-reader-content img.emoji {
    display: inline-block;
    border-width: 0;
    width: 1em;
    margin: 0 0.07em;
    padding: 0;
}

html[m3c0_reader_mode="true"] .meco-reader-content .reader-show-element {
    display: initial;
}

/* Provide extra spacing for images that may be aided with accompanying element such as <figcaption> */
html[m3c0_reader_mode="true"] .meco-reader-content .meco-reader-block-img:not(:last-child) {
    margin-block-end: 12px;
}

html[m3c0_reader_mode="true"] .meco-reader-content .meco-reader-wide-table {
    overflow-x: auto;
    display: block;
}

html[m3c0_reader_mode="true"] .meco-reader-content pre code {
    background-color: var(--main-background);
    border: 1px solid var(--main-foreground);
    display: block;
    overflow: auto;
}
`
