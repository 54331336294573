import { NLMailboxProfileType } from "@models/Models";
import { kErrorConstants } from "../../constants/ErrorConstants";
import { kGoogleConstants } from "../../constants/GoogleConstants";
import * as appAuth from "@openid/appauth";

var windowObjectReference: Window | null = null;
var previousUrl: URL | null = null;
var receiveMessageFunc: ((event: MessageEvent<any>) => void) | null;

export function initiateSignInFlow(request: appAuth.AuthorizationRequest, configuration: appAuth.AuthorizationServiceConfiguration, integrationType: NLMailboxProfileType, resolve, reject) {
	const authorizationUrl = buildQueryString(request, configuration);
	openSignInWindow(authorizationUrl, integrationType, resolve, reject);
}

function buildQueryString(request: appAuth.AuthorizationRequest, configuration: appAuth.AuthorizationServiceConfiguration): URL {
	var requestMap = {
		redirect_uri: request.redirectUri,
		client_id: request.clientId,
		response_type: request.responseType,
		state: request.state,
		scope: request.scope,
	};
	
	if (request.extras) {
		for (var extra in request.extras) {
			if (request.extras.hasOwnProperty(extra)) {
				requestMap[extra] = request.extras[extra];
			}
		}
	}

	const queryStringUtils = new appAuth.BasicQueryStringUtils();
	var query = queryStringUtils.stringify(requestMap);
	var baseUrl = configuration.authorizationEndpoint;
	var urlString = baseUrl + "?" + query;
	var url = new URL(urlString);
	return url;
}

function openSignInWindow(url: URL, integrationType: NLMailboxProfileType, resolve, reject) {
	
    receiveMessageFunc = (event: MessageEvent) => receiveMessage(event, integrationType, resolve, reject);

	window.removeEventListener("message", receiveMessageFunc);

	var screen = window.screen;
	var width = Math.min(500, screen.width - 40);
	var height = Math.min(550, screen.height - 40);

	const strWindowFeatures = ["toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no", "width=" + width, "height=" + height, "top=" + (screen.height / 2 - height / 2), "left=" + (screen.width / 2 - width / 2)].join();

	const windowTitle = "Login";

	if (windowObjectReference === null || windowObjectReference.closed) {
		windowObjectReference = window.open(url, windowTitle, strWindowFeatures);
	} else if (previousUrl !== url) {
		windowObjectReference = window.open(url, windowTitle, strWindowFeatures);
		windowObjectReference?.focus();
	} else {
		windowObjectReference.focus();
	}

	window.addEventListener("message", receiveMessageFunc);

	previousUrl = url;
}

function receiveMessage(event, integrationType, resolve?, reject?) {

	if (typeof event.data !== 'string')  return;

    if (receiveMessageFunc) {
        window.removeEventListener("message", receiveMessageFunc);
        receiveMessageFunc = null;
    }

	if (event.origin !== kGoogleConstants.baseUrl) {
		reject();
		return;
	}
	
	const queryStringUtils = new appAuth.BasicQueryStringUtils();
	const data = queryStringUtils.parseQueryString(event.data);
	if (data) {

		if (integrationType === NLMailboxProfileType.Gmail) {
			console.log(data);
			const grantedScopes = data.scope.split(" ");
			for (let scope of kGoogleConstants.scopes) {
				if (!grantedScopes.includes(scope)) {
					reject(kErrorConstants.oauthIntegration.permissionError);
					return;				
				}
			}
		}

		resolve(data);
		
	} else {
		reject();
	}

}
