import moment from "moment";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import { kAppConfig } from "@utils/configurations/AppConfig";
import { NLMailboxProfile, NLMailboxProfileType, NLUserMailState, NLUserMailStateNames } from "@models/Models";
import * as Date from "@utils/Date+GetDateFor";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";

export class GetUserMailStatesService {
	private queries: GetUserMailStatesService.QueryBuilder[];
	private stateTypes: NLUserMailStateNames[] | undefined;

	constructor(queries: GetUserMailStatesService.QueryBuilder[], stateTypes: NLUserMailStateNames[] | undefined = undefined) {
		this.queries = queries;
		this.stateTypes = stateTypes;
	}

	getUserMailStates(completion: (userMailStates: NLUserMailState[] | undefined, mailboxProfileIds: string[] | undefined, error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();

		let requestArray: GetUserMailStatesService.RequestModel[] = [];
		let responseArray: GetUserMailStatesService.ResponseModel[] = [];
		const promises: Promise<void>[] = [];

		for (const requestModel of this.queries) {
			const promise = new Promise<void>((innerResolve, innerReject) => {
				if (requestModel.mailboxProfile.type !== NLMailboxProfileType.Internal) {
					oauthSessionManager
						.fetchAccessTokenForMailboxProfile(requestModel.mailboxProfile.id, requestModel.mailboxProfile.type)
						.then((accessToken) => {
							const request = new GetUserMailStatesService.RequestModel(requestModel.mailboxProfile.id, accessToken, requestModel.dateQuery, requestModel.lastUpdated);
							requestArray.push(request);
							innerResolve();
						})
						.catch((error) => {
							if (error instanceof Error && error.message === "noInternetError") {
								innerReject(error);
							} else {
								var userMailResponseModel = new GetUserMailStatesService.ResponseModel(requestModel.mailboxProfile.id);
								userMailResponseModel.error = oauthSessionManager.generateSignInRequiredError(requestModel.mailboxProfile.id, error);
								responseArray.push(userMailResponseModel);
								innerResolve();
							}
						});
				} else {
					const request = new GetUserMailStatesService.RequestModel(requestModel.mailboxProfile.id, null, requestModel.dateQuery, requestModel.lastUpdated);
					requestArray.push(request);
					innerResolve();
				}
			});
			promises.push(promise);
		}

		Promise.all(promises)
			.then(() => {
				if (requestArray.length === 0) {
					completion(undefined, undefined, undefined);
					return;
				}

				this.makeRequests(requestArray, responseArray, completion);
			})
			.catch((error) => {
				completion(undefined, undefined, error);
			});
	}

	private makeRequests(requestArray: GetUserMailStatesService.RequestModel[], responseArray: GetUserMailStatesService.ResponseModel[], completion: (userMailState: NLUserMailState[] | undefined, mailboxProfileIds: string[] | undefined, error: Error | undefined) => void) {
		networkManager
			.getUserMailStates(requestArray, this.stateTypes)
			.then((jsonData) => {
				var responseModels = responseArray;

				for (let mailboxProfileId in jsonData) {
					let responseData = jsonData[mailboxProfileId];
					var responseModel = new GetUserMailStatesService.ResponseModel(mailboxProfileId);

					if (Array.isArray(responseData["user_mail_states"])) {
						let userMailStates = responseData["user_mail_states"].map((userMailState: NLUserMailState) => new NLUserMailState(userMailState));
						responseModel.userMailStates = userMailStates;
					}

					if (responseData["error"]) {
						responseModel.error = MailboxError.returnError(responseData["error"], mailboxProfileId);
					}

					responseModels.push(responseModel);
				}

				var userMailStates: NLUserMailState[] | undefined = undefined;

				var mailboxProfileIds: string[] = [];

				for (let userMailStateResponse of responseModels) {
					if (!userMailStateResponse.userMailStates) {
						continue;
					}

					if (userMailStates) {
						userMailStates.push(...userMailStateResponse.userMailStates);
					} else {
						userMailStates = userMailStateResponse.userMailStates;
					}

					mailboxProfileIds.push(userMailStateResponse.mailboxProfileId);
				}

				completion(userMailStates, mailboxProfileIds, undefined);
			})
			.catch((error) => {
				completion(undefined, undefined, error);
			});
	}
}

export namespace GetUserMailStatesService {
	export class RequestModel {
		access_token: string | null;
		mailbox_profile_id!: string;
		date_query: string | null;
		last_updated: string | null;

		constructor(mailboxProfileId: string, accessToken: string | null, dateQuery: string | null, lastUpdated: string | null) {
			this.mailbox_profile_id = mailboxProfileId;
			this.access_token = accessToken;
			this.date_query = dateQuery;
			this.last_updated = lastUpdated;
		}
	}

	export class ResponseModel {
		mailboxProfileId!: string;
		userMailStates?: NLUserMailState[] | null;
		error?: Error | null;

		constructor(mailboxProfileId: string) {
			this.mailboxProfileId = mailboxProfileId;
		}
	}

	export class QueryBuilder {
		mailboxProfile: NLMailboxProfile;
		dateQuery: string | null;
		lastUpdated: string | null;

		constructor(mailboxProfile: NLMailboxProfile, lookbackDays: [number, number] | null = null, addSavedLastUpdatedToQuery: boolean, forceLastUpdatedAtDate: string | null = null) {
			this.mailboxProfile = mailboxProfile;
			this.dateQuery = QueryBuilder.generateDateQuery(mailboxProfile.id, lookbackDays);
			this.lastUpdated = forceLastUpdatedAtDate || (addSavedLastUpdatedToQuery ? QueryBuilder.getLastUpdatedDate(mailboxProfile.id) : null);
		}

		static generateDateQuery(mailboxProfileId: string, lookbackDays: [number, number] | null): string | null {
			let dateString: string | null = null;

			if (lookbackDays) {
				const afterDateString = Date.getDateFor(lookbackDays[0]);
				const beforeDateString = Date.getDateFor(lookbackDays[1]);

				if (afterDateString && beforeDateString) {
					dateString = `after:${afterDateString} before:${beforeDateString}`;
				}
			} else {
				const backendLastUpdatedAt = this.getLastUpdatedDate(mailboxProfileId);

				if (backendLastUpdatedAt) {
					const dateQuery = this.convertBackendDateToDateQuery(backendLastUpdatedAt);
					if (dateQuery) {
						dateString = `after:${dateQuery}`;
					}
				} else {
					const initialDateString = Date.getDateFor(kAppConfig.initialProspectQueryFromDay);

					if (initialDateString) {
						dateString = `after:${initialDateString}`;
					}
				}
			}

			return dateString;
		}

		static getLastUpdatedDate(mailboxProfileId: string): string | null {
			try {
				const lastUpdatedAt = localStorage.getItem(kLocalStorageKeys.Backend.userStatesLastUpdatedAt);

				if (lastUpdatedAt) {
					const lastUpdatedAtDict = JSON.parse(lastUpdatedAt) as Record<string, string>;

					if (lastUpdatedAtDict && Object.prototype.hasOwnProperty.call(lastUpdatedAtDict, mailboxProfileId)) {
						return lastUpdatedAtDict[mailboxProfileId];
					}
				}

				return null;
			} catch (error) {
				return null;
			}
		}

		static convertBackendDateToDateQuery(backendDate: string): string | null {
			const inputDateFormatter = "YYYY-MM-DD-HH-mm-ss";
			const outputDateFormatter = "YYYY/MM/DD";

			const inputDate = moment(backendDate, inputDateFormatter, true);

			if (!inputDate.isValid()) {
				return null;
			}

			const outputDateString = inputDate.utc().format(outputDateFormatter);
			return outputDateString;
		}
	}
}
