import { NLMail, NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import { ModifyMessagesService } from "./mailboxFunctions/ModifyMessagesService";
import { kErrorConstants } from "@utils/constants/ErrorConstants";

export const modifyMessages = (mails: NLMail[], mailboxProfiles: NLMailboxProfile[], action: string, completion: (successArray: string[] | undefined, error: Error | undefined) => void) => {
	var requests: ModifyMessagesService.QueryBuilder[] = [];

	const mailsByMailboxProfileId: { [mailboxProfileId: string]: NLMail[] } = {};
	mails.forEach((mail) => {
		if (!mailsByMailboxProfileId[mail.mailbox_profile_id]) {
			mailsByMailboxProfileId[mail.mailbox_profile_id] = [];
		}
		mailsByMailboxProfileId[mail.mailbox_profile_id].push(mail);
	});

	for (let mailboxProfileId in mailsByMailboxProfileId) {
		const mailboxProfile = mailboxProfiles.find((x) => x.id === mailboxProfileId);
		if (!mailboxProfile) {
			continue;
		}

		const messageIds = mailsByMailboxProfileId[mailboxProfileId].map((x) => x.id);

		var query = new ModifyMessagesService.QueryBuilder(mailboxProfile, messageIds, action);
		requests.push(query);
	}

	if (requests.length === 0) {
		completion(undefined, kErrorConstants.dataProcessingError);
		return;
	}

	const modifyMessagesService = new ModifyMessagesService(requests);

	modifyMessagesService.modifyMessages((responseArray, error) => {
		var successfulMessageIdArray: string[] | undefined;
		var newError: Error | undefined = error;

		for (let response of responseArray ?? []) {
			if (response.error) {
				newError = response.error;
				continue;
			}

			if (response.successes) {
				successfulMessageIdArray = successfulMessageIdArray ?? [];
				successfulMessageIdArray.push(...response.successes);
			}
		}

		completion(successfulMessageIdArray, error);
	});
};
