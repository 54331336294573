import React, { MouseEventHandler } from "react";
import { AudioModel, NLAudioEpisode } from "@models/Models";
import { ReactComponent as PlayIcon } from "@images/audio_play_icon.svg";
import { ReactComponent as PauseIcon } from "@images/audio_pause_icon.svg";

import { useAudio, useAudioDispatch } from "@providers/AudioContext";
import IconButton from "@components/common/IconButton";
import { dateStringToAudioDate } from "@utils/Date+GetDateFor";

interface AudioUserCardProps {
	audioItem: AudioModel;
	onEpisodeClick: (episode: NLAudioEpisode) => void;
}

const AudioUserCard: React.FC<AudioUserCardProps> = ({ audioItem, onEpisodeClick }) => {
	const { episode, isPlaying } = useAudio();

	if (!audioItem.episodes) {
		return null;
	}

	const onCardClick = () => {
		if (!audioItem.episodes) {
			return;
		}
		onEpisodeClick(audioItem.episodes[0]);
	};

	const getDateAndTime = (episode?: NLAudioEpisode) => {
		if (!episode) {
			return null;
		}
		let dateString = dateStringToAudioDate(episode.created_at);
		const minutes = Math.ceil(episode.duration / 60);
		const minutesString = `${minutes} MIN`;
		if (dateString) {
			return `${dateString} – ${minutesString}`;
		}
		return minutesString;
	};

	return (
		<div className="w-full px-4 pb-4">
			<div
				className="relative w-full bg-cover bg-center rounded-[16px] p-4 flex flex-row items-center justify-between gap-4 cursor-pointer"
				style={{
					background: `linear-gradient(0deg, rgba(7,171,54, 0.95), rgba(7,171,54, 0.95)), url(${audioItem.episodes[0].image_url})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center",
				}}
				onClick={onCardClick}>
				<div className="flex flex-col items-start gap-3">
					<div className="flex flex-col items-start justify-center gap-1">
						<div>
							<div className="font-primary font-bold text-base text-white/50">{audioItem.section.name}</div>
						</div>
						<div className="font-primary font-medium text-xl text-white line-clamp-2">{audioItem.episodes[0].title}</div>
						<div className="font-primary font-regular text-base text-white">{audioItem.episodes[0].summary}</div>
					</div>
				</div>
				<div className="flex flex-col items-center gap-2">
					<div className="font-primary font-black text-xs text-white/50">{getDateAndTime(audioItem.episodes[0])}</div>
					<button className="bg-white hover:white/90 duration-300 ease-in-out transition-all text-success-green rounded-full px-[80px] py-2.5 font-primary font-medium text-lg text-nowrap">
						<div className="flex flex-row items-center justify-center gap-2">
							{episode?.id === audioItem.episodes[0].id && isPlaying ? <PauseIcon className="!fill-success-green w-[12px] h-[12px]" /> : <PlayIcon className="!fill-success-green w-[12px] h-[12px]" />}
							<div className="font-primary font-medium text-base text-success-green">Play</div>
						</div>
					</button>
				</div>
			</div>
		</div>
	);
};

export default AudioUserCard;
