import { DiscoverSubscriptionState } from "./Discover";
import React, { useState } from "react";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { Textfit } from "react-textfit";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

export const DiscoverCard = (props) => {
	const [isLoading, setLoading] = useState(false);

	const renderSubscribeButton = () => {
		if (props.subscriptionState === DiscoverSubscriptionState.oneClickSubscribe) {
			return (
				<button disabled={isLoading} className={`bg-success-green px-3 rounded-[10px] min-w-[100px] font-primary font-bold text-xs text-white h-[27px] transition duration-300 ease-in-out ${isLoading ? null : "hover:scale-105"}`} onClick={(e) => oneClickSubscribeOnClick(e, props.newsletter)}>
					{isLoading ? <LoadingSpinner className="m-auto h-[20px] text-white/20 animate-spin fill-white" /> : <div>SUBSCRIBE</div>}
				</button>
			);
		} else if (props.subscriptionState === DiscoverSubscriptionState.subscribed) {
			return (
				<button className="border border-success-green px-2 min-w-[100px] rounded-[10px] font-primary font-bold text-xs text-success-green h-[27px]" onClick={(e) => alreadySubscribedOnClick(e, props.newsletter)}>
					SUBSCRIBED
				</button>
			);
		} else {
			return (
				<button className="bg-primary px-3 min-w-[100px] rounded-[10px] font-primary font-bold text-xs text-secondary-dark h-[27px] hover:scale-105 transition duration-300 ease-in-out" onClick={(e) => visitOnClick(e, props.newsletter)}>
					VISIT
				</button>
			);
		}
	};

	const visitOnClick = (e, newsletter) => {
		window.open(newsletter.subscribe_link);
		analyticsManager.recordEvent(kAnalyticsConstants.Discover.newsletterTapped, {"newsletter_id" : props.newsletter.id, "newsletter_title" : props.newsletter.name ?? "", "reference" : kAnalyticsConstants.Reference.discover,
		"category_id": props.category.id, "category_name": props.category.name ?? "", "category_index": props.category.sort_index})
	};

	const alreadySubscribedOnClick = (e, newsletter) => {
		props.alreadySubscribedOnClick(newsletter);
		analyticsManager.recordEvent(kAnalyticsConstants.Discover.alreadySubscribedTapped, {"newsletter_id" : props.newsletter.id, "newsletter_title" : props.newsletter.name ?? "", "reference" : kAnalyticsConstants.Reference.discover,
		"category_id": props.category.id, "category_name": props.category.name ?? "", "category_index": props.category.sort_index})
	};

	const oneClickSubscribeOnClick = (e, newsletter) => {
		setLoading(true);
		props.oneClickSubscribeOnClick(newsletter, loadingCompleted);
		analyticsManager.recordEvent(kAnalyticsConstants.Discover.personalisedDiscoverOCSTapped, {"newsletter_id" : props.newsletter.id, "newsletter_title" : props.newsletter.name ?? "", "reference" : kAnalyticsConstants.Reference.discover,
		"category_id": props.category.id, "category_name": props.category.name ?? "", "category_index": props.category.sort_index})
	};

	const loadingCompleted = (isError) => {
		setLoading(false);
	};

	return (
		<div className="bg-surface flex flex-col rounded-[10px] w-[312px] border border-primary-100 overflow-hidden" key={props.newsletter.id}>
			<img src={props.newsletter.image_url == null ? props.newsletter.logo : props.newsletter.image_url} className="h-[180px] object-cover" />
			<div className="h-[1px] bg-primary-100"></div>
			<div className="flex flex-col py-[12px] px-[12px] pb-20px gap-2">
				<div className="flex justify-between gap-2 items-center">
					<div className="w-[170px]">
						<Textfit mode="single" max={18} className="font-primary font-black text-primary">
							{props.newsletter.name}
						</Textfit>
					</div>
					{renderSubscribeButton()}
				</div>
				<div className="font-primary font-regular text-sm text-primary-500">
					{props.newsletter.summary}
				</div>
			</div>
		</div>
	);
};

export default DiscoverCard;
