import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment, MouseEventHandler } from "react";

export interface AlertState {
	isShow: boolean | null;
	title: string | null;
	message: string | null;
	actionButton: string | null;
	dismissButton: string | null;
	actionButtonAction: MouseEventHandler<HTMLButtonElement> | undefined;
}

interface AlertProps {
	alertState: AlertState;
	setAlertState: React.Dispatch<React.SetStateAction<AlertState>>;
}

const Alert: FC<AlertProps> = ({ alertState, setAlertState }) => {
	const { isShow, title, message, actionButton, dismissButton, actionButtonAction } = alertState;

	const onClickAction = (e) => {
		if (actionButtonAction) {
			actionButtonAction(e);
		}
		setAlertState({ ...alertState, isShow: false });
	};

	return (
		<>
			<Transition as={Fragment} show={isShow ?? false}>
				<Dialog onClose={() => setAlertState({ ...alertState, isShow: false })} className="relative z-40">
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black/30" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-secondary border border-primary-100 p-6 text-left align-middle shadow-xl transition-all">
								<Dialog.Title as="h3" className="text-xl text-primary font-primary font-medium leading-6">
									{title}
								</Dialog.Title>
								<div className="mt-2">
									<p className="text-sm text-primary font-primary font-regular">{message}</p>
								</div>
								<div className="mt-4 flex flex-row gap-2">
									{actionButton != null ? (
										<button className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium font-primary transition ease-in-out duration-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 text-secondary bg-primary hover:bg-primary/80" onClick={(e) => onClickAction(e)}>
											{actionButton}
										</button>
									) : null}
									{dismissButton != null ? (
										<button className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium font-primary transition ease-in-out duration-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 text-white bg-primary-500 hover:bg-primary/40" onClick={() => setAlertState({ ...alertState, isShow: false })}>
											{dismissButton}
										</button>
									) : null}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default Alert;
