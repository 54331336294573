import { Popover, Transition } from "@headlessui/react";
import { ReactComponent as ReaderModeIcon } from "@images/reader_mode_icon.svg";
import { ReactComponent as ReaderModeDisabledIcon } from "@images/reader_mode_disabled_icon.svg";
import IconButton from "./IconButton";
import { FC, Fragment, ReactNode, useState } from "react";
import ListboxView from "./ListboxView";
import { ReaderModeChoices } from "@utils/ReaderModeChoices";
import { NLReaderMode } from "@models/Models";
import PaywallModal from "./PaywallModal";
import { trackAnalyticsReaderModeState } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import { usePremium } from "@providers/PremiumContext";

interface ReaderModePopOverProps {
	readerMode: NLReaderMode;
	setReaderMode: (setReaderMode: NLReaderMode) => void;
	isError: boolean;
}

const ReaderModePopOver: FC<ReaderModePopOverProps> = ({ readerMode, setReaderMode, isError }) => {
	const { isPremium } = usePremium();
	const [showPaywall, setShowPaywall] = useState(false);

	const fontSelected = (displayName: string) => {
		const font = Object.values(ReaderModeChoices.Font).find((font) => ReaderModeChoices.fontDisplayName(font) === displayName);
		if (font) {
			const updatedReaderMode = { ...readerMode, font };
			setReaderMode(updatedReaderMode);
			const tempReaderMode = updatedReaderMode;
			trackAnalyticsReaderModeState(tempReaderMode, false);
		}
	};

	const themeSelected = (theme: (typeof ReaderModeChoices.Theme)[keyof typeof ReaderModeChoices.Theme]) => {
		const updatedReaderMode = { ...readerMode, theme };
		setReaderMode({ ...readerMode, theme });
		const tempReaderMode = updatedReaderMode;
		trackAnalyticsReaderModeState(tempReaderMode, false);
	};

	const readerModeStateChanged = () => {
		const newState = !readerMode.state;
		if (newState && !isPremium()) {
			setShowPaywall(true);
			return;
		}
		const updatedReaderMode = { ...readerMode, state: newState };
		setReaderMode(updatedReaderMode);
		const tempReaderMode = updatedReaderMode;
		trackAnalyticsReaderModeState(tempReaderMode, true);
	};

	const renderIconButton = (isOpen: boolean): ReactNode => {
		if (isError) {
			return <IconButton Icon={ReaderModeDisabledIcon} className="bg-primary hover:bg-primary/90" iconClassName="fill-secondary" onClick={(e) => e} />;
		}

		if (isOpen) {
			return <IconButton Icon={ReaderModeIcon} className="bg-success-green/20 hover:bg-success-green/20" iconClassName="fill-primary" onClick={(e) => e} />;
		}

		if (readerMode.state) {
			return <IconButton Icon={ReaderModeIcon} className="bg-primary hover:bg-primary/90" iconClassName="fill-secondary" onClick={(e) => e} />;
		}

		return <IconButton Icon={ReaderModeIcon} onClick={(e) => e} />;
	};

	const fontSizeIncreased = () => {
		const fontSize = readerMode.fontSize + 1 > 40 ? 40 : readerMode.fontSize + 1;
		setReaderMode({ ...readerMode, fontSize: fontSize });
	};

	const fontSizeDecreased = () => {
		const fontSize = readerMode.fontSize - 1 < 12 ? 12 : readerMode.fontSize - 1;
		setReaderMode({ ...readerMode, fontSize: fontSize });
	};

	const fontSizeDefault = () => {
		setReaderMode({ ...readerMode, fontSize: 20 });
	};

	return (
		<>
			<PaywallModal isShow={showPaywall} onClose={() => setShowPaywall(false)} analyticsReference={kAnalyticsConstants.Reference.Paywall.readerMode} />
			<div className="w-full max-w-sm">
				<Popover className="relative">
					{({ open }) => (
						<>
							<Popover.Button className="flex flex-row items-center">{renderIconButton(open)}</Popover.Button>
							<Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
								<Popover.Panel className="absolute left-1/2 z-10 mt-1 w-screen max-w-sm -translate-x-1/2 transform">
									<div className="overflow-hidden rounded-lg shadow-lg foc ring-0 ring-black/5">
										<div className="relative bg-surface-200 border border-primary-100 rounded-xl py-4">
											<div className="flex flex-col gap-4 w-full">
												<div className="px-4">
													<button className={`font-primary font-regular text-lg rounded-full p-2.5 w-full transition ease-in-out duration-300 ${readerMode.state ? "border-[3px] border-success-green text-primary" : "bg-success-green hover:bg-success-green/90 text-white"}`} onClick={readerModeStateChanged}>
														Turn {readerMode.state ? "off" : "on"} reader mode
													</button>
													{isError && <div className="font-primary font-regular text-xs text-yellow-500 mt-2 text-center">This newsletter is not compatible with Reader Mode.</div>}
												</div>
												<div className={`${readerMode.state && !isError ? "opacity-100 pointer-events-auto" : "opacity-50 pointer-events-none"} flex flex-col gap-4 w-full`}>
													<div className="h-[1px] w-full bg-primary-100" />
													<div className="px-4 flex flex-row justify-between items-center">
														<div className="font-primary font-regular text-md text-primary">Font</div>
														<ListboxView selected={ReaderModeChoices.fontDisplayName(readerMode.font)} setSelected={(fontDisplayName) => fontSelected(fontDisplayName)} options={Object.values(ReaderModeChoices.Font).map((font) => ReaderModeChoices.fontDisplayName(font))} />
													</div>
													<div className="h-[1px] w-full bg-primary-100" />
													<div className="px-4 grid grid-cols-4 gap-3 font-primary font-regular text-sm">
														{Object.values(ReaderModeChoices.Theme).map((theme) => {
															return (
																<button style={{ backgroundColor: ReaderModeChoices.themeBackgroundColor(theme), color: ReaderModeChoices.themeForegroundColor(theme) }} className={`px-2 py-2.5 rounded-xl text-center ${theme == readerMode.theme ? "border-[3px] border-success-green" : null}`} onClick={() => themeSelected(theme)}>
																	{ReaderModeChoices.themeDisplayName(theme)}
																</button>
															);
														})}
													</div>
													<div className="h-[1px] w-full bg-primary-100" />
													<div className="px-4 grid grid-cols-3 gap-3 font-primary font-medium text-sm">
														<button className="px-2 py-2.5 bg-surface hover:bg-surface-100 transition duration-300 ease-in-out text-primary rounded-xl text-center text-xs" onClick={() => fontSizeDecreased()}>
															A
														</button>
														<button className={`px-2 py-2.5 bg-surface hover:bg-surface-100 transition duration-300 ease-in-out text-primary rounded-xl text-center ${readerMode.fontSize === 20 ? "opacity-50" : "opacity-100"}`} onClick={() => fontSizeDefault()}>
															100%
														</button>
														<button className="px-2 py-2.5 bg-surface hover:bg-surface-100 transition duration-300 ease-in-out text-primary rounded-xl text-center text-md" onClick={() => fontSizeIncreased()}>
															A
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Popover.Panel>
							</Transition>
						</>
					)}
				</Popover>
			</div>
		</>
	);
};

export default ReaderModePopOver;
