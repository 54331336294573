import { NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";

export class PartialSyncMessagesService {
	private queries: PartialSyncMessagesService.QueryBuilder[];

	constructor(queries: PartialSyncMessagesService.QueryBuilder[]) {
		this.queries = queries;
	}

	partialSyncMessages(completion: (responseModel: PartialSyncMessagesService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();

		let requestArray: PartialSyncMessagesService.RequestModel[] = [];
		let responseArray: PartialSyncMessagesService.ResponseModel[] = [];
		const promises: Promise<void>[] = [];

		for (const requestModel of this.queries) {
			const promise = new Promise<void>((innerResolve, innerReject) => {
				if (requestModel.mailboxProfile.type !== NLMailboxProfileType.Internal) {
					oauthSessionManager
						.fetchAccessTokenForMailboxProfile(requestModel.mailboxProfile.id, requestModel.mailboxProfile.type)
						.then((accessToken) => {
							const request = new PartialSyncMessagesService.RequestModel(requestModel.mailboxProfile.id, requestModel.historyId, accessToken);
							requestArray.push(request);
							innerResolve();
						})
						.catch((error) => {
							if (error instanceof Error && error.message === "noInternetError") {
								innerReject(error);
							} else {
								var responseModel = new PartialSyncMessagesService.ResponseModel(requestModel.mailboxProfile.id);
								responseModel.error = oauthSessionManager.generateSignInRequiredError(requestModel.mailboxProfile.id, error);
								responseArray.push(responseModel);
								innerResolve();
							}
						});
				} else {
					const request = new PartialSyncMessagesService.RequestModel(requestModel.mailboxProfile.id, requestModel.historyId, null);
					requestArray.push(request);
					innerResolve();
				}
			});
			promises.push(promise);
		}

		Promise.all(promises)
			.then(() => {
				if (requestArray.length === 0) {
					completion(responseArray, undefined);
					return;
				}

				this.makeRequests(requestArray, responseArray, completion);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}

	private makeRequests(requestArray: PartialSyncMessagesService.RequestModel[], responseArray: PartialSyncMessagesService.ResponseModel[], completion: (responseArray: PartialSyncMessagesService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		networkManager
			.partialSyncMessages(requestArray)
			.then((jsonData) => {
				var responseModels = responseArray;

				for (let mailboxProfileId in jsonData) {
					let responseData = jsonData[mailboxProfileId];
					var responseModel = new PartialSyncMessagesService.ResponseModel(mailboxProfileId);

					if (responseData["messages_added"]) {
						responseModel.messages_added = responseData["messages_added"];
					}

					if (responseData["messages_deleted"]) {
						responseModel.messages_deleted = responseData["messages_deleted"];
					}

					if (responseData["history_id"]) {
						responseModel.history_id = responseData["history_id"];
					}

					if (responseData["error"]) {
						responseModel.error = MailboxError.returnError(responseData["error"], mailboxProfileId);
					}

					responseModels.push(responseModel);
				}

				completion(responseModels, undefined);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}
}

export namespace PartialSyncMessagesService {
	export class RequestModel {
		access_token: string | null;
		mailbox_profile_id!: string;
		history_id!: string;

		constructor(mailboxProfileId: string, historyId: string, accessToken: string | null) {
			this.mailbox_profile_id = mailboxProfileId;
			this.history_id = historyId;
			this.access_token = accessToken;
		}
	}

	export class ResponseModel {
		mailboxProfileId!: string;
		messages_added?: string[] | null;
		messages_deleted?: string[] | null;
		history_id?: string | null;
		error?: Error | null;

		constructor(mailboxProfileId: string) {
			this.mailboxProfileId = mailboxProfileId;
		}
	}

	export class QueryBuilder {
		mailboxProfile: NLMailboxProfile;
		historyId: string;

		constructor(mailboxProfile: NLMailboxProfile, historyId: string) {
			this.mailboxProfile = mailboxProfile;
			this.historyId = historyId;
		}
	}
}
