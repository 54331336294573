import { Popover, Transition } from "@headlessui/react";
import { ReactComponent as SparkleIcon } from "@images/sparkle_icon.svg";
import IconButton from "./IconButton";
import { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { NLMail } from "@models/Models";
import PaywallModal from "./PaywallModal";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import { useAuth } from "@providers/AuthContext";
import { MessageSummaryService } from "@utils/managers/backendMailManager/mailboxFunctions/MessageSummaryService";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { usePremium } from "@providers/PremiumContext";
import { recordEvent, generateNewsletterProperties } from "@utils/managers/AnalyticsManager";

interface SummaryPopOverProps {
	mail: NLMail;
}

const SummaryPopOver: FC<SummaryPopOverProps> = ({ mail }) => {
	const { authUser } = useAuth();
	const [summaryText, setSummaryText] = useState<string | undefined>(undefined);
	const { isPremium } = usePremium();

	enum SummaryState {
		readyToQuery,
		upgradeToPro,
		loading,
		error,
		summary,
	}

	const [showPaywall, setShowPaywall] = useState(false);
	const [isOpened, setIsOpened] = useState<boolean>(false);
	const [summaryState, setSummaryState] = useState<SummaryState>(SummaryState.loading);

	useEffect(() => {
		if (isOpened && summaryState === SummaryState.readyToQuery) {
			getSummary();
		}
	}, [isOpened]);

	useEffect(() => {
		if (isOpened) {
			getSummary();
		} else {
			setSummaryState(SummaryState.readyToQuery);
		}
	}, [mail]);

	const getSummary = () => {

		recordEvent(kAnalyticsConstants.App.summaryTapped, generateNewsletterProperties(mail));

		if (!isPremium()) {
			setSummaryState(SummaryState.upgradeToPro);
			return;
		}

		let mailboxProfile = authUser!.mailbox_profiles.find((x) => x.id === mail.mailbox_profile_id);

		if (!mailboxProfile) {
			setSummaryState(SummaryState.error);
			return;
		}

		let summaryRequest = new MessageSummaryService(mailboxProfile, mail.id);

		setSummaryState(SummaryState.loading);

		summaryRequest.getMessageSummary((summaryString, error) => {
			if (!summaryString) {
				var mutableProperties = generateNewsletterProperties(mail);
				if (error) {
					mutableProperties["error"] = error.message;
				}
				recordEvent(kAnalyticsConstants.App.summaryError, mutableProperties);
				setSummaryText(undefined);
				setSummaryState(SummaryState.error);
				return;
			}
			recordEvent(kAnalyticsConstants.App.summaryLoaded, generateNewsletterProperties(mail));
			setSummaryText(summaryString);
			setSummaryState(SummaryState.summary);
		});
	};

	const renderIconButton = (isOpen: boolean): ReactNode => {
		setIsOpened(isOpen);
		if (isOpen) {
			return <IconButton Icon={SparkleIcon} className="bg-success-green/20 hover:bg-success-green/20" iconClassName="fill-primary" onClick={(e) => e} />;
		}
		return <IconButton Icon={SparkleIcon} onClick={(e) => e} />;
	};

	const renderState = () => {
		switch (summaryState) {
			case SummaryState.loading:
			case SummaryState.readyToQuery:
				return (
					<div className="h-full flex items-center justify-center">
						<LoadingSpinner className="m-auto h-[30px] text-primary/20 animate-spin fill-primary" />
					</div>
				);
			case SummaryState.upgradeToPro:
				return (
					<div className="h-full flex flex-col gap-3 items-center justify-center">
						<div className="text-primary font-medium font-primary text-xl text-center">Save time with AI generated newsletter summaries</div>
						<button className={`flex gap-2 cta-button w-full !max-w-full shadow-2xl !bg-success-green hover:bg-success-green/80`} onClick={() => setShowPaywall(true)}>
							<div>Upgrade to PRO</div>
						</button>
					</div>
				);
			case SummaryState.error:
				return (
					<div className="h-full flex flex-col items-center justify-center">
						<div className="font-[none] text-[28px] text-center">⚠️</div>
						<div className="font-primary font-medium text-primary-500 text-sm">Something went wrong. Please try again later.</div>
					</div>
				);
			case SummaryState.summary:
				return (
					<div className="flex flex-col gap-3">
						<div className="font-primary font-medium text-primary text-base">{summaryText}</div>
						<div className="font-primary font-regular text-primary-500 text-xs">Note: Summaries are generated using AI. While we strive for accuracy, AI-generated content may not always be perfect. Please review critically and use your judgment.</div>
					</div>
				);
		}
	};

	return (
		<>
			<PaywallModal isShow={showPaywall} onClose={() => setShowPaywall(false)} analyticsReference={kAnalyticsConstants.Reference.Paywall.aiSummaru} />
			<div className="w-full max-w-sm">
				<Popover className="relative">
					{({ open }) => (
						<>
							<Popover.Button className="flex flex-row items-center">{renderIconButton(open)}</Popover.Button>
							<Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
								<Popover.Panel className="absolute left-1/2 z-10 mt-1 w-screen max-w-sm -translate-x-1/2 transform">
									<div className="overflow-hidden rounded-lg shadow-lg foc ring-0 ring-black/5">
										<div className="relative bg-surface-200 border border-primary-100 rounded-xl pt-4">
											<div className="flex flex-col w-full items-center">
												<div className="font-primary font-black text-primary text-base pb-3">✨ Summary</div>
												<div className="w-full h-[1px] bg-primary-100" />
												<div className="h-[300px] overflow-y-scroll p-4 pt-3">{renderState()}</div>
											</div>
										</div>
									</div>
								</Popover.Panel>
							</Transition>
						</>
					)}
				</Popover>
			</div>
		</>
	);
};

export default SummaryPopOver;
