import { ReactComponent as NewSenderIcon } from "@images/new_sender_icon.svg";
import { ReactComponent as AddSenderSearchIcon } from "@images/add_sender_search_icon.svg";
import { ReactComponent as SearchIcon } from "@images/search_icon.svg";
import { ReactComponent as FilterIcon } from "@images/unread_filter_icon.svg";
import { useData } from "@providers/DataContext";
import { NLUserMailStateNames } from "@models/Enums";
import { ReactComponent as OptionsIcon } from "@images/options_dots.svg";
import { Textfit } from "react-textfit";
import { NLMailboxProfileType, NLUserMailState } from "@models/Models";
import { useEffect, useRef, useState } from "react";
import { LayoutGroup, motion } from "framer-motion";
import IconButton from "@components/common/IconButton";
import Dropdown, { DropdownItem } from "@components/common/Dropdown";
import { useAuth } from "@providers/AuthContext";
import NewSenderModal from "@components/senders/NewSenderModal";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import moment from "moment";
import AddSenderSearchModal from "./AddSenderSearchModal/AddSenderSearchModal";
import SectionLayout from "@layouts/SectionLayout";
import AddGroupModal, { AddGroupState } from "@components/common/AddGroupModal";
import { useNavigate } from "react-router-dom";

const Senders = () => {
	const { userMailStates, setActiveUserMailState, changeStates, setDigestNeedsReloading } = useData();
	const { authUser } = useAuth();
	const [searchExpanded, setSearchExpanded] = useState<boolean>(false);
	const searchBoxRef = useRef<HTMLInputElement>(null);
	const [senderSearchQuery, setSearchQuery] = useState("");
	const [filteredUserMailStates, setFilteredUserMailStates] = useState<NLUserMailState[] | undefined>(undefined);
	const [showNewSenderModal, setShowNewSenderModal] = useState<boolean>(false);
	const [showAddSenderSearchModal, setAddSenderSearchModal] = useState<boolean>(false);
	const [addGroupState, setAddGroupState] = useState<AddGroupState>({
		state: undefined,
		isShow: false,
	});
	const [newSendersCount, setNewSendersCount] = useState<number | undefined>(undefined);
	const navigate = useNavigate();

	useEffect(() => {
		const filteredItems = filterUserMailStates(userMailStates ?? [], senderSearchQuery);
		setFilteredUserMailStates(filteredItems);
	}, [userMailStates, senderSearchQuery]);

	useEffect(() => {
		updateNewSenderCount();
	}, [userMailStates]);

	const senderOnClick = (state: NLUserMailState) => {
		setActiveUserMailState(state);
		navigate("/");
	};

	const searchOnClick = () => {
		if (!searchBoxRef.current) {
			return;
		}

		if (searchExpanded) {
			setSearchQuery("");
			setSearchExpanded(false);
			searchBoxRef.current.blur();
			return;
		}

		if (!searchExpanded) {
			setSearchQuery("");
			setSearchExpanded(true);
			searchBoxRef.current.focus();
			return;
		}
	};

	const updateNewSenderCount = () => {
		if (!userMailStates) {
			setNewSendersCount(undefined);
			return;
		}

		var newStates = userMailStates.filter((x) => x.state_id === NLUserMailStateNames.new);
		const newSenderLastView = localStorage.getItem(kLocalStorageKeys.App.newSenderLastView);
		if (newSenderLastView) {
			const lastViewDate = moment(newSenderLastView).toDate();
			newStates = newStates.filter((x) => x.updated_at >= lastViewDate);
		}
		setNewSendersCount(newStates.length);
	};

	const filterUserMailStates = (userMailStates: NLUserMailState[], searchQuery: string): NLUserMailState[] => {
		const query = searchQuery.toLowerCase();
		var filteredUserMailStates = userMailStates.filter((x) => x.state_id === NLUserMailStateNames.feed);
		if (query) {
			filteredUserMailStates = filteredUserMailStates.filter((sender) => (sender.sender_name ?? "").toLowerCase().includes(query));
		}
		return filteredUserMailStates.sort((a, b) => (a?.sender_name || "").localeCompare(b?.sender_name || ""));
	};

	const getStateOptions = (userMailState: NLUserMailState) => {
		var dropDownOptions: DropdownItem[] = [{ name: "Add sender to group", icon: "folder_icon", action: () => setAddGroupState({ state: { ...userMailState }, isShow: true }) }];
		const mailboxProfile = authUser!.mailbox_profiles.find((x) => x.id === userMailState.mailbox_profile_id)
		if (mailboxProfile && mailboxProfile.type !== NLMailboxProfileType.Internal) {
			const typeName = mailboxProfile?.type === NLMailboxProfileType.Gmail ? "Gmail" : "Outlook"
			const stateActionItem = {
				name: `Move sender to ${typeName} inbox`,
				icon: "revert_icon",
				action: () => {
					changeStates(authUser!.mailbox_profiles, [userMailState], NLUserMailStateNames.inbox, () => {});
				},
			};
			dropDownOptions.push(stateActionItem);
		}
		return dropDownOptions;
	};

	return (
		<>
			<NewSenderModal
				isShow={showNewSenderModal}
				onClose={() => {
					updateNewSenderCount();
					setShowNewSenderModal(false);
				}}
			/>
			<AddGroupModal addGroupState={addGroupState} setAddGroupState={setAddGroupState} />
			<AddSenderSearchModal isShow={showAddSenderSearchModal} onClose={() => setAddSenderSearchModal(false)} />
			<div className="container mx-auto px-12 py-5">
				<div className="flex flex-col gap-5">
					{authUser!.mailbox_profiles.find((x) => x.type !== NLMailboxProfileType.Internal) && (
						<SectionLayout sectionTitle="Add new senders">
							<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
								<button className="flex flex-row justify-between items-center bg-surface border hover:surface-100 transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 shadow-sm" onClick={() => setShowNewSenderModal(true)}>
									<div className="flex flex-row items-center">
										<NewSenderIcon className="fill-primary" />
										<div className="pl-[12px] font-primary font-medium text-base  text-primary">New senders</div>
									</div>
									{newSendersCount !== undefined && newSendersCount > 0 && <div className="rounded-full px-3 bg-brand-red font-primary font-medium text-xs text-white">{newSendersCount}</div>}
								</button>
								<button className="flex flex-row justify-between items-center bg-surface border hover:surface-100 transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 shadow-sm" onClick={() => setAddSenderSearchModal(true)}>
									<div className="flex flex-row items-center">
										<AddSenderSearchIcon className="fill-primary" />
										<div className="pl-[12px] font-primary font-medium text-base  text-primary">Add senders by search</div>
									</div>
								</button>
							</div>
						</SectionLayout>
					)}
					<SectionLayout
						sectionTitle="Senders"
						sectionRightDiv={
							<div className="flex flex-row gap-1 items-center">
								<IconButton Icon={SearchIcon} onClick={() => searchOnClick()} />
								<input value={senderSearchQuery} ref={searchBoxRef} type="text" className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border h-[40px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${searchExpanded ? "sender-search-expanded" : "sender-search-hidden"}`} placeholder="Search senders..." onChange={(value) => setSearchQuery(value.currentTarget.value)} />
								{authUser!.mailbox_profiles.length > 1 && <IconButton Icon={FilterIcon} forcedIconSize={16} onClick={() => searchOnClick()} />}
							</div>
						}>
						<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
							<LayoutGroup>
								{filteredUserMailStates &&
									filteredUserMailStates.map((sender) => {
										return (
											<motion.div key={sender.id} id={`sender-${sender.id}`} layout layoutScroll={true} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ ease: "easeInOut", duration: 0.3, type: "spring" }}>
												<button className="group flex items-center gap-4 bg-surface border hover:bg-surface-100 transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full shadow-sm" onClick={() => senderOnClick(sender)}>
													<div className="flex min-w-0 flex-grow">
														<Textfit min={12} max={16} mode="single" className="font-primary font-medium text-base text-primary text-left truncate">
															{sender.sender_name}
														</Textfit>
													</div>
													<Dropdown buttonOnEdge={true} buttonIcon={OptionsIcon} items={getStateOptions(sender)} />
												</button>
											</motion.div>
										);
									})}
							</LayoutGroup>
						</div>
					</SectionLayout>
				</div>
			</div>
		</>
	);
};

export default Senders;
