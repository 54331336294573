import React, { useState, useEffect, FC, Fragment } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { useHotkeys } from "react-hotkeys-hook";
import IconButton from "@components/common/IconButton";
import { useAuth } from "@providers/AuthContext";
import Alert, { AlertState } from "@components/common/Alert";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { getConsents, postConsent, deleteConsent } from "@utils/managers/networking/NetworkManager";
import { optInOptOutAnalytics } from "@utils/managers/AnalyticsManager";
import { NLConsent } from "@models/Models";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import LoadingView, { LoadingState } from "@components/common/LoadingView";

interface ManageConsentsModalProps {
	isShow: boolean;
	onClose: () => void;
}

const ManageConsentsModal: FC<ManageConsentsModalProps> = ({ isShow, onClose }) => {
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});
	const [consents, setConsents] = useState<NLConsent[] | undefined>(undefined);

	useHotkeys("Escape", () => onModalClose());

	useEffect(() => {
		if (isShow) {
			getConsents()
				.then((response) => {
					setConsents(response);
				})
				.catch((_error) => {
					setAlertState({
						isShow: true,
						title: "Uh oh! Something went wrong",
						message: "Please try again and contact us if the problem persists",
						actionButton: "OK",
						dismissButton: null,
						actionButtonAction: () => onClose(),
					});
				});
		}
	}, [isShow]);

	const onModalClose = () => {
		onClose();
	};

	const consentOnChange = (consent: NLConsent, isEnabled: boolean) => {
		setLoadingState({ isLoading: true });
		if (isEnabled) {
			postConsent(consent.id)
				.then((response) => {
                    if (consent.key == "analytics") {
                        optInOptOutAnalytics(true);
                    }
					setConsents(response);
				})
				.catch((_error) => {
					setAlertState({
						isShow: true,
						title: "Uh oh! Something went wrong",
						message: "Please try again and contact us if the problem persists",
						actionButton: "OK",
						dismissButton: null,
						actionButtonAction: () => onClose(),
					});
				})
				.finally(() => {
					setLoadingState({ isLoading: false });
				});
		} else {
			deleteConsent(consent.id)
				.then((response) => {
                    if (consent.key == "analytics") {
                        optInOptOutAnalytics(false);
                    }
					setConsents(response);
				})
				.catch((_error) => {
					setAlertState({
						isShow: true,
						title: "Uh oh! Something went wrong",
						message: "Please try again and contact us if the problem persists",
						actionButton: "OK",
						dismissButton: null,
						actionButtonAction: () => onClose(),
					});
				})
				.finally(() => {
					setLoadingState({ isLoading: false });
				});
		}
	};

	return (
		<>
			<LoadingView loadingState={loadingState} />
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child className="w-full h-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="m-auto w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
								<div className="absolute w-full bg-surface backdrop-blur-md top-0 left-0 flex flex-row justify-between items-center p-4 pb-3 border-b border-primary-200 z-20">
									<div className="text-primary font-medium font-primary text-xl">Manage your consents</div>
									<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onModalClose()} />
								</div>
								<div className="flex flex-col gap-4 text-left h-full p-4 py-[105px] bg-secondary overflow-auto">
									{consents !== undefined ? (
										consents.map((consent, index) => {
											return (
												<div className="flex flex-col gap-2">
													<div className="flex flex-row justify-between items-center bg-surface border transition ease-in-out duration-300 border-primary-200 rounded-xl p-3 w-full">
														<div className="flex flex-col gap-1 w-full">
															<div className="text-primary font-medium font-primary text-base">{consent.title}</div>
															<div className="text-primary-500 font-regular font-primary text-xs text-left">{consent.description}</div>
														</div>
														<div className="flex-shrink-0">
															<Switch disabled={consent.required} checked={consent.status} onChange={(checked) => consentOnChange(consent, checked)} className={`${consent.status ? "bg-success-green" : "bg-primary-200"} ${consent.required ? "opacity-50" : "opacity-100"} relative inline-flex h-[30px] w-[55px] items-center rounded-full`}>
																<span className={`${consent.status ? "translate-x-[26px]" : "translate-x-[2px]"} inline-block h-[28px] w-[28px] transform rounded-full bg-white transition`} />
															</Switch>
														</div>
													</div>
												</div>
											);
										})
									) : (
										<LoadingSpinner className="w-[30px] text-primary-200 animate-spin fill-primary m-auto" />
									)}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default ManageConsentsModal;
