import { ReactComponent as SidebarExpandIcon } from "@images/sidebar_expand_icon.svg";
import { useLayout } from "@providers/LayoutContext";
import IconButton from "@components/common/IconButton";
import { Textfit } from "react-textfit";
import { FC, useEffect, useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "@images/search_icon.svg";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";

interface SearchHeaderProps {
	searchQuery: string;
	setSearchQuery: (searchQuery: string) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
}

const SearchHeader: FC<SearchHeaderProps> = ({ searchQuery, setSearchQuery, isLoading, setIsLoading }) => {
	const { isSidebarHidden, setSidebarHidden, searchNavigationBarHeight } = useLayout();
	const searchRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setTimeout(() => {
			searchRef?.current?.focus();
		}, 100);
	}, []);

	return (
		<>
			<div className="sticky top-0 bg-white/5 backdrop-blur-lg">
				<div className="flex flex-col gap-3 items-center border-b border-primary-100" style={{ height: searchNavigationBarHeight + "px" }}>
					<div className="flex flex-row justify-between w-full p-3 items-center h-full">
						<div className="flex flex-col items-start gap-3 w-full">
							<div className="flex flex-row gap-2 items-center min-w-0 flex-grow">
								<div className={isSidebarHidden ? "block" : "hidden"}>
									<IconButton Icon={SidebarExpandIcon} forcedIconSize={24} onClick={() => setSidebarHidden(false)} />
								</div>
								<div className="flex flex-row items-center gap-1 w-full">
									<Textfit max={18} mode="single" className="font-primary text-lg font-black text-primary text-left truncate">
										Search
									</Textfit>
								</div>
							</div>
							<div className="flex flex-row items-center gap-3 w-full">
								<div className="relative w-full">
									<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
										<SearchIcon className="w-[20px] h-[20px] fill-primary" />
									</div>
									<input type="text" value={searchQuery} className={`!ps-12 flex-grow bg-secondary focus:ring-1 focus:border-primary focus:ring-primary border h-[55px] border-primary-200 rounded-[12px] font-regular font-primary placeholder-primary-500 w-full pr-[150px] text-primary`} placeholder="Search senders..." onChange={(e) => setSearchQuery(e.currentTarget.value)} ref={searchRef} />
									{isLoading && <LoadingSpinner className="absolute end-3 bottom-3 m-auto h-[25px] text-primary-200 animate-spin fill-primary" />}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SearchHeader;
