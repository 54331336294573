import { Textfit } from "react-textfit";
import "../../onboarding/NewsletterPicker/NewsletterPickerRow.scss";
import { FC } from "react";
import { AudioSenderModel } from "@models/Models";
import * as ElapsedDate from "@utils/Date+ElapsedInterval";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

interface AudioPickSendersRowProps {
	audioSenderModel: AudioSenderModel;
	isChecked: boolean;
	checkDidChange: (senderAddress: string) => void;
	isLimit: boolean;
}

const AudioPickSendersRow: FC<AudioPickSendersRowProps> = ({ audioSenderModel, isChecked, checkDidChange, isLimit }) => {
	const previewOnClick = () => {
		if (!audioSenderModel.mail) {
			return;
		}
		recordEvent(kAnalyticsConstants.Audio.senderPreviewed, { sender_name: audioSenderModel.mail.sender_name ?? "", sender_address: audioSenderModel.mail.sender_address ?? "" });
		window.open(`/mail/${audioSenderModel.mail.id}`);
	};

	return (
		<div key={audioSenderModel.userMailState.id} className="relative">
			<label className={`flex p-[30px] flex-row w-full justify-between items-center gap-2 cursor-pointer select-newsletter-row transition ease-in-out ${isChecked ? "selected-sender" : "hover:bg-white/20"} ${isLimit && !isChecked ? "opacity-50" : "opacity-100"}`}>
				<div className="flex flex-row items-center text-left gap-3">
					<input readOnly className="checkbox-round" type="checkbox" name="selectable-sender" checked={isChecked} value={audioSenderModel.userMailState.id} onChange={() => checkDidChange(audioSenderModel.userMailState.sender_address)} />
					<div className="flex flex-col gap-2">
						<div>
							<Textfit mode="single" className="text-white font-medium text-xl font-primary max-w-[250px]" max={20}>
								{audioSenderModel.userMailState.sender_name}
							</Textfit>
							<Textfit mode="single" className="text-white/50 font-regular text-sm font-primary max-w-[250px]" max={14}>
								{audioSenderModel.userMailState.sender_address}
							</Textfit>
						</div>
						{audioSenderModel.mail && <div className="text-white/50 font-regular text-xs font-primary">Last received: {ElapsedDate.elapsedInterval(audioSenderModel.mail.receive_date)}</div>}
					</div>
				</div>
				{audioSenderModel.mail && (
					<button className={`absolute rounded-lg font-medium font-primary text-sm px-3 py-2 bg-white/50 text-white bottom-3 right-3`} onClick={previewOnClick}>
						PREVIEW
					</button>
				)}
			</label>
		</div>
	);
};

export default AudioPickSendersRow;
