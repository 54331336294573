import { NLMail, NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import { DeleteUndeleteMessagesService } from "./mailboxFunctions/DeleteUndeleteMessagesService";
import { kErrorConstants } from "@utils/constants/ErrorConstants";

export const deleteUndeleteMessages = (mails: NLMail[], mailboxProfiles: NLMailboxProfile[], isDelete: boolean, completion: (successArray: string[] | undefined, error: Error | undefined) => void) => {
	var requests: DeleteUndeleteMessagesService.QueryBuilder[] = [];

	const mailsByMailboxProfileId: { [mailboxProfileId: string]: NLMail[] } = {};
	mails.forEach((mail) => {
		if (!mailsByMailboxProfileId[mail.mailbox_profile_id]) {
			mailsByMailboxProfileId[mail.mailbox_profile_id] = [];
		}
		mailsByMailboxProfileId[mail.mailbox_profile_id].push(mail);
	});

	for (let mailboxProfileId in mailsByMailboxProfileId) {
		const mailboxProfile = mailboxProfiles.find((x) => x.id === mailboxProfileId);
		if (!mailboxProfile) {
			continue;
		}
		if (!isDelete && mailboxProfile.type !== NLMailboxProfileType.Internal) {
			continue;
		}

		const messageIds = mailsByMailboxProfileId[mailboxProfileId].map((x) => x.id);

		var query = new DeleteUndeleteMessagesService.QueryBuilder(mailboxProfile, messageIds, isDelete);
		requests.push(query);
	}

	if (requests.length === 0) {
		completion(undefined, kErrorConstants.dataProcessingError);
		return;
	}

	const deleteMessagesService = new DeleteUndeleteMessagesService(requests);

	deleteMessagesService.deleteUndeleteMessages((responseArray, error) => {
		var successfulMessageIdArray: string[] | undefined;
		var newError: Error | undefined = error;

		for (let response of responseArray ?? []) {
			if (response.error) {
				newError = response.error;
				continue;
			}

			if (response.successes) {
				successfulMessageIdArray = successfulMessageIdArray ?? [];
				successfulMessageIdArray.push(...response.successes);
			}
		}

		completion(successfulMessageIdArray, error);
	});
};
