import { NLMailboxProfile, NLMailboxProfileType, NLUserMailState } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";

export class CreateSenderService {
	private mailboxProfile: NLMailboxProfile;
	private senderName: string;
	private senderAddress: string;

	constructor(mailboxProfile: NLMailboxProfile, senderName: string, senderAddress: string) {
		this.mailboxProfile = mailboxProfile;
		this.senderName = senderName;
		this.senderAddress = senderAddress;
	}

	createSender(completion: (userMailState: NLUserMailState | undefined, error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();
		var requestModel: CreateSenderService.RequestModel | undefined;
		const promises: Promise<void>[] = [];

		const promise = new Promise<void>((innerResolve, innerReject) => {
			oauthSessionManager
				.fetchAccessTokenForMailboxProfile(this.mailboxProfile.id, this.mailboxProfile.type)
				.then((accessToken) => {
					requestModel = new CreateSenderService.RequestModel(this.mailboxProfile.id, this.senderName, this.senderAddress, accessToken);
					innerResolve();
				})
				.catch((error) => {
					innerReject(error);
					return;
				});
		});
		promises.push(promise);

		Promise.all(promises)
			.then(() => {
				if (!requestModel) {
					completion(undefined, undefined);
					return;
				}
				this.makeRequests(requestModel, completion);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}

	private makeRequests(request: CreateSenderService.RequestModel, completion: (userMailState: NLUserMailState | undefined, error: Error | undefined) => void) {
		networkManager
			.createSender(request)
			.then((userMailState) => {
				completion(userMailState, undefined);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}
}

export namespace CreateSenderService {
	export class RequestModel {
		access_token: string | undefined;
		mailbox_profile_id!: string;
		sender_name!: string;
		sender_address!: string;

		constructor(mailboxProfileId: string, senderName: string, senderAddress: string, accessToken?: string) {
			this.mailbox_profile_id = mailboxProfileId;
			this.sender_name = senderName;
			this.sender_address = senderAddress;
			this.access_token = accessToken;
		}
	}
}
