import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const ParticlesBackground: React.FC = () => {
	const particlesInit = async (main) => {
		await loadFull(main);
	};

	return (
		<div>
			<div>
				<div className="gradient_overlay"></div>
				<Particles
					className="particles"
					init={particlesInit}
					options={{
						particles: {
							number: {
								value: 200,
							},
							color: {
								value: "#FFFFFF",
							},
							shape: {
								type: "circle",
								stroke: {
									width: 0,
									color: "#000000",
								},
								polygon: {
									nb_sides: 5,
								},
							},
							opacity: {
								value: 0.5,
								random: true,
								anim: {
									enable: false,
									speed: 1,
									opacity_min: 0.1,
									sync: false,
								},
							},
							size: {
								value: 1,
								random: true,
								anim: {
									enable: false,
									speed: 40,
									size_min: 0.1,
									sync: false,
								},
							},
							line_linked: {
								enable: false,
								distance: 150,
								color: "#ffffff",
								opacity: 0.4,
								width: 1,
							},
							move: {
								enable: true,
								speed: 1,
								direction: "right",
								random: false,
								straight: false,
								out_mode: "out",
								bounce: false,
								attract: {
									enable: false,
									rotateX: 600,
									rotateY: 1200,
								},
							},
						},
						interactivity: {
							detect_on: "canvas",
							events: {
								onhover: {
									enable: true,
									mode: "repulse",
								},
								onclick: {
									enable: true,
									mode: "push",
								},
								resize: true,
							},
							modes: {
								grab: {
									distance: 400,
									line_linked: {
										opacity: 1,
									},
								},
								bubble: {
									distance: 400,
									size: 40,
									duration: 2,
									opacity: 8,
									speed: 3,
								},
								repulse: {
									distance: 200,
									duration: 0.4,
								},
								push: {
									particles_nb: 4,
								},
								remove: {
									particles_nb: 2,
								},
							},
						},
						retina_detect: false,
					}}
				/>
				<div className="green_gradient"></div>
				<div className="red_gradient"></div>
				<div className="container_overlay sm:bg-transparent bg-black/30"></div>
			</div>
		</div>
	);
};

export default React.memo(ParticlesBackground);
