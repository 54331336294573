import React, { useState, useEffect, useCallback, FC, Fragment } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import IconButton from "../common/IconButton";
import { useData } from "@providers/DataContext";
import { NLUserMailState, NLUserMailStateNames } from "@models/Models";
import SenderListRow from "../common/SenderListRow";
import { useAuth } from "@providers/AuthContext";
import EmptyStateView from "@components/common/EmptyStateView";
import baloonImage from "@images/collage_paywall_balloon.png";
import { useHotkeys } from "react-hotkeys-hook";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import moment from "moment";
import { Dialog, Transition } from "@headlessui/react";

interface NewSenderModalProps {
	isShow: boolean;
	onClose: () => void;
}

const NewSenderModal: FC<NewSenderModalProps> = ({ isShow, onClose }) => {
	const { userMailStates, setUserMailStates, setDigestNeedsReloading, changeStates } = useData();
	const { authUser } = useAuth();
	const [newUserMailStates, setNewUserMailStates] = useState<NLUserMailState[]>([]);
	const [selectedSenders, setSelectedSenders] = useState<NLUserMailState[]>([]);
	const [previousLastViewDate, setPreviousLastViewDate] = useState<Date | undefined>(undefined);

	var hasMadeChanges = false;

	useHotkeys("Escape", () => onModalClose());

	useEffect(() => {
		if (isShow) {
			const newSenderLastView = localStorage.getItem(kLocalStorageKeys.App.newSenderLastView);
			if (newSenderLastView) {
				setPreviousLastViewDate(moment(newSenderLastView).toDate());
			}
			localStorage.setItem(kLocalStorageKeys.App.newSenderLastView, new Date().toUTCString());
		}
		return () => {
			setSelectedSenders([]);
			hasMadeChanges = false;
		}
	}, [isShow]);

	useEffect(() => {
		const filteredItems = filterUserMailStates(userMailStates ?? []);
		setNewUserMailStates(filteredItems);
	}, [userMailStates]);

	const filterUserMailStates = (userMailStates: NLUserMailState[]): NLUserMailState[] => {
		var filteredUserMailStates = userMailStates.filter((x) => x.state_id === NLUserMailStateNames.new);
		return filteredUserMailStates.sort((a, b) => +b.updated_at - +a.updated_at);
	};

	const senderSelected = (userMailState: NLUserMailState) => {
		var tempSelectedSenders = [...selectedSenders];
		const selectedIds = tempSelectedSenders.map((x) => x.id);
		if (selectedIds.includes(userMailState.id)) {
			tempSelectedSenders = tempSelectedSenders.filter((x) => x.id != userMailState.id);
		} else {
			tempSelectedSenders.push(userMailState);
		}

		setSelectedSenders(tempSelectedSenders);
	};

	const modifyStates = (action: NLUserMailStateNames) => {
		if (senderSelected.length === 0 || !userMailStates) {
			return;
		}

		changeStates(authUser!.mailbox_profiles, selectedSenders, action, () => {});

		if (action === NLUserMailStateNames.feed) {
			hasMadeChanges = true;
		}
		setSelectedSenders([]);
		const filteredItems = filterUserMailStates(newUserMailStates ?? []);
		if (filteredItems.length === 0) {
			onModalClose();
		}
	};

	const onModalClose = () => {
		if (hasMadeChanges) {
			setDigestNeedsReloading(hasMadeChanges);
		}
		onClose();
	};

	const checkLastView = (sender: NLUserMailState): boolean => {
		if (previousLastViewDate) {
			return sender.updated_at >= previousLastViewDate;
		}
		return false;
	};

	return (
		<>
			<Transition as={Fragment} show={isShow}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child className="w-full h-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="m-auto w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
								<div className="absolute w-full bg-surface backdrop-blur-md top-0 left-0 flex flex-row justify-between items-center p-4 pb-3 border-b border-primary-200 z-20">
									<div className="text-primary font-medium font-primary text-xl">New Senders</div>
									<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onModalClose()} />
								</div>
								{newUserMailStates.length > 0 ? (
									<div className="flex flex-col gap-4 text-left h-full p-4 py-[105px] bg-secondary overflow-auto">
										<div className="text-primary-500 font-regular font-primary text-base">Would you like to read these senders in Meco?</div>
										<div>
											{newUserMailStates.length > 0 &&
												newUserMailStates.map((sender) => {
													return <SenderListRow userMailState={sender} isSelected={selectedSenders.map((x) => x.id).includes(sender.id)} onSelectedItem={(userMailState) => senderSelected(userMailState)} mailboxProfiles={authUser!.mailbox_profiles} isFlash={isShow && checkLastView(sender)} />;
												})}
										</div>
									</div>
								) : (
									<EmptyStateView title="No new senders" description="When we find a new sender in your inbox, it will magically appear here" alertImage={baloonImage} centerInParent={true} />
								)}
								<div className="absolute bottom-0 w-full bg-surface backdrop-blur-md border-t border-primary-100 px-4 py-4 pt-3 z-20">
									<div className="flex flex-row justify-between">
										<button disabled={selectedSenders.length === 0} className={`${selectedSenders.length > 0 ? "opacity-100 hover:bg-primary-100" : "opacity-50"} text-primary font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-primary-200 transition ease-in-out whitespace-nowrap`} onClick={() => modifyStates(NLUserMailStateNames.inbox)}>
											Keep in Inbox {selectedSenders.length > 0 && <div className="inline">({selectedSenders.length})</div>}
										</button>
										<button disabled={selectedSenders.length === 0} className={`${selectedSenders.length > 0 ? "opacity-100 hover:bg-success-green/80" : "opacity-50"} text-white font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-success-green transition ease-in-out whitespace-nowrap`} onClick={() => modifyStates(NLUserMailStateNames.feed)}>
											Add to Meco {selectedSenders.length > 0 && <div className="inline">({selectedSenders.length})</div>}
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default NewSenderModal;
