import { MailState, QueryResponse } from "@providers/MailContext";
import { NLMail, NLMailboxProfile } from "@models/Models";
import { QueryMessagesService } from "./mailboxFunctions/QueryMessagesService";

export const queryMessages = (mailboxProfile: NLMailboxProfile, mailState: MailState, beforeDate: string | undefined, senderAddress: string | undefined, isUnread: boolean | undefined, groupId: number | undefined, completion: (mails: NLMail[] | undefined, queryResponse: QueryResponse | undefined, error: Error | undefined) => void) => {
	
    const matchingLog = mailState.queryResponses.find((x) => x.mailbox_profile_id === mailState.cutOffMail?.mailbox_profile_id && x.cut_off_mail_id === mailState.cutOffMail.id);

    if (matchingLog) {
        completion([], undefined, undefined);
        return;
    }

    var queryMessagesQuery = new QueryMessagesService.QueryBuilder(mailboxProfile, mailState, senderAddress, beforeDate, isUnread, groupId, undefined);
    const queryMessagesService = new QueryMessagesService(queryMessagesQuery);

    queryMessagesService.queryMessages((mails, error) => {

        if (error) {
            completion(undefined, undefined, error);
            return;
        }

        const newQueryResponse: QueryResponse = { mailbox_profile_id: mailboxProfile.id, cut_off_mail_id: mailState.cutOffMail?.id }

        completion(mails, newQueryResponse, undefined)

    })

};
