import React, { createContext, Dispatch, useReducer, useContext, ReactNode } from "react";
import { NLMail } from "@models/Models";
import { UnreadState, MesageLabelAction } from "@models/Enums";

export interface MailState {
	groupId: number | undefined;
	isUnread: UnreadState | undefined;
	userMailStateId: number | undefined;
	isBookmark: boolean | undefined;
	cutOffMail?: NLMail | undefined;
	mails: NLMail[];
	queryResponses: QueryResponse[];
}

export interface QueryResponse {
    mailbox_profile_id: string
	cut_off_mail_id: string | undefined
}

interface AppState {
	baseMails: NLMail[] | undefined;
	historyIds: Record<string, string> | undefined;
	mailStates: MailState[];
}

type Action = { type: "SET_BASE_MAILS"; payload: { baseMails: NLMail[] | undefined; historyIds: Record<string, string> | undefined } } | { type: "SET_LOAD_MORE"; payload: MailState } | { type: "DELETE_MAILS"; messages: NLMail[] } | { type: "MODIFY_MAILS"; messages: NLMail[], action: string } | { type: "CLEANUP"; } | { type: "SET_READER_MODE"; message: NLMail, readerModeHtml: string };

const initialState: AppState = {
	baseMails: undefined,
	historyIds: {},
	mailStates: [],
};

// Create context
const MailsStateContext = createContext<AppState | undefined>(undefined);
const MailsDispatchContext = createContext<Dispatch<Action> | undefined>(undefined);

// Reducer function
const mailsReducer = (state: AppState, action: Action): AppState => {
	switch (action.type) {
		case "SET_BASE_MAILS":
			var newAppState = { ...state };
			newAppState.baseMails = action.payload.baseMails ?? [];
			newAppState.historyIds = action.payload.historyIds;

			return {
				...newAppState,
				mailStates: [...state.mailStates],
			};

		case "SET_LOAD_MORE":

			var newAppState = { ...state };
			var isBaseLoadMore = action.payload.groupId === undefined && action.payload.isBookmark === undefined && action.payload.isUnread === UnreadState.off && action.payload.userMailStateId === undefined;

			if (isBaseLoadMore) {
				var baseMails = newAppState.baseMails ?? []
				baseMails.push(...action.payload.mails);
				newAppState.baseMails = baseMails;
			}

			const existingStateIndex = state.mailStates.findIndex((state) => compareMailStates(state, action.payload));
			let newMailStates: MailState[];

			if (existingStateIndex !== -1) {
				const updatedMailState = { ...state.mailStates[existingStateIndex] };
				if (!isBaseLoadMore) {
					updatedMailState.mails.push(...action.payload.mails);
				}
				updatedMailState.queryResponses.push(...action.payload.queryResponses);
				newMailStates = [...state.mailStates];
				newMailStates[existingStateIndex] = updatedMailState;
			} else {
				if (!isBaseLoadMore) {
					newMailStates = [...state.mailStates, action.payload];
				} else {
					newMailStates = [...state.mailStates, {...action.payload, mails: []}];
				}
			}

			return {
				...newAppState,
				mailStates: newMailStates,
			};
			
		case "DELETE_MAILS":

			var newAppState = { ...state };
			const deletedMessageIds = action.messages.map((x) => x.id);
			newAppState.baseMails = newAppState.baseMails?.filter((x) => !deletedMessageIds.includes(x.id));

			var changedMailStates: MailState[] = [];

			for (const mailState of newAppState.mailStates) {
				var newMailState: MailState = {...mailState};
				newMailState.mails = mailState.mails.filter((x) => !deletedMessageIds.includes(x.id));
				if (newMailState.cutOffMail) {
					if (deletedMessageIds.includes(newMailState.cutOffMail.id)) {
						newMailState.cutOffMail = undefined;
					}
				}
				changedMailStates.push(newMailState);
			}
		
			return {
				...newAppState,
				mailStates: changedMailStates,
			};

		case "MODIFY_MAILS":

			var newAppState = { ...state };
			var modifiedMessages = action.messages;

			var isUnread = false;
			switch (action.action) {
				case MesageLabelAction.read:
					isUnread = false;
					break;
				case MesageLabelAction.unread:
					isUnread = true;
					break;
			}

			for (let message of modifiedMessages) {
				message.is_unread = isUnread
			}

			const modifiedMessageIds = action.messages.map((x) => x.id);
			for (let message of newAppState.baseMails ?? []) {
				if (modifiedMessageIds.includes(message.id)) {
					message.is_unread = isUnread;
				}
			}

			for (var mailState of newAppState.mailStates) {
				for (let message of mailState.mails) {
					if (modifiedMessageIds.includes(message.id)) {
						message.is_unread = isUnread;
					}
				}
			}
		
			return {
				...newAppState
			};

		case "SET_READER_MODE":

			var newAppState = { ...state };
			var readerModeMessage = action.message;
			readerModeMessage.reader_mode_html = action.readerModeHtml;

			for (let message of newAppState.baseMails ?? []) {
				if (readerModeMessage.id === message.id) {
					message.reader_mode_html = action.readerModeHtml;
				}
			}

			for (var mailState of newAppState.mailStates) {
				for (let message of mailState.mails) {
					if (readerModeMessage.id === message.id) {
						message.reader_mode_html = action.readerModeHtml;
					}
				}
			}
		
			return {
				...newAppState
			};

		case "CLEANUP":
			return {
				baseMails: undefined,
				historyIds: {},
				mailStates: [],
			}
			
		default:
			return state;
	}
};

function compareMailStates(state1: MailState, state2: MailState): boolean {
	return state1.groupId === state2.groupId && state1.isUnread === state2.isUnread && state1.userMailStateId === state2.userMailStateId && state1.isBookmark === state2.isBookmark;
}

// MailsProvider component
const MailsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [state, dispatch] = useReducer(mailsReducer, initialState);

	return (
		<MailsStateContext.Provider value={state}>
			<MailsDispatchContext.Provider value={dispatch}>{children}</MailsDispatchContext.Provider>
		</MailsStateContext.Provider>
	);
};

// Custom hooks to use the context
const useMailsState = () => {
	const context = useContext(MailsStateContext);
	if (context === undefined) {
		throw new Error("useMailsState must be used within a MailsProvider");
	}
	return context;
};

const useMailsDispatch = () => {
	const context = useContext(MailsDispatchContext);
	if (context === undefined) {
		throw new Error("useMailsDispatch must be used within a MailsProvider");
	}
	return context;
};

export { MailsProvider, useMailsState, useMailsDispatch };
