import { NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";

export class ReaderModeService {
	private mailboxProfile: NLMailboxProfile;
	private messageId: string;
	private senderAddress: string | undefined;

	constructor(mailboxProfile: NLMailboxProfile, messageId: string, senderAddress: string | undefined) {
		this.mailboxProfile = mailboxProfile;
		this.messageId = messageId;
		this.senderAddress = senderAddress;
	}

	getMessageWithReaderMode(completion: (readerModeHtml: string | undefined, error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();
		let request: ReaderModeService.RequestModel | undefined = undefined;

		const promise = new Promise<void>((innerResolve, innerReject) => {
			if (this.mailboxProfile.type !== NLMailboxProfileType.Internal) {
				oauthSessionManager
					.fetchAccessTokenForMailboxProfile(this.mailboxProfile.id, this.mailboxProfile.type)
					.then((accessToken) => {
						request = new ReaderModeService.RequestModel(this.mailboxProfile.id, this.messageId, this.senderAddress, accessToken);
						innerResolve();
					})
					.catch((error) => {
						innerReject(error);
						return;
					});
			} else {
				request = new ReaderModeService.RequestModel(this.mailboxProfile.id, this.messageId, this.senderAddress, null);
				innerResolve();
			}
		});

		Promise.all([promise])
			.then(() => {
				if (!request) {
					completion(undefined, undefined);
					return;
				}
				this.makeRequests(request, completion);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}

	private makeRequests(request: ReaderModeService.RequestModel, completion: (readerModeHtml: string | undefined, error: Error | undefined) => void) {
		networkManager
			.getMessageWithReaderMode(request)
			.then((jsonData) => {
				if (jsonData.error) {
					const mailboxError = MailboxError.returnError(jsonData.error, jsonData.mailbox_profile_id);
					completion(undefined, mailboxError);
					return;
				}
				completion(jsonData.reader_mode_html, undefined);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}
}

export namespace ReaderModeService {
	export class RequestModel {
		access_token: string | null;
		mailbox_profile_id!: string;
		message_id!: string;
		sender_address: string | undefined;

		constructor(mailboxProfileId: string, messageId: string, sender_address: string | undefined, accessToken: string | null) {
			this.mailbox_profile_id = mailboxProfileId;
			this.message_id = messageId;
			this.sender_address = sender_address;
			this.access_token = accessToken;
		}
	}
}
