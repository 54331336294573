import { ReactComponent as PlusIcon } from "@images/plus_icon.svg";
import { useData } from "@providers/DataContext";
import SectionLayout from "@layouts/SectionLayout";
import ManageGroupModal, { ManageGroupState } from "@components/common/ManageGroupModal";
import { useState } from "react";
import { ReactComponent as RightArrow } from "@images/right_arrow.svg";
import { postUserGroups } from "@utils/managers/networking/NetworkManager";
import { useAuth } from "@providers/AuthContext";
const Groups = () => {
	const { userGroups, setUserGroups } = useData();
	const [manageGroupState, setManageGroupState] = useState<ManageGroupState>({
		isShow: false,
	});
	const [draggedGroupId, setDraggedGroupId] = useState(undefined);
	const { authUser } = useAuth();
	
	function handleDragStart(e, groupId) {
		setDraggedGroupId(groupId);
		e.dataTransfer.setData("groupId", groupId);
	}

	function handleDragOver(e, index) {
		e.preventDefault();
		if (!userGroups) return;
		const draggedIndex = userGroups.findIndex((group) => group.id === Number(draggedGroupId));

		if (draggedIndex !== -1 && draggedIndex !== index) {
			const newGroups = [...userGroups];
			const [draggedGroup] = newGroups.splice(draggedIndex, 1);
			newGroups.splice(index, 0, draggedGroup);
			setUserGroups(newGroups);
		}
	}

	function handleDragEnd() {
		setDraggedGroupId(undefined);
		let clonedGroups = JSON.parse(JSON.stringify(userGroups));
		for (var i = 0; i < clonedGroups.length; i++) {
			var group = clonedGroups[i];
			group.group_index = i + 1;
		}
		postUserGroups(clonedGroups);
	}

	return (
		<>
			<SectionLayout
				id="groups"
				sectionTitle="Groups"
				sectionDescription="Organise your newsletters by creating groups."
				sectionRightDiv={
					<button className="flex flex-row justify-center items-center gap-2 bg-success-green hover:bg-success-green/80 transition duration-300 ease-in-out px-3 py-2 rounded-full shrink-0" onClick={() => setManageGroupState({ group: undefined, isShow: true })}>
						<PlusIcon className="fill-white w-[16px]" />
						<div className="font-primary font-medium text-base text-white">Add New Group</div>
					</button>
				}>
				<ManageGroupModal manageGroupState={manageGroupState} setManageGroupState={setManageGroupState} />
				<div className="flex flex-row gap-4 flex-wrap">
					{userGroups &&
						userGroups.filter((x) => x.id !== authUser!.audio_profiles[0]?.group_id ).sort().map((group, index) => {
							return (
								<button draggable onDragStart={(e) => handleDragStart(e, group.id)} onDragOver={(e) => handleDragOver(e, index)} onDragEnd={handleDragEnd} className="group flex items-center justify-between gap-4 bg-surface border hover:bg-surface-100 transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full" onClick={() => setManageGroupState({ group: { ...group }, isShow: true })} title="Reorder Groups">
									<div className="flex min-w-0 flex-grow gap-2.5 items-center">
										<div className="font-primary font-medium text-base text-primary text-left truncate">{group.group_name}</div>
									</div>
									<div className="group-hover:flex hidden flex-col w-[30px] gap-[2px] hover:cursor-move bg-primary-100 p-2 rounded-full transition ease-in-out duration-300 group/item">
										<div className="h-[2px] rounded-full w-full bg-primary-500 group-hover/item:bg-primary transition ease-in-out duration-300" />
										<div className="h-[2px] rounded-full w-full bg-primary-500 group-hover/item:bg-primary transition ease-in-out duration-300" />
										<div className="h-[2px] rounded-full w-full bg-primary-500 group-hover/item:bg-primary transition ease-in-out duration-300" />
									</div>
									<RightArrow className="fill-primary-500 group-hover:fill-primary w-[20px]" />
								</button>
							);
						})}
				</div>
			</SectionLayout>
		</>
	);
};

export default Groups;
