import React, { MouseEventHandler, ReactNode, useState } from "react";
import { ReactComponent as SidebarExpandIcon } from "@images/sidebar_expand_icon.svg";
import { ReactComponent as RefreshIcon } from "@images/syncing_icon.svg";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { ReactComponent as CautionIcon } from "@images/caution_icon.svg";
import { MailState } from "@providers/MailContext";
import { useLayout } from "@providers/LayoutContext";
import { useData } from "@providers/DataContext";
import IconButton from "./IconButton";
import { Textfit } from "react-textfit";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import { useAuth } from "@providers/AuthContext";
import { NLMailboxProfile, NLMailboxProfileType, NLUserMailState, NLUserMailStateNames } from "@models/Models";
import MailboxDetailModal from "@components/settings/connected-mailboxes/MailboxDetailModal";
import Dropdown, { DropdownItem } from "./Dropdown";
import { ReactComponent as OptionsIcon } from "@images/options_dots.svg";
import AddGroupModal, { AddGroupState } from "./AddGroupModal";
import { generateToast } from "@utils/managers/ToastManager";

interface MailListHeaderProps {
	mailState: MailState | undefined;
	isMailListFetching: boolean;
	syncOnClick: () => void;
}

const MailListHeader: React.FC<MailListHeaderProps> = ({ mailState, isMailListFetching, syncOnClick }) => {
	const { isSidebarHidden, setSidebarHidden, navigationBarHeight } = useLayout();
	const { userGroups, userMailStates, changeStates, setDigestNeedsReloading, setActiveUserMailState } = useData();
	const { authUser } = useAuth();
	const oauthSessionManager = OauthSessionManager.sharedInstance();
	const [errorMailboxProfile, setErrorMailboxProfile] = React.useState<NLMailboxProfile | undefined>(undefined);
	const [addGroupState, setAddGroupState] = useState<AddGroupState>({
		state: undefined,
		isShow: false,
	});
	
	const getUnreadCount = (): ReactNode => {
		if (mailState) {
			return "(" + mailState.mails?.filter((x) => x.is_unread === true).length + " unread" + ")";
		}
	};

	const getMailListTitle = (): ReactNode => {
		if (!mailState) {
			return;
		}

		if (userGroups && mailState.groupId) {
			const group = userGroups.find((x) => x.id === mailState.groupId);
			return group?.group_name;
		}

		if (userMailStates && mailState.userMailStateId) {
			const userMailState = userMailStates.find((x) => x.id === mailState.userMailStateId);
			return userMailState?.sender_name;
		}

		return "Digest";
	};

	const getStateOptions = (userMailState: NLUserMailState) => {
		const mailboxProfile = authUser!.mailbox_profiles.find((x) => x.id === userMailState.mailbox_profile_id);
		const addToGroupItem = { name: "Add to group", icon: "folder_icon", action: () => setAddGroupState({ state: { ...userMailState }, isShow: true }) };
		var dropDownOptions: DropdownItem[] = [addToGroupItem];
		if (mailboxProfile && mailboxProfile.type !== NLMailboxProfileType.Internal) {
			const typeName = mailboxProfile?.type === NLMailboxProfileType.Gmail ? "Gmail" : "Outlook"
			const stateActionItem = {
				name: `Move sender to ${typeName} inbox`,
				icon: "revert_icon",
				action: () => {
					changeStates(authUser!.mailbox_profiles, [userMailState], NLUserMailStateNames.inbox, () => {});
					setDigestNeedsReloading(true);
					setActiveUserMailState(undefined);
					generateToast({ status: "success", message: userMailState.sender_name + " moved to " + typeName + " inbox", position: "bottom-center" });
				},
			};
			dropDownOptions.push(stateActionItem);
		}
		//TODO: Implement these actions
		// const unsubscribeItem = { name: "Unsubscribe from this sender", icon: "unsubscribe_icon", action: () => console.log("unsub") };
		// const renameSenderItem = { name: "Rename sender", icon: "rename_icon", action: () => console.log("rename") };
		// dropDownOptions.push(...[unsubscribeItem, renameSenderItem]);
		return dropDownOptions;
	};

	const renderPrimaryButton = (): ReactNode => {
		const userMailState = userMailStates?.find((x) => x.id === mailState?.userMailStateId);
		if (userMailState) {
			return <Dropdown buttonOnEdge={true} buttonIcon={OptionsIcon} items={getStateOptions(userMailState)} />;
		}

		return (
			<button type="button" disabled={isMailListFetching} className="w-[40px] h-[40px] hover:bg-primary-100 -mr-2.5 focus:ring-0 focus:outline-none rounded-lg text-sm text-center items-center transition-[background] ease-in-out duration-300" onClick={syncOnClick}>
				{isMailListFetching ? <LoadingSpinner className="w-[20px] text-primary-200 animate-spin fill-primary m-auto" /> : <RefreshIcon className="fill-primary m-auto" />}
			</button>
		);
	};

	const renderMailboxErrorBanner = (): ReactNode => {
		if (!oauthSessionManager.hasOneOrMoreAccessTokenErrors) return;
		if (oauthSessionManager.notProMailboxProfileIds.size > 0) {
			const [firstMailboxProfileId] = oauthSessionManager.notProMailboxProfileIds;
			const mailboxProfile = authUser!.mailbox_profiles.find((x) => x.id === firstMailboxProfileId);
			if (mailboxProfile) {
				const mailboxProfileTypeName = mailboxProfile.type === NLMailboxProfileType.Gmail ? "Gmail" : "Outlook";
				return (
					<button className="bg-brand-red hover:bg-brand-red/80 transition ease-in-out duration-300 w-full text-center p-2.5 font-primary font-medium text-base text-white flex flex-row gap-1 items-center justify-center" onClick={() => setErrorMailboxProfile(mailboxProfile)}>
						<CautionIcon className="fill-white h-[14px]" />
						Action required to continue using {mailboxProfileTypeName}
					</button>
				);
			}
		}
		if (oauthSessionManager.unauthorizedMailboxProfileIds.size > 0) {
			const [firstMailboxProfileId] = oauthSessionManager.unauthorizedMailboxProfileIds;
			const mailboxProfile = authUser!.mailbox_profiles.find((x) => x.id === firstMailboxProfileId);
			if (mailboxProfile) {
				const mailboxProfileTypeName = mailboxProfile.type === NLMailboxProfileType.Gmail ? "Gmail" : "Outlook";
				return (
					<button className="bg-brand-red hover:bg-brand-red/80 transition ease-in-out duration-300 w-full text-center p-2.5 font-primary font-medium text-base text-white flex flex-row gap-1 items-center justify-center" onClick={() => setErrorMailboxProfile(mailboxProfile)}>
						<CautionIcon className="fill-white h-[14px]" />
						Action required: Sign-in to {mailboxProfileTypeName}
					</button>
				);
			}
		}
	};

	return (
		<>
			<AddGroupModal addGroupState={addGroupState} setAddGroupState={setAddGroupState} />
			<MailboxDetailModal mailboxProfile={errorMailboxProfile} onClose={() => setErrorMailboxProfile(undefined)} onResync={(mbp) => (mbp)} />
			<div className="sticky top-0 bg-white/5 backdrop-blur-lg">
				<div className="flex flex-col gap-3 items-center border-b border-primary-100" style={{ height: navigationBarHeight + "px" }}>
					<div className="flex flex-row justify-between w-full p-3 items-center h-full">
						<div className="flex flex-row items-center justify-between w-full">
							<div className="flex flex-row gap-2 items-center min-w-0 flex-grow">
								<div className={isSidebarHidden ? "block" : "hidden"}>
									<IconButton Icon={SidebarExpandIcon} forcedIconSize={24} onClick={() => setSidebarHidden(false)} />
								</div>
								<div className="flex flex-row items-center gap-1 w-full">
									<Textfit max={18} mode="single" className="font-primary text-lg font-black text-primary text-left truncate">
										{getMailListTitle()}
									</Textfit>
									<Textfit max={12} mode="single" className="font-primary font-regular text-primary-500 text-xs">
										{getUnreadCount()}
									</Textfit>
								</div>
							</div>
							<div className="flex flex-row gap-2.5">{renderPrimaryButton()}</div>
						</div>
					</div>
				</div>
				{renderMailboxErrorBanner()}
			</div>
		</>
	);
};

export default MailListHeader;
