import { useState, FC, Fragment } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { useHotkeys } from "react-hotkeys-hook";
import IconButton from "@components/common/IconButton";
import Alert, { AlertState } from "@components/common/Alert";
import { Dialog, Transition } from "@headlessui/react";
import collage_paywall_balloon from "@images/collage_paywall_balloon.png";

interface InternalTrialModalProps {
	isShow: boolean;
	onClose: () => void;
}

const InternalTrialModal: FC<InternalTrialModalProps> = ({ isShow, onClose }) => {
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});

	useHotkeys("Escape", () => onModalClose());

	const onModalClose = () => {
		onClose();
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="max-w-[400px] transform overflow-hidden rounded-2xl bg-surface border border-white/10 text-left align-middle shadow-xl transition-all p-4">
								<div className="flex flex-col">
									<div className="w-full flex flex-row justify-end items-center">
										<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onModalClose()} />
									</div>
									<div className="flex flex-col gap-3 text-center items-center h-full w-full">
										<img src={collage_paywall_balloon} className="max-w-[120px] object-contain" />
										<div className="text-primary font-medium font-primary text-xl text-center">A little gift for you 🎁</div>
										<div className="text-primary font-regular font-primary text-sm">We’ve unlocked Meco PRO for 7 days! You can now access the full experience and add your newsletters from Gmail or Outlook.</div>
										<button className={`bg-success-green hover:bg-success-green/80 transition duration-300 ease-in-out py-2 font-primary text-lg text-white rounded-xl shadow-sm w-full h-[44px]`} onClick={() => onModalClose()}>
											Get started with PRO
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default InternalTrialModal;
