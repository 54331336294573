import { kAppConfig } from "@utils/configurations/AppConfig";
import { NLMailboxProfile, NLMailboxProfileType, NLUserMailState, NLUserMailStateNames } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";
import { chunkArray } from "@utils/Array+Chunk";

export class ChangeUserMailStatesService {
	private queries: ChangeUserMailStatesService.QueryBuilder[];

	constructor(queries: ChangeUserMailStatesService.QueryBuilder[]) {
		this.queries = queries;
	}

	changeUserMailStates(completion: (responseModel: ChangeUserMailStatesService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();

		let requestArray: ChangeUserMailStatesService.RequestModel[] = [];
		let responseArray: ChangeUserMailStatesService.ResponseModel[] = [];
		const promises: Promise<void>[] = [];

		for (const requestModel of this.queries) {
			const promise = new Promise<void>((innerResolve, innerReject) => {
				if (requestModel.mailboxProfile.type !== NLMailboxProfileType.Internal) {
					oauthSessionManager
						.fetchAccessTokenForMailboxProfile(requestModel.mailboxProfile.id, requestModel.mailboxProfile.type)
						.then((accessToken) => {
							let chunkedUserMailStateIds: number[][] = chunkArray(requestModel.userMailStateIds, kAppConfig.backendPostRequestChunkSize);

							for (let userMailStateIds of chunkedUserMailStateIds) {
								const request = new ChangeUserMailStatesService.RequestModel(requestModel.mailboxProfile.id, requestModel.state, userMailStateIds, accessToken);
								requestArray.push(request);
							}

							innerResolve();
						})
						.catch((error) => {
							if (error instanceof Error && error.message === "noInternetError") {
								innerReject(error);
							} else {
								var userMailResponseModel = new ChangeUserMailStatesService.ResponseModel(requestModel.mailboxProfile.id);
								userMailResponseModel.error = oauthSessionManager.generateSignInRequiredError(requestModel.mailboxProfile.id, error);
								responseArray.push(userMailResponseModel);
								innerResolve();
							}
						});
				} else {
					let chunkedUserMailStateIds: number[][] = chunkArray(requestModel.userMailStateIds, kAppConfig.backendPostRequestChunkSize);

					for (let userMailStateIds of chunkedUserMailStateIds) {
						const request = new ChangeUserMailStatesService.RequestModel(requestModel.mailboxProfile.id, requestModel.state, userMailStateIds, null);
						requestArray.push(request);
					}

					innerResolve();
				}
			});
			promises.push(promise);
		}

		Promise.allSettled(promises).then(() => {
			if (requestArray.length === 0) {
				completion(responseArray, undefined);
				return;
			}

			this.makeRequests(requestArray, responseArray)
				.then((result) => this.processResponses(result, responseArray, completion))
				.catch((error) => completion(responseArray, error));
		});
	}

	private makeRequests(requestArray: ChangeUserMailStatesService.RequestModel[], responseArray: ChangeUserMailStatesService.ResponseModel[]): Promise<PromiseSettledResult<any>[]> {
		const promises: Promise<any>[] = [];

		const requestDictByMailboxProfile: { [mailboxProfileId: string]: ChangeUserMailStatesService.RequestModel[] } = {};
		requestArray.forEach((request) => {
			if (!requestDictByMailboxProfile[request.mailbox_profile_id]) {
				requestDictByMailboxProfile[request.mailbox_profile_id] = [];
			}
			requestDictByMailboxProfile[request.mailbox_profile_id].push(request);
		});

		const maxIterations = Math.max(...Object.values(requestDictByMailboxProfile).map((arr) => arr.length));

		for (let i = 0; i < maxIterations; i++) {
			var batchRequests: ChangeUserMailStatesService.RequestModel[] = [];
			for (let mailboxProfileId in requestDictByMailboxProfile) {
				const requestObjects = requestDictByMailboxProfile[mailboxProfileId];
				if (!requestObjects) {
					continue;
				}
				if (!requestObjects[i]) {
					continue;
				}

				batchRequests.push(requestObjects[i]);
			}

			var promise = networkManager.changeUserMailStates(batchRequests);
			promises.push(promise);
		}

		return Promise.allSettled(promises);
	}

	private processResponses(promiseResults: PromiseSettledResult<any>[], responseArray: ChangeUserMailStatesService.ResponseModel[], completion: (responseModel: ChangeUserMailStatesService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		var serverError: Error | undefined = undefined;
		var responseModels = responseArray;

		for (let promiseResult of promiseResults) {
			if (promiseResult.status === "rejected") {
				serverError = promiseResult.reason;
				continue;
			}

			const jsonData: Record<string, any> = promiseResult.value;

			for (let mailboxProfileId in jsonData) {
				let responseData = jsonData[mailboxProfileId];
				var responseModel = new ChangeUserMailStatesService.ResponseModel(mailboxProfileId);

				if (Array.isArray(responseData["user_mail_states"])) {
					let userMailStates = responseData["user_mail_states"].map((userMailState: NLUserMailState) => new NLUserMailState(userMailState));
					responseModel.userMailStates = userMailStates;
				}

				if (responseData["error"]) {
					responseModel.error = MailboxError.returnError(responseData["error"], mailboxProfileId);
				}

				responseModels.push(responseModel);
			}
		}

		completion(responseModels, serverError);
	}
}

export namespace ChangeUserMailStatesService {
	export class RequestModel {
		access_token: string | null;
		mailbox_profile_id!: string;
		state_id!: number;
		user_mail_state_ids!: number[];

		constructor(mailboxProfileId: string, stateId: number, userMailStateIds: number[], accessToken: string | null) {
			this.mailbox_profile_id = mailboxProfileId;
			this.access_token = accessToken;
			this.state_id = stateId;
			this.user_mail_state_ids = userMailStateIds;
		}
	}

	export class ResponseModel {
		mailboxProfileId!: string;
		userMailStates?: NLUserMailState[] | null;
		error?: Error | null;

		constructor(mailboxProfileId: string) {
			this.mailboxProfileId = mailboxProfileId;
		}
	}

	export class QueryBuilder {
		mailboxProfile: NLMailboxProfile;
		state: number;
		userMailStateIds: number[];

		constructor(mailboxProfile: NLMailboxProfile, state: NLUserMailStateNames, userMailStateIds: number[]) {
			this.mailboxProfile = mailboxProfile;
			this.state = state;
			this.userMailStateIds = userMailStateIds;
		}
	}
}
