import React, { useState, useEffect, FC, Fragment, useRef } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import IconButton from "../common/IconButton";
import { useData } from "@providers/DataContext";
import { NLUserGroup, NLUserMailState, NLUserMailStateNames, NLUserSenderGroup } from "@models/Models";
import { ReactComponent as SearchIcon } from "@images/search_icon.svg";
import EmptyStateView from "@components/common/EmptyStateView";
import collageGlasses from "@images/collage_search_no_results.png";
import { useHotkeys } from "react-hotkeys-hook";
import { Dialog, Transition } from "@headlessui/react";
import SenderListRow from "@components/common/SenderListRow";
import { deleteUserGroup, postUserGroups, createUserSenderGroups, deleteUserSenderGroup } from "@utils/managers/networking/NetworkManager";
import Alert, { AlertState } from "./Alert";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { ReactComponent as DeleteIcon } from "@images/delete_icon.svg";
import { generateToast } from "@utils/managers/ToastManager";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

export interface ManageGroupState {
	group?: NLUserGroup | undefined;
	isShow: boolean;
}

interface ManageGroupModalProps {
	manageGroupState: ManageGroupState;
	setManageGroupState: React.Dispatch<React.SetStateAction<ManageGroupState>>;
}

const ManageGroupModal: FC<ManageGroupModalProps> = ({ manageGroupState, setManageGroupState }) => {
	const { group, isShow } = manageGroupState;

	const { userMailStates, setDigestNeedsReloading, userGroups, setUserGroups, userSenderGroups, setUserSenderGroups } = useData();
	const [filteredUserMailStates, setFilteredUserMailStates] = useState<NLUserMailState[]>([]);

	const [selectedSenders, setSelectedSenders] = useState<NLUserMailState[]>([]);
	const [initialSenders, setInitialSenders] = useState<NLUserMailState[]>([]);
	const [senderSearchQuery, setSearchQuery] = useState("");
	const [groupName, setGroupName] = useState<string>("");
	const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false);
	const groupNameRef = useRef<HTMLInputElement>(null);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});

	useHotkeys("Escape", () => onClose());

	useEffect(() => {
		if (isShow) {
			setSelectedSenders([]);
			setGroupName("");
			setSearchQuery("");
			setHasMadeChanges(false);
		}

		if (isShow && !group) {
			setTimeout(() => {
				groupNameRef?.current?.focus();
			}, 100);
		}
	}, [isShow]);

	useEffect(() => {
		const filteredItems = filterUserMailStates(userMailStates ?? [], senderSearchQuery);
		setFilteredUserMailStates(filteredItems);
	}, [userMailStates, senderSearchQuery]);

	useEffect(() => {
		const alreadyAddedSenders = getAddedMailStates();
		setInitialSenders(alreadyAddedSenders);
		setSelectedSenders(alreadyAddedSenders);
		setGroupName(group?.group_name ?? "");
	}, [group]);

	useEffect(() => {
		const senderChangeMade = changesMade();
		const isValidSender = selectedSenders.length > 0;
		const isValidGroupName = groupName.length > 0;
		const groupNameChanged = group ? groupName !== group?.group_name : true;
		setHasMadeChanges((senderChangeMade || groupNameChanged) && isValidSender && isValidGroupName);
	}, [selectedSenders, groupName]);

	function changesMade(): boolean {
		const initialSenderAddresses = initialSenders.map((x) => x.sender_address);
		const selectedSenderAddresses = selectedSenders.map((x) => x.sender_address);
		const copyArr1 = initialSenderAddresses.slice();
		const copyArr2 = selectedSenderAddresses.slice();
		copyArr1.sort();
		copyArr2.sort();
		return JSON.stringify(copyArr1) !== JSON.stringify(copyArr2);
	}

	const getAddedMailStates = (): NLUserMailState[] => {
		if (!group || !userSenderGroups || !userMailStates) return [];
		const existingGroupSenders: string[] = userSenderGroups.filter((x) => x.user_group === group.id).map((x) => x.sender_address) ?? [];
		const alreadyAddedStates = userMailStates.filter((x) => existingGroupSenders.includes(x.sender_address));
		return alreadyAddedStates;
	};

	const filterUserMailStates = (userMailStates: NLUserMailState[], searchQuery: string): NLUserMailState[] => {
		const query = searchQuery.toLowerCase();
		var filteredUserMailStates = userMailStates.filter((x) => x.state_id === NLUserMailStateNames.feed);
		if (query) {
			filteredUserMailStates = filteredUserMailStates.filter((sender) => (sender.sender_name ?? "").toLowerCase().includes(query));
		}
		return filteredUserMailStates.sort((a, b) => (a?.sender_name || "").localeCompare(b?.sender_name || ""));
	};

	const senderSelected = (userMailState: NLUserMailState) => {
		var tempSelectedSenders = [...selectedSenders];
		const selectedIds = tempSelectedSenders.map((x) => x.id);
		if (selectedIds.includes(userMailState.id)) {
			tempSelectedSenders = tempSelectedSenders.filter((x) => x.id != userMailState.id);
		} else {
			tempSelectedSenders.push(userMailState);
		}
		setSelectedSenders(tempSelectedSenders);
	};

	const saveOnClick = () => {
		setLoadingState({ isLoading: true });

		if (group) {
			//Update existing group
			recordEvent(kAnalyticsConstants.Groups.groupModified, { [kAnalyticsConstants.Groups.GroupsKeys.groupId]: group.id, [kAnalyticsConstants.Groups.GroupsKeys.groupName]: group.group_name, [kAnalyticsConstants.Groups.GroupsKeys.groupSenderCount]: selectedSenders.length });

			if (group.group_name !== groupName) {
				var tempUserGroup = { ...group };
				tempUserGroup.group_name = groupName;

				var tempUserGroups = [...(userGroups ?? [])];
				tempUserGroups = tempUserGroups.filter((x) => x.id !== group.id);
				tempUserGroups.push(tempUserGroup);
				setUserGroups(tempUserGroups);

				postUserGroups([tempUserGroup]);
			}

			const initialSenderAddresses = initialSenders.map((x) => x.sender_address);
			const selectedSenderAddresses = selectedSenders.map((x) => x.sender_address);

			var existingsSendersForGroup: NLUserSenderGroup[] = [];
			if (userSenderGroups) {
				existingsSendersForGroup = userSenderGroups.filter((x) => x.user_group === group.id) ?? [];
			}

			const toBeDeletedAddresses = initialSenderAddresses.filter((item) => !selectedSenderAddresses.includes(item));
			const toBeAddedAddresses = selectedSenderAddresses.filter((item) => !initialSenderAddresses.includes(item));

			const sendersToBeDeleted = existingsSendersForGroup.filter((x) => toBeDeletedAddresses.includes(x.sender_address));

			const promises: Promise<NLUserSenderGroup[]>[] = [];

			const deletePromise = new Promise<NLUserSenderGroup[]>((innerResolve, innerReject) => {
				if (sendersToBeDeleted.length === 0) {
					innerResolve([]);
					return;
				}
				const deleteIds = sendersToBeDeleted.map((senderGroup) => senderGroup.id);
				for (let [index, deleteId] of deleteIds.entries()) {
					deleteUserSenderGroup(deleteId).finally(() => {
						if (index === deleteIds.length - 1) {
							innerResolve(sendersToBeDeleted);
						}
					});
				}
			});

			const addPromise = new Promise<NLUserSenderGroup[]>((innerResolve, innerReject) => {
				if (toBeAddedAddresses.length === 0) {
					innerResolve([]);
					return;
				}
				var tempUserSenderGroups: NLUserSenderGroup[] = [];
				for (const senderAddress of toBeAddedAddresses) {
					var newSenderGroup = new NLUserSenderGroup();
					newSenderGroup.sender_address = senderAddress;
					newSenderGroup.user_group = group.id;
					newSenderGroup.sender_address_index = 0;
					tempUserSenderGroups.push(newSenderGroup);
				}
				createUserSenderGroups(tempUserSenderGroups)
					.then((userSenderGroups) => {
						innerResolve(userSenderGroups);
					})
					.catch((error) => {
						innerReject(error);
					});
			});

			promises.push(deletePromise, addPromise);

			Promise.all(promises)
				.then(([deletedSenders, addedSenders]) => {
					var tempUserSenderGroups = [...(userSenderGroups ?? [])];
					tempUserSenderGroups = tempUserSenderGroups.filter((x) => !deletedSenders.map((y) => y.id).includes(x.id));
					tempUserSenderGroups.push(...addedSenders);
					setUserSenderGroups(tempUserSenderGroups);
					generateToast({ status: "success", message: "Group updated", position: "bottom-center" });
					onClose();
				})
				.catch((_error) => {
					setAlertState({ isShow: true, title: "Uh oh! Something went wrong", message: "Please try again and contact us if the problem persists", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
				})
				.finally(() => {
					setLoadingState({ isLoading: false });
				});
		} else {
			//New Group
			var newGroup = new NLUserGroup();
			newGroup.group_name = groupName;
			newGroup.group_index = userGroups?.length ?? 0;

			postUserGroups([newGroup])
				.then((groups) => {
					const newlyCreatedGroup = groups.find((x) => x.group_name === newGroup.group_name && x.group_index === newGroup.group_index);

					if (!newlyCreatedGroup) {
						setAlertState({ isShow: true, title: "Uh oh! Something went wrong", message: "Please try again and contact us if the problem persists", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
						return;
					}

					recordEvent(kAnalyticsConstants.Groups.groupCreated, { [kAnalyticsConstants.Groups.GroupsKeys.groupName]: newlyCreatedGroup.group_name, [kAnalyticsConstants.Groups.GroupsKeys.groupSenderCount]: selectedSenders.length });

					var tempUserGroups = [...(userGroups ?? [])];
					tempUserGroups.push(newlyCreatedGroup);
					setUserGroups(tempUserGroups);

					var tempUserSenderGroups: NLUserSenderGroup[] = [];
					for (const selectedSender of selectedSenders) {
						var newSenderGroup = new NLUserSenderGroup();
						newSenderGroup.sender_address = selectedSender.sender_address;
						newSenderGroup.user_group = newlyCreatedGroup.id;
						newSenderGroup.sender_address_index = 0;
						tempUserSenderGroups.push(newSenderGroup);
					}

					return createUserSenderGroups(tempUserSenderGroups);
				})
				.then((addedUserSenderGroups) => {
					if (!addedUserSenderGroups) return;
					var tempUserSenderGroups = [...(userSenderGroups ?? [])];
					tempUserSenderGroups.push(...addedUserSenderGroups);
					setUserSenderGroups(tempUserSenderGroups);
					generateToast({ status: "success", message: "Group created", position: "bottom-center" });
					onClose();
				})
				.catch((_error) => {
					setAlertState({ isShow: true, title: "Uh oh! Something went wrong", message: "Please try again and contact us if the problem persists", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
				})
				.finally(() => {
					setLoadingState({ isLoading: false });
				});
		}
	};

	const deleteGroupOnClick = () => {
		setAlertState({ isShow: true, title: "Are you sure?", message: "This will permanently delete the group and cannot be undone.", actionButton: "Delete", dismissButton: "Cancel", actionButtonAction: () => confirmDeleteGroup() });
	};

	const confirmDeleteGroup = () => {
		if (!group) return;
		onClose();

		if (userGroups) {
			var tempUserGroups = [...userGroups];
			tempUserGroups = tempUserGroups.filter((x) => x.id !== group.id);
			setUserGroups(tempUserGroups);
		}

		if (userSenderGroups) {
			var tempUserSenderGroups = [...userSenderGroups];
			tempUserSenderGroups = tempUserSenderGroups.filter((x) => x.user_group !== group.id);
			setUserSenderGroups(tempUserSenderGroups);
		}

		recordEvent(kAnalyticsConstants.Groups.groupDeleted, { [kAnalyticsConstants.Groups.GroupsKeys.groupId]: group.id, [kAnalyticsConstants.Groups.GroupsKeys.groupName]: group.group_name });
		deleteUserGroup(group.id);
		generateToast({ status: "success", message: "Group deleted", position: "bottom-center" });
		onClose();
	};

	const onClose = () => {
		setManageGroupState({ ...manageGroupState, isShow: false });
	};

	return (
		<>
			<LoadingView loadingState={loadingState} />
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog static onClose={() => null} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child className="w-full h-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="m-auto w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
								<div className="absolute w-full bg-surface backdrop-blur-md top-0 left-0 flex flex-col p-4 border-b border-primary-200 z-20 gap-3">
									<div className="flex flex-row justify-between items-center">
										<div className="text-primary font-medium font-primary text-xl">{group ? "Edit " + group.group_name : "Add New Group"}</div>
										<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onClose()} />
									</div>
									<div className="flex flex-row items-center gap-3">
										<div className="relative w-full">
											<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
												<SearchIcon className="w-[20px] h-[20px] fill-primary" />
											</div>
											<input type="text" value={senderSearchQuery} className={`!ps-12 flex-grow bg-secondary focus:ring-1 focus:border-primary focus:ring-primary border h-[55px] border-primary-200 rounded-[12px] font-regular font-primary placeholder-primary-500 w-full pr-[150px] text-primary`} placeholder="Search senders..." onChange={(value) => setSearchQuery(value.currentTarget.value)} />
										</div>
									</div>
								</div>
								<div className="flex flex-col gap-4 text-left h-full p-4 pt-[180px] pb-[105px] bg-secondary overflow-auto">
									<input type="text" value={groupName} onChange={(e) => setGroupName(e.target.value)} className="bg-transparent focus:ring-1 focus:border-primary focus:ring-primary border border-primary-200 text-primary font-medium font-primary text-3xl text-left !p-3 rounded-xl" placeholder="Group name" ref={groupNameRef} />
									<div>
										{filteredUserMailStates.length > 0 ? (
											filteredUserMailStates.map((sender) => {
												return <SenderListRow userMailState={sender} isSelected={selectedSenders.map((x) => x.id).includes(sender.id)} onSelectedItem={(userMailState) => senderSelected(userMailState)} isFlash={false} />;
											})
										) : (
											<EmptyStateView title="No senders found" description="Please search for senders that are already added to Meco" alertImage={collageGlasses} centerInParent={true} />
										)}
									</div>
								</div>
								<div className="absolute bottom-0 w-full bg-surface backdrop-blur-md border-t border-primary-100 px-4 py-4 pt-3 z-20">
									<div className={`flex flex-row ${group ? "justify-between" : "justify-end"}`}>
										{group && <IconButton Icon={DeleteIcon} className="bg-brand-red hover:bg-brand-red/80" isIconHover={true} iconClassName="fill-white" onClick={() => deleteGroupOnClick()} />}
										<button disabled={!hasMadeChanges} className={`${hasMadeChanges ? "opacity-100 hover:bg-success-green/80" : "opacity-50"} text-white font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-success-green transition ease-in-out whitespace-nowrap`} onClick={() => saveOnClick()}>
											{group ? "Update Group" : "Add Group"}
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default ManageGroupModal;
