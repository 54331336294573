import { Textfit } from "react-textfit";
import "./NewsletterPickerRow.scss";

export const NewsletterPickerRow = (props) => {
	return (
		<div key={props.id}>
			<label className={`flex p-[30px] flex-row w-full justify-between items-center gap-2 cursor-pointer select-newsletter-row transition ease-in-out ${props.isChecked ? "selected-sender" : "hover:bg-white/20"}`}>
				<div className="flex flex-row items-center text-left gap-3">
					<input readOnly className="checkbox-round" type="checkbox" name="selectable-sender" checked={props.isChecked} value={props.id}  onChange={(e) => props.checkOnChange(e, props.id)}/>
					<div className="flex flex-col">
						<Textfit mode="single" className="text-white font-medium text-xl font-primary max-w-[250px]" max={20}>
							{props.sender_name}
						</Textfit>
						<Textfit mode="single" className="text-white/50 font-regular text-sm font-primary max-w-[250px]" max={14}>
							{props.sender_address}
						</Textfit>
					</div>
				</div>
				<div>{props.is_whitelisted === true && <div className={`rounded-lg font-medium font-primary text-sm px-3 py-2 ${props.isChecked ? "bg-white/20 text-white" : "bg-success-green/50 text-white"}`}>Suggested</div>}</div>
			</label>
		</div>
	);
};

export default NewsletterPickerRow;
