import Alert, { AlertState } from "@components/common/Alert";
import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import animation from "@images/checkmark.json";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { usePremium } from "@providers/PremiumContext";
import { useNavigate } from "react-router-dom";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

export interface StripeSessionState {
	sessionId: string;
	productId: string;
}

interface SuccessPaywallProps {
	sessionState: StripeSessionState;
	setSessionState: React.Dispatch<React.SetStateAction<StripeSessionState | undefined>>;
}

const SuccessPaywall: FC<SuccessPaywallProps> = ({ sessionState, setSessionState }) => {
	const [fadeInAnimation, setFadeInAnimation] = useState<boolean>(false);
	const { setQonversionPremiumStatus } = usePremium();
	const navigate = useNavigate();

	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const playerRef = useRef<LottieRefCurrentProps>(null);

	useEffect(() => {
		if (sessionState) {
			setFadeInAnimation(false);
			recordEvent(kAnalyticsConstants.Paywall.iapPurchaseSuccessful, { session_id: sessionState.sessionId, price_id: sessionState.productId });
			setQonversionPremiumStatus(true);
			setTimeout(() => {
				setFadeInAnimation(true);
				setTimeout(() => {
					onClose();
				}, 2000);
			}, 1000);
		}
	}, [sessionState]);

	const onClose = () => {
		var reloadUrl = window.location.pathname;
		if (window.location.search.includes("is_account")) {
			reloadUrl = reloadUrl + "?is_account=true";
		}
		navigate(reloadUrl, { replace: true });
		setSessionState(undefined);
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={sessionState !== undefined}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="w-[250px] h-[250px] transform overflow-hidden rounded-2xl bg-white border border-white/20 text-left align-middle shadow-xl transition-all">
								<div className="flex flex-col justify-center items-center h-full p-4 bg-white">
									<>
										<Lottie className="max-w-[280px]" animationData={animation} lottieRef={playerRef} loop={false} autoPlay={false} />
										<div className={`${fadeInAnimation ? "" : "opacity-0 duration-0"} font-primary font-medium text-xl text-black transition-opacity duration-[500ms] ease-in`}>Welcome to PRO</div>
									</>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default SuccessPaywall;
