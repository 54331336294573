import { MouseEventHandler } from "react";

interface IconButtonProps {
	Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	onClick: MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	isIconHover?: boolean;
	isActive?: boolean;
	className?: string;
	iconClassName?: string;
	forcedIconSize?: number;
	buttonOnEdge?: boolean;
	children?: React.ReactNode;
}

const IconButton: React.FC<IconButtonProps> = ({ Icon, disabled, onClick, className, iconClassName, isIconHover, isActive, forcedIconSize, buttonOnEdge, children }) => {
	return (
		<button type="button" disabled={disabled} className={`group w-[40px] h-[40px] ${isIconHover ? "" : "hover:bg-primary-100"} ${isActive ? "bg-primary hover:bg-primary" : null } focus:ring-0 focus:outline-none rounded-lg text-sm text-center items-center transition-[background] ease-in-out duration-300 ${buttonOnEdge ? "-mr-2.5" : ""} ${disabled ? "opacity-50" : "opacity-100"} ${className}`} onClick={onClick}>
			<Icon className={`h-[20px] aspect-square ${isIconHover ? "" : "fill-primary"} ${isActive ? "fill-secondary" : "fill-primary" } m-auto ${iconClassName} transition-[fill] ease-in-out duration-300`} style={{height: forcedIconSize + "px" }} />
			{children}
		</button>
	);
};

export default IconButton;
