import React, { useState, useEffect, FC, Fragment, useRef } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import IconButton from "../common/IconButton";
import { useData } from "@providers/DataContext";
import { NLUserGroup, NLUserMailState, NLUserSenderGroup } from "@models/Models";
import EmptyStateView from "@components/common/EmptyStateView";
import collageGlasses from "@images/collage_search_no_results.png";
import { useHotkeys } from "react-hotkeys-hook";
import { Dialog, Transition } from "@headlessui/react";
import { createUserSenderGroups, deleteUserSenderGroup } from "@utils/managers/networking/NetworkManager";
import Alert, { AlertState } from "./Alert";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import GroupListRow from "./GroupListRow";
import { generateToast } from "@utils/managers/ToastManager";
import { useAuth } from "@providers/AuthContext";

export interface AddGroupState {
	state: NLUserMailState | undefined;
	isShow: boolean;
}

interface AddGroupProps {
	addGroupState: AddGroupState;
	setAddGroupState: React.Dispatch<React.SetStateAction<AddGroupState>>;
}

const AddGroupModal: FC<AddGroupProps> = ({ addGroupState, setAddGroupState }) => {
	const { state, isShow } = addGroupState;

	const { userGroups, setUserGroups, userSenderGroups, setUserSenderGroups } = useData();
	const [selectedGroups, setSelectedGroups] = useState<NLUserGroup[]>([]);
	const [initialSelectedGroups, setInitialSelectedGroups] = useState<NLUserGroup[]>([]);
	const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false);
	const { authUser } = useAuth()
	
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});

	useHotkeys("Escape", () => onClose());

	useEffect(() => {
		if (isShow) {
			setInitialSelectedGroups([]);
			setSelectedGroups([]);
			setHasMadeChanges(false);
		}
	}, [isShow]);

	useEffect(() => {
		const alreadyAddedGroups = getAddedGroups();
		setInitialSelectedGroups(alreadyAddedGroups);
		setSelectedGroups(alreadyAddedGroups);
	}, [state]);

	useEffect(() => {
		const groupChangeMade = changesMade();
		setHasMadeChanges(groupChangeMade);
	}, [selectedGroups]);

	const getAddedGroups = (): NLUserGroup[] => {
		if (!state || !userGroups || !userSenderGroups) return [];
		const existingGroups: number[] = userSenderGroups.filter((x) => x.sender_address === state.sender_address).map((x) => x.user_group) ?? [];
		const alreadyAddedGroups = userGroups.filter((x) => existingGroups.includes(x.id));
		return alreadyAddedGroups;
	};

	const groupSelected = (userGroup: NLUserGroup) => {
		var tempSelectedGroups = [...selectedGroups];
		const selectedIds = tempSelectedGroups.map((x) => x.id);
		if (selectedIds.includes(userGroup.id)) {
			tempSelectedGroups = tempSelectedGroups.filter((x) => x.id != userGroup.id);
		} else {
			tempSelectedGroups.push(userGroup);
		}
		setSelectedGroups(tempSelectedGroups);
	};

	function changesMade(): boolean {
		const initialGroupIds = initialSelectedGroups.map((x) => x.id);
		const selectedGroupIds = selectedGroups.map((x) => x.id);
		const copyArr1 = initialGroupIds.slice();
		const copyArr2 = selectedGroupIds.slice();
		copyArr1.sort();
		copyArr2.sort();
		return JSON.stringify(copyArr1) !== JSON.stringify(copyArr2);
	}

	const onClose = () => {
		setAddGroupState({ ...addGroupState, isShow: false });
	};

	const saveOnClick = () => {
		if (!state) return;
		setLoadingState({ isLoading: true });

		const initialGroupIds = initialSelectedGroups.map((x) => x.id);
		const selectedGroupIds = selectedGroups.map((x) => x.id);

		var existingSenderGroupsForSender: NLUserSenderGroup[] = [];
		if (userSenderGroups) {
			existingSenderGroupsForSender = userSenderGroups.filter((x) => x.sender_address === state.sender_address) ?? [];
		}

		const toBeDeletedGroups = initialGroupIds.filter((item) => !selectedGroupIds.includes(item));
		const toBeAddedGroups = selectedGroupIds.filter((item) => !initialGroupIds.includes(item));

		const groupsToBeDeleted = existingSenderGroupsForSender.filter((x) => toBeDeletedGroups.includes(x.user_group));

		const promises: Promise<NLUserSenderGroup[]>[] = [];

		const deletePromise = new Promise<NLUserSenderGroup[]>((innerResolve, innerReject) => {
			if (groupsToBeDeleted.length === 0) {
				innerResolve([]);
				return;
			}
			const deleteIds = groupsToBeDeleted.map((senderGroup) => senderGroup.id);
			for (let [index, deleteId] of deleteIds.entries()) {
				deleteUserSenderGroup(deleteId).finally(() => {
					if (index === deleteIds.length - 1) {
						innerResolve(groupsToBeDeleted);
					}
				});
			}
		});

		const addPromise = new Promise<NLUserSenderGroup[]>((innerResolve, innerReject) => {
			if (toBeAddedGroups.length === 0) {
				innerResolve([]);
				return;
			}
			var tempUserSenderGroups: NLUserSenderGroup[] = [];
			for (const groupId of toBeAddedGroups) {
				var newSenderGroup = new NLUserSenderGroup();
				newSenderGroup.sender_address = state.sender_address;
				newSenderGroup.user_group = groupId;
				newSenderGroup.sender_address_index = 0;
				tempUserSenderGroups.push(newSenderGroup);
			}
			createUserSenderGroups(tempUserSenderGroups)
				.then((userSenderGroups) => {
					innerResolve(userSenderGroups);
				})
				.catch((error) => {
					innerReject(error);
				});
		});

		promises.push(deletePromise, addPromise);

		Promise.all(promises)
			.then(([deletedSenders, addedSenders]) => {
				var tempUserSenderGroups = [...(userSenderGroups ?? [])];
				tempUserSenderGroups = tempUserSenderGroups.filter((x) => !deletedSenders.map((y) => y.id).includes(x.id));
				tempUserSenderGroups.push(...addedSenders);
				setUserSenderGroups(tempUserSenderGroups);
				generateToast({ status: "success", message: "Groups updated", position: "bottom-center" });
				onClose();
			})
			.catch((_error) => {
				setAlertState({ isShow: true, title: "Uh oh! Something went wrong", message: "Please try again and contact us if the problem persists", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
			})
			.finally(() => {
				setLoadingState({ isLoading: false });
			});
	};

	return (
		<>
			<LoadingView loadingState={loadingState} />
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog static onClose={() => null} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child className="w-full h-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="m-auto w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
								<div className="absolute w-full bg-surface top-0 left-0 flex flex-col p-4 border-b border-primary-200 z-20 gap-3">
									<div className="flex flex-row justify-between items-center">
										<div className="text-primary font-medium font-primary text-xl">Add to Group</div>
										<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onClose()} />
									</div>
								</div>
								<div className="flex flex-col gap-4 text-left h-full p-4 py-[105px] bg-secondary overflow-auto">
									<div className="text-primary-500 font-regular font-primary text-base">Which group you would like to add {state?.sender_name} to? You can add this sender to as many groups as you like.</div>
									<div>
										{userGroups ? (
											userGroups.filter((x) => x.id !== authUser!.audio_profiles[0]?.group_id ).map((userGroup) => {
												return <GroupListRow group={userGroup} isSelected={selectedGroups.map((x) => x.id).includes(userGroup.id)} onSelectedItem={(group) => groupSelected(group)} />;
											})
										) : (
											<EmptyStateView title="No groups found" description="Create a new group to continue" alertImage={collageGlasses} centerInParent={true} />
										)}
									</div>
								</div>
								<div className="absolute bottom-0 w-full bg-surface backdrop-blur-md border-t border-primary-100 px-4 py-4 pt-3 z-20">
									<div className={`flex flex-row justify-end`}>
										<button disabled={!hasMadeChanges} className={`${hasMadeChanges ? "opacity-100 hover:bg-success-green/80" : "opacity-50"} text-white font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-success-green transition ease-in-out whitespace-nowrap`} onClick={() => saveOnClick()}>
											Done
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default AddGroupModal;
