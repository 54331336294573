import React, { useState, useEffect, useCallback, Fragment } from "react";
import { NLBookmark } from "@models/Models";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { ReactTags } from "react-tag-autocomplete";
import "@styles/tags_auto_complete.scss";
import { updateBookmark } from "@utils/managers/networking/NetworkManager";
import { useBookmarksDispatch } from "@providers/BookmarkContext";
import IconButton from "./IconButton";
import { Dialog, Transition } from "@headlessui/react";
import { generateToast } from "@utils/managers/ToastManager";

export interface BookmarkTagNoteModalState {
	bookmark: NLBookmark | undefined;
	existingTags: string[];
}

interface BookmarkTagNoteModalProps {
	bookmarkTagNoteModalState: BookmarkTagNoteModalState;
	onClose: () => void;
}

const BookmarkTagNoteModal: React.FC<BookmarkTagNoteModalProps> = ({ bookmarkTagNoteModalState, onClose }) => {
	const { bookmark, existingTags } = bookmarkTagNoteModalState;
	const [selectedTags, setSelected] = useState<Array<{ value: number; label: string }>>([]);
	const [note, setNote] = useState<string | undefined>(undefined);
	const [isValid, setValid] = useState<boolean>(false);
	const bookmarkDispatch = useBookmarksDispatch();

	useEffect(() => {
		const isTextNotEmpty = note === undefined ? false : note.trim() !== "";
		setValid(isTextNotEmpty || selectedTags.length > 0);
	}, [note, selectedTags]);

	useEffect(() => {
		if (!bookmark) {
			const timeoutId = setTimeout(() => {
				setNote(undefined);
				setSelected([]);
			}, 451);
			return () => clearTimeout(timeoutId);
		}
	}, [bookmark]);

	const onTagAdd = useCallback(
		(newTag) => {
			setSelected([...selectedTags, newTag]);
		},
		[selectedTags]
	);

	const onTagDelete = useCallback(
		(tagIndex) => {
			setSelected(selectedTags.filter((_, i) => i !== tagIndex));
		},
		[selectedTags]
	);

	const getAllTagsWithIndex = () => {
		var tags: Array<{ value: number; label: string }> = [];
		existingTags.forEach((value, i) => {
			tags.push({ value: i, label: value });
		});
		return tags;
	};

	const saveOnClick = () => {
		if (!isValid) {
			return;
		}

		if (!bookmark) {
			return;
		}

		const updatedBookmark: NLBookmark = { ...bookmark };
		updatedBookmark.tags = selectedTags.map((x) => x.label);
		updatedBookmark.note = note;
		updateBookmark(updatedBookmark)
			.then((bookmark) => {
				bookmarkDispatch({ type: "MODIFY_BOOKMARK", payload: { updatedBookmark: bookmark } });
				generateToast({ status: "success", message: "Updated bookmark", position: "bottom-center" });
				onClose();
			})
			.catch(() => {
				generateToast({ status: "error", message: "Failed to update bookmark", position: "bottom-center" });
			});
	};

	const onModalClose = () => {
		onClose();
	};

	if (bookmark) {
		return (
			<>
				<Transition as={Fragment} show={bookmark !== undefined}>
					<Dialog onClose={() => onClose()} className="relative z-40">
						<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
							<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
						</Transition.Child>
						<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
							<Transition.Child className="w-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
								<Dialog.Panel className="m-auto w-full h-full max-w-3xl transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
									<div className="flex flex-row justify-between items-center p-4 pb-3 bg-surface border-b border-primary-200">
										<div className="text-primary font-medium font-primary text-xl">Tag your bookmark & add note</div>
										<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={onModalClose} />
									</div>
									<div className="flex flex-col gap-4 mx-auto text-left p-4">
										<ReactTags labelText="Select tags" allowNew={true} collapseOnSelect={true} selected={selectedTags} suggestions={getAllTagsWithIndex()} onAdd={onTagAdd} onDelete={onTagDelete} placeholderText="Filter or create tags..." newOptionText="Add Tag: %value%" />
										<textarea value={note} onChange={(e) => setNote(e.target.value)} className="w-full bg-surface rounded-[12px] p-3 border border-primary-100  placeholder:font-regular font-medium font-primary text-base text-primary placeholder-primary-500 focus:outline-none resize-none min-h-[180px]" placeholder="Add a bookmark note..." />
									</div>
									<div className="flex flex-col gap-3 p-4 pt-3 bg-surface border-t border-primary-200">
										<div className="flex flex-row justify-end">
											<button type="submit" disabled={!isValid} className={`text-white font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-success-green hover:bg-success-green/80 whitespace-nowrap ${isValid ? "opacity-100" : "opacity-50"}`} onClick={() => saveOnClick()}>
												Save
											</button>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition>
			</>
		);
	}

	return null;
};

export default BookmarkTagNoteModal;
