import { Menu, Transition } from "@headlessui/react";
import { ReactComponent as RenameIcon } from "@images/rename_icon.svg";
import { ReactComponent as FolderIcon } from "@images/folder_icon.svg";
import { ReactComponent as RevertIcon } from "@images/revert_icon.svg";
import { ReactComponent as UnsubscribeIcon } from "@images/unsubscribe_icon.svg";
import { ReactComponent as InboxIcon } from "@images/tabbar_inbox.svg";
import { ReactComponent as LinkIcon } from "@images/link_icon.svg";
import { ReactComponent as ReplyIcon } from "@images/reply_icon.svg";
import { ReactNode } from "react";
import IconButton from "./IconButton";

interface DropdownProps {
	buttonIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	items: DropdownItem[];
	iconSize?: number | undefined;
	className?: string;
	buttonOnEdge?: boolean;
}

export interface DropdownItem {
	name: string;
	icon?: string | undefined;
	action: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({ buttonIcon, iconSize, items, buttonOnEdge, className }) => {
	const getIconByName = (iconName: string, classes: string[]): ReactNode => {
		switch (iconName) {
			case "rename_icon":
				return <RenameIcon className={classes} />;
			case "folder_icon":
				return <FolderIcon className={classes} />;
			case "revert_icon":
				return <RevertIcon className={classes} />;
			case "unsubscribe_icon":
				return <UnsubscribeIcon className={classes} />;
			case "inbox_icon":
				return <InboxIcon className={classes} />;
			case "link_icon":
				return <LinkIcon className={classes} />;
			case "reply_icon":
				return <ReplyIcon className={classes} />;
		}

		return null;
	};

	const itemOnClick = (e, item: DropdownItem) => {
		e.stopPropagation();
		item.action();
	};

	return (
		<Menu as="div" className="relative inline-block text-left">
			{({ open }) => (
				<>
					<Menu.Button onClick={(e) => e.stopPropagation()}>
						<IconButton className={`${open ? "bg-success-green/30 hover:bg-success-green/30" : "bg-inherit hover:bg-inherit/20"} ${className}`} Icon={buttonIcon} forcedIconSize={iconSize} buttonOnEdge={buttonOnEdge} onClick={(e) => e} />
					</Menu.Button>
					<Transition enter="transition duration-100 ease-out" enterFrom="transform scale-95 opacity-0" enterTo="transform scale-100 opacity-100" leave="transition duration-75 ease-out" leaveFrom="transform scale-100 opacity-100" leaveTo="transform scale-95 opacity-0">
						<Menu.Items className="absolute right-0 mt-1 w-56 origin-top-right rounded-md bg-surface-200 shadow-xl ring-1 ring-black/5 focus:outline-none border border-primary-100 z-40">
							<div className="px-1 py-1 ">
								{items.map((item) => {
									return (
										<Menu.Item>
											<button className="group/item flex flex-row items-center gap-2.5 p-2.5 cursor-pointer rounded-lg hover:bg-success-green w-full" onClick={(e) => itemOnClick(e, item)}>
												{item.icon && <div className="w-[16px] flex-shrink-0">{getIconByName(item.icon, ["group-hover/item:fill-white fill-primary-500"])}</div>}
												<div className="font-primary font-regular text-base text-primary group-hover/item:text-white text-left leading-5">{item.name}</div>
											</button>
										</Menu.Item>
									);
								})}
							</div>
						</Menu.Items>
					</Transition>
				</>
			)}
		</Menu>
	);
};

export default Dropdown;
