import { ReactComponent as SidebarExpandIcon } from "@images/sidebar_expand_icon.svg";
import { useLayout } from "@providers/LayoutContext";
import IconButton from "@components/common/IconButton";
import { Textfit } from "react-textfit";
import { ReactComponent as SettingsIcon } from "@images/settings_icon.svg";
import { useAuth } from "@providers/AuthContext";

interface AudioHeaderProps {
	settingsOnClick: () => void;
}

const AudioHeader: React.FC<AudioHeaderProps> = ({ settingsOnClick }) => {
	const { isSidebarHidden, setSidebarHidden, navigationBarHeight } = useLayout();
	const { authUser } = useAuth();
	return (
		<>
			<div className="sticky top-0 bg-white/5 backdrop-blur-lg z-20">
				<div className="flex flex-col gap-3 items-center border-b border-primary-100" style={{ height: navigationBarHeight + "px" }}>
					<div className="flex flex-row justify-between w-full p-3 items-center h-full">
						<div className="flex flex-row items-center justify-between w-full">
							<div className="flex flex-row gap-2 items-center min-w-0 flex-grow">
								<div className={isSidebarHidden ? "block" : "hidden"}>
									<IconButton Icon={SidebarExpandIcon} forcedIconSize={24} onClick={() => setSidebarHidden(false)} />
								</div>
								<div className="flex flex-row items-center gap-1 w-full justify-between">
									<Textfit max={18} mode="single" className="font-primary text-lg font-black text-primary text-left truncate">
										Audio
									</Textfit>
									{authUser!.audio_profiles.length > 0 && <IconButton Icon={SettingsIcon} buttonOnEdge={true} onClick={ settingsOnClick } /> }
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AudioHeader;
