import { useLayout } from "@providers/LayoutContext";
import SectionLayout from "../../../layouts/SectionLayout";
import { NLNotificationPreferenceType, ThemeState } from "@models/Enums";
import { ReactComponent as LightModeIcon } from "@images/light_mode_icon.svg";
import { ReactComponent as DarkModeIcon } from "@images/dark_mode_icon.svg";
import { ReactComponent as AutoModeIcon } from "@images/auto_mode_icon.svg";
import collageNotifications from "@images/collage_notifications.png";
import { ReactComponent as CheckMark } from "@images/check_mark_selected.svg";
import { useAuth } from "@providers/AuthContext";
import ListboxView from "../../common/ListboxView";
import { getLocalDate, getUTCDate } from "@utils/Date+NotificationDate";
import { NLNotificationAsArrivedType, NLNotificationSetTimeType } from "@models/Models";
import { deleteExistingNotifications, setAsArrivedNotification, setTimeNotification } from "@utils/managers/NotificationsManager";
import { recordEvent } from "@utils/managers/AnalyticsManager";

const Preferences = () => {
	const { wallpapers, wallpaper, setWallpaper, theme, setTheme } = useLayout();
	const { authUser, setAuthUser } = useAuth();

	const daysArray = ["on Mondays", "on Tuesdays", "on Wednesdays", "on Thursdays", "on Fridays", "on Saturdays", "on Sundays", "Every day"];
	const hoursArray = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
	const minutesArray = ["15", "30", "45", "00"];

	const getThemeIcon = (theme: ThemeState) => {
		switch (theme) {
			case ThemeState.light:
				return <LightModeIcon className="fill-primary h-[30px] m-auto" />;
			case ThemeState.dark:
				return <DarkModeIcon className="fill-primary h-[25px] m-auto" />;
			case ThemeState.auto:
				return <AutoModeIcon className="fill-primary h-[30px] m-auto" />;
		}
	};

	const getNotificationDay = (dayNumber: number) => {
		const [localDay, _localHour, _localMinute] = getLocalDate(dayNumber, authUser!.notifications?.set_time?.hour ?? 0, authUser!.notifications?.set_time?.minute ?? 0);

		if (localDay === 0) {
			return daysArray[daysArray.length - 1];
		}

		return daysArray[localDay - 1];
	};

	const getNotificationHour = (hour: number) => {
		const [_localDay, localHour, _localMinute] = getLocalDate(authUser!.notifications?.set_time?.day ?? 0, hour, authUser!.notifications?.set_time?.minute ?? 0);
		return hoursArray.find((x) => x === localHour.toString()) ?? "00";
	};

	const getNotificationMinute = (minute: number) => {
		const [_localDay, _localHour, localMinute] = getLocalDate(authUser!.notifications?.set_time?.day ?? 0, authUser!.notifications?.set_time?.hour ?? 0, minute);
		return minutesArray.find((x) => x === localMinute.toString()) ?? "00";
	};

	const timeOnChange = (selection, timeFormat) => {
		var [localDay, localHour, localMinute] = getLocalDate(authUser!.notifications?.set_time?.day ?? 0, authUser!.notifications?.set_time?.hour ?? 0, authUser!.notifications?.set_time?.minute ?? 0);

		if (timeFormat === "day") {
			if (selection === "Every day") {
				localDay = 0;
			} else {
				localDay = daysArray.indexOf(selection) + 1;
			}
		} else if (timeFormat === "hour") {
			localHour = selection;
		} else if (timeFormat === "minute") {
			localMinute = selection;
		}

		const [utcDay, utcHour, utcMinute] = getUTCDate(localDay, parseInt(localHour), parseInt(localMinute));
		const newNotifications = new NLNotificationSetTimeType({ id: authUser!.notifications?.set_time?.id ?? "0", type: NLNotificationPreferenceType.setTime, day: utcDay, hour: utcHour, minute: utcMinute });
		setAuthUser({ ...authUser!, notifications: { set_time: newNotifications } });
		setAsArrivedNotification(authUser!, newNotifications);
	};

	const setTimeOnClick = () => {
		const [utcDay, utcHour, utcMinute] = getUTCDate(0, 20, 0);
		const newNotifications = new NLNotificationSetTimeType({ id: "0", type: NLNotificationPreferenceType.setTime, day: utcDay, hour: utcHour, minute: utcMinute });
		setAuthUser({ ...authUser!, notifications: { set_time: newNotifications } });
		setTimeNotification(authUser!, newNotifications);
	};

	const setArriveOnClick = () => {
		const newNotifications = new NLNotificationAsArrivedType({ id: "0", type: NLNotificationPreferenceType.asArrived });
		setAuthUser({ ...authUser!, notifications: { as_arrived: newNotifications } });
		setAsArrivedNotification(authUser!, newNotifications);
	};

	const noNotificationsOnClick = () => {
		setAuthUser({ ...authUser!, notifications: {} });
		deleteExistingNotifications(authUser!);
	};

	return (
		<SectionLayout id="preferences" sectionTitle="Preferences">
			<div className="flex flex-col gap-4 rounded-xl p-3 border border-primary-100">
				<div className="flex flex-row gap-3 justify-between">
					<div className="flex flex-col gap-2">
						<div className="font-primary font-bold text-lg text-primary">Notifications</div>
						<div className="font-primary font-regular text-sm text-primary-500">How do you want to be notified? We recommend choosing a set time to create a habit for newsletter reading.</div>
					</div>
					<img src={collageNotifications} className="max-w-[80px] object-contain" />
				</div>
				<div className="flex flex-row gap-4 flex-wrap">
					<button className={`group flex items-center justify-between gap-4 ${authUser!.notifications?.set_time ? "dark:bg-success-green bg-success-green/20" : "bg-surface hover:bg-surface-100"} border transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 w-full`} onClick={() => setTimeOnClick()}>
						<div className="flex min-w-0 flex-grow gap-2.5 items-center">
							<div className="flex flex-col">
								<div className="font-primary font-medium text-base text-primary text-left truncate">Set time</div>
								<div className="font-primary font-medium text-sm text-primary-500 text-left truncate">Remind me at a chosen time every day or week</div>
								{authUser!.notifications?.set_time && (
									<div className="flex flex-row gap-2 pt-3 items-center">
										<ListboxView selected={getNotificationDay(authUser!.notifications.set_time.day)} setSelected={(selectionString) => timeOnChange(selectionString, "day")} options={daysArray.map((day) => day)} />
										<ListboxView selected={getNotificationHour(authUser!.notifications.set_time.hour)} setSelected={(selectionString) => timeOnChange(selectionString, "hour")} options={hoursArray.map((hour) => hour)} />
										<ListboxView selected={getNotificationMinute(authUser!.notifications.set_time.minute)} setSelected={(selectionString) => timeOnChange(selectionString, "minute")} options={minutesArray.map((minute) => minute)} />
									</div>
								)}
							</div>
						</div>
						{authUser!.notifications?.set_time && <CheckMark className="fill-primary w-[26px] h-[26px]" />}
					</button>
					<button className={`group flex items-center justify-between gap-4 ${authUser!.notifications?.as_arrived ? "dark:bg-success-green bg-success-green/20" : "bg-surface hover:bg-surface-100"} border transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 w-full`} onClick={() => setArriveOnClick()}>
						<div className="flex min-w-0 flex-grow gap-2.5 items-center">
							<div className="flex flex-col">
								<div className="font-primary font-medium text-base text-primary text-left truncate">As newsletters arrive</div>
								<div className="font-primary font-medium text-sm text-primary-500 text-left truncate">Notify me each time I receive a newsletter</div>
							</div>
						</div>
						{authUser!.notifications?.as_arrived && <CheckMark className="fill-primary w-[26px] h-[26px]" />}
					</button>
					<button className={`group flex items-center justify-between gap-4 ${authUser!.notifications?.as_arrived || authUser!.notifications?.set_time ? "bg-surface hover:bg-surface-100" : "dark:bg-brand-red/50  bg-brand-red/20"} border transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 w-full`} onClick={() => noNotificationsOnClick()}>
						<div className="flex min-w-0 flex-grow gap-2.5 items-center">
							<div className="flex flex-col">
								<div className="font-primary font-medium text-base text-primary text-left truncate">No notifications</div>
								<div className="font-primary font-medium text-sm text-primary-500 text-left truncate">Don't notify me</div>
							</div>
						</div>
						{!(authUser!.notifications?.as_arrived || authUser!.notifications?.set_time) && <CheckMark className="fill-primary w-[26px] h-[26px]" />}
					</button>
				</div>
			</div>
			<div className="flex flex-col gap-4 rounded-xl p-3 border border-primary-100">
				<div className="font-primary font-bold text-lg text-primary">Appearance</div>
				<div className="flex flex-row gap-4 flex-wrap">
					{Object.entries(ThemeState)
						.filter(([key]) => isNaN(Number(key)))
						.map(([themeKey]) => {
							const themeOption = ThemeState[themeKey as keyof typeof ThemeState];
							return (
								<div className="flex flex-col gap-2 items-center">
									<button
										className={`relative w-20 h-20 bg-surface p-1 rounded-lg overflow-hidden transition ease-in-out duration-300 hover:opacity-80 border border-primary-200 ${themeOption === theme ? "opacity-100" : "opacity-50"}`}
										onClick={() => {
											recordEvent("MCO - Theme Changed", { theme: themeOption });
											setTheme(themeOption);
										}}>
										{getThemeIcon(themeOption)}
										<input type="radio" value={themeKey} checked={themeOption === theme} className="w-3 h-3 absolute bottom-1 left-1  accent-success-green" />
									</button>
									<div className="text-sm font-primary font-medium text-primary text-center">{themeOption === ThemeState.light ? "Light Mode" : themeOption === ThemeState.dark ? "Dark Mode" : "Auto Mode"}</div>
								</div>
							);
						})}
				</div>
			</div>
			<div className="flex flex-col gap-4 rounded-xl p-3 border border-primary-100">
				<div className="font-primary font-bold text-lg text-primary">Wallpaper</div>
				<div className="flex flex-row gap-4 flex-wrap">
					{wallpapers.map((wallpaperOption, index) => {
						return (
							<div>
								<button
									className={`relative w-20 h-20 rounded-lg overflow-hidden transition ease-in-out duration-300 hover:opacity-80 ${wallpaperOption.imageId === wallpaper.imageId ? "opacity-100" : "opacity-50"}`}
									onClick={() => {
										recordEvent("MCO - Wallpaper Changed", { wallpaper_id: wallpaperOption.imageId });
										setWallpaper(wallpaperOption);
									}}>
									<img src={wallpaperOption.image} alt="Wallpaper" className="w-full h-full object-cover" />
									<input type="radio" value={wallpaperOption.imageId} checked={wallpaperOption.imageId === wallpaper.imageId} className="w-3 h-3 absolute bottom-1 left-1  accent-success-green" />
								</button>
							</div>
						);
					})}
				</div>
			</div>
		</SectionLayout>
	);
};

export default Preferences;
