import React, { createContext, useContext, ReactNode, useEffect } from "react";
import { NLProfile } from "@models/Models";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { Outlet } from "react-router-dom";

interface AuthContextProps {
	authUser: NLProfile | null | undefined;
	setAuthUser: (profile: NLProfile) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = () => {
	const [authUser, setAuthUser] = React.useState<NLProfile | null | undefined>(undefined);

	useEffect(() => {
		if (authUser) {
			return;
		}

		networkManager
			.getUserProfile(false)
			.then((profile) => {
				setAuthUser(profile);
			})
			.catch((_error) => {
				setAuthUser(null);
			})
	}, []);

	const providerValue: AuthContextProps = {
		authUser,
		setAuthUser,
	};

	return <AuthContext.Provider value={providerValue}><Outlet /></AuthContext.Provider>;
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
