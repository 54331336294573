import { NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";

export class MessageSummaryService {
	private mailboxProfile: NLMailboxProfile;
	private messageId: string;

	constructor(mailboxProfile: NLMailboxProfile, messageId: string) {
		this.mailboxProfile = mailboxProfile;
		this.messageId = messageId;
	}

	getMessageSummary(
		completion: (
			summaryString: string | undefined,
			error: Error | undefined
		) => void
	) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();
		let request: MessageSummaryService.RequestModel | undefined = undefined;

		const promise = new Promise<void>((innerResolve, innerReject) => {
			if (this.mailboxProfile.type !== NLMailboxProfileType.Internal) {
				oauthSessionManager
					.fetchAccessTokenForMailboxProfile(
						this.mailboxProfile.id,
						this.mailboxProfile.type
					)
					.then((accessToken) => {
						request = new MessageSummaryService.RequestModel(
							this.mailboxProfile.id,
							this.messageId,
							accessToken
						);
						innerResolve();
					})
					.catch((error) => {
						innerReject(error);
						return;
					});
			} else {
				request = new MessageSummaryService.RequestModel(
					this.mailboxProfile.id,
					this.messageId,
					null
				);
				innerResolve();
			}
		});

		Promise.all([promise])
			.then(() => {
				if (!request) {
					completion(undefined, undefined);
					return;
				}
				this.makeRequests(request, completion);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}

	private makeRequests(
		request: MessageSummaryService.RequestModel,
		completion: (summary: string | undefined, error: Error | undefined) => void
	) {
		networkManager
			.getMessageSummary(request)
			.then((jsonData) => {
				if (jsonData.error) {
					const mailboxError = MailboxError.returnError(
						jsonData.error,
						jsonData.mailbox_profile_id
					);
					completion(undefined, mailboxError);
					return;
				}
                completion(jsonData.summary.summary, undefined);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}
}

export namespace MessageSummaryService {
	export class RequestModel {
		access_token: string | null;
		mailbox_profile_id!: string;
		message_id!: string;

		constructor(
			mailboxProfileId: string,
			messageId: string,
			accessToken: string | null
		) {
			this.mailbox_profile_id = mailboxProfileId;
			this.message_id = messageId;
			this.access_token = accessToken;
		}
	}
}
