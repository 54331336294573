import { FC } from "react";

interface BenefitProps {
	benefit: BenefitViewState;
}

export interface BenefitViewState {
	icon: any;
	title: string;
	description: string;
}

const BenefitView: FC<BenefitProps> = (props) => {
	return (
		<div className="flex flex-row bg-white/10 hover:bg-white/20 transition ease-in-out border border-white/20 p-3 rounded-[10px] gap-2 items-center">
			<div className="p-2">
				{props.benefit.icon}
			</div>
			<div className="flex-col text-left">
				<div className="text-force-primary-light font-medium font-primary text-base  sm:text-xl">{props.benefit.title}</div>
				<div className="text-force-primary-light/50 font-regular font-primary text-xs">{props.benefit.description}</div>
			</div>
		</div>
	);
};

export default BenefitView;
