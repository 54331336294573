import { ReactComponent as SidebarExpandIcon } from "@images/sidebar_expand_icon.svg";
import { useLayout } from "@providers/LayoutContext";
import IconButton from "@components/common/IconButton";
import { ReactComponent as AudioIcon } from "@images/tabbar_audio.svg";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { ReactComponent as ThumbsUpIcon } from "@images/thumbs_up_icon.svg";
import { ReactComponent as ThumbsDownIcon } from "@images/thumbs_down_icon.svg";
import { ReactComponent as ShareIcon } from "@images/share_icon.svg";
import { ReactComponent as PreviousChapterIcon } from "@images/previous_chapter_icon.svg";
import { ReactComponent as PlayIcon } from "@images/audio_play_icon.svg";
import { ReactComponent as PauseIcon } from "@images/audio_pause_icon.svg";
import { ReactComponent as SpeedIcon } from "@images/audio_speed_icon.svg";
import { useAudio, useAudioDispatch } from "@providers/AudioContext";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Textfit } from "react-textfit";
import { postShareAudio, postAudioRating } from "@utils/managers/networking/NetworkManager";
import { generateToast } from "@utils/managers/ToastManager";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import createPersistedState from "use-persisted-state";
import Alert, { AlertState } from "@components/common/Alert";
import { useNavigate } from "react-router-dom";
import { NLAudioEpisode } from "@models/Models";
import { recordEvent, generateAudioProperties } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const usePlaybackSpeed = createPersistedState(kLocalStorageKeys.Audio.playbackSpeed);

const AudioPlayer = () => {
	const { navigationBarHeight } = useLayout();
	const navigate = useNavigate();
	const audioDispatch = useAudioDispatch();
	const { episode, isPlaying, audio, audioProgress, activeChapterId } = useAudio();
	const progressBarRef = useRef<HTMLDivElement>(null);
	const barRef = useRef<HTMLDivElement>(null);
	const { mailListWidth, isAudioPlayerActive, setAudioPlayerActive } = useLayout();
	let playbackSpeedOptions: number[] = [0.5, 0.8, 1.0, 1.25, 1.5, 2.0];
	const [playbackSpeed, setPlaybackSpeed] = usePlaybackSpeed(1.0);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});

	useEffect(() => {
		if (progressBarRef.current && audio.duration) {
			const progress = (audio.currentTime / audio.duration) * 100;
			progressBarRef.current.style.width = `${progress}%`;
		}
	}, [audioProgress]);

	const handleSeek = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const rect = event.currentTarget.getBoundingClientRect();
		const clickX = event.clientX - rect.left;
		const newTime = (clickX / rect.width) * audio.duration;
		audioDispatch({ type: "SEEK", payload: { to: newTime } });
	};

	const formatTime = (currentTime?: number): string => {
		if (!currentTime) {
			return "--:--";
		}
		const roundedTime = Math.round(currentTime);
		const minutes = Math.floor(roundedTime / 60);
		const seconds = roundedTime % 60;
		return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
	};

	const playbackOptionOnClick = () => {
		const index = playbackSpeedOptions.indexOf(audio.playbackRate);
		if (index === -1) {
			return;
		}
		const nextIndex = (index + 1) % playbackSpeedOptions.length;
		let playbackSpeed = playbackSpeedOptions[nextIndex];
		audioDispatch({ type: "SET_PLAYBACK_SPEED", payload: { playbackSpeed: playbackSpeed } });
		setPlaybackSpeed(playbackSpeed);
		if (episode) {
			var properties = generateAudioProperties(episode);
			properties["playback_speed"] = playbackSpeed;
			recordEvent(kAnalyticsConstants.Audio.playbackSpeedChanged, properties)
		}
	};

	const playPauseOnClick = () => {
		if (isPlaying) {
			audioDispatch({ type: "PAUSE" });
		} else {
			audioDispatch({ type: "PLAY" });
		}
	};

	const shareButtonOnClick = () => {
		if (!episode) {
			return;
		}

		let audioShareDisclaimer = localStorage.getItem(kLocalStorageKeys.ShowOnce.audioShareDisclaimer);

		if (!audioShareDisclaimer) {
			setAlertState({
				isShow: true,
				title: "Sharing Disclaimer",
				message: "This podcast is created from your personal newsletters. If you share it, please note that the audio will be accessible to anyone with the link.",
				actionButton: "OK",
				dismissButton: "Cancel",
				actionButtonAction: () => {
					localStorage.setItem(kLocalStorageKeys.ShowOnce.audioShareDisclaimer, "true");
					shareButtonOnClick();
				},
			});
			return;
		}

		postShareAudio(episode.id).then((shareUrl) => {
			recordEvent(kAnalyticsConstants.Audio.audioLinkShared, generateAudioProperties(episode));
			setTimeout(() => {
				navigator.clipboard.writeText(shareUrl);
			}, 0);
			generateToast({ status: "success", message: "Share link copied to clipboard", position: "bottom-center" });
		});
	};

	const newsletterOnClick = (messageId: string) => {
		if (episode?.is_generated_for_public) {
			setAlertState({ isShow: true, title: "Newsletter unavailable", message: "Only newsletters in personalized podcasts are currently accessible. We hope to make all newsletters accessible soon!", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
			return;
		}

		window.open(`/mail/${messageId}`);
	};

	const ratingOnClick = (isThumbsUp: boolean) => {
		if (!episode) {
			return;
		}
		postAudioRating(episode.id, isThumbsUp);
		let thumbsUp = isThumbsUp ? kAnalyticsConstants.Audio.thumbsUp : kAnalyticsConstants.Audio.thumbsDown;
		recordEvent(thumbsUp, generateAudioProperties(episode));
		generateToast({ status: "success", message: isThumbsUp ? "Glad to hear! ✨" : "Thanks for your feedback!", position: "bottom-center" });
	};

	const exitOnClick = () => {
		setAudioPlayerActive(false);
		audioDispatch({ type: "RESET" });
	};

	const renderAboutSection = () => {
		const localDate = moment.utc(episode?.created_at).local();
		const formattedDate = localDate.format("MMMM D, YYYY");

		return (
			<div className="flex flex-col gap-3 bg-surface-200 mb-4 p-3 rounded-2xl border border-primary-100">
				<div className="font-primary text-base font-bold text-primary text-left py-2">About this episode</div>
				<div className="font-primary text-sm font-regular text-primary-500 text-left">{formattedDate}</div>
				<div className="font-primary text-sm font-regular text-primary-500 text-left">{episode?.summary}</div>
				<div className="font-primary text-xs font-regular text-primary-500 text-left">Disclaimer: This podcast was generated and voiced using AI. While we strive for accuracy, AI-generated content may not always be perfect. Please review critically and use your judgment. If you encounter errors or have concerns, contact us at team@meco.app.</div>
			</div>
		);
	};

	const renderReferencedNewsletters = () => {
		if (!(episode && episode.chapters.length > 0)) {
			return null;
		}

		let currentChapter = episode.chapters.find((x) => x.id === activeChapterId);
		if (!currentChapter) {
			return null;
		}

		let bannedChapters = ["Intro", "Outro"];
		if (currentChapter.title && bannedChapters.includes(currentChapter.title)) {
			return null;
		}

		if (!(currentChapter.referenced_messages.length > 0)) {
			return null;
		}

		return (
			<div className="flex flex-col gap-3 bg-surface-200 mb-4 p-3 rounded-2xl border border-primary-100">
				<div className="font-primary text-base font-bold text-primary text-left py-2">Newsletters in this chapter</div>
				{currentChapter.referenced_messages.map((newsletter) => {
					return (
						<div key={newsletter.message_id} className={`w-full flex flex-row bg-surface rounded-xl px-3 py-3 hover:bg-surface-100 transition ease-in-out duration-[250] cursor-pointer shadow-md`} onClick={() => newsletterOnClick(newsletter.message_id)}>
							<div className="flex flex-row gap-2 justify-between items-center w-full">
								<div className={`flex flex-col gap-2 opacity-1`}>
									<div className="flex flex-col gap-0">
										<div className="text-xs text-left font-primary font-medium text-primary transition ease-in-out">{newsletter.sender_name}</div>
										<div className="text-base  font-primary font-bold text-primary leading-6">{newsletter.message_subject}</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	const renderChaptersSection = () => {
		if (!(episode && episode.chapters.length > 0)) {
			return null;
		}
		return (
			<div className="flex flex-col gap-3 bg-surface-200 mb-4 p-3 rounded-2xl border border-primary-100">
				<div className="font-primary text-base font-bold text-primary text-left py-2">Chapters</div>
				{episode.chapters.map((chapter) => {
					return (
						<button key={chapter.id} className={`${activeChapterId == chapter.id ? "dark:bg-success-green bg-success-green/20" : "bg-surface hover:bg-surface-100"} flex flex-row items-center justify-start border transition ease-in-out duration-300 border-primary-100 rounded-xl p-2 h-[50px] w-full gap-3`} onClick={() => audioDispatch({ type: "SEEK", payload: { to: chapter.start_time ?? 0 } })}>
							<div className="font-primary text-xs font-regular text-primary-500 text-left">{formatTime((chapter.end_time ?? 0) - (chapter.start_time ?? 0))}</div>
							<Textfit mode="single" max={14} className="font-primary font-regular text-primary text-left truncate">
								{chapter.title}
							</Textfit>
						</button>
					);
				})}
			</div>
		);
	};

	const renderChapterIndicators = () => {
		if (!(episode && episode.chapters.length > 0)) {
			return null;
		}

		let audioDuration = audio.duration;
		let barWidth = barRef.current?.offsetWidth ?? 0;

		return (
			<>
				{episode.chapters.map((chapter) => {
					if (!chapter.start_time) {
						return null;
					}
					let ratio = chapter.start_time / audioDuration;
					let marginLeft = barWidth * ratio;
					return (
						<div
							key={chapter.id}
							className="absolute top-0 h-full w-[10px] hover:scale-[2] transition-all ease-in-out duration-300 flex flex-row justify-center text-center"
							style={{ left: marginLeft - 4 }}
							onClick={(e) => {
								e.stopPropagation();
								audioDispatch({ type: "SEEK", payload: { to: chapter.start_time ?? 0 } });
							}}>
							<div className="w-[2px] h-full bg-primary top-0 " />
						</div>
					);
				})}
			</>
		);
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<div className={`${isAudioPlayerActive ? "flex" : "hidden"} bg-secondary h-screen border-r border-primary-100 z-20 transition-[width] ease-in-out duration-300 overflow-auto overflow-x-hidden flex flex-col pb-4 shadow-2xl`} style={{ width: mailListWidth + "px", minWidth: mailListWidth + "px" }} id="root-container">
				<div className="bg-contrast/5 dark:bg-transparent backdrop-blur-lg flex flex-row justify-between w-full items-center p-3" style={{ height: navigationBarHeight + "px" }}>
					<div className="flex-none w-[36px] h-[36px]" />
					<AudioIcon className="grow fill-primary !h-[20px]" />
					<IconButton forcedIconSize={16} buttonOnEdge={true} Icon={ExitIcon} onClick={exitOnClick} />
				</div>
				<div className="p-4 pb-0">
					<div className="w-full flex flex-row items-center justify-center aspect-square">
						<img className="rounded-xl w-full min-w-full min-h-full object-cover" src={episode?.image_url} />
					</div>
					<div className="flex flex-row w-full items-center justify-between pt-4 gap-2">
						<div className="flex flex-col w-full min-w-0 flex-grow">
							<div className="font-primary text-lg font-medium text-primary text-left truncate text-ellipsis">{episode?.chapters.find((x) => x.id === activeChapterId)?.title}</div>
							<div className="font-primary text-base font-medium text-primary-500 text-left truncate text-ellipsis">{episode?.title}</div>
						</div>
						<div className="flex flex-row gap-2">
							<IconButton Icon={ThumbsUpIcon} iconClassName="!fill-success-green" className="!rounded-full border-2 border-success-green" onClick={() => ratingOnClick(true)} />
							<IconButton Icon={ThumbsDownIcon} iconClassName="!fill-brand-red" className="!rounded-full border-2 border-brand-red" onClick={() => ratingOnClick(false)} />
						</div>
					</div>
					<div ref={barRef} className="relative w-full h-2 bg-primary-200 rounded-full mt-4 cursor-pointer" onClick={handleSeek}>
						<div ref={progressBarRef} className="absolute h-full bg-success-green rounded-full" />
						{renderChapterIndicators()}
						<div className="flex flex-row justify-between pt-[12px]">
							<div className="font-primary text-xs font-regular text-primary-500 text-left">{formatTime(audio.currentTime)}</div>
							<div className="font-primary text-xs font-regular text-primary-500 text-left">{formatTime(audio.duration)}</div>
						</div>
					</div>
					<div className="flex flex-row justify-between my-5">
						<IconButton Icon={ShareIcon} forcedIconSize={26} onClick={shareButtonOnClick} />
						<IconButton Icon={PreviousChapterIcon} forcedIconSize={26} onClick={() => audioDispatch({ type: "MOVE_CHAPTER", payload: { isNext: false } })} />
						<IconButton Icon={isPlaying ? PauseIcon : PlayIcon} forcedIconSize={40} isIconHover={true} onClick={playPauseOnClick} />
						<IconButton Icon={PreviousChapterIcon} forcedIconSize={26} iconClassName="rotate-180" onClick={() => audioDispatch({ type: "MOVE_CHAPTER", payload: { isNext: true } })} />
						<IconButton Icon={SpeedIcon} forcedIconSize={26} className="relative" onClick={playbackOptionOnClick}>
							{playbackSpeed != 1.0 && <div className="font-primary text-xs font-regular text-secondary text-center bg-primary rounded-full mt-1">{playbackSpeed}x</div>}
						</IconButton>
					</div>
					{renderReferencedNewsletters()}
					{renderAboutSection()}
					{renderChaptersSection()}
				</div>
			</div>
		</>
	);
};

export default AudioPlayer;
