import { ReactComponent as SidebarExpandIcon } from "@images/sidebar_expand_icon.svg";
import { useLayout } from "@providers/LayoutContext";
import IconButton from "@components/common/IconButton";
import { Textfit } from "react-textfit";

const BookmarksHeader = () => {
	const { isSidebarHidden, setSidebarHidden, navigationBarHeight } = useLayout();

	return (
		<>
			<div className="sticky top-0 bg-white/5 backdrop-blur-lg">
				<div className="flex flex-col gap-3 items-center border-b border-primary-100" style={{ height: navigationBarHeight + "px" }}>
					<div className="flex flex-row justify-between w-full p-3 items-center h-full">
						<div className="flex flex-row items-center justify-between w-full">
							<div className="flex flex-row gap-2 items-center min-w-0 flex-grow">
								<div className={isSidebarHidden ? "block" : "hidden"}>
									<IconButton Icon={SidebarExpandIcon} forcedIconSize={24} onClick={() => setSidebarHidden(false)} />
								</div>
								<div className="flex flex-row items-center gap-1 w-full">
									<Textfit max={18} mode="single" className="font-primary text-lg font-black text-primary text-left truncate">
										Bookmarks
									</Textfit>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BookmarksHeader;
