import { NLNotificationPreferenceType } from "@models/Enums";
import { NLNotificationAsArrivedType, NLNotificationSetTimeType, NLProfile } from "@models/Models";
import { kAppConstants } from "@utils/constants/AppConstants";
import { changeNotificationPreferenceAsArrived, changeNotificationPreferenceSetTime, deleteNotificationPreference, registerPush } from "@utils/managers/networking/NetworkManager";

export const setTimeNotification = (profile: NLProfile, notification: NLNotificationSetTimeType) => {
	Notification.requestPermission().then((permission) => {
		if (permission === "granted") {
			subscribeToPushNotifications().then(() => {
				deleteExistingNotifications(profile).then(() => {
					changeNotificationPreferenceSetTime(notification);
				});
			});
		} else {
			deleteExistingNotifications(profile);
		}
	});
};

export const setAsArrivedNotification = (profile: NLProfile, notification: NLNotificationAsArrivedType) => {
	Notification.requestPermission().then((permission) => {
		if (permission === "granted") {
			subscribeToPushNotifications()
				.then(() => {
					deleteExistingNotifications(profile).then(() => {
						changeNotificationPreferenceAsArrived(notification);
					});
				})
				.catch((error) => {
					deleteExistingNotifications(profile);
					console.log(`Error in subscribing to push notifications  ${error}`);
				});
		} else {
			deleteExistingNotifications(profile);
		}
	});
};

export const deleteExistingNotifications = (profile: NLProfile): Promise<void> => {
	return new Promise((resolve, reject) => {
		if (profile.notifications?.set_time || profile.notifications?.as_arrived) {
			const type = profile.notifications?.set_time ? NLNotificationPreferenceType.setTime : NLNotificationPreferenceType.asArrived;
			deleteNotificationPreference(type).finally(() => {
				resolve();
			});
		} else {
			resolve();
		}
	});
};

export const subscribeToPushNotifications = (): Promise<void> => {
	return new Promise((resolve, reject) => {
		navigator.serviceWorker.ready
			.then((serviceWorkerRegistration) => {
				return subscribeWorker(serviceWorkerRegistration);
			})
			.then((data) => {
				return registerPushWithBackend(data);
			})
			.then(() => {
				resolve();
			})
			.catch((_error) => {
				resolve();
			});
	});
};

const subscribeWorker = (serviceWorkerRegistration: ServiceWorkerRegistration): Promise<Record<string, any>> => {
	return new Promise((resolve, reject) => {
		const applicationServerKey = kAppConstants.vapidPublicKey;
		const options = {
			userVisibleOnly: true,
			applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
		};
		serviceWorkerRegistration.pushManager.subscribe(options).then(
			(pushSubscription) => {
				try {
					var registration_id = pushSubscription.endpoint;
					const p256 = pushSubscription.getKey("p256dh");
					const auth = pushSubscription.getKey("auth");
					var data = {
						p256dh: btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(p256!)))),
						auth: btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(auth!)))),
						registration_id: registration_id,
					};
					resolve(data);
				} catch (error) {
					reject(error);
				}
			},
			(error) => {
				reject(error);
			}
		);
	});
};

const registerPushWithBackend = (data: Record<string, any>): Promise<void> => {
	return new Promise((resolve, reject) => {
		registerPush(data)
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

function urlBase64ToUint8Array(base64String) {
	var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	var base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

	var rawData = window.atob(base64);
	var outputArray = new Uint8Array(rawData.length);

	for (var i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
