import { kAppConfig } from "@utils/configurations/AppConfig";
import { NLMail, NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";
import { chunkArray } from "@utils/Array+Chunk";

export class GetMessagesService {
	private queries: GetMessagesService.QueryBuilder[];

	constructor(queries: GetMessagesService.QueryBuilder[]) {
		this.queries = queries;
	}

	getMessages(completion: (responseModel: GetMessagesService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();

		let requestArray: GetMessagesService.RequestModel[] = [];
		let responseArray: GetMessagesService.ResponseModel[] = [];
		var maxIterations = 0;
		const promises: Promise<void>[] = [];

		for (const requestModel of this.queries) {
			const promise = new Promise<void>((innerResolve, innerReject) => {
				if (requestModel.mailboxProfile.type !== NLMailboxProfileType.Internal) {
					oauthSessionManager
						.fetchAccessTokenForMailboxProfile(requestModel.mailboxProfile.id, requestModel.mailboxProfile.type)
						.then((accessToken) => {
							let chunkedMessageIds: string[][] = chunkArray(requestModel.messageIds, kAppConfig.backendPostRequestChunkSize);
							maxIterations = chunkedMessageIds.length > maxIterations ? chunkedMessageIds.length : maxIterations;

							for (let messageIds of chunkedMessageIds) {
								const request = new GetMessagesService.RequestModel(requestModel.mailboxProfile.id, messageIds, accessToken);
								requestArray.push(request);
							}

							innerResolve();
						})
						.catch((error) => {
							if (error instanceof Error && error.message === "noInternetError") {
								innerReject(error);
							} else {
								var responseModel = new GetMessagesService.ResponseModel(requestModel.mailboxProfile.id);
								responseModel.error = oauthSessionManager.generateSignInRequiredError(requestModel.mailboxProfile.id, error);
								responseArray.push(responseModel);
								innerResolve();
							}
						});
				} else {
					let chunkedMessageIds: string[][] = chunkArray(requestModel.messageIds, kAppConfig.backendPostRequestChunkSize);
					maxIterations = chunkedMessageIds.length > maxIterations ? chunkedMessageIds.length : maxIterations;

					for (let messageIds of chunkedMessageIds) {
						const request = new GetMessagesService.RequestModel(requestModel.mailboxProfile.id, messageIds, null);
						requestArray.push(request);
					}

					innerResolve();
				}
			});
			promises.push(promise);
		}

		Promise.all(promises)
			.then(() => {
				if (requestArray.length === 0) {
					completion(undefined, undefined);
					return;
				}

				this.makeRequests(requestArray, responseArray, maxIterations, completion);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}

	private makeRequests(requestArray: GetMessagesService.RequestModel[], responseArray: GetMessagesService.ResponseModel[], maxIterations: number, completion: (responseModel: GetMessagesService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		var newResponseArray = responseArray;

		const requestDictByMailboxProfile: { [mailboxProfileId: string]: GetMessagesService.RequestModel[] } = {};
		requestArray.forEach((request) => {
			if (!requestDictByMailboxProfile[request.mailbox_profile_id]) {
				requestDictByMailboxProfile[request.mailbox_profile_id] = [];
			}
			requestDictByMailboxProfile[request.mailbox_profile_id].push(request);
		});

		var responseCount = 0;

		for (var i = 0; i < maxIterations; i++) {
			var batchRequests: GetMessagesService.RequestModel[] = [];

			for (let mailboxProfileId in requestDictByMailboxProfile) {
				const requestObjects = requestDictByMailboxProfile[mailboxProfileId];
				if (!requestObjects) {
					continue;
				}
				if (!requestObjects[i]) {
					continue;
				}

				batchRequests.push(requestObjects[i]);
			}

			networkManager
				.getMessages(batchRequests)
				.then((jsonData) => {
					responseCount += 1;

					for (let mailboxProfileId in jsonData) {
						let responseData = jsonData[mailboxProfileId];
						var responseModel = new GetMessagesService.ResponseModel(mailboxProfileId);

						if (Array.isArray(responseData["messages"])) {
							let messages = responseData["messages"].map((mail: NLMail) => new NLMail(mail));
							responseModel.messages = messages;
						}

						if (responseData["error"]) {
							responseModel.error = MailboxError.returnError(responseData["error"], mailboxProfileId);
						}

						newResponseArray.push(responseModel);
					}

					if (responseCount === maxIterations) {
						completion(newResponseArray, undefined);
					}
				})
				.catch((error) => {
					completion(undefined, error);
				});
		}
	}
}

export namespace GetMessagesService {
	export class RequestModel {
		access_token: string | null;
		mailbox_profile_id!: string;
		message_ids!: string[];

		constructor(mailboxProfileId: string, messageIds: string[], accessToken: string | null) {
			this.mailbox_profile_id = mailboxProfileId;
			this.access_token = accessToken;
			this.message_ids = messageIds;
		}
	}

	export class ResponseModel {
		mailboxProfileId!: string;
		messages?: NLMail[] | null;
		error?: Error | null;

		constructor(mailboxProfileId: string) {
			this.mailboxProfileId = mailboxProfileId;
		}
	}

	export class QueryBuilder {
		mailboxProfile: NLMailboxProfile;
		messageIds: string[];

		constructor(mailboxProfile: NLMailboxProfile, messageIds: string[]) {
			this.mailboxProfile = mailboxProfile;
			this.messageIds = messageIds;
		}
	}
}
