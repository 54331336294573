import React, { useState, useEffect, FC, Fragment, useRef } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import IconButton from "../common/IconButton";
import { NLMail, NLMailboxProfileType } from "@models/Models";
import { Dialog, Transition } from "@headlessui/react";
import Alert, { AlertState } from "./Alert";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { generateToast } from "@utils/managers/ToastManager";
import { useAuth } from "@providers/AuthContext";
import { ReplyMessageService } from "@utils/managers/backendMailManager/mailboxFunctions/ReplyMessageService";
import { kStringConstants } from "@utils/constants/StringConstants";
import { recordEvent, generateNewsletterProperties } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

export interface ReplyMessageModalState {
	mail: NLMail | undefined;
	isShow: boolean;
}

interface ReplyMessageModalProps {
	replyMessageState: ReplyMessageModalState;
	setReplyMessageState: React.Dispatch<React.SetStateAction<ReplyMessageModalState>>;
}

const ReplyMessageModal: FC<ReplyMessageModalProps> = ({ replyMessageState, setReplyMessageState }) => {
	const { mail, isShow } = replyMessageState;
	const { authUser } = useAuth();
	const [replyText, setReplyText] = useState<string | undefined>(undefined);
	const [isValid, setValid] = useState<boolean>(false);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});
	const replyTextRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		const isTextNotEmpty = replyText === undefined ? false : replyText.trim() !== "";
		setValid(isTextNotEmpty);
	}, [replyText]);

	useEffect(() => {
		if (!isShow) {
			const timeoutId = setTimeout(() => {
				setAlertState({
					isShow: null,
					title: null,
					message: null,
					actionButton: null,
					dismissButton: null,
					actionButtonAction: undefined,
				})
				setReplyText(undefined);
			}, 451);
			return () => clearTimeout(timeoutId);
		}
		if (isShow) {
			checkOutlook();
			recordEvent(kAnalyticsConstants.ReplyMessage.presented);
			setTimeout(() => {
				replyTextRef?.current?.focus();
			}, 100);
		}
	}, [isShow]);

	const onClose = () => {
		setReplyMessageState({ ...replyMessageState, isShow: false });
	};

	const convertToHTML = (text: string) => {
		let escapedText = text.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#39;");
		escapedText = escapedText.replace(/\n/g, "<br>");
		const htmlString = `
            <html>
            <head>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        font-size: 14px;
                        color: black;
                        background-color: white;
                    }
                </style>
            </head>
            <body>${escapedText}</body>
            </html>
        `;
		return htmlString;
	};

	const sendOnClick = () => {
		if (!mail) {
			return;
		}

		if (!isValid) {
			return;
		}

		let replyHtml = convertToHTML(replyText!);

		if (!replyHtml) {
			return;
		}

		const mailboxProfile = authUser!.mailbox_profiles.find((x) => x.id === mail!.mailbox_profile_id);
		if (!mailboxProfile) {
			return;
		}

		setLoadingState({ isLoading: true });

		let newsletterProperties = generateNewsletterProperties(mail);
		recordEvent(kAnalyticsConstants.ReplyMessage.replied, newsletterProperties);

		const replyMessageService = new ReplyMessageService(mailboxProfile, mail.id, replyHtml);
		replyMessageService.replyMessage((error) => {
			setLoadingState({ isLoading: false });

			if (error) {
				setAlertState({
					isShow: true,
					title: kStringConstants.Common.errorAlertTitle,
					message: kStringConstants.Common.errorAlertMessage,
					actionButton: "OK",
					dismissButton: null,
					actionButtonAction: undefined,
				});
				return;
			}

			generateToast({ status: "success", message: "Your message has been sent", position: "bottom-center" });
			onClose();
		});
	};

	const checkOutlook = () => {
		const mailboxProfile = authUser!.mailbox_profiles.find((x) => x.id === mail!.mailbox_profile_id);
		if (mailboxProfile!.type === NLMailboxProfileType.Outlook) {
			setAlertState({ isShow: true, title: "Not availble for Outlook", message: "Reply feature is not yet availble for Outlook yet. This will become available soon.", actionButton: "OK", dismissButton: null, actionButtonAction: () => onClose() });
		}
	}

	return (
		<>
			<LoadingView loadingState={loadingState} />
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog static onClose={() => null} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child className="w-full h-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="m-auto w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
								<div className="absolute w-full bg-surface top-0 left-0 flex flex-col p-4 border-b border-primary-200 z-20 gap-3">
									<div className="flex flex-row justify-between items-center">
										<div className="text-primary font-medium font-primary text-xl line-clamp-1">Reply to {mail?.sender_name}</div>
										<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onClose()} />
									</div>
								</div>
								<div className="flex flex-col gap-[1px] text-left h-full py-[90px] bg-secondary overflow-auto">
									<div className="w-full h-[60px] bg-surface">
										<div className="flex flex-row p-3 gap-2 items-center">
											<div className="text-primary-500 font-medium font-primary text-base line-clamp-1">To:</div>
											<div className="text-primary font-medium font-primary text-base line-clamp-1">{mail?.sender_name}</div>
										</div>
									</div>
									<div className="w-full h-[60px] bg-surface">
										<div className="flex flex-row p-3 gap-2 items-center">
											<div className="text-primary-500 font-medium font-primary text-base line-clamp-1">Subject:</div>
											<div className="text-primary font-medium font-primary text-base line-clamp-1">RE: {mail?.subject}</div>
										</div>
									</div>
									<textarea ref={replyTextRef} value={replyText} onChange={(e) => setReplyText(e.target.value)} className="w-full p-3 bg-transparent placeholder:font-regular font-medium font-primary text-base text-primary placeholder-primary-500 focus:outline-none resize-none h-full" placeholder="Type your reply..." />
								</div>
								<div className="absolute bottom-0 w-full bg-surface backdrop-blur-md border-t border-primary-100 px-4 py-4 pt-3 z-20">
									<div className={`flex flex-row justify-end`}>
										<button disabled={!isValid} className={`${isValid ? "opacity-100 hover:bg-success-green/80" : "opacity-50"} text-white font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-success-green transition ease-in-out whitespace-nowrap`} onClick={() => sendOnClick()}>
											Send
										</button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default ReplyMessageModal;
