import React, { MouseEventHandler, useState } from "react";
import { AudioRowType } from "@models/Models";
import { ReactComponent as WaveIcon } from "@images/tabbar_audio.svg";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import PaywallModal from "@components/common/PaywallModal";
import { useNavigate } from "react-router-dom";
import { recordEvent } from "@utils/managers/AnalyticsManager";

interface AudioActionCardProps {
	audioRowType: AudioRowType;
	settingsOnClick: () => void;
}

const AudioActionCard: React.FC<AudioActionCardProps> = ({ audioRowType, settingsOnClick }) => {
	const [showPaywall, setShowPaywall] = useState<boolean>(false);
	const navigate = useNavigate();

	const generateTitle = () => {
		switch (audioRowType) {
			case AudioRowType.UserOnboarding:
			case AudioRowType.UserDeactivated:
				return "A daily podcast, tailored to you";
			case AudioRowType.UserDeactivatedUnengaged:
				return "Your Daily Brief is paused";
			case AudioRowType.UserDeactivatedNotPro:
				return "Get personalized daily news briefs with PRO";
			default:
				break;
		}
	};

	const generateDescription = () => {
		switch (audioRowType) {
			case AudioRowType.UserOnboarding:
			case AudioRowType.UserDeactivated:
				return "Get a personalized news briefing based on your newsletters";
			case AudioRowType.UserDeactivatedUnengaged:
				return "We paused your Daily Brief due to inactivity. You can re-activate at any time.";
			case AudioRowType.UserDeactivatedNotPro:
				return "Daily Brief is the most efficient way to stay up-to-date with topics you care about.";
			default:
				break;
		}
	};

	const generateButtonTitle = () => {
		switch (audioRowType) {
			case AudioRowType.UserOnboarding:
			case AudioRowType.UserDeactivated:
				return "Start listening";
			case AudioRowType.UserDeactivatedUnengaged:
				return "Continue listening";
			case AudioRowType.UserDeactivatedNotPro:
				return "Upgrade to PRO";
			default:
				break;
		}
	};

	const onButtonAction = () => {
		switch (audioRowType) {
			case AudioRowType.UserDeactivatedNotPro:
				setShowPaywall(true);
				break;
			case AudioRowType.UserOnboarding:
				recordEvent(kAnalyticsConstants.Audio.onboardingStarted);
				navigate("/audio/get-started");
				break;
			default:
				settingsOnClick();
				break;
		}
	};

	return (
		<>
			<PaywallModal isShow={showPaywall} onClose={() => setShowPaywall(false)} analyticsReference={kAnalyticsConstants.Reference.Paywall.audio} />
			<div className="w-full px-4 pb-4">
				<div className="w-full bg-surface border border-primary-200 rounded-[16px] p-4 flex flex-row items-center justify-between gap-4">
					<div className="flex flex-col items-start gap-3">
						<WaveIcon className="w-[20px] h-[20px] fill-primary" />
						<div className="flex flex-col items-start justify-center gap-1">
							<div className="font-primary font-medium text-lg text-primary">{generateTitle()}</div>
							<div className="font-primary font-regular text-base text-primary">{generateDescription()}</div>
						</div>
					</div>
					<button className="bg-success-green hover:bg-success-green/90 duration-300 ease-in-out transition-all text-white rounded-full px-5 py-2.5 font-primary font-medium text-lg text-nowrap" onClick={onButtonAction}>
						{generateButtonTitle()}
					</button>
				</div>
			</div>
		</>
	);
};

export default AudioActionCard;
