import React, { useState, useEffect, FC, Fragment, useRef } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import IconButton from "../common/IconButton";
import { useHotkeys } from "react-hotkeys-hook";
import { Dialog, Switch, Transition } from "@headlessui/react";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { useAuth } from "@providers/AuthContext";
import { ReactComponent as RightArrow } from "@images/right_arrow.svg";
import { convertHourToLocalTime } from "@utils/Date+GetDateFor";
import { useNavigate } from "react-router-dom";
import { updateAudioProfile } from "@utils/managers/networking/NetworkManager";
import Alert, { AlertState } from "@components/common/Alert";
import { NLAudioProfile } from "@models/Models";
import { useData } from "@providers/DataContext";

export interface AudioSettingsState {
	isShow: boolean;
}

interface AudioSettingsProps {
	audioSettingsState: AudioSettingsState;
	setAudioSettingsState: React.Dispatch<React.SetStateAction<AudioSettingsState>>;
}

const AudioSettingsModal: FC<AudioSettingsProps> = ({ audioSettingsState, setAudioSettingsState }) => {
	const { isShow } = audioSettingsState;
	const { authUser, setAuthUser } = useAuth();
	const [isAudioEnabled, setAudioEnabled] = useState<boolean>(authUser!.audio_profiles[0]?.is_enabled ?? false);
	const [isUnreadOnly, setIsUnreadOnly] = useState<boolean>(authUser!.audio_profiles[0]?.is_unread_only ?? false);
	const navigate = useNavigate();
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const { userSenderGroups } = useData();

	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});

	useHotkeys("Escape", () => onClose());

	const onClose = () => {
		setAudioSettingsState({ ...audioSettingsState, isShow: false });
	};

	const isAudioEnabledChanged = (isEnabled: boolean) => {
		let audioProfile = authUser?.audio_profiles[0];
		if (!audioProfile) {
			return;
		}

		if (isEnabled) {
			changeAudioEnabledDisabled(true, audioProfile);
			setAlertState({
				isShow: true,
				title: "Daily personalized podcasts incoming! ✨",
				message: "Your Daily Brief is now active and you’ll start to receive a new podcast made from your newsletters each day!",
				actionButton: "OK",
				dismissButton: null,
				actionButtonAction: undefined,
			});
			return;
		}

		setAlertState({
			isShow: true,
			title: "Are you sure?",
			message: "We will stop creating a personalized podcast for you each day. Would you like to proceed?",
			actionButton: "Continue",
			dismissButton: "Dismiss",
			actionButtonAction: () => {
				changeAudioEnabledDisabled(false, audioProfile);
			},
		});
	};

	const changeAudioEnabledDisabled = (isEnabled: boolean, audioProfile: NLAudioProfile) => {
		var newAudioProfile = audioProfile;
		newAudioProfile.is_enabled = isEnabled;

		updateAudioProfile(newAudioProfile)
			.then((profile) => {
				setAuthUser(profile);
			})
			.catch((error) => {
				console.log(error);
			});

		setAudioEnabled(isEnabled);
	};

	const isUnreadOnlyChanged = (isEnabled: boolean) => {
		let audioProfile = authUser?.audio_profiles[0];
		if (!audioProfile) {
			return;
		}
		var newAudioProfile = audioProfile;
		newAudioProfile.is_unread_only = isEnabled;

		updateAudioProfile(newAudioProfile)
			.then((profile) => {
				setAuthUser(profile);
			})
			.catch((error) => {
				console.log(error);
			});

		setIsUnreadOnly(isEnabled);
	};

	const manageSendersOnClick = () => {
		let audioProfile = authUser!.audio_profiles[0];
		if (!audioProfile) {
			return;
		}
		let senders = userSenderGroups?.filter((x) => x.user_group === audioProfile.group_id) ?? [];

		navigate("/audio/pick-senders", { state: { alreadySelectedSenders: senders } });
	};

	const deliveryOnClick = () => {
		navigate("/audio/schedule", { state: { isUpdate: true } });
	};

	const generateStatusText = () => {
		let audioProfile = authUser!.audio_profiles[0];
		if (!audioProfile) {
			return null;
		}
		if (isAudioEnabled) {
			let localScheduledTimeHour = convertHourToLocalTime(audioProfile.scheduled_hour);
			let isUnreadCopy = isUnreadOnly ? "It will only include unread newsletters." : "It will include read and unread newsletters.";
			return (
				<>
					Your Daily Brief is currently active.
					<br />
					You’ll receive your next one at {localScheduledTimeHour}.<br />
					{isUnreadCopy}
				</>
			);
		}

		return (
			<>
				Your Daily Brief is currently turned off.
				<br />
				Switch it on above to receive future episodes.
			</>
		);
	};

	return (
		<>
			<LoadingView loadingState={loadingState} />
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog static onClose={() => null} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child className="w-full h-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="m-auto w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
								<div className="absolute w-full bg-surface top-0 left-0 flex flex-col p-4 border-b border-primary-200 z-20 gap-3">
									<div className="flex flex-row justify-between items-center">
										<div className="text-primary font-medium font-primary text-xl">Daily Brief Settings</div>
										<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onClose()} />
									</div>
								</div>
								<div className="flex flex-col gap-4 text-center h-full p-4 py-[105px] bg-secondary overflow-auto">
									<div className="text-primary-500 font-regular font-primary text-base">Your Daily Brief is a personalized 5-10 minute podcast featuring summaries and highlights from your selected newsletters.</div>
									<div className="flex flex-col gap-3">
										<div className="flex justify-between items-center gap-4 bg-surface border transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full shadow-sm ">
											<div className="font-primary font-medium text-base text-primary text-left">Daily Brief</div>
											<Switch checked={isAudioEnabled} onChange={(checked) => isAudioEnabledChanged(checked)} className={`${isAudioEnabled ? "bg-success-green" : "bg-primary-200"} relative inline-flex h-[30px] w-[55px] items-center rounded-full`}>
												<span className={`${isAudioEnabled ? "translate-x-[26px]" : "translate-x-[2px]"} inline-block h-[28px] w-[28px] transform rounded-full bg-white transition`} />
											</Switch>
										</div>
										<button disabled={!isAudioEnabled} onClick={manageSendersOnClick} className={`flex justify-between items-center gap-4 bg-surface border transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full shadow-sm ${isAudioEnabled ? "opacity-100 cursor-pointer" : "opacity-50 cursor-not-allowed"}`}>
											<div className="font-primary font-medium text-base text-primary text-left">Manager Senders</div>
											<RightArrow className="fill-primary-500 group-hover:fill-primary w-[20px]" />
										</button>
										<button disabled={!isAudioEnabled} onClick={deliveryOnClick} className={`flex justify-between items-center gap-4 bg-surface border transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full shadow-sm  ${isAudioEnabled ? "opacity-100 cursor-pointer" : "opacity-50 cursor-not-allowed"}`}>
											<div className="font-primary font-medium text-base text-primary text-left">Delivery Schedule & Notifications</div>
											<RightArrow className="fill-primary-500 group-hover:fill-primary w-[20px]" />
										</button>
										<div className={`flex justify-between items-center gap-4 bg-surface border transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full shadow-sm  ${isAudioEnabled ? "opacity-100" : "opacity-50"}`}>
											<div className="font-primary font-medium text-base text-primary text-left">Only include unread newsletters</div>
											<Switch disabled={!isAudioEnabled} checked={isUnreadOnly} onChange={(checked) => isUnreadOnlyChanged(checked)} className={`${isUnreadOnly ? "bg-success-green" : "bg-primary-200"} relative inline-flex h-[30px] w-[55px] items-center rounded-full`}>
												<span className={`${isUnreadOnly ? "translate-x-[26px]" : "translate-x-[2px]"} inline-block h-[28px] w-[28px] transform rounded-full bg-white transition`} />
											</Switch>
										</div>
										<div className="font-primary font-regular text-base text-primary text-center mt-2">{generateStatusText()}</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default AudioSettingsModal;
