import { NLMail, NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";
import { MailState } from "@providers/MailContext";

export class DeleteUndeleteMessagesService {
	private queries: DeleteUndeleteMessagesService.QueryBuilder[];

	constructor(queries: DeleteUndeleteMessagesService.QueryBuilder[]) {
		this.queries = queries;
	}

	deleteUndeleteMessages(completion: (responseArray: DeleteUndeleteMessagesService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();

		let requestArray: DeleteUndeleteMessagesService.RequestModel[] = [];
		let responseArray: DeleteUndeleteMessagesService.ResponseModel[] = [];
		const promises: Promise<void>[] = [];

		for (const requestModel of this.queries) {
			const promise = new Promise<void>((innerResolve, innerReject) => {
				if (requestModel.mailboxProfile.type !== NLMailboxProfileType.Internal) {
					oauthSessionManager
						.fetchAccessTokenForMailboxProfile(requestModel.mailboxProfile.id, requestModel.mailboxProfile.type)
						.then((accessToken) => {
							const request = new DeleteUndeleteMessagesService.RequestModel(requestModel.mailboxProfile.id, accessToken, requestModel.messageIds, requestModel.action);
							requestArray.push(request);
							innerResolve();
						})
						.catch((error) => {
							if (error instanceof Error && error.message === "noInternetError") {
								innerReject(error);
							} else {
								var responseModel = new DeleteUndeleteMessagesService.ResponseModel(requestModel.mailboxProfile.id);
								responseModel.error = oauthSessionManager.generateSignInRequiredError(requestModel.mailboxProfile.id, error);
								responseArray.push(responseModel);
								innerResolve();
							}
						});
				} else {
					const request = new DeleteUndeleteMessagesService.RequestModel(requestModel.mailboxProfile.id, undefined, requestModel.messageIds, requestModel.action);
					requestArray.push(request);
					innerResolve();
				}
			});
			promises.push(promise);
		}

		Promise.all(promises)
			.then(() => {
				if (requestArray.length === 0) {
					completion(responseArray, undefined);
					return;
				}

				this.makeRequests(requestArray, responseArray, completion);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}

	private makeRequests(requests: DeleteUndeleteMessagesService.RequestModel[], responseArray: DeleteUndeleteMessagesService.ResponseModel[], completion: (responseArray: DeleteUndeleteMessagesService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		var responseModels = responseArray;

		networkManager
			.deleteMessages(requests)
			.then((jsonData) => {
				for (let mailboxProfileId in jsonData) {
					let responseData = jsonData[mailboxProfileId];
					var responseModel = new DeleteUndeleteMessagesService.ResponseModel(mailboxProfileId);

					if (Array.isArray(responseData["successes"])) {
						responseModel.successes = responseData["successes"];
					}

					if (responseData["error"]) {
						responseModel.error = MailboxError.returnError(responseData["error"], mailboxProfileId);
					}

					responseModels.push(responseModel);
				}

				completion(responseModels, undefined);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}
}

export namespace DeleteUndeleteMessagesService {
	export class RequestModel {
		access_token: string | undefined;
		mailbox_profile_id!: string;
		message_ids!: string[];
		action!: string;

		constructor(mailboxProfileId: string, accessToken: string | undefined, messageIds: string[], action: string) {
			this.mailbox_profile_id = mailboxProfileId;
			this.access_token = accessToken;
			this.message_ids = messageIds;
			this.action = action;
		}
	}

	export class ResponseModel {
		mailboxProfileId!: string;
		successes?: string[] | undefined;
		error?: Error | undefined;

		constructor(mailboxProfileId: string) {
			this.mailboxProfileId = mailboxProfileId;
		}
	}

	export class QueryBuilder {
		mailboxProfile: NLMailboxProfile;
		messageIds: string[];
		action: string;

		constructor(mailboxProfile: NLMailboxProfile, messageIds: string[], isDelete: boolean) {
			this.mailboxProfile = mailboxProfile;
			this.messageIds = messageIds;
			this.action = isDelete ? "delete" : "undelete";
		}
	}
}
