import { ReactNode, useEffect, useRef, useState } from "react";
import DigestHeaderView from "./DigestHeaderView";
import EmailViewer from "../email-viewer/EmailViewer";
import useMailState from "@hooks/UseMailState";
import { useData } from "@providers/DataContext";
import { useAuth } from "@providers/AuthContext";
import * as mailManager from "@utils/managers/MailManager";
import { MailState, useMailsDispatch, useMailsState } from "@providers/MailContext";
import MailCard from "@components/common/MailCard";
import { LayoutGroup } from "framer-motion";
import EmptyStateView from "@components/common/EmptyStateView";
import collageHourglass from "@images/collage_hourglass.png";
import collageBinoculars from "@images/collage_binoculars.png";
import useOnScreen from "@hooks/UseOnScreen";
import * as Date from "@utils/Date+GetDateFor";
import { UnreadState } from "@models/Enums";
import { useLayout } from "@providers/LayoutContext";
import { NLMail } from "@models/Models";
import { useBookmarks } from "@providers/BookmarkContext";
import MailListHeader from "@components/common/MailListHeader";
import "@utils/String+MessageIdType";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import createPersistedState from "use-persisted-state";
import NotificationsModal from "@components/common/NotificationsModal";
import { subscribeToPushNotifications } from "@utils/managers/NotificationsManager";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import baloonImage from "@images/collage_paywall_balloon.png"
import NewMailboxModal from "@components/settings/connected-mailboxes/NewMailboxModal";
import { useParams } from "react-router-dom";
import { getIndividualMail  } from "@utils/managers/MailManager";

const useNotificationsReminder = createPersistedState(kLocalStorageKeys.ShowOnce.notificationsReminderShown);

const Digest = () => {
	const { mailListWidth, isMailListHidden, setMailListHidden, setSidebarHidden, setSettingsActive } = useLayout();
	const { activeGroup, digestIsUnread, activeMail, setActiveMail, setStartingPointMail, activeUserMailState, digestNeedsReloading, setDigestNeedsReloading } = useData();
	const { authUser } = useAuth();
	const [isDigestFetching, setDigestFetching] = useState<boolean>(false);
	const [notificationsReminderHasShown, setNotificationsReminder] = useNotificationsReminder(false);

	//This is global
	const mailState = useMailState({ isUnread: digestIsUnread, groupId: activeGroup?.id, userMailStateId: activeUserMailState?.id, isBookmark: undefined, mails: [], queryResponses: [] });
	//This is for syncing
	const baseCriteria: MailState = { groupId: undefined, isUnread: UnreadState.off, userMailStateId: undefined, isBookmark: undefined, mails: [], queryResponses: [] };
	const baseState = useMailState(baseCriteria);

	const { baseMails, historyIds } = useMailsState();
	const dispatch = useMailsDispatch();

	const { bookmarks } = useBookmarks();
	const { isInViewport, ref } = useOnScreen();
	const [isFetchingNextBatch, setFetchingNextBatch] = useState<boolean>(false);
	const feedRef = useRef<HTMLDivElement>(null);
	const mailListRef = useRef<HTMLDivElement>(null);
	const [isShowNewMailboxModal, setShowNewMailboxModal] = useState(false);
	const { mail_id } = useParams(); 

	useEffect(() => {
		setSettingsActive(false);
		if (!notificationsReminderHasShown && (authUser!.notifications?.set_time || authUser!.notifications?.as_arrived)) {
			subscribeToPushNotifications();
			setNotificationsReminder(true);
		}
	}, []);

	useEffect(() => {
		if (!mailState || digestNeedsReloading) {
			syncDigest();
		}
	}, [mailState, digestNeedsReloading]);

	useEffect(() => {
		if (isInViewport) {
			fetchNextBatch();
		}
	}, [isInViewport]);

	useEffect(() => {
		if (feedRef?.current) {
			feedRef.current.scrollTop = 0;
		}
	}, [mailState?.userMailStateId, mailState?.groupId]);

	useEffect(() => {
		if (mailListRef.current && activeMail) {
			const activeMailNode = mailListRef.current.querySelector(`#mail-${activeMail.id.safeId()}`);
			if (activeMailNode && !isElementInViewport(activeMailNode)) {
				activeMailNode.scrollIntoView({ behavior: "smooth", block: "center" });
			}
		}
	}, [activeMail]);

	useEffect(() => {
		if (!mail_id) return;
		setSidebarHidden(true);
		setMailListHidden(true);
		getIndividualMail(authUser!.mailbox_profiles, mail_id, (mail, _error) => {
			sessionStorage.removeItem(kLocalStorageKeys.Session.nextPath);
			if (mail) {
				setActiveMail(mail)
			}
		});
	}, [mail_id]);

	const isElementInViewport = (el: Element) => {
		const rect = el.getBoundingClientRect();
		return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
	};

	const syncDigest = () => {
		setDigestFetching(true);
		mailManager.syncMessages(authUser!, baseMails, historyIds, baseState?.cutOffMail, (messages, historyIdDictionary, error) => {
			if (error) {
				recordEvent(kAnalyticsConstants.App.fetchEmailsError, { error: error.message });
			}
			const baseMailState = { baseMails: messages, historyIds: historyIdDictionary };
			dispatch({ type: "SET_BASE_MAILS", payload: baseMailState });
			setDigestFetching(false);
			setDigestNeedsReloading(false);
		});
	};

	const fetchNextBatch = () => {
		if (!mailState || !mailState.cutOffMail || isFetchingNextBatch) {
			return;
		}

		const mailboxProfile = authUser!.mailbox_profiles.find((x) => x.id === mailState.cutOffMail!.mailbox_profile_id);

		if (!mailboxProfile) {
			return;
		}

		setFetchingNextBatch(true);

		mailManager.queryMessages(mailboxProfile!, mailState, Date.dateToEpochString(mailState.cutOffMail.receive_date), activeUserMailState?.sender_address, digestIsUnread, activeGroup?.id, (mails, queryResponse, _error) => {
			var existingMailState = { ...mailState };
			if (mails) {
				existingMailState.mails = mails;
			}
			if (queryResponse) {
				existingMailState.queryResponses.push(queryResponse);
			}
			dispatch({ type: "SET_LOAD_MORE", payload: existingMailState });
			setFetchingNextBatch(false);
		});
	};

	const mailCardOnClick = (mail: NLMail) => {
		if (activeMail && mail.id === activeMail.id) {
			setActiveMail(undefined);
			return;
		}
		setStartingPointMail(mail);
		setActiveMail(mail);
	};

	const renderDigest = (): ReactNode => {
		if (!mailState) {
			return <EmptyStateView title="Fetching your newsletters..." description="They will appear here when ready" alertImage={collageHourglass} />;
		}

		if (mailState.mails.length > 0) {
			return (
				<div className="flex flex-col p-3 gap-3" ref={mailListRef}>
					<LayoutGroup>
						{mailState &&
							mailState.mails &&
							mailState.mails.map((mail, i) => {
								return (
									<div key={mail.id}>
										<MailCard mail={mail} bookmark={bookmarks?.find((x) => x.mail_id === mail.id)} isActive={mail.id === activeMail?.id} onClick={() => mailCardOnClick(mail)} />
										{mailState.cutOffMail && mailState.cutOffMail.id && mailState.mails.length - 1 === i && <div ref={ref} />}
									</div>
								);
							})}
					</LayoutGroup>
				</div>
			);
		} else {
			if (authUser!.mailbox_profiles.length === 0) {
				return <EmptyStateView title="Add a mailbox to start using Meco" description="Add an existing Gmail, Outlook or create a new Meco email address" alertImage={baloonImage} isDisplayCta="Add mailbox" ctaAction={() => setShowNewMailboxModal(true)} />;
			}
			if (isFetchingNextBatch) {
				return <EmptyStateView title="Fetching your newsletters..." description="They will appear here when ready" alertImage={collageHourglass} />;
			}
			return (
				<>
					<EmptyStateView title="You’re all caught up!" description="You’ve read all newsletters" alertImage={collageBinoculars} />
					{mailState.cutOffMail && mailState.cutOffMail.id && <div ref={ref} />}
				</>
			);
		}
	};

	return (
		<>
			{!notificationsReminderHasShown && <NotificationsModal isShow={!notificationsReminderHasShown} onClose={() => setNotificationsReminder(true)} />}
			<NewMailboxModal isShow={isShowNewMailboxModal} onClose={() => setShowNewMailboxModal(false)} />
			<div className="flex flex-row w-full items-stretch">
				<div className={`${isMailListHidden ? "hidden" : "flex"} h-screen border-r border-primary-100 z-20 transition-[width] ease-in-out duration-300 overflow-auto overflow-x-hidden  flex flex-col pb-4`} style={{ width: mailListWidth + "px", minWidth: mailListWidth + "px" }} ref={feedRef} id="root-container">
					<MailListHeader mailState={mailState} isMailListFetching={isDigestFetching} syncOnClick={() => syncDigest()} />
					<DigestHeaderView />
					{renderDigest()}
				</div>
				<EmailViewer mailState={mailState} reference={kAnalyticsConstants.Reference.digest} />
			</div>
		</>
	);
};

export default Digest;
