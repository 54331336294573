import React, { useCallback, useEffect, useState } from "react";

function useOnScreen(): { isInViewport: boolean; ref: React.RefCallback<HTMLElement> } {
    const [isInViewport, setIsInViewport] = useState(false);
    const [refElement, setRefElement] = useState<HTMLElement | null>(null);

    const setRef = useCallback((node: HTMLElement | null) => {
        setRefElement(node);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIsInViewport(entry.isIntersecting)
        );
        if (refElement) {
            observer.observe(refElement);
        }

        return () => {
            observer.disconnect();
        };
    }, [isInViewport, refElement]);

    return { isInViewport, ref: setRef };
}

export default useOnScreen;