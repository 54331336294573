import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ReactComponent as ListIcon } from "@images/list_icon.svg";
import { ReactComponent as CheckmarkIcon } from "@images/checmark_raw_icon.svg";

interface ListboxViewProps {
	options: string[];
	selected: string;
	setSelected: (selectedOption: string) => void;
}

const ListboxView = ({ options, selected, setSelected }: ListboxViewProps) => {
	return (
		<Listbox value={selected} onChange={setSelected}>
			<div className="relative w-40">
				<Listbox.Button className="relative w-full cursor-default rounded-lg shadow-sm bg-surface border border-primary-100 px-3 py-1.5" onClick={(e) => { e.stopPropagation();}}>
					<span className="block truncate font-primary font-regular text-sm text-left">{selected}</span>
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
						<ListIcon className="h-5 fill-primary w-[10px]" />
					</span>
				</Listbox.Button>
				<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
					<Listbox.Options className="absolute mt-1 max-h-[10rem] w-full overflow-auto rounded-md bg-contrast py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
						{options.map((option, idx) => (
							<Listbox.Option key={idx} className={({ active }) => `relative cursor-default select-none py-2 pl-4 pr-4 ${active ? "bg-success-green text-white" : "text-primary"}`} onClick={(e) => { e.stopPropagation();}} value={option}>
								{({ selected, active }) => (
									<>
										<span className={`font-primary font-regular text-sm block truncate ${selected ? "font-medium" : "font-normal"}`}>{option}</span>
										{selected ? (
											<span className="absolute inset-y-0 left-0 flex items-center pl-2 text-amber-600">
												<CheckmarkIcon className={`w-[10px] ${active ? "fill-white" : "fill-success-green" }`} />	
											</span>
										) : null}
									</>
								)}
							</Listbox.Option>
						))}
					</Listbox.Options>
				</Transition>
			</div>
		</Listbox>
	);
};

export default ListboxView;
