import { useEffect, useState } from "react";
import { NLNewsletterCategory } from "@models/Models";
import ParticlesBackground from "@components/common/ParticlesBackground";
import { useOnboardingProfile } from "@providers/OnboardingContext";
import { Link } from "react-router-dom";
import CategorySelectionRow from "./CategorySelectionRow";
import pickingFruit from "@images/collage_picking_fruit.png";
import * as networkManager from "@utils/managers/networking/NetworkManager";

const CategorySelection = (props) => {
	const { onboardingProfile, setOnboardingProfile } = useOnboardingProfile();
	const [categories, setCategories] = useState<NLNewsletterCategory[] | null>(null);
	const [isShakeEffect, setShakeEffect] = useState<boolean>(false);
	const [isLimitReached, setLimitReached] = useState<boolean>(false);

	useEffect(() => {
		loadCategories();
	}, []);

	const loadCategories = () => {
		networkManager.getNewsletterCategories()
		.then((newsletterCategories) => {
			let sortedArray = newsletterCategories.sort((a, b) => (a.sort_index ?? 0) - (b.sort_index ?? 0));
			setCategories(sortedArray);
		});
	};

	const categoryOnClick = (e, category) => {
		var tempOnboardingProfile = { ...onboardingProfile! };
		var currentCategory = tempOnboardingProfile.persistence.categoryArray;

		if (currentCategory.includes(category.id)) {
			currentCategory = currentCategory.filter((x) => x !== category.id);
		} else {
			if (currentCategory.length < 3) {
				currentCategory.push(category.id);
			} else {
				shakeTitle();
			}
		}

		setLimitReached(currentCategory.length >= 3);
		tempOnboardingProfile.persistence.categoryArray = currentCategory;
		setOnboardingProfile(tempOnboardingProfile);
	};

	const shakeTitle = () => {
		setShakeEffect(true);
		setTimeout(() => {
			setShakeEffect(false);
		}, 400);
	};

	const returnButtonBg = () => {
		if (onboardingProfile!.persistence.categoryArray.length == 1) {
			return `bg-success-green/30`;
		} else if (onboardingProfile!.persistence.categoryArray.length == 2) {
			return `bg-success-green/70`;
		} else {
			return `bg-success-green/100`;
		}
	};

	return (
		<div>
			<ParticlesBackground />
			<div className="flex min-h-[100dvh] flex-col md:justify-center justify-start overflow-hidden py-2 md:py-12">
				<div className="md:hidden fixed top-0 bg-black/50 w-full p-3 z-30 backdrop-blur-lg border-b border-white/10 shadow-2xl">
					<div className="max-w-[1080px] w-full z-30 m-auto">
						<div className="flex justify-end items-center">
							<Link to="/get-started/discover">
								<button className={`text-white font-medium font-primary text-lg focus:outline-none rounded-xl p-2 px-3 py-2 items-center ${onboardingProfile!.persistence.categoryArray.length > 0 ? returnButtonBg() : "bg-white/20 hover:bg-brand-blue/80"} transition ease-in-out whitespace-nowrap`}>Next</button>
							</Link>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-10 w-full items-center justify-center p-3">
					<div className="meco_container max-w-[1080px] py-[40px] md:p-[60px] md:pb-[40px]">
						<div className="flex flex-col md:flex-row gap-5 items-center mt-[50px] md:mt-[0px]">
							<div className="w-full md:w-1/2 flex flex-col gap-3 md:items-start items-center md:text-left text-center">
								<img src={pickingFruit} className="h-[100px] object-contain" />
								<div className={`text-white font-bold font-primary text-4xl leading-10 ${isShakeEffect ? "animate-shake" : null}`}>Pick up to 3 interests</div>
								<div className="text-force-primary-light font-medium font-primary text-base  md:text-xl">We’ll tailor newsletter recommendations within Meco to match your interests</div>
							</div>
							<div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-3">
								<div className="flex flex-row flex-wrap gap-4 justify-center">
									{categories &&
										categories.map((category, i) => {
											return <CategorySelectionRow {...category} key={i} isChecked={onboardingProfile!.persistence.categoryArray.includes(category.id)} checkOnChange={categoryOnClick} isLimit={isLimitReached} />;
										})}
								</div>
							</div>
						</div>
						<div className="w-full hidden md:block">
							<div className="h-[1px] w-full bg-force-primary-light/20 mt-5"></div>
							<div className="flex justify-end">
								<Link to="/get-started/discover">
									<button className={`text-force-primary-light font-medium font-primary text-lg focus:outline-none rounded-xl p-2 px-3 py-2 items-center ${onboardingProfile!.persistence.categoryArray.length > 0 ? returnButtonBg() : "bg-white/20 hover:bg-brand-blue/80"} transition ease-in-out whitespace-nowrap mt-3`}>Next</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CategorySelection;
