import { ReactComponent as UnreadIcon } from "@images/unread_filter_icon.svg";
import { useData } from "@providers/DataContext";
import { NLUserMailStateNames, TimeState, UnreadState } from "@models/Enums";
import Picker from "@components/common/Picker";
import { ReactComponent as TimeIcon } from "@images/time_icon.svg";
import { useEffect, useState } from "react";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import moment from "moment";
import NewSenderModal from "@components/senders/NewSenderModal";

interface DigestHeaderViewProps {
	// onNewSenderClick: () => void;
}

const DigestHeaderView: React.FC<DigestHeaderViewProps> = ({}) => {
	const { digestIsUnread, setDigestUnread, activeTimeFilter, setActiveTimeFilter, userMailStates, activeGroup, activeUserMailState } = useData();
	const [newSendersCount, setNewSendersCount] = useState<number | undefined>(undefined);
	const [showNewSenderModal, setShowNewSenderModal] = useState<boolean>(false);

	useEffect(() => {
		updateNewSenderCount();
	}, [userMailStates]);

	function getNewSenderCopy() {
		var copy = "No new sender";
		if (newSendersCount != undefined && newSendersCount > 0) {
			let senderOrSenders = newSendersCount == 1 ? "sender" : "senders";
			copy = newSendersCount + " new " + senderOrSenders + " found";
		}
		return copy;
	}

	const updateNewSenderCount = () => {
		if (!userMailStates) {
			setNewSendersCount(undefined);
			return;
		}
		var newStates = userMailStates.filter((x) => x.state_id === NLUserMailStateNames.new);
		const newSenderLastView = localStorage.getItem(kLocalStorageKeys.App.newSenderLastView);
		if (newSenderLastView) {
			const lastViewDate = moment(newSenderLastView).toDate();
			newStates = newStates.filter((x) => x.updated_at >= lastViewDate);
		}
		setNewSendersCount(newStates.length);
	};

	const getTimeOptions = (): string[] => {
		const options: string[] = [];
		for (const key in TimeState) {
			const value = parseInt(key);
			if (!isNaN(value)) {
				const timeState = value as TimeState;
				const timeString = TimeState.getTimeString(timeState);
				options.push(timeString);
			}
		}

		return options;
	};

	return (
		<div>
			<NewSenderModal
				isShow={showNewSenderModal}
				onClose={() => {
					updateNewSenderCount();
					setShowNewSenderModal(false);
				}}
			/>
			<div className="flex flex-col justify-between px-3 pt-3 pb-0 gap-3 items-center w-full">
				{newSendersCount !== undefined && !activeGroup && !activeUserMailState && newSendersCount > 0 && (
					<div className="flex flex-col gap-3 w-full">
						<button className="flex flex-row items-center justify-center gap-2 bg-primary w-full p-2.5 rounded-xl transition ease-in-out duration-300" onClick={() => setShowNewSenderModal(true)}>
							<span className="relative flex h-[10px] w-[10px]">
								<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-success-green opacity-75"></span>
								<span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-success-green"></span>
							</span>
							<div className="font-primary font-medium text-base text-secondary">{getNewSenderCopy()}</div>
						</button>
						<div className="w-full h-[1px] bg-primary-100" />
					</div>
				)}
				<div className="flex flex-row justify-between items-center w-full">
					<Picker Icon={TimeIcon} options={getTimeOptions()} defaultValue={TimeState.getTimeString(activeTimeFilter)} setSelectedOption={(selectedOption) => setActiveTimeFilter(TimeState.getTimeStateFromString(selectedOption))} />
					<div>
						<button type="button" className={`hover:bg-primary-100 focus:outline-none rounded-lg text-sm px-2.5 py-1.5 inline-flex items-center border border-primary-100 transition duration-300 ease-in-out ${digestIsUnread ? "opacity-100" : "opacity-50"}`} onClick={() => setDigestUnread(digestIsUnread === UnreadState.off ? UnreadState.unread : UnreadState.off)}>
							<UnreadIcon className="fill-primary w-[18px] mr-2" />
							<span className="font-primary font-medium text-sm text-primary">Unread</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DigestHeaderView;
