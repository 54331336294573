import mixpanel from "mixpanel-browser";
import { kAnalyticsConstants } from "../constants/AnalyticsConstants";
import Qonversion, { UserPropertiesBuilder } from "@qonversion/web-sdk";
import { NLAudioEpisode, NLMail, NLProfile, NLReaderMode, NLUserMailState, NLUserMailStateNames, OnboardingProfile } from "@models/Models";
import Hotjar from "@hotjar/browser";
import moment from "moment";

export const recordEvent = (name: string, properties: Record<string, any> = {}) => {
	mixpanel.track(name, properties);
};

export const analyticsCompletedSignUp = (profile: NLProfile) => {
	createMixpanelProfile(profile);
	createQonversionProfile(profile);
	Hotjar.identify(profile.id, {});
	recordEvent(kAnalyticsConstants.Onboarding.loginSuccessful);
};

export const sendOnboardingStats = (onboardingProfile: OnboardingProfile, premiumStatus: boolean) => {
	var onboardingMutableProperties = {};
	let onboardingKeys = kAnalyticsConstants.Onboarding.OnboardingProfileKeys;
	let discoverKeys = kAnalyticsConstants.Onboarding.personalisedDiscoverKeys;

	//Feed
	onboardingMutableProperties[onboardingKeys.displayedFeed] = onboardingProfile.analytics.displayedFeed;
	onboardingMutableProperties[onboardingKeys.newslettersAdded] = onboardingProfile.persistence.feedArray.length;

	// Categories
	onboardingMutableProperties[discoverKeys.selectedCategoryCount] = onboardingProfile.persistence.categoryArray.length;
	onboardingMutableProperties[discoverKeys.selectedCategoriesIds] = onboardingProfile.analytics.selectedCategoriesIds;
	onboardingMutableProperties[discoverKeys.selectedCategoriesNames] = onboardingProfile.analytics.selectedCategoriesNames;

	//Discover
	onboardingMutableProperties[discoverKeys.returnNewsletterCount] = onboardingProfile.analytics.disReturnNewsletterCount;
	onboardingMutableProperties[discoverKeys.returnNewsletterLimit] = onboardingProfile.analytics.disReturnNewsletterLimit;
	onboardingMutableProperties[discoverKeys.returnedNewsletterIds] = onboardingProfile.analytics.disReturnedNewsletterIds;
	onboardingMutableProperties[discoverKeys.returnedNewsletterNames] = onboardingProfile.analytics.disReturnedNewsletterNames;

	onboardingMutableProperties[discoverKeys.returnedOCSCount] = onboardingProfile.analytics.disReturnedOCSCount;
	onboardingMutableProperties[discoverKeys.returnedOCSNewsletterIds] = onboardingProfile.analytics.disReturnedOCSNewsletterIds;
	onboardingMutableProperties[discoverKeys.returnedOCSNewsletterNames] = onboardingProfile.analytics.disReturnedOCSNewsletterNames;

	onboardingMutableProperties[discoverKeys.returnedNOCSCount] = onboardingProfile.analytics.disReturnedNOCSCount;
	onboardingMutableProperties[discoverKeys.returnedNOCSNewsletterIds] = onboardingProfile.analytics.disReturnedNOCSNewsletterIds;
	onboardingMutableProperties[discoverKeys.returnedNOCSNewsletterNames] = onboardingProfile.analytics.disReturnedNOCSNewsletterNames;

	onboardingMutableProperties[discoverKeys.subscribedNewsletterCount] = onboardingProfile.analytics.disSubscribedNewsletterCount;
	onboardingMutableProperties[discoverKeys.subscribedNewsletterIds] = onboardingProfile.analytics.disSubscribedNewsletterIds;
	onboardingMutableProperties[discoverKeys.subscribedNewsletterNames] = onboardingProfile.analytics.disSubscribedNewsletterNames;
	if (onboardingProfile.analytics.discoveredItems == undefined) {
		onboardingMutableProperties[onboardingKeys.discoveredItems] = 0;
	} else {
		onboardingMutableProperties[onboardingKeys.discoveredItems] = onboardingProfile.analytics.discoveredItems;
	}

	onboardingMutableProperties[onboardingKeys.isPremium] = premiumStatus;

	recordEvent(kAnalyticsConstants.Onboarding.completed, onboardingMutableProperties);
};

export const generateNewsletterProperties = (mail: NLMail, readerMode?: NLReaderMode, reference?: string) => {
	let newsletterSenderAddress = kAnalyticsConstants.App.NewsletterKeys.newsletterSenderAddress;
	let newsletterSenderName = kAnalyticsConstants.App.NewsletterKeys.newsletterSenderName;
	let newsletterReceiveDate = kAnalyticsConstants.App.NewsletterKeys.newsletterReceiveDate;
	let newsletterSubject = kAnalyticsConstants.App.NewsletterKeys.newsletterSubject;
	let newsletterReadVia = kAnalyticsConstants.App.NewsletterKeys.newsletterReadVia;

	let analyticsProperties: Record<string, any> = { [newsletterSenderAddress]: mail.sender_address, [newsletterSenderName]: mail.sender_name, [newsletterReceiveDate]: mail.receive_date, [newsletterSubject]: mail.subject ?? "" };

	if (readerMode) {
		analyticsProperties[kAnalyticsConstants.SuperProperties.reader_mode_on] = readerMode.state;
		analyticsProperties[kAnalyticsConstants.SuperProperties.reader_mode_font] = readerMode.font;
		analyticsProperties[kAnalyticsConstants.SuperProperties.reader_mode_theme] = readerMode.theme;
		analyticsProperties[kAnalyticsConstants.SuperProperties.reader_mode_font_size] = readerMode.fontSize;
	}

	if (reference) {
		analyticsProperties[newsletterReadVia] = reference;
	}

	return analyticsProperties;
};

export const generateAudioProperties = (episode: NLAudioEpisode): Record<string, any> => {
	const analyticsProperties: Record<string, any> = {
		[kAnalyticsConstants.App.AudioKeys.audioEpisodeId]: episode.id,
		[kAnalyticsConstants.App.AudioKeys.audioIsPublic]: episode.is_generated_for_public,
		[kAnalyticsConstants.App.AudioKeys.audioDuration]: episode.duration,
		[kAnalyticsConstants.App.AudioKeys.audioChapterCount]: episode.chapters.length,
	};

	if (episode.title) {
		analyticsProperties[kAnalyticsConstants.App.AudioKeys.audioEpisodeTitle] = episode.title;
	}

	let createdAt = moment.utc(episode.created_at).local().toISOString();
	if (createdAt) {
		analyticsProperties[kAnalyticsConstants.App.AudioKeys.audioCreationDate] = createdAt!;
	}

	return analyticsProperties;
};

export const trackAnalyticsReaderModeState = (userConfig: NLReaderMode, stateChanged: boolean) => {
	if (stateChanged) {
		recordEvent(userConfig.state ? kAnalyticsConstants.ReaderMode.turnedOn : kAnalyticsConstants.ReaderMode.turnedOff);
	}

	mixpanel.register({ [kAnalyticsConstants.SuperProperties.reader_mode_on]: userConfig.state, [kAnalyticsConstants.SuperProperties.reader_mode_font]: userConfig.font, [kAnalyticsConstants.SuperProperties.reader_mode_theme]: userConfig.theme, [kAnalyticsConstants.SuperProperties.reader_mode_font_size]: userConfig.fontSize });
};

const createMixpanelProfile = (profile: NLProfile) => {
	if (profile.is_profile_setup) {
		mixpanel.identify(profile.id);
	} else {
		mixpanel.alias(profile.id, mixpanel.get_distinct_id());
		mixpanel.identify(mixpanel.get_distinct_id());
	}

	refreshSuperProperties(profile);
};

const createQonversionProfile = (profile) => {
	const qonversionInstance = Qonversion.getSharedInstance();
	qonversionInstance
		.identify(profile.id)
		.then(() => {
			const properties = new UserPropertiesBuilder().setCustomUserId(profile.id).setEmail(profile.email).build();
			qonversionInstance.setUserProperties(properties);
		})
		.catch((error) => {
			console.log(error);
		});
};

export const refreshSuperProperties = (profile: NLProfile, userMailStates: NLUserMailState[] | undefined = undefined) => {
	let firstName = profile.first_name;
	let lastName = profile.last_name;
	let email = profile.email;
	let createdAt = profile.created_at;

	if (firstName == null || lastName == null || email == null || createdAt == null) {
		return;
	}

	var feedCount = 0;
	var newCount = 0;

	if (userMailStates) {
		feedCount = userMailStates.filter((x) => x.state_id == NLUserMailStateNames.feed).length ?? 0;
		newCount = userMailStates.filter((x) => x.state_id == NLUserMailStateNames.new).length ?? 0;
	}

	let signUpDate = kAnalyticsConstants.SuperProperties.signUpDate;
	let userId = kAnalyticsConstants.SuperProperties.userId;
	let premiumStatus = kAnalyticsConstants.SuperProperties.premiumStatus;
	let premiumEnabled = kAnalyticsConstants.SuperProperties.premiumEnabled;
	let newsletters_added = kAnalyticsConstants.SuperProperties.newsletters_added;
	let newsletters_new = kAnalyticsConstants.SuperProperties.newsletters_new;

	mixpanel.people.set({ $first_name: firstName, $last_name: lastName, $email: email, [signUpDate]: createdAt, [userId]: profile.id, [premiumStatus]: profile.premium_status?.toString() ?? "", [premiumEnabled]: profile.premium_enabled || false, [newsletters_added]: feedCount, [newsletters_new]: newCount });

	mixpanel.register({ [userId]: profile.id, [signUpDate]: createdAt, [premiumStatus]: profile.premium_status?.toString() ?? "", [premiumEnabled]: profile.premium_enabled ?? false, [newsletters_added]: feedCount, [newsletters_new]: newCount });
};

export const optInOptOutAnalytics = (optedIn: boolean) => {
	if (mixpanel.has_opted_out_tracking() && optedIn) {
		mixpanel.opt_in_tracking();
		return;
	}

	if (!mixpanel.has_opted_out_tracking() && !optedIn) {
		mixpanel.opt_out_tracking();
		return;
	}
};

export const optInStatus = () => {
	return !mixpanel.has_opted_out_tracking();
};
