import { ReactComponent as MecoLogo } from "@images/meco_logo.svg";
import ParticlesBackground from "@components/common/ParticlesBackground";
import { Link } from "react-router-dom";

const NotFound = () => {
	return (
		<div>
			<ParticlesBackground />
			<div className="flex min-h-[100dvh] flex-col items-center justify-center">
				<div className="flex flex-col gap-5 items-center justify-center p-3 text-center">
					<div className="flex flex-col gap-3 justify-center items-center">
						<div className="text-white font-medium font-primary text-[80px]">Oops! Lost in space?</div>
						<div className="text-white font-medium font-primary text-2xl">Could not find the page you're looking for</div>
					</div>
					<Link to="/">
						<button className="bg-success-green hover:bg-success-green/80 transition duration-300 ease-in-out font-primary font-medium text-lg text-white rounded-xl shadow-sm p-5 py-3">Get back home</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
