import { useEffect, useState, useRef } from "react";
import Alert, { AlertState } from "@components/common/Alert";
import { NLProfile } from "@models/Models";
import { recordEvent, sendOnboardingStats } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import ParticlesBackground from "@components/common/ParticlesBackground";
import { useOnboardingProfile } from "@providers/OnboardingContext";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import loadingAnimation from "@images/particles_loading.json";
import { useAuth } from "@providers/AuthContext";
import { MailboxIntegrationManager } from "@utils/managers/MailboxIntegrationManager";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import { useNavigate } from "react-router-dom";
import { usePremium } from "@providers/PremiumContext";

var completedLoading = false;

const Welcome = () => {
	const navigate = useNavigate();
	const { onboardingProfile } = useOnboardingProfile();
	const { isPremium } = usePremium();
	const { authUser, setAuthUser } = useAuth();
	const playerRef = useRef<LottieRefCurrentProps | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [animation1, setAnimation1] = useState<boolean>(false);
	const [animation2, setAnimation2] = useState<boolean>(false);
	const [animation3, setAnimation3] = useState<boolean>(false);
	const [animation4, setAnimation4] = useState<boolean>(false);

	useEffect(() => {
		applyChanges();
		recordEvent(kAnalyticsConstants.Onboarding.welcomePresented);
	}, []);

	useEffect(() => {
		if (!isLoading) {
			fadeInDivs();
		}
	}, [isLoading]);

	const renderLoadingView = () => {
		return (
			<div>
				<Lottie className="opacity-100" animationData={loadingAnimation} lottieRef={playerRef} onLoopComplete={aniamtionLoopComplete} />
				<div className="absolute m-auto left-0 right-0 top-[46%] text-xl text-center font-primary font-black text-white">Applying inbox changes</div>
			</div>
		);
	};

	const renderWelcomeView = () => {
		return (
			<div className="flex flex-col items-center justify-center gap-4 w-full">
				<div className="flex flex-col items-center justify-center gap-0 w-full">
					<div className={`${animation1 ? "" : "opacity-0 duration-0"} text-3xl font-primary font-regular text-white transition-opacity duration-[750ms] ease-in text-center`}>{authUser!.first_name}, welcome to</div>
					<div className={`${animation2 ? "" : "opacity-0 duration-0"} text-6xl font-primary font-medium text-white transition-opacity duration-[750ms] ease-in leading-[70px]`}>Meco</div>
				</div>
				<button className={`${animation3 ? "" : "opacity-0 duration-0"} flex m-auto cta-button w-full shadow-2xl transition-opacity duration-[250ms] ease-in`} onClick={() => moveToHome()}>
					Start reading
				</button>
			</div>
		);
	};

	const applyChanges = () => {
		const mailboxIntegrationManager = new MailboxIntegrationManager(onboardingProfile!, authUser!, MailboxIntegrationManager.Reference.signUp);

		mailboxIntegrationManager
			.applyNewsletterChanges()
			.then(([_userMailStatesArray, profile]) => {
				if (profile) {
					const userProfile: NLProfile = profile;
					setAuthUser(userProfile);
				}
				recordEvent(kAnalyticsConstants.App.appliedNewsletterChanges, { newsletter_changes_count: onboardingProfile!.persistence.feedArray.length, newsletter_changes_error: false, is_onboarding: true });
				completedLoading = true;
			})
			.catch((error) => {
				recordEvent(kAnalyticsConstants.App.appliedNewsletterChanges, { newsletter_changes_count: onboardingProfile!.persistence.feedArray.length, newsletter_changes_error: true, is_onboarding: true, error: error.message ?? "" });
				setAlertState({ isShow: true, title: "Oops something went wrong", message: "Some of your selected newsletter changes were not applied, please try again within the Settings.", actionButton: "OK", dismissButton: null, actionButtonAction: () => {setIsLoading(false);} });
			});
	};

	const fadeInDivs = () => {
		setAnimation4(true);
		setTimeout(() => {
			setAnimation1(true);
			setTimeout(() => {
				setAnimation2(true);
				setTimeout(() => {
					setAnimation3(true);
				}, 750);
			}, 500);
		}, 1500);
	};

	const aniamtionLoopComplete = () => {
		if (completedLoading) {
			setIsLoading(false);
		}
	};

	const moveToHome = () => {
		sendOnboardingStats(onboardingProfile!, isPremium());
		sessionStorage.removeItem(kLocalStorageKeys.Session.onboardingProfile);
		navigate("/");
	};

	return (
		<div>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<ParticlesBackground />
			<div className={`flex min-h-[100dvh] flex-col justify-center`}>
				<div className={`flex flex-col gap-10 items-center justify-center p-3`}>
					<div className={`meco_container max-w-[1080px] py-[40px] md:p-[60px] md:p-b-[80px] ${animation4 ? "!bg-transparent !border-0 !border-transparent !shadow-none" : null} transition-all duration-[750ms] ease-out`}>
						<div className="flex items-center m-auto w-full max-w-[400px] h-[50vh]">{isLoading ? renderLoadingView() : renderWelcomeView()}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Welcome;
