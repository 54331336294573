import React, { MouseEventHandler, useState } from "react";
import { NLBookmark, NLMail } from "@models/Models";
import { motion } from "framer-motion";
import * as ElapsedDate from "@utils/Date+ElapsedInterval";
import { ReactComponent as RightArrow } from "@images/right_arrow.svg";
import { ReactComponent as TagIcon } from "@images/tag_icon.svg";

interface BookmarksHighlightCardProps {
	bookmark: NLBookmark;
	mail: NLMail | undefined;
	isActive: boolean;
	onClick: MouseEventHandler<HTMLDivElement>;
}

const BookmarksHighlightCard: React.FC<BookmarksHighlightCardProps> = ({ bookmark, mail, isActive, onClick }) => {
	const [tagsExpanded, setTagsExpanded] = useState<boolean>(false);
	const [noteExpanded, setNoteExpanded] = useState<boolean>(false);

	const getDomain = (pageUrl?: string): string | null => {
		if (!pageUrl) return null;
		try {
			const url = new URL(pageUrl);
			if (url.host) {
				return url.host.replace("www.", "");
			}
			return pageUrl;
		} catch (error) {
			return null;
		}
	};

    const goToLink = (url: string | undefined) => {
        if (!url) return;
        window.open(url, "_blank");
    }

	return (
		<motion.div key={bookmark.id} id={`bookmark-${bookmark.id}`} layout layoutScroll={true} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ ease: "easeInOut", duration: 0.3, type: "spring" }} onClick={onClick}>
			<div className={`w-full flex flex-row bg-surface rounded-xl px-3 py-3 transition ease-in-out duration-[250] cursor-pointer shadow-md`}>
				<div className="flex flex-col gap-3 justify-center items-center w-full">
					<div className="flex flex-row gap-2 justify-between items-center w-full">
						<div className="flex flex-row gap-2 w-full items-center">
							<div className="flex flex-col min-w-0 flex-grow">
								{mail && <div className="text-2xs font-primary font-regular text-primary-500">{ElapsedDate.elapsedInterval(mail.receive_date)}</div>}
								<div className="text-base text-left font-primary font-medium text-primary transition ease-in-out">{bookmark.mail_sender_name}</div>
								<div className="text-sm font-primary font-regular text-primary truncate text-ellipsis">{bookmark.mail_subject}</div>
							</div>
							<div>
								<button className="bg-secondary rounded-full h-[36px] w-[36px] border border-primary-200 hover:bg-surface-100 transition ease-in-out duration-300">
									<RightArrow className="fill-primary w-[16px] m-auto" />
								</button>
							</div>
						</div>
					</div>
					{bookmark.bookmark_highlight && (
						<div className="flex flex-row gap-2.5 items-center w-full">
							<div className="bg-yellow-600 dark:bg-yellow-500 w-[3px] rounded-lg h-full flex-shrink-0" />
							<div className="text-sm text-left font-primary font-regular text-yellow-600 dark:text-yellow-500 min-w-0 flex-grow leading-5">{bookmark.bookmark_highlight.text}</div>
						</div>
					)}
					{bookmark.tags && bookmark.tags.length > 0 && (
						<div className="w-full flex flex-row gap-2 flex-wrap">
							{(tagsExpanded ? bookmark.tags : bookmark.tags.slice(0, 2)).map((tag, index) => (
								<div key={tag} className="bg-primary-100 rounded-md px-2 py-1 whitespace-nowrap flex flex-row gap-1 items-center">
									<TagIcon className="fill-primary h-[12px]" />
									<div className="text-xs font-primary font-regular text-primary">{tag}</div>
								</div>
							))}
							{!tagsExpanded && bookmark.tags.length > 2 && (
								<div
									onClick={(e) => {
										e.stopPropagation();
										setTagsExpanded(true);
									}}
									className="bg-transpartent border border-primary-200 rounded-md px-2 py-1 whitespace-nowrap flex flex-row gap-1 items-center">
									<div className="text-xs font-primary font-regular text-primary">{bookmark.tags.length - 2} more tags</div>
								</div>
							)}
						</div>
					)}
					{bookmark.note && (
						<div className="text-sm font-primary font-regular text-primary w-full">
							{noteExpanded ? bookmark.note : bookmark.note.split(" ").slice(0, 20).join(" ")}
							{!noteExpanded && bookmark.note.split(" ").length > 20 && (
								<span
									onClick={(e) => {
										e.stopPropagation();
										setNoteExpanded(true);
									}}
									className="text-primary-500 font-medium cursor-pointer">
									... show more
								</span>
							)}
						</div>
					)}
				</div>
			</div>
		</motion.div>
	);
};

export default BookmarksHighlightCard;
