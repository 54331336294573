import React, { createContext, useContext, ReactNode, useEffect } from "react";
import { NLMailboxProfile, OnboardingProfile } from "@models/Models";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import { Outlet } from "react-router-dom";

interface OnboardingContextProps {
	onboardingProfile: OnboardingProfile | null | undefined,
    setOnboardingProfile: (onboardingProfile: OnboardingProfile) => void;
}

const OnboardingContext = createContext<OnboardingContextProps | undefined>(undefined);

export const OnboardingProvider = () => {
	const [onboardingProfile, setOnboardingProfile] = React.useState<OnboardingProfile | null | undefined>(undefined);

	useEffect(() => {
        var currentOnboardingProfile = null;
        const storedOnboardingProfile = sessionStorage.getItem(kLocalStorageKeys.Session.onboardingProfile);
		if (storedOnboardingProfile) {
			const jsonOnboardingProfile = JSON.parse(storedOnboardingProfile);
            const mailboxProfile = new NLMailboxProfile(jsonOnboardingProfile.persistence.mailboxProfile);
            currentOnboardingProfile = Object.assign(new OnboardingProfile(mailboxProfile), jsonOnboardingProfile);
        }
        setOnboardingProfile(currentOnboardingProfile);
	}, []);

    useEffect(() => {
        if (onboardingProfile) {
            sessionStorage.setItem(kLocalStorageKeys.Session.onboardingProfile, JSON.stringify(onboardingProfile));
        }
	}, [onboardingProfile]);

	const providerValue: OnboardingContextProps = {
		onboardingProfile,
        setOnboardingProfile,
	};

	return <OnboardingContext.Provider value={providerValue}><Outlet /></OnboardingContext.Provider>;
};

export const useOnboardingProfile = () => {
	const context = useContext(OnboardingContext);
	if (!context) {
		throw new Error("useOnboardingProfile must be used within an OnboardingProvider");
	}
	return context;
};
