class CustomError extends Error {
    constructor(message, statusCode) {
     super(message)
     this.statusCode = statusCode
    }
}

export const kErrorConstants = {
    dataProcessingError: new CustomError("Data processing error occured", 422),
    commonBackendError: {
        unknownError: new CustomError("Backend Error", 400),
    },
    signUp: {
        existingAccount: new CustomError("There is already an account with this mail address", 403)
    },
    logIn: {
        nonExistantAccount: new CustomError("Account not found", 404)
    },
    oauthIntegration: {
        unknownError: new CustomError("Unknown Error", 400),
        permissionError: new CustomError("Permissions are not granted", 401),
        accountMismatch: new CustomError("Gmail account and reconnect accounts are different", 409),
        userCancelled: new CustomError("User cancelled authorization flow", 0),
        alreadyIntegratedAccount: new CustomError("This account has already been integrated", 406)
    },
    mailboxProfiles: {
        unknownHistoryId: new CustomError("Unknown history id. Revert to full sync", 404),
        oauth: {
            signInRequired: new CustomError("No refresh token or access token can't be generated. Possible revoke.", 401),
            notProUser: new CustomError("Not pro user", 403)
        },
        oauthTokenRefresh: {
            backendIsError: new CustomError("Backend marked this mailbox profile as is error", 401)
        },
        unknownError: new CustomError("Unknown error", 400),
    }
}