import React, { useState, useEffect, FC, Fragment } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { useHotkeys } from "react-hotkeys-hook";
import IconButton from "@components/common/IconButton";
import { ReactComponent as GmailIcon } from "@images/gmail_icon.svg";
import { ReactComponent as OutlookIcon } from "@images/outlook_logo.svg";
import { ReactComponent as MecoIcon } from "@images/meco_app_logo.svg";
import { ReactComponent as FilledArrow } from "@images/filled_arrow_icon.svg";
import { useAuth } from "@providers/AuthContext";
import { NLMail, NLMailboxProfileType } from "@models/Models";
import Alert, { AlertState } from "@components/common/Alert";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

interface NewMailboxModalProps {
	isShow: boolean;
	onClose: () => void;
}

const NewMailboxModal: FC<NewMailboxModalProps> = ({ isShow, onClose }) => {
	const { authUser } = useAuth();
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const navigate = useNavigate();

	interface AddMailboxCard {
		title: string;
		description: string;
		icon: JSX.Element;
		color: string;
		onClick: () => void;
	}

	const addMailboxCards: AddMailboxCard[] = [
		{
			title: "Connect a Gmail",
			description: "Add your existing newsletters from your Gmail to Meco in seconds. Just sign in to your Gmail and select the newsletters you want to read in Meco going forward.",
			icon: <GmailIcon className="p-1" />,
			color: "#c71610",
			onClick: () => gmailOnClick(),
		},
		{
			title: "Connect an Outlook",
			description: "Add your existing newsletters from your Outlook to Meco in seconds. Just sign in to your Outlook and select the newsletters you want to read in Meco going forward.",
			icon: <OutlookIcon className="p-1 mr-[2px]" />,
			color: "#0364B8",
			onClick: () => outlookOnClick(),
		},
		{
			title: "Create a Meco Inbox",
			description: "Get a new Meco email address just for newsletters. Subscribe to any newsletter with your personal Meco address to read it in the app.",
			icon: <MecoIcon className="p-1" />,
			color: "#191D2E",
			onClick: () => mecoInboxOnClick(),
		},
	];

	useHotkeys("Escape", () => onModalClose());

	const gmailOnClick = () => {
		navigate("/get-started/connect-gmail?is_account=true", { state: { isAccount: true } });
	};

	const outlookOnClick = () => {
		navigate("/get-started/connect-outlook?is_account=true", { state: { isAccount: true } });
	};

	const mecoInboxOnClick = () => {
		const existingMecoInbox = authUser!.mailbox_profiles.find((profile) => profile.type === NLMailboxProfileType.Internal);
		if (existingMecoInbox) {
			setAlertState({ isShow: true, title: "Already set up", message: "You already have a Meco email address set up: " + existingMecoInbox.email_address, actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
			return;
		}
		navigate("/get-started/meco-mailbox", { state: { isAccount: true } });
	};

	const onModalClose = () => {
		onClose();
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child className="h-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
								<div className="absolute w-full bg-surface backdrop-blur-md top-0 left-0 flex flex-row justify-between items-center p-4 pb-3 border-b border-primary-200 z-20">
									<div className="text-primary font-medium font-primary text-xl">Add New Mailbox</div>
									<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onModalClose()} />
								</div>
								<div className="flex flex-col gap-4 text-left h-full p-4 py-[105px] bg-secondary overflow-auto">
									{addMailboxCards.map((card, index) => {
										return (
											<button key={index} className="flex flex-col hover:opacity-100 opacity-90 duration-300 transition ease-in-out w-full item rounded-[15px] p-3 gap-3 shadow-md" style={{ backgroundColor: card.color }} onClick={card.onClick}>
												<div className="flex flex-row items-center justify-between gap-2 w-full">
													<div className="flex flex-row items-center gap-2">
														<div className="w-[40px] h-[40px] bg-white border border-brand-blue/20 rounded-md flex flex-row justify-center items-center">{card.icon}</div>
														<div className="font-primary font-medium text-xl text-white">{card.title}</div>
													</div>
													<FilledArrow className="fill-white w-[32px] h-[32px]" />
												</div>
												<div className="font-primary font-regular text-base text-white text-left">{card.description}</div>
											</button>
										);
									})}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default NewMailboxModal;
