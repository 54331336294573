export const DiscoverCategory = (props) => {
	return (
		<button key={props.key} className="bg-surface hover:bg-surface-100 flex flex-col items-center p-3 rounded-[10px] min-w-[120px] border border-primary-100  transition duration-500 ease-in-out" onClick={((category) => props.categoryOnClick(category))}>
			<div className="text-[30px]">{props.emoji}</div>
			<div className="font-primary font-medium text-base text-primary">{props.name}</div>
		</button>
	);
};

export default DiscoverCategory;
