Object.defineProperty(Array.prototype, "chunked", {
	value: function (len) {
		var chunks = [],
			i = 0,
			n = this.length;

		while (i < n) {
			chunks.push(this.slice(i, (i += len)));
		}

		return chunks;
	},
});

export function chunkArray(array, len) {
	const chunks = [];
	let i = 0;
	const n = array.length;

	while (i < n) {
		chunks.push(array.slice(i, (i += len)));
	}

	return chunks;
}

export function groupBy(xs, key) {
	return xs.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
}
