import { FC } from "react";

interface AudioSchedulePickerRowProps {
	hour: number;
    displayHour: string;
	isChecked: boolean;
	didSelect: (hour: number) => void;
}

const AudioSchedulePickerRow: FC<AudioSchedulePickerRowProps> = ({ hour, displayHour, isChecked, didSelect }) => {

	return (
		<div className="col-md-auto col-centered no-select">
			<div className={`color-white rounded-[6px] border border-white/20 cursor-pointer px-3 py-2 transition ease-in-out ${isChecked ? "bg-success-green hover:bg-success-green scale-105" : "bg-white/10 hover:bg-white/20 scale-100"}`} onClick={(e) => didSelect(hour)}>
				<div className="font-medium font-primary text-base  text-force-primary-light">{displayHour}</div>
			</div>
		</div>
	);
};

export default AudioSchedulePickerRow;
