import { NLMailboxProfile, NLMailboxProfileType, NLSearchResult } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";

export class SearchSenderService {
	private mailboxProfiles: NLMailboxProfile[];
	private query: string;

	constructor(mailboxProfiles: NLMailboxProfile[], query: string) {
		this.mailboxProfiles = mailboxProfiles;
		this.query = query;
	}

	performSearch(completion: (searchModelArray: NLSearchResult[] | undefined, error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();
		const idArray: SearchSenderService.SearchIdModel[] = [];
		const promises: Promise<void>[] = [];

		for (const mailboxProfile of this.mailboxProfiles) {
			const promise = new Promise<void>((innerResolve, innerReject) => {
				if (mailboxProfile.type !== NLMailboxProfileType.Internal) {
					oauthSessionManager
						.fetchAccessTokenForMailboxProfile(mailboxProfile.id, mailboxProfile.type)
						.then((accessToken) => {
							const idModel = new SearchSenderService.SearchIdModel(mailboxProfile.id, accessToken);
							idArray.push(idModel);
							innerResolve();
						})
						.catch((error) => {
							innerReject(error);
							return;
						});
				} else {
					innerReject();
				}
			});
			promises.push(promise);
		}

		Promise.all(promises)
			.then(() => {
				if (idArray.length === 0) {
					completion(undefined, undefined);
					return;
				}
				const request = new SearchSenderService.RequestModel(idArray, this.query);
				this.makeRequests(this.mailboxProfiles, request, completion);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}

	private makeRequests(mailboxProfiles: NLMailboxProfile[], request: SearchSenderService.RequestModel, completion: (searchModelArray: NLSearchResult[] | undefined, error: Error | undefined) => void) {
		networkManager
			.searchSenders(request)
			.then((jsonData) => {
				var responseModels: NLSearchResult[] = [];

				for (let mailboxProfileId in jsonData) {
					let responseData = jsonData[mailboxProfileId];

					if (Array.isArray(responseData["search_senders"])) {
						for (const searchSender of responseData["search_senders"]) {
							const senderAddress = searchSender["sender_address"];
							const senderName = searchSender["sender_name"];
							const mailboxProfile = mailboxProfiles.find((x) => x.id === mailboxProfileId);

							var isAdded = false;
							if (searchSender["is_added"]) {
								isAdded = searchSender["is_added"];
							}

							if (senderAddress && senderName && mailboxProfile) {
								const responseModel = new NLSearchResult({ mailboxProfile: mailboxProfile, senderAddress: senderAddress, senderName: senderName, isAdded: isAdded });
								responseModels.push(responseModel);
							}
						}
					}
				}

				completion(responseModels, undefined);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}
}

export namespace SearchSenderService {
	export class SearchIdModel {
		access_token?: string | undefined;
		mailbox_profile_id!: string;

		constructor(mailboxProfileId: string, accessToken?: string | undefined) {
			this.mailbox_profile_id = mailboxProfileId;
			this.access_token = accessToken;
		}
	}

	export class RequestModel {
		search_senders!: SearchIdModel[];
		query!: string;

		constructor(searchSenders: SearchIdModel[], query: string) {
			this.search_senders = searchSenders;
			this.query = query;
		}
	}
}
