import { kLocalStorageKeys } from "../../constants/kLocalStorageKeys";

export const Production_Base_URL = "https://www.meco.app"
export const Stage_Base_URL = "https://stage.meco.app"

export function baseURL() {
    if (process.env.REACT_APP_STAGE === 'production') {
        return Production_Base_URL
    } else {
        return Stage_Base_URL
    }
}

export function redirectUri() {
    return process.env.REACT_APP_GOOGLE_REDIRECT_URI;
}

export const Endpoints = {
    registerGmail: '/api/register/gmail',
    userProfile: '/api/profile',
    userMailStats: '/api/user_mail_stats',
    postFilters: '/api/gmail_create_filters',
    postEvents: '/api/events',
    listNewsletters: '/api/newsletters',
    postShareMail: '/api/share',
    getGoogleAccessToken: '/api/gmail/access-token',
    getOutlookAccessToken: '/api/outlook/access-token',
    getNewsletterCategories: '/api/newsletters/categories',
    getOnboardingRecommendedNewsletters: '/api/newsletters/onboarding',
    postOneClickSubscribeNewsletters: '/api/newsletter_subscribe/one_click_subscribe',
    getConsents: '/api/profile/consent',
    getAppConfig: '/api/config',
    getUserGroups: '/api/user_groups',
    getBookmarks: '/api/bookmarks',
    getUserSenderGroups: '/api/user_sender_groups',
    signUpUser: '/api/sign-up',
    logInUser: '/api/log-in',
    validateMagicLink: '/api/auth/magic-link',
    validateMagicCode: '/api/auth/magic-code',
    getDomainMXRecord: '/api/mx-check',
    logOutUser: '/api/log-out',
    getDiscoverRecommendedNewsletters: '/api/newsletters/discover',
    getCheckOutUrl: '/api/stripe/checkout',
    changeNotificationPreference: '/api/push_notifications',
    registerPush: '/device/webpush',
    activateInternalTrial: '/api/activate-internal-trial',
    //Mailbox Profile
    mecoMailboxProfile: '/api/mailbox-profile/internal',
    updateMailboxProfile: '/api/mailbox-profile',
    gmailMailboxProfile: '/api/mailbox-profile/gmail',
    outlookMailboxProfile: '/api/mailbox-profile/outlook',
    verifyMecoMailboxUsername: '/api/mailbox-profile/internal/verify',
    //Mailbox
    mailboxGetUserMailStates: '/api/mailbox/user-mail-states',
    mailboxChangeUserMailStates: '/api/mailbox/change-states',
    mailboxSyncGmailFilters: '/api/mailbox/gmail/sync-filters',
    mailboxSyncMessages: '/api/mailbox/sync-messages',
    mailboxPartialSyncMessages: '/api/mailbox/partial-sync-messages',
    mailboxGetMessages: '/api/mailbox/get-messages',
    mailboxQueryMessages: '/api/mailbox/query-messages',
    mailboxDeleteMessages: '/api/mailbox/delete-undelete-message',
    mailboxModifyMessages: '/api/mailbox/modify-message',
    mailboxSearchSenders: '/api/mailbox/search-senders',
    mailboxCreateSender: '/api/mailbox/create-sender',
    mailboxMessageReaderMode: '/api/mailbox/get-reader-mode-message',
    mailboxLabelHealth: "/api/mailbox/label-health",
    mailboxReplyMessage: "/api/mailbox/reply-message",
    mailboxMessageSummary: "/api/mailbox/get-message-summary",
    //Audio
    getAudioSections: "/api/audio/sections",
    getAudioSectionDetail: "/api/audio/section-detail/",
    postAudioRating: "/api/audio/rating",
    createAudioProfile: "/api/audio/profile",
    postAudioHistory: "/api/audio/history",
    postShareAudio: "/api/share/audio",
}

export function getUserAuthToken() {
    return localStorage.getItem(kLocalStorageKeys.User.token);
}