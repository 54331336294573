import * as analyticsManager from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

export const OnboardingDiscoverRow = (props) => {
	function rowClicked() {
		analyticsManager.recordEvent(kAnalyticsConstants.Discover.personalisedDiscoverTapped, { newsletter_id: props.id, newsletter_title: props.name, newsletter_index: props.onboarding_sort_index, reference: "onboarding" });
		props.itemVisited();
		window.open(props.subscribe_link, "_blank");
	}

	if (props.one_click_subscribe_method != null) {
		return (
			<div key={props.id} className="flex flex-col bg-white/10 hover:bg-white/20 transition ease-in-out border border-white/20 p-3 rounded-[10px] gap-2 items-start mb-[12px]" onClick={(e) => props.checkOnChange(e, props)}>
				<div className="flex flex-row justify-between items-center w-full">
					<div className="flex flex-row items-center gap-2 text-left">
						<img src={props.logo} className="w-[50px] h-[50px] rounded-[6px] object-contain border border-white/20" />
						<div className="text-white font-medium font-primary text-xl">{props.name}</div>
					</div>
					<input readOnly className="checkbox-round green" type="checkbox" name="selectable-sender" checked={props.isChecked} value={props.id} />
				</div>
				<div className="flex-col">
					<div className="text-force-primary-light/50 font-regular font-primary text-xs text-left">{props.summary}</div>
				</div>
			</div>
		);
	} else {
		return (
			<div key={props.id} className="flex flex-col bg-white/10 hover:bg-white/20 transition ease-in-out border border-white/20 p-3 rounded-[10px] gap-2 items-start mb-[12px]">
				<div className="flex flex-row justify-between items-center w-full">
					<div className="flex flex-row items-center gap-2 text-left">
						<img src={props.logo} className="w-[50px] h-[50px] rounded-[6px] object-contain border border-white/20" />
						<div className="text-white font-medium font-primary text-xl">{props.name}</div>
					</div>
					<button className="bg-white rounded-[10px] px-3 py-2 font-bold font-primary text-xs text-brand-blue hover:scale-105 transition ease-in-out" onClick={() => rowClicked()}>
						VISIT
					</button>
				</div>
				<div className="flex-col">
					<div className="text-force-primary-light/50 font-regular font-primary text-xs text-left">{props.summary}</div>
				</div>
			</div>
		);
	}
};

export default OnboardingDiscoverRow;
