import { useLayout } from "@providers/LayoutContext";
import DefaultSidebar from "./DefaultSidebar";
import SettingsSidebar from "./SettingsSidebar";
import { useEffect } from "react";

export interface MenuItem {
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
	title: string;
	subMenu?: SubMenuItem[] | undefined;
	subMenuExpandState?: boolean;
	subMenuLoadingState?: boolean;
	link: LinkItem;
}

export interface SubMenuItem {
	key: string;
	title: string;
	action: () => void;
	isActive: boolean;
}

export interface LinkItem {
	link: string;
	onAction?: () => void;
	isParentLink?: boolean | undefined;
}

const SidebarWrapper = () => {
	const { isSidebarHidden, sideBarWidth, isSettingsActive, wallpaper } = useLayout();

	useEffect(() => {
		const section = document.getElementById("sidebar_wrapper");
		if (section) {
			section.scrollTo({
				left: isSettingsActive ? sideBarWidth : 0,
			});
		}
	}, []);

	useEffect(() => {
		const section = document.getElementById("sidebar_wrapper");
		if (section) {
			section.scrollTo({
				left: isSettingsActive ? sideBarWidth : 0,
				behavior: "smooth",
			});
		}
	}, [isSettingsActive]);

	return (
		<>
			<div id="sidebar_wrapper" style={{ width: sideBarWidth }} className={`${isSidebarHidden ? "hidden" : "block"} h-screen fixed border-r border-primary-100 z-20 transition-[width] ease-in-out duration-300 overflow-y-auto overflow-x-hidden flex flex-row bg-white-overlay ${wallpaper.sidebarColor} scrollbar scrollbar-thumb-surface scrollbar-track-transparent`}>
				<DefaultSidebar />
				<SettingsSidebar />
			</div>
		</>
	);
};

export default SidebarWrapper;
