import React, { useState, useEffect, useRef } from "react";

interface PickerProps {
	Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined;
	options: string[];
	defaultValue: string;
	setSelectedOption: (option: string | null) => void; // Setter function prop
}

const Picker: React.FC<PickerProps> = ({ Icon, options, defaultValue, setSelectedOption }) => {
	const [selectedOption, setSelected] = useState<string>(defaultValue);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setSelected(defaultValue);	
	}, [defaultValue]);

	const handleOptionClick = (option: string) => {
		setSelected(option);
		setSelectedOption(option); // Call the setter function with the selected option
		setIsOpen(false);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="relative" ref={dropdownRef}>
			<button onClick={() => setIsOpen(!isOpen)} className={`focus:outline-none border border-primary-100 rounded-lg text-sm px-2.5 py-1.5 inline-flex items-center ${isOpen ? "bg-success-green/20" : "bg-transparent hover:bg-primary-200"} text-brand-blue transition ease-in-out duration-300`}>
				{Icon && <Icon className="fill-primary mr-2 w-[16px]" />}
				<div className="font-primary font-medium text-sm text-primary">{selectedOption}</div>
			</button>

			{isOpen && (
				<div className="absolute z-20 w-full min-w-[160px] mt-2 py-1 origin-top-left bg-secondary rounded-lg shadow-lg border border-primary-200">
					<div className="flex flex-col">
						{options.map((option, index) => (
							<div key={index} onClick={() => handleOptionClick(option)} className={`group px-2 py-2 mx-1 my-1 cursor-pointer rounded-lg hover:bg-success-green`}>
								<div className="font-primary font-regular text-base group-hover:text-white">{option}</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default Picker;
