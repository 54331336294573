import { Navigate, useLocation } from "react-router-dom";

const DeepLinkRoute = () => {
	const location = useLocation();
	const decodeMagicLink = (base64EncodedData) => {
		try {
			const urlDecodedData = decodeURIComponent(atob(base64EncodedData));
			return JSON.parse(urlDecodedData);
		} catch (error) {
			throw new Error("Invalid or expired magic link");
		}
	};

	const url = location.pathname;
	const base64EncodedData = url.split("/").pop();

	try {
		if (!base64EncodedData) {
			throw new Error("Invalid or expired magic link");
		}

		const magicLinkObject = decodeMagicLink(base64EncodedData);
		return <Navigate to="/magic-link" state={{ magicLinkObject: magicLinkObject, email: magicLinkObject.email_address }} replace />;
	} catch (error) {
		return <Navigate to="/login" state={{ hasSessionExpired: true }} replace />;
	}
};

export default DeepLinkRoute;
