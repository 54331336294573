import { NLMailboxProfile, NLMailboxProfileType, NLUserMailState } from "@models/Models";
import { ChangeUserMailStatesService } from "./mailboxFunctions/ChangeUserMailStatesService";
import { FiltersService } from "./mailboxFunctions/FiltersService";

export const changeState = (changeStateServiceArray: ChangeUserMailStatesService.QueryBuilder[], syncFilters: boolean, completion: (userMailStates: NLUserMailState[] | undefined, error: Error | undefined) => void) => {
	changeStatesForArray(changeStateServiceArray)
		.then(([userMailStates, responseArray, error]) => {
			if (error) {
                return Promise.reject(error);
			}
			return syncFiltersWithBackend(userMailStates ?? [], responseArray, syncFilters, changeStateServiceArray.map(x => x.mailboxProfile));
		})
		.then(([userMailStates, error]) => {
			completion(userMailStates, error);
		})
		.catch((error) => {
			completion(undefined, error);
		});
};

const changeStatesForArray = (changeStateServiceArray: ChangeUserMailStatesService.QueryBuilder[]): Promise<[NLUserMailState[]?, ChangeUserMailStatesService.ResponseModel[]?, Error?]> => {
	return new Promise((resolve, _reject) => {
		const changeUserMailStatesService = new ChangeUserMailStatesService(changeStateServiceArray);
		changeUserMailStatesService.changeUserMailStates((userMailStateResponses, error) => {
			var parentUserMailStates: NLUserMailState[] | undefined = undefined;
			var userMailStates: NLUserMailState[] | undefined;
			var newError = error;

			for (let userMailStateResponse of userMailStateResponses ?? []) {
				if (userMailStateResponse.error) {
					newError = userMailStateResponse.error;
					continue;
				}

				let responseStates = userMailStateResponse.userMailStates;
				if (responseStates) {
					userMailStates = userMailStates ?? [];
					userMailStates.push(...responseStates);
				}
			}

			if ((userMailStates?.length ?? 0) > 0) {
				parentUserMailStates = userMailStates!;
			}

			resolve([parentUserMailStates, userMailStateResponses, newError]);
		});
	});
};

const syncFiltersWithBackend = (userMailStates: NLUserMailState[], responseArray: ChangeUserMailStatesService.ResponseModel[] | undefined, syncFilters, mailboxProfiles: NLMailboxProfile[]): Promise<[NLUserMailState[]?, Error?]> => {
	return new Promise((resolve, _reject) => {

		if (!syncFilters) {
			resolve([userMailStates, undefined]);
			return;
		}

		if (!responseArray) {
			resolve([userMailStates, undefined]);
			return;
		}

		const groupByMailboxId: { [mailboxProfileId: string]: NLUserMailState[] } = {};
		userMailStates.forEach((state) => {
			if (!groupByMailboxId[state.mailbox_profile_id]) {
				groupByMailboxId[state.mailbox_profile_id] = [];
			}
			groupByMailboxId[state.mailbox_profile_id].push(state);
		});

		var filterRequests: FiltersService.QueryBuilder[] = [];

		for (const mailboxProfileId in groupByMailboxId) {
			const mailboxProfile = mailboxProfiles.find((x) => x.id === mailboxProfileId);
			if (mailboxProfile && mailboxProfile.type !== NLMailboxProfileType.Internal) {
				const filterRequest = new FiltersService.QueryBuilder(mailboxProfile);
				filterRequests.push(filterRequest);
			}
		}

		if (filterRequests.length === 0) {
			resolve([userMailStates, undefined]);
			return;
		}

		const filterService = new FiltersService(filterRequests);

		filterService.syncFilters((syncFiltersResponse, error) => {
			var newError = error;

			for (let response of syncFiltersResponse ?? []) {
				if (response.error) {
					newError = response.error;
				}
			}

			resolve([userMailStates, error]);
		});

	});
};
