import { ChangeUserMailStatesService } from "./backendMailManager/mailboxFunctions/ChangeUserMailStatesService";
import * as changeStatesManager from "./backendMailManager/ChangeUserMailStatesManager";
import * as syncMessagesManager from "./backendMailManager/SyncMessagesManager";
import * as queryMessagesManager from "./backendMailManager/QueryMessagesManager";
import * as deleteUndeleteMessagesManager from "./backendMailManager/DeleteUndeleteMessagesManager";
import * as modifyMessagesManager from "./backendMailManager/ModifyMessagesManager";
import * as searchSenderService from "./backendMailManager/mailboxFunctions/SearchSenderService";
import { NLMail, NLMailboxProfile, NLMailboxProfileType, NLProfile, NLSearchResult, NLUserMailState } from "@models/Models";
import { MessageIdType, UnreadState } from "@models/Enums";
import { MailState, QueryResponse } from "@providers/MailContext";
import "@utils/String+MessageIdType";
import { GetMessagesService } from "./backendMailManager/mailboxFunctions/GetMessagesService";
import { kErrorConstants } from "@utils/constants/ErrorConstants";
import { QueryMessagesService } from "./backendMailManager/mailboxFunctions/QueryMessagesService";

export const syncMessages = (profile: NLProfile, baseMails: NLMail[] | undefined, historyIdDictionary: Record<string, string> | undefined, cutOffMail: NLMail | undefined, completion: (mails: NLMail[] | undefined, historyIdDictionary: Record<string, string> | undefined, error: Error | undefined) => void) => {
	syncMessagesManager.syncMessages(profile.mailbox_profiles, baseMails, historyIdDictionary, cutOffMail, completion);
};

export const changeState = (changeStateServiceArray: ChangeUserMailStatesService.QueryBuilder[], syncFilters: boolean, completion: (userMailStates: NLUserMailState[] | undefined, error: Error | undefined) => void) => {
	changeStatesManager.changeState(changeStateServiceArray, syncFilters, completion);
};

export const queryMessages = (mailboxProfile: NLMailboxProfile, mailState: MailState, beforeDate: string | undefined, senderAddress: string | undefined, isUnread: UnreadState | undefined, groupId: number | undefined, completion: (mails: NLMail[] | undefined, queryResponse: QueryResponse | undefined, error: Error | undefined) => void) => {
	const unreadStatus = UnreadState.getUnreadBoolean(isUnread);
	queryMessagesManager.queryMessages(mailboxProfile, mailState, beforeDate, senderAddress, unreadStatus, groupId, completion);
};

export const deleteUndeleteMessages = (mails: NLMail[], mailboxProfiles: NLMailboxProfile[], isDelete: boolean, completion: (successArray: string[] | undefined, error: Error | undefined) => void) => {
	deleteUndeleteMessagesManager.deleteUndeleteMessages(mails, mailboxProfiles, isDelete, completion);
};

export const modifyMessages = (mails: NLMail[], mailboxProfiles: NLMailboxProfile[], action: string, completion: (successArray: string[] | undefined, error: Error | undefined) => void) => {
	modifyMessagesManager.modifyMessages(mails, mailboxProfiles, action, completion);
};

export const searchSenders = (mailboxProfiles: NLMailboxProfile[], query: string, completion: (searchModelArray: NLSearchResult[] | undefined, error: Error | undefined) => void) => {
	const service = new searchSenderService.SearchSenderService(mailboxProfiles, query);
	service.performSearch(completion);
};

export const getIndividualMail = (mailboxProfiles: NLMailboxProfile[], mailId: string, completion: (mail: NLMail | undefined, error: Error | undefined) => void) => {
	const messageIdType = mailId.messageIdType();
	var getMessagesRequests: GetMessagesService.QueryBuilder[] = [];

	switch (messageIdType) {
		case MessageIdType.Legacy:
			//Try with all mailboxes
			for (const mailboxProfile of mailboxProfiles) {
				if (mailboxProfile.type === NLMailboxProfileType.Internal) {
					continue;
				}
				const query = new GetMessagesService.QueryBuilder(mailboxProfile, [mailId + "_" + mailboxProfile.id]);
				getMessagesRequests.push(query);
			}
			break;
		case MessageIdType.OAuth:
			//We know the mailbox profile id from message id
			const mailboxProfileId = mailId.split("_").pop();
			const mailboxProfile = mailboxProfiles.find((x) => x.id === mailboxProfileId);
			if (!mailboxProfile) {
				completion(undefined, kErrorConstants.mailboxProfiles.unknownError);
				return;
			}
			const oauthQuery = new GetMessagesService.QueryBuilder(mailboxProfile, [mailId]);
			getMessagesRequests.push(oauthQuery);
			break;
		case MessageIdType.Internal:
			//We filter the internal mailbox to get mailbox profile id
			const internalMailboxProfile = mailboxProfiles.find((x) => x.type === NLMailboxProfileType.Internal);
			if (!internalMailboxProfile) {
				completion(undefined, kErrorConstants.mailboxProfiles.unknownError);
				return;
			}
			const internalQuery = new GetMessagesService.QueryBuilder(internalMailboxProfile, [mailId]);
			getMessagesRequests.push(internalQuery);
			break;
	}

	if (getMessagesRequests.length === 0) {
		completion(undefined, kErrorConstants.mailboxProfiles.unknownError);
		return;
	}

	const service = new GetMessagesService(getMessagesRequests);
	service.getMessages((getMessagesResponses, error) => {
		if (error) {
			completion(undefined, error);
			return;
		}

		var message: NLMail | undefined;

		for (const getMessagesResponse of getMessagesResponses ?? []) {
			message = getMessagesResponse.messages?.[0];
			if (message) {
				break;
			}
		}

		completion(message, undefined);
	});
};

export const searchMessages = (searchTerm: string, mailboxProfiles: NLMailboxProfile[], completion: (mails: NLMail[] | undefined, error: Error | undefined) => void) => {
	var queryMessagesRequests: QueryMessagesService.QueryBuilder[] = [];

	for (let mailboxProfile of mailboxProfiles) {
		const mailState = { isUnread: UnreadState.off, groupId: undefined, userMailStateId: undefined, isBookmark: false, mails: [], queryResponses: [] };
		const query = new QueryMessagesService.QueryBuilder(mailboxProfile, mailState, undefined, undefined, undefined, undefined, searchTerm);
		queryMessagesRequests.push(query);
	}

	var messages: NLMail[] = [];
	var individualError: Error | undefined;
	const promises: Promise<void>[] = [];

	for (let queryMessage of queryMessagesRequests) {
		const queryMessageService = new QueryMessagesService(queryMessage);

		const promise = new Promise<void>((innerResolve, innerReject) => {
			queryMessageService.queryMessages((mails, error) => {
				individualError = error;
				if (mails && mails.length > 0) {
					messages.push(...mails);
				}
				innerResolve();
			});
		});
		promises.push(promise);
	}

	Promise.all(promises)
		.then(() => {
			if (queryMessagesRequests.length === 0) {
				completion(undefined, undefined);
				return;
			}
			completion(messages, individualError);
		})
		.catch((error) => {
			completion(undefined, error);
		});
};
