import { useEffect, useState } from "react";
import collageDiscover from "@images/collage_discover.png";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import DiscoverRow from "./DiscoverRow";
import DiscoverCategory from "./DiscoverCategory";
import LottieView from "@utils/LottieView";
import { DiscoverModel, NLNewsletterCategory } from "@models/Models";
import Alert, { AlertState } from "@components/common/Alert";
import EmptyStateView from "@components/common/EmptyStateView";
import { useData } from "@providers/DataContext";

export const DiscoverSubscriptionState = {
	web: 0,
	oneClickSubscribe: 1,
	oneClickSubscribeOnly: 2,
	subscribed: 3,
};

const Discover = (props) => {
	const [categories, setCategories] = useState<NLNewsletterCategory[] | undefined>(undefined);
	const [discoverArray, setDiscoverArray] = useState<DiscoverModel[] | undefined>(undefined);
	const [ocsSubscribed, setOcsSubscribed] = useState<string | undefined>(undefined);
	const [isError, setError] = useState(false);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const { userMailStates, setUserMailStates } = useData();

	useEffect(() => {
		getCategories()
			.then((categories) => {
				let newCategories = organizeCategories(categories);
				setCategories(newCategories);
				return getNewsletterRecommendations(newCategories);
			})
			.catch(() => {
				setError(true);
			});
	}, []);

	const getCategories = () => {
		return new Promise((resolve, reject) => {
			networkManager
				.getNewsletterCategories()
				.then((categories) => {
					resolve(categories);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const organizeCategories = (categories) => {
		var newCategories = categories;
		let trending = new NLNewsletterCategory({ id: 9000, name: "Trending", emoji: "🔥", sort_index: -1 });
		newCategories.push(trending);
		newCategories.sort((a, b) => a.sort_index - b.sort_index);
		return newCategories;
	};

	const getNewsletterRecommendations = (categories): Promise<void> => {
		return new Promise((resolve, reject) => {
			var queriesToBeMade = categories.length;
			var queriesMade = 0;
			var discoverModels = categories.map((category) => new DiscoverModel({ category: category, isRowLoading: true }));
			setDiscoverArray(discoverModels);

			for (let category of categories) {
				let categoryId = category.name == "Trending" ? null : category.id;

				networkManager
					.getDiscoverRecommendations(categoryId)
					.then((recommendedNewsletters) => {
						if (recommendedNewsletters && recommendedNewsletters.newsletters.length > 0) {
							let sortedNewsletterIds = Array.from(recommendedNewsletters.newsletter_scores)
								.sort((a, b) => b.combined_score - a.combined_score)
								.map((newsletter) => newsletter.newsletter_id);

							let newsletterResponse = Array.from(recommendedNewsletters.newsletters);
							let sortedNewsletters = sortedNewsletterIds.map((newsletterId) => newsletterResponse.find((newsletter) => newsletter.id === newsletterId)).filter(Boolean);

							let index = discoverModels.findIndex((discoverModel) => discoverModel.category.id === category.id);
							let object = discoverModels[index];
							object.recommendedNewsletters = sortedNewsletters;
							object.isRowLoading = false;
							discoverModels[index] = object;
							setDiscoverArray([...discoverModels]);
						}
					})
					.finally(() => {
						queriesMade += 1;
						if (queriesMade == queriesToBeMade) {
							resolve();
						}
					});
			}
		});
	};

	const updateSubscribedNewsletter = (newsletter) => {
		if (!discoverArray) {
			return;
		}

		var modifedDiscoverArray = [...discoverArray];
		for (var discoverObject of modifedDiscoverArray) {
			if (!discoverObject.recommendedNewsletters) {
				continue;
			}

			if (discoverObject.recommendedNewsletters.length > 0) {
				for (var recommendedNewsletter of discoverObject.recommendedNewsletters) {
					if (recommendedNewsletter.id == newsletter.id) {
						recommendedNewsletter.is_user_subscribed = true;
					}
				}
			}
		}
		setDiscoverArray(modifedDiscoverArray);
	};

	const categoryOnClick = (e, category) => {
		const section = document.getElementById(category.name);
		if (section) {
			section.scrollIntoView({ behavior: "smooth", block: "center" });
			analyticsManager.recordEvent(kAnalyticsConstants.Discover.categorySelected, { category_id: category.id, category_name: category.name, category_index: category.sort_index ?? 0 });
		}
	};

	const alreadySubscribedOnClick = () => {
		setAlertState({ isShow: true, title: "Already subscribed", message: "You’re already subscribed to this newsletter. Unsubscribe via the normal unsubscribe link in their email.", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
	};

	const oneClickSubscribeOnClick = (newsletter, loadingCompleted) => {
		networkManager
			.postOneClickSubscribeNewsletters([newsletter.id], kAnalyticsConstants.Reference.discover)
			.then((subscribedUserMailStates) => {
				for (let userMailState of subscribedUserMailStates) {
					analyticsManager.recordEvent(kAnalyticsConstants.App.oneClickSubscribeConversion, { sender_name: userMailState.sender_name, sender_address: userMailState.sender_address, reference: kAnalyticsConstants.Reference.Discover });
				}

				setUserMailStates([...(userMailStates ?? []), ...subscribedUserMailStates]);
				updateSubscribedNewsletter(newsletter);
				loadingCompleted();
				let subscribeMessage = newsletter.name != null ? "Subscribed to " + newsletter.name : "Subscribed";
				setOcsSubscribed(subscribeMessage);
			})
			.catch((error) => {
				setAlertState({ isShow: true, title: "Something went wrong", message: "We encountered an issue - please try subscribing again later.", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
			})
			.finally(() => {
				loadingCompleted();
			});
	};

	return (
		<>
			<div>
				<Alert alertState={alertState} setAlertState={setAlertState} />
				<LottieView isShow={ocsSubscribed != null} message={ocsSubscribed} setIsShow={() => setOcsSubscribed(undefined)} />

				{isError == true ? (
					<EmptyStateView title="Well this is awkward..." description="We don’t have any newsletters here right now, check back later" alertImage={collageDiscover} />
				) : (
					<div className="container mx-auto px-12 py-5">
						<div className="flex flex-row gap-3 overflow-auto pb-5 justify-between">
							{categories &&
								categories.map((category, i) => {
									return <DiscoverCategory {...category} key={category.id} categoryOnClick={(e) => categoryOnClick(e, category)} />;
								})}
						</div>
						<div className="pb-5">
							{discoverArray &&
								discoverArray.map((discoverRow, i) => {
									return <DiscoverRow {...discoverRow} key={discoverRow.category.id} alreadySubscribedOnClick={() => alreadySubscribedOnClick} oneClickSubscribeOnClick={() => oneClickSubscribeOnClick} />;
								})}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Discover;
