import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import * as networkManager from "./networking/NetworkManager";
import Qonversion from "@qonversion/web-sdk";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";

export function logOutUser(resetDistinctId?: boolean, completion?: () => void) {
	networkManager.logOutUser().finally(() => {
		if (resetDistinctId) {
			mixpanel.reset();
		}
		localStorage.clear();
		const utmCampaign = sessionStorage.getItem(kLocalStorageKeys.Session.utmCampaign);
		const nextPath = sessionStorage.getItem(kLocalStorageKeys.Session.nextPath);
		sessionStorage.clear();
		if (utmCampaign) {
			//keep utm campaign
			sessionStorage.setItem(kLocalStorageKeys.Session.utmCampaign, utmCampaign);
		}

		if (nextPath) {
			//keep next path
			sessionStorage.setItem(kLocalStorageKeys.Session.nextPath, nextPath);
		}

		navigator.serviceWorker.ready.then((registration) => {
			registration?.pushManager?.getSubscription().then((subscription) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
		});

		indexedDB.databases().then((dbs) => {
			dbs.forEach((db) => {
				if (db.name) {
					indexedDB.deleteDatabase(db.name);
				}
			});
		});

		Sentry.configureScope((scope) => scope.setUser(null));
		const qonversionInstance = Qonversion.getSharedInstance();
		qonversionInstance.logout();

		if (completion) {
			completion();
		}
	});
}
