import SidebarWrapper from "@components/common/Sidebar/SidebarWrapper";
import { AudioProvider } from "@providers/AudioContext";
import { BookmarksProvider } from "@providers/BookmarkContext";
import { DataProvider } from "@providers/DataContext";
import { useLayout } from "@providers/LayoutContext";
import { MailsProvider } from "@providers/MailContext";
import { SidebarProvider } from "@providers/SidebarContext";
import { Outlet } from "react-router-dom";

const HomeLayout = () => {
	const { isSidebarHidden, sideBarWidth, wallpaper } = useLayout();

	return (
		<>
			<SidebarProvider>
				<BookmarksProvider>
					<DataProvider>
						<MailsProvider>
							<AudioProvider>
								<img className="h-full min-h-screen fixed w-full bottom-0 left-0 z-[-2] object-cover" src={wallpaper.image} />
								<div className={`top-0 left-0 h-full ${wallpaper.containerColor}`} style={isSidebarHidden ? { position: "fixed", width: "100%" } : { width: "calc(100% - " + sideBarWidth + "px)", left: sideBarWidth + "px", position: "fixed" }} />
								<div className="flex flex-row">
									<SidebarWrapper />
									<div style={isSidebarHidden ? { position: "relative", width: "100%" } : { width: "calc(100% - " + sideBarWidth + "px)", left: sideBarWidth + "px", position: "relative" }}>
										<Outlet />
									</div>
								</div>
							</AudioProvider>
						</MailsProvider>
					</DataProvider>
				</BookmarksProvider>
			</SidebarProvider>
		</>
	);
};

export default HomeLayout;
