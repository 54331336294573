import { NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";

export class FiltersService {
	private queries: FiltersService.QueryBuilder[];

	constructor(queries: FiltersService.QueryBuilder[]) {
		this.queries = queries;
	}

	syncFilters(completion: (responseModel: FiltersService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();

		let requestArray: FiltersService.RequestModel[] = [];
		let responseArray: FiltersService.ResponseModel[] = [];
		const promises: Promise<void>[] = [];

		for (const requestModel of this.queries) {
			const promise = new Promise<void>((innerResolve, innerReject) => {
				if (requestModel.mailboxProfile.type !== NLMailboxProfileType.Internal) {
					oauthSessionManager
						.fetchAccessTokenForMailboxProfile(requestModel.mailboxProfile.id, requestModel.mailboxProfile.type)
						.then((accessToken) => {
							const request = new FiltersService.RequestModel(requestModel.mailboxProfile.id, accessToken);
							requestArray.push(request);
							innerResolve();
						})
						.catch((error) => {
							if (error instanceof Error && error.message === "noInternetError") {
								innerReject(error);
							} else {
								var userMailResponseModel = new FiltersService.ResponseModel(requestModel.mailboxProfile.id);
								userMailResponseModel.error = oauthSessionManager.generateSignInRequiredError(requestModel.mailboxProfile.id, error);
								responseArray.push(userMailResponseModel);
								innerResolve();
							}
						});
				}
			});
			promises.push(promise);
		}

		Promise.all(promises)
			.then(() => {
				if (requestArray.length === 0) {
					completion(responseArray, undefined);
					return;
				}

				this.makeRequests(requestArray, responseArray, completion);
			})
			.catch((error) => {
				completion(responseArray, error);
			});
	}

	private makeRequests(requestArray: FiltersService.RequestModel[], responseArray: FiltersService.ResponseModel[], completion: (responseModel: FiltersService.ResponseModel[] | undefined, error: Error | undefined) => void) {
		networkManager
			.syncFilters(requestArray)
			.then((jsonData) => {
				var responseModels = responseArray;

				for (let mailboxProfileId in jsonData) {
					let responseData = jsonData[mailboxProfileId];
					var responseModel = new FiltersService.ResponseModel(mailboxProfileId);

					if (responseData["error"]) {
						responseModel.error = MailboxError.returnError(responseData["error"], mailboxProfileId);
					}

					responseModels.push(responseModel);
				}

				completion(responseModels, undefined);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}
}

export namespace FiltersService {
	export class RequestModel {
		mailbox_profile_id!: string;
		access_token!: string;

		constructor(mailboxProfileId: string, accessToken: string) {
			this.mailbox_profile_id = mailboxProfileId;
			this.access_token = accessToken;
		}
	}

	export class ResponseModel {
		mailboxProfileId!: string;
		error?: Error | null;

		constructor(mailboxProfileId: string) {
			this.mailboxProfileId = mailboxProfileId;
		}
	}

	export class QueryBuilder {
		mailboxProfile: NLMailboxProfile;

		constructor(mailboxProfile: NLMailboxProfile) {
			this.mailboxProfile = mailboxProfile;
		}
	}
}
