import { NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";

export class ReplyMessageService {
	private mailboxProfile: NLMailboxProfile;
	private messageId: string;
	private replyHtml: string;

	constructor(mailboxProfile: NLMailboxProfile, messageId: string, replyHtml: string) {
		this.mailboxProfile = mailboxProfile;
		this.messageId = messageId;
		this.replyHtml = replyHtml;
	}

	replyMessage(completion: (error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();
		let request: ReplyMessageService.RequestModel | undefined = undefined;

		const promise = new Promise<void>((innerResolve, innerReject) => {
			if (this.mailboxProfile.type !== NLMailboxProfileType.Internal) {
				oauthSessionManager
					.fetchAccessTokenForMailboxProfile(this.mailboxProfile.id, this.mailboxProfile.type)
					.then((accessToken) => {
						request = new ReplyMessageService.RequestModel(this.mailboxProfile.id, this.messageId, this.replyHtml, accessToken);
						innerResolve();
					})
					.catch((error) => {
						innerReject(error);
						return;
					});
			} else {
				request = new ReplyMessageService.RequestModel(this.mailboxProfile.id, this.messageId, this.replyHtml, null);
				innerResolve();
			}
		});

		Promise.all([promise])
			.then(() => {
				if (!request) {
					completion(undefined);
					return;
				}
				this.makeRequests(request, completion);
			})
			.catch((error) => {
				completion(error);
			});
	}

	private makeRequests(request: ReplyMessageService.RequestModel, completion: (error: Error | undefined) => void) {
		networkManager
			.replyMessage(request)
			.then((jsonData) => {
				if (jsonData.error) {
					const mailboxError = MailboxError.returnError(jsonData.error, jsonData.mailbox_profile_id);
					completion(mailboxError);
					return;
				}
                completion(undefined);
			})
			.catch((error) => {
				completion(error);
			});
	}
}

export namespace ReplyMessageService {
	export class RequestModel {
		access_token: string | null;
		mailbox_profile_id!: string;
		message_id!: string;
		reply_html!: string;

		constructor(mailboxProfileId: string, messageId: string, reply_html: string, accessToken: string | null) {
			this.mailbox_profile_id = mailboxProfileId;
			this.message_id = messageId;
			this.reply_html = reply_html;
			this.access_token = accessToken;
		}
	}
}
