import { NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import { useAuth } from "@providers/AuthContext";
import { ReactComponent as GmailIcon } from "@images/gmail_icon.svg";
import { ReactComponent as OutlookIcon } from "@images/outlook_logo.svg";
import { ReactComponent as MecoIcon } from "@images/meco_app_logo.svg";
import { ReactComponent as PlusIcon } from "@images/plus_icon.svg";
import SectionLayout from "@layouts/SectionLayout";
import NewMailboxModal from "../connected-mailboxes/NewMailboxModal";
import { ReactNode, useState } from "react";
import Alert, { AlertState } from "@components/common/Alert";
import MailboxDetailModal from "../connected-mailboxes/MailboxDetailModal";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import ResyncMailboxModal from "@components/common/ResyncMailboxModal";

const ConnectedMailboxes = () => {
	const { authUser } = useAuth();
	const [isShowNewMailboxModal, setShowNewMailboxModal] = useState(false);
	const [selectedMailboxProfile, setSelectedMailboxProfile] = useState<NLMailboxProfile | undefined>(undefined);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const oauthSessionManager = OauthSessionManager.sharedInstance();
	const [showResyncMailbox, setShowResyncMailbox] = useState<NLMailboxProfile | undefined>(undefined);

	const getMailboxName = (type: string, isDefault: boolean | undefined): string => {
		const tempIsDefault = isDefault ?? true;
		const mailboxProfileType = NLMailboxProfileType.getByValue(type);

		switch (mailboxProfileType) {
			case NLMailboxProfileType.Gmail:
				return tempIsDefault ? "Gmail (Primary)" : "Gmail";
			case NLMailboxProfileType.Outlook:
				return tempIsDefault ? "Outlook (Primary)" : "Outlook";
			case NLMailboxProfileType.Internal:
				return tempIsDefault ? "Meco Inbox (Primary)" : "Meco Inbox";
			default:
				return "Undefined";
		}
	};

	const getMailboxLogo = (type: string) => {
		const mailboxProfileType = NLMailboxProfileType.getByValue(type);

		switch (mailboxProfileType) {
			case NLMailboxProfileType.Gmail:
				return <GmailIcon className="p-2" />;
			case NLMailboxProfileType.Outlook:
				return <OutlookIcon className="p-2" />;
			case NLMailboxProfileType.Internal:
				return <MecoIcon className="p-2" />;
			default:
				return null;
		}
	};

	const addNewMailboxOnClick = () => {
		//If we put a limit, this is the place.
		setShowNewMailboxModal(true);
	};

	const renderFooter = (mailboxProfile: NLMailboxProfile): ReactNode => {
		if (mailboxProfile.type === NLMailboxProfileType.Internal) {
			return (
				<>
					<div className="bg-primary-100 h-[1px] w-full" />
					<div className="text-sm font-primary font-medium text-primary text-left">Use this email address when subscribing to newsletters. All newsletters sent to this address will appear here in Meco.</div>
				</>
			);
		}

		if (oauthSessionManager.unauthorizedMailboxProfileIds.has(mailboxProfile.id) || oauthSessionManager.notProMailboxProfileIds.has(mailboxProfile.id)) {
			return (
				<>
					<div className="bg-primary-100 h-[1px] w-full" />
					<div className="text-sm font-primary font-medium text-primary text-left">⚠️ Something requires your attention within this account. Please click for more details.</div>
				</>
			);
		}
	};

	return (
		<>
			<SectionLayout
				id="connected_mailboxes"
				sectionTitle="Connected Mailboxes"
				sectionDescription="Add your existing newsletters to Meco in seconds by connecting your Gmail or Outlook inboxes."
				sectionRightDiv={
					<button className="flex flex-row justify-center items-center gap-2 bg-success-green hover:bg-success-green/80 transition duration-300 ease-in-out px-3 py-2 rounded-full shrink-0" onClick={() => addNewMailboxOnClick()}>
						<PlusIcon className="fill-white w-[16px]" />
						<div className="font-primary font-medium text-base text-white">Add New Mailbox</div>
					</button>
				}>
				<NewMailboxModal isShow={isShowNewMailboxModal} onClose={() => setShowNewMailboxModal(false)} />
				{showResyncMailbox && <ResyncMailboxModal mailboxProfile={showResyncMailbox} onClose={() => setShowResyncMailbox(undefined)} />}
				<MailboxDetailModal
					mailboxProfile={selectedMailboxProfile}
					onClose={() => setSelectedMailboxProfile(undefined)}
					onResync={(mailboxProfile) => {
						setSelectedMailboxProfile(undefined);
						setShowResyncMailbox(mailboxProfile);
					}}
				/>
				<Alert alertState={alertState} setAlertState={setAlertState} />
				<div className="flex flex-row gap-4 flex-wrap">
					{authUser!.mailbox_profiles
						.sort((a, b) => (b.is_default ?? false ? 1 : 0) - (a.is_default ?? false ? 1 : 0))
						.map((mailboxProfile) => {
							return (
								<button key={mailboxProfile.id} className="flex flex-col bg-surface hover:bg-surface-100 border border-primary-100 duration-300 transition ease-in-out w-full item rounded-[15px] p-3 gap-3" onClick={() => setSelectedMailboxProfile(mailboxProfile)}>
									<div className="flex flex-row gap-3">
										<div className="w-[60px] h-[60px] bg-primary-100 border border-primary-100 rounded-md flex flex-row justify-center">{getMailboxLogo(mailboxProfile.type)}</div>
										<div className="flex flex-col justify-center">
											<div className="font-primary font-medium text-base text-primary-500 text-left">
												{getMailboxName(mailboxProfile.type, mailboxProfile.is_default)} – {mailboxProfile.name}
											</div>
											<div className="font-primary font-medium text-base text-primary text-left">{mailboxProfile.email_address}</div>
										</div>
									</div>
									{renderFooter(mailboxProfile)}
								</button>
							);
						})}
				</div>
			</SectionLayout>
		</>
	);
};

export default ConnectedMailboxes;
