import { useState, useEffect, FC, Fragment, ReactNode } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { useHotkeys } from "react-hotkeys-hook";
import IconButton from "@components/common/IconButton";
import { ReactComponent as GmailIcon } from "@images/gmail_icon.svg";
import { ReactComponent as OutlookIcon } from "@images/outlook_logo.svg";
import { ReactComponent as MecoIcon } from "@images/meco_app_logo.svg";
import { useAuth } from "@providers/AuthContext";
import { NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import Alert, { AlertState } from "@components/common/Alert";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { updateMailboxProfile, deleteMailboxProfile } from "@utils/managers/networking/NetworkManager";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import { useData } from "@providers/DataContext";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { useMailsDispatch } from "@providers/MailContext";
import { kStringConstants } from "@utils/constants/StringConstants";
import { kErrorConstants } from "@utils/constants/ErrorConstants";
import { generateToast } from "@utils/managers/ToastManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import PaywallModal from "@components/common/PaywallModal";
import { usePremium } from "@providers/PremiumContext";
import { recordEvent } from "@utils/managers/AnalyticsManager";

interface MailboxDetailModalProps {
	mailboxProfile: NLMailboxProfile | undefined;
	onClose: () => void;
	onResync: (mailboxProfile: NLMailboxProfile) => void;
}

const MailboxDetailModal: FC<MailboxDetailModalProps> = ({ mailboxProfile, onClose, onResync }) => {
	const { authUser, setAuthUser } = useAuth();
	const { fetchFreshData, setDigestNeedsReloading } = useData();
	const mailsDispatch = useMailsDispatch();
	const [tempMailboxProfile, setTempMailboxProfile] = useState<NLMailboxProfile | undefined>(undefined);
	const [isDefault, setIsDefault] = useState(false);
	const [mailboxName, setMailboxName] = useState<string | undefined>(undefined);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});
	const oauthSessionManager = OauthSessionManager.sharedInstance();
	const [showPaywall, setShowPaywall] = useState<boolean>(false);
	const { isPremium } = usePremium();

	useHotkeys("Escape", () => onModalClose());

	useEffect(() => {
		setTempMailboxProfile(mailboxProfile);
		setIsDefault(mailboxProfile?.is_default ?? false);
		setMailboxName(mailboxProfile?.name);
	}, [mailboxProfile]);

	useEffect(() => {
		if (!tempMailboxProfile) {
			return undefined;
		}

		var hasMadeChanges = false;
		if (mailboxProfile?.name !== mailboxName) {
			if (mailboxProfile) {
				recordEvent("MCO - Mailbox Renamed", { mailbox_profile_id: mailboxProfile.id, mailbox_profile_email: mailboxProfile.email_address, old_mailbox_name: mailboxProfile.name ?? "", new_mailbox_name: mailboxName ?? "" });
			}
			hasMadeChanges = true;
		}
		if (mailboxProfile?.is_default !== isDefault) {
			if (mailboxProfile) {
				recordEvent("MCO - Mailbox Primary Status Changed", { mailbox_profile_id: mailboxProfile.id, mailbox_profile_email: mailboxProfile.email_address });
			}
			hasMadeChanges = true;
		}

		if (hasMadeChanges) {
			const tempMailboxProfileCopy: NLMailboxProfile = JSON.parse(JSON.stringify(tempMailboxProfile));
			tempMailboxProfileCopy.name = mailboxName;
			tempMailboxProfileCopy.is_default = isDefault;
			setTempMailboxProfile(tempMailboxProfileCopy);

			updateMailboxProfile(tempMailboxProfileCopy).then((profile) => {
				setAuthUser(profile);
			});
		}
	}, [isDefault, mailboxName]);

	const onModalClose = () => {
		onClose();
	};

	const getMailboxLogo = (type: string) => {
		const mailboxProfileType = NLMailboxProfileType.getByValue(type);

		switch (mailboxProfileType) {
			case NLMailboxProfileType.Gmail:
				return <GmailIcon className="w-[80px] h-[80px] m-auto p-2" />;
			case NLMailboxProfileType.Outlook:
				return <OutlookIcon className="w-[80px] h-[80px] m-auto p-2" />;
			case NLMailboxProfileType.Internal:
				return <MecoIcon className="w-[80px] h-[80px] m-auto p-2" />;
			default:
				return null;
		}
	};

	const copyToClipboard = (emailAddress: string) => {
		navigator.clipboard.writeText(emailAddress);
		generateToast({ status: "success", message: "Email address copied", position: "bottom-center" });
	};

	const getMailboxTypeName = (type: string) => {
		const mailboxProfileType = NLMailboxProfileType.getByValue(type);

		switch (mailboxProfileType) {
			case NLMailboxProfileType.Gmail:
				return "Gmail";
			case NLMailboxProfileType.Outlook:
				return "Outlook";
			case NLMailboxProfileType.Internal:
				return "Meco";
			default:
				return "";
		}
	};

	const disconnectFromMecoOnClick = (mailboxProfile: NLMailboxProfile) => {
		const mailboxTypeName = getMailboxTypeName(mailboxProfile.type);
		setAlertState({ isShow: true, title: "Are you sure you want to disconnect this " + mailboxTypeName + "?", message: "Any newsletters in Meco associated with this " + mailboxTypeName + " address will be reverted back to your " + mailboxTypeName + " inbox.", actionButton: "Disconnect", dismissButton: "Cancel", actionButtonAction: () => disconnectMailbox(mailboxProfile) });
	};

	const disconnectMailbox = (mailboxProfile: NLMailboxProfile) => {
		setLoadingState({ isLoading: true, message: "Give us a moment" });

		const deepCopyMailboxProfile: NLMailboxProfile = JSON.parse(JSON.stringify(mailboxProfile));

		const mailboxTypeName = getMailboxTypeName(mailboxProfile.type);
		oauthSessionManager
			.fetchAccessTokenForMailboxProfile(mailboxProfile.id, mailboxProfile.type)
			.then((accessToken) => {
				return deleteMailboxProfile(mailboxProfile.id, accessToken);
			})
			.then((profile) => {
				mailsDispatch({ type: "CLEANUP" });
				setLoadingState({ isLoading: false });
				setAuthUser(profile);
				fetchFreshData();
				setDigestNeedsReloading(true);
				generateToast({ status: "success", message: mailboxName + " disconnected", position: "bottom-center" });
				recordEvent("MCO - Mailbox Disconnected", { mailbox_profile_id: deepCopyMailboxProfile.id, mailbox_profile_email: mailboxProfile.email_address, mailboxox_type: getMailboxTypeName(mailboxProfile.type) });

				if (profile.email === mailboxProfile.email_address) {
					setAlertState({ isShow: true, title: "Important reminder", message: "You’ll still need to use this " + mailboxTypeName + " address (" + profile.email + ") when you log in to Meco as it is the email you signed up with.", actionButton: "OK", dismissButton: null, actionButtonAction: () => onModalClose() });
				} else {
					onModalClose();
				}
			})
			.catch((_error) => {
				setLoadingState({ isLoading: false });
				fetchFreshData();
				setDigestNeedsReloading(true);
				setAlertState({ isShow: true, title: "We couldn't make changes in your " + mailboxTypeName, message: "Your account has been successfully removed however we were unable to make changes to your " + mailboxTypeName + " account. Please manually remove the Meco label and any filters associated with the Meco label from your " + mailboxTypeName + " account.", actionButton: "OK", dismissButton: null, actionButtonAction: () => onModalClose() });
			});
	};

	const renderMailboxError = (mailboxProfile: NLMailboxProfile): ReactNode => {
		const mailboxTypeName = getMailboxTypeName(mailboxProfile.type);
		if (oauthSessionManager.notProMailboxProfileIds.has(mailboxProfile.id) && !isPremium()) {
			return (
				<div className="flex flex-col gap-3 p-3 bg-primary rounded-xl items-center justify-center">
					<div className="flex flex-col">
						<div className="font-[none] text-[32px] text-center">⚠️</div>
						<div className="text-secondary font-medium font-primary text-base text-center">
							Upgrade to PRO to continue reading newsletters from this {mailboxTypeName}, If you no longer wish to read from this {mailboxTypeName}, please disconnect it below.
						</div>
					</div>
					<button className={`flex gap-2 cta-button w-full !max-w-full shadow-2xl !bg-success-green hover:bg-success-green/80`} onClick={() => upgradeToPro()}>
						<div>Upgrade to PRO</div>
					</button>
				</div>
			);
		}

		if (oauthSessionManager.unauthorizedMailboxProfileIds.has(mailboxProfile.id)) {
			return (
				<div className="flex flex-col gap-3 p-3 bg-primary rounded-xl items-center justify-center">
					<div className="flex flex-col">
						<div className="font-[none] text-[32px] text-center">⚠️</div>
						<div className="text-secondary font-medium font-primary text-base text-center">
							{mailboxTypeName} address is no longer connected. Please reconnect this {mailboxTypeName} address to continue to read and receive your newsletters in Meco.
						</div>
					</div>
					<button className={`flex gap-2 cta-button w-full !max-w-full shadow-2xl !bg-success-green hover:bg-success-green/80`} onClick={() => reconnectMailbox(mailboxProfile)}>
						<div>Reconnect {mailboxTypeName}</div>
					</button>
				</div>
			);
		}
	};

	const reconnectMailbox = (mailboxProfile: NLMailboxProfile) => {
		const mailboxProfileType = NLMailboxProfileType.getByValue(mailboxProfile.type);
		switch (mailboxProfileType) {
			case NLMailboxProfileType.Gmail:
				oauthSessionManager
					.integrateWithGmail(mailboxProfile!.email_address)
					.then((_profile) => {
						recordEvent("GMI - Reconnect Successful", { is_reconnect_screen: false });
						window.location.reload();
					})
					.catch((error) => {
						recordEvent("GMI - Reconnect Error", { error: error?.message ?? "", is_reconnect_screen: false });
						handleSignInError(error);
					});
				break;
			case NLMailboxProfileType.Outlook:
				oauthSessionManager
					.integrateWithOutlook(mailboxProfile!.email_address)
					.then((_profile) => {
						recordEvent("OUT - Reconnect Successful", { is_reconnect_screen: false });
						window.location.reload();
					})
					.catch((error) => {
						recordEvent("OUT - Reconnect Error", { error: error?.message ?? "", is_reconnect_screen: false });
						handleSignInError(error);
					});
				break;
		}
	};

	const upgradeToPro = () => {
		setShowPaywall(true);
	};

	const handleSignInError = (error) => {
		var alertTitle = kStringConstants.Common.errorAlertTitle;
		var alertMessage = kStringConstants.Common.errorAlertMessage;

		if (error === kErrorConstants.gmailIntegration.permissionError) {
			alertTitle = "Please allow Gmail permissions";
			alertMessage = "We require these permissions to add and manage newsletters with Meco. We designed Meco with your privacy in mind. Your emails remain private and are only accessible by you.";
		}

		if (error === kErrorConstants.gmailIntegration.accountMismatch) {
			alertTitle = "Incorrect Gmail address";
			alertMessage = "The address you selected did not match the email above. Please retry with the correct Gmail.";
		}

		setAlertState({ isShow: true, title: alertTitle, message: alertMessage, actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<LoadingView loadingState={loadingState} />
			<PaywallModal isShow={showPaywall} onClose={() => setShowPaywall(false)} analyticsReference={mailboxProfile?.type === NLMailboxProfileType.Gmail ? kAnalyticsConstants.Reference.Paywall.gmailIntegration : kAnalyticsConstants.Reference.Paywall.outlookIntegration} />
			<Transition as={Fragment} show={mailboxProfile !== undefined}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child className="h-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							{mailboxProfile && (
								<Dialog.Panel className="w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
									<div className="absolute w-full bg-surface backdrop-blur-md top-0 left-0 flex flex-row justify-between items-center p-4 pb-3 border-b border-primary-200 z-20">
										<div className="text-primary font-medium font-primary text-xl">{mailboxProfile.name}</div>
										<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onModalClose()} />
									</div>
									<div className="flex flex-col gap-4 text-left h-full p-4 py-[105px] bg-secondary overflow-auto">
										<div>{getMailboxLogo(mailboxProfile.type)}</div>
										{renderMailboxError(mailboxProfile)}
										<div className="flex flex-row justify-between items-center bg-surface border transition ease-in-out duration-300 border-primary-200 rounded-xl p-3 w-full">
											<div className="text-primary font-medium font-primary text-base">Mailbox Name</div>
											<input type="text" value={mailboxName} onChange={(e) => setMailboxName(e.target.value)} className="bg-transparent text-primary font-regular font-primary text-sm text-right !p-0" />
										</div>
										<div className="flex flex-row justify-between items-center bg-surface border transition ease-in-out duration-300 border-primary-200 rounded-xl p-3 w-full">
											<div className="text-primary font-medium font-primary text-base">Email Address</div>
											<div className="flex flex-row items-center gap-2">
												{mailboxProfile.type === NLMailboxProfileType.Internal ? (
													<button className="font-regular font-primary text-sm text-success-green hover:text-primary transition duration-300 ease-in-out" title="Copy email address" onClick={() => copyToClipboard(mailboxProfile.email_address)}>
														{mailboxProfile.email_address}
													</button>
												) : (
													<div className="text-primary font-regular font-primary text-sm">{mailboxProfile.email_address}</div>
												)}
											</div>
										</div>
										<div className="flex flex-col gap-2">
											<div className="flex flex-row justify-between items-center bg-surface border transition ease-in-out duration-300 border-primary-200 rounded-xl p-3 w-full">
												<div className="text-primary font-medium font-primary text-base">Primary Mailbox</div>
												<Switch disabled={authUser!.mailbox_profiles.length === 1} checked={isDefault} onChange={setIsDefault} className={`${isDefault ? "bg-success-green" : "bg-primary-200"} ${authUser!.mailbox_profiles.length === 1 ? "opacity-50" : "opacity-100"} relative inline-flex h-[30px] w-[55px] items-center rounded-full`}>
													<span className={`${isDefault ? "translate-x-[26px]" : "translate-x-[2px]"} inline-block h-[28px] w-[28px] transform rounded-full bg-white transition`} />
												</Switch>
											</div>
											<div className="text-primary-500 font-regular font-primary text-xs text-center">Your primary mailbox will be used when you subscribe to any new newsletters within the Meco app.</div>
										</div>
										{mailboxProfile.type !== NLMailboxProfileType.Internal && (
											<>
												{!oauthSessionManager.notProMailboxProfileIds.has(mailboxProfile.id) && !oauthSessionManager.unauthorizedMailboxProfileIds.has(mailboxProfile.id) && (
													<div className="flex flex-col gap-3 bg-surface p-3 rounded-xl border border-primary-100">
														<div className="text-primary-500 font-regular font-primary text-xs text-center">
															If you're not seeing new newsletters coming into Meco or newsletters added to Meco arriving on {getMailboxTypeName(mailboxProfile.type)}, you can fix this by resyncing your {getMailboxTypeName(mailboxProfile.type)} with Meco. If you continue having issues, email us at team@meco.app.
														</div>
														<button className="text-success-green hover:text-success-green/80 transition ease-in-out duration-300 font-medium font-primary text-base text-center" onClick={() => onResync(mailboxProfile)}>
															Resync {getMailboxTypeName(mailboxProfile.type)}
														</button>
													</div>
												)}

												<div className="flex flex-col gap-3 bg-surface p-3 rounded-xl border border-primary-100">
													<div className="text-primary-500 font-regular font-primary text-xs text-center">
														Disconnect this mailbox if you no longer want to receive newsletters from this {getMailboxTypeName(mailboxProfile.type)} within Meco. Once disconnected, you'll receive these newsletters directly in your {getMailboxTypeName(mailboxProfile.type)} inbox.
													</div>
													<button className="text-brand-red hover:text-brand-red/80 transition ease-in-out duration-300 font-medium font-primary text-base text-center" onClick={() => disconnectFromMecoOnClick(mailboxProfile)}>
														Disconnect from Meco
													</button>
												</div>
											</>
										)}
									</div>
								</Dialog.Panel>
							)}
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default MailboxDetailModal;
