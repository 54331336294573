import React, { createContext, useContext, ReactNode, useState, useEffect } from "react";
import { NLWallpaper } from "@models/Models";
import sunsetWallpaper from "@images/wallpapers/wallpaper1.jpg";
import GreeneryWallpaper from "@images/wallpapers/wallpaper2.jpg";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import createPersistedState from "use-persisted-state";
import { ThemeState } from "@models/Enums";
import { Outlet } from "react-router-dom";

interface LayoutContextProps {
	isSidebarHidden: boolean;
	sideBarWidth: number;
	setSidebarHidden: (isSidebarHidden: boolean) => void;
	isMailListHidden: boolean;
	mailListWidth: number;
	setMailListHidden: (isMailListHidden: boolean) => void;
	navigationBarHeight: number;
	searchNavigationBarHeight: number;
	isSettingsActive: boolean;
	setSettingsActive: (settingsActive: boolean) => void;
	wallpaper: NLWallpaper;
	setWallpaper: (wallpaper: NLWallpaper) => void;
    wallpapers: NLWallpaper[];
	setTheme: (theme: ThemeState) => void;
	theme: ThemeState;
	isAudioPlayerActive: boolean;
	setAudioPlayerActive: (isActive: boolean) => void;
}

const LayoutContext = createContext<LayoutContextProps | undefined>(undefined);
const useWallpaper = createPersistedState(kLocalStorageKeys.AppSettings.activeWallpaper);
const useTheme = createPersistedState(kLocalStorageKeys.AppSettings.theme);

export const LayoutProvider = () => {
	const sideBarWidth = 300;
	const mailListWidth = 400;
	const navigationBarHeight = 75;
	const searchNavigationBarHeight = 140;
	const sunsetWallpaper: NLWallpaper = { imageId: 0, image: "https://meco-static-1337.s3.us-east-2.amazonaws.com/media/sunset.jpg", sidebarColor: "bg-sidebar/70", containerColor: "bg-secondary" };
	const mountainWallpaper: NLWallpaper = { imageId: 1, image: "https://meco-static-1337.s3.us-east-2.amazonaws.com/media/mountain.jpg", sidebarColor: "bg-sidebar/80", containerColor: "bg-secondary" };
	const greeneryWallpaper: NLWallpaper = { imageId: 2, image: "https://meco-static-1337.s3.us-east-2.amazonaws.com/media/wallpaper2.jpg", sidebarColor: "bg-sidebar/90", containerColor: "bg-secondary" };
	const jungleWallpaper: NLWallpaper = { imageId: 3, image: "https://meco-static-1337.s3.us-east-2.amazonaws.com/media/wallpaper5.jpg", sidebarColor: "bg-sidebar/80", containerColor: "bg-secondary" };
	const sandWallpaper: NLWallpaper = { imageId: 4, image: "https://meco-static-1337.s3.us-east-2.amazonaws.com/media/mountain2.jpg", sidebarColor: "bg-sidebar/70", containerColor: "bg-secondary" };
	const greenShapeWallpaper: NLWallpaper = { imageId: 5, image: "https://meco-static-1337.s3.us-east-2.amazonaws.com/media/green_shape.jpg", sidebarColor: "bg-sidebar/90", containerColor: "bg-secondary" };
	const auroraWallpaper: NLWallpaper = { imageId: 6, image: "https://meco-static-1337.s3.us-east-2.amazonaws.com/media/aurora.jpg", sidebarColor: "bg-sidebar/70", containerColor: "bg-secondary" };
	const beachWallpaper: NLWallpaper = { imageId: 7, image: "https://meco-static-1337.s3.us-east-2.amazonaws.com/media/beach.jpg", sidebarColor: "bg-sidebar/80", containerColor: "bg-secondary" };

	const [isSidebarHidden, setSidebarHidden] = React.useState<boolean>(false);
	const [isMailListHidden, setMailListHidden] = React.useState<boolean>(false);
	const [isSettingsActive, setSettingsActive] = React.useState<boolean>(false);
	const wallpapers: NLWallpaper[] = [sunsetWallpaper, mountainWallpaper, sandWallpaper, beachWallpaper, greeneryWallpaper, jungleWallpaper, auroraWallpaper, greenShapeWallpaper];
	const [wallpaper, setWallpaper] = useWallpaper(sunsetWallpaper);
	const [theme, setTheme] = useTheme(ThemeState.auto);
	const [isAudioPlayerActive, setAudioPlayerActive] = React.useState<boolean>(false);

	useEffect(() => {
		toggleTheme();
	}, [])

	useEffect(() => {
		toggleTheme();
	}, [theme])

	const toggleTheme = () => {
		var themeToBeSet = theme;
		if (theme === ThemeState.auto) {
			const now = new Date();
			const currentHour = now.getHours()
			const isNightTime = currentHour >= 18 || currentHour <= 5;
			themeToBeSet = isNightTime ? ThemeState.dark : ThemeState.light;
		}
		document.documentElement.setAttribute("data-theme", themeToBeSet);
	}

	const providerValue: LayoutContextProps = {
		isSidebarHidden,
		sideBarWidth,
		setSidebarHidden,
		isMailListHidden,
		mailListWidth,
		setMailListHidden,
		navigationBarHeight,
		searchNavigationBarHeight,
		isSettingsActive,
		setSettingsActive,
		wallpaper,
		setWallpaper,
        wallpapers,
		theme,
		setTheme,
		isAudioPlayerActive,
		setAudioPlayerActive
	};

	return <LayoutContext.Provider value={providerValue}><Outlet /></LayoutContext.Provider>;
};

export const useLayout = () => {
	const context = useContext(LayoutContext);
	if (!context) {
		throw new Error("useLayout must be used within an LayoutProvider");
	}
	return context;
};
