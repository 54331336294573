import { useState, useEffect, useRef } from "react";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import animation from "@images/checkmark.json";

function LottieView(props) {
    const playerRef = useRef();

    useEffect(() => {
		if (props.isShow) {
            playerRef.current.stop();
            playerRef.current.play(0);

		}
	}, [props.isShow]);

    const onAnimationComplete = () => {
        setTimeout(() => {
            props.setIsShow();
        }, 451);
    }

	return <div className={`fixed left-0 top-0 bg-black bg-opacity-50 w-screen h-screen flex justify-center items-center transition-all duration-450 ease-in-out z-30 overflow-unset ${props.isShow ? "block" : "hidden"}`}>
        <div className="bg-white/70 rounded-[10px] p-3 backdrop-blur w-[250px]">
            <Lottie className="max-w-[280px] m-auto" animationData={animation} lottieRef={playerRef} loop={false} autoPlay={false} onComplete={onAnimationComplete}/>
            <div className="font-primary font-medium text-lg text-black text-center">{props.message}</div>
        </div>
    </div>;
}

export default LottieView;
