import { ReactComponent as GmailIcon } from "@images/gmail_icon.svg";
import { ReactComponent as OutlookIcon } from "@images/outlook_logo.svg";
import { ReactComponent as Checkmark } from "@images/check_mark_selected.svg";
import { NLMailboxProfileType } from "@models/Models";

function ReconnectMailboxView({ mailboxProfile, isReconnected, integrationOnClick }) {
	const getMailboxName = (type: string, isDefault: boolean): string => {
		const mailboxProfileType = NLMailboxProfileType.getByValue(type);

		switch (mailboxProfileType) {
			case NLMailboxProfileType.Gmail:
				return isDefault ? "Gmail (Primary)" : "Gmail";
			case NLMailboxProfileType.Outlook:
				return isDefault ? "Outlook (Primary)" : "Outlook";
			default:
				return "Undefined";
		}
	};

	const getMailboxLogo = (type: string) => {
		const mailboxProfileType = NLMailboxProfileType.getByValue(type);

		switch (mailboxProfileType) {
			case NLMailboxProfileType.Gmail:
				return <GmailIcon className="p-2" />;
			case NLMailboxProfileType.Outlook:
				return <OutlookIcon className="p-2" />;
			default:
				return null;
		}
	};

	const getButtonTitle = (type: string) => {
		const mailboxProfileType = NLMailboxProfileType.getByValue(type);

		if (isReconnected) {
			return "Reconnected";
		}

		switch (mailboxProfileType) {
			case NLMailboxProfileType.Gmail:
				return "Reconnect Gmail";
			case NLMailboxProfileType.Outlook:
				return "Reconnect Outlook";
			default:
				return null;
		}
	};

	return (
		<div className="flex flex-col bg-white/10 w-full rounded-[15px] p-4 gap-3">
			<div className="flex flex-row gap-3">
				<div className="w-[60px] h-[60px] bg-force-primary-light/10 border border-force-primary-light/20 rounded-md flex flex-row justify-center">{getMailboxLogo(mailboxProfile.type)}</div>
				<div className="flex flex-col justify-center">
					<div className="font-primary font-medium text-base  text-force-primary-light/50">
						{getMailboxName(mailboxProfile.type, mailboxProfile.is_default)} – {mailboxProfile.name}
					</div>
					<div className="font-primary font-medium text-lg text-force-primary-light">{mailboxProfile.email_address}</div>
				</div>
			</div>
			<button disabled={isReconnected} className={`flex gap-2 cta-button w-full !max-w-full shadow-2xl ${isReconnected ? "!bg-success-green/50" : "!bg-success-green"}`} onClick={() => integrationOnClick(mailboxProfile)}>
				<div>{getButtonTitle(mailboxProfile.type)}</div>
                {isReconnected ? <Checkmark className="w-[20px]"/> : null }
			</button>
		</div>
	);
}

export default ReconnectMailboxView;
