import { NLPurchaseProduct } from "@models/Models";
import { kAppConstants } from "./AppConstants";

export const yearlyPlan: NLPurchaseProduct = {
	price_id: kAppConstants.appStage === "production" ? "price_1P5nQUGM9IN4MI0rV4SOVOxt" : "price_1P5nXIGM9IN4MI0rOj7SkRIk",
	product_name: "Yearly Plan",
	monthly_price: "$2.92",
	total_price: "$34.99",
	trial_days: 7,
	term: "year",
	badge: "BEST VALUE",
};

export const monthlyPlan: NLPurchaseProduct = {
	price_id: kAppConstants.appStage === "production" ? "price_1P5nPrGM9IN4MI0rieJ4KOcU" : "price_1P5nWzGM9IN4MI0r2hLhUNQw",
	product_name: "Monthly Plan",
	monthly_price: "$3.99",
	total_price: "$3.99",
	term: "month",
};
