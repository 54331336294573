import { useEffect, useState } from "react";
import collageDiscover from "@images/collage_discover.png";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { NLAudioSection, AudioModel, AudioProfileError, AudioRowType, NLAudioEpisode } from "@models/Models";
import Alert, { AlertState } from "@components/common/Alert";
import EmptyStateView from "@components/common/EmptyStateView";
import AudioHeader from "./AudioHeader";
import AudioPlayer from "./AudioPlayer";
import { useAuth } from "@providers/AuthContext";
import AudioGeneralRow from "./audio-rows/AudioGeneralRow";
import { useLayout } from "@providers/LayoutContext";
import { useAudio, useAudioDispatch } from "@providers/AudioContext";
import AudioUserCard from "./audio-rows/AudioUserCard";
import AudioErrorBanner from "./audio-rows/AudioErrorBanner";
import AudioActionCard from "./audio-rows/AudioActionCard";
import AudioEmptyCard from "./audio-rows/AudioEmptyCard";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import { useLocation } from "react-router-dom";
import AudioSettingsModal, { AudioSettingsState } from "./AudioSettingsModal";

const Audio = (props) => {
	const [audioArray, setAudioArray] = useState<AudioModel[] | undefined>(undefined);
	const [isError, setError] = useState(false);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const { authUser, setAuthUser } = useAuth();
	const { setAudioPlayerActive } = useLayout();
	const audioDispatch = useAudioDispatch();
	const { episode, isPlaying } = useAudio();

	const location = useLocation();
	const isOnboardingAudioEligible: boolean = location.state?.isOnboardingAudioEligible ?? false;
	const [audioSettingsState, setAudioSettingsState] = useState<AudioSettingsState>({
		isShow: false,
	});
	
	useEffect(() => {
		if (isOnboardingAudioEligible) {
			sessionStorage.setItem(kLocalStorageKeys.Session.isFirstEpisodeEligible, "true");
		}

		fetchSections();
	}, []);

	const fetchSections = () => {
		networkManager
			.getUserProfile()
			.then((profile) => {
				setAuthUser(profile);
				return networkManager.getAudioSections();
			})
			.then((audioSections) => {
				processAudioSections(audioSections);
			}).catch((_) => {
				setError(true);
			});
	};

	const processAudioSections = (audioSections: NLAudioSection[]) => {
		var tempAudioSections = audioSections;

		if (tempAudioSections.length == 0) {
			setError(true);
			return;
		}

		let sortedAudioArray = audioSections.sort((a, b) => a.sort_index - b.sort_index);

		const audioModels: AudioModel[] = sortedAudioArray.map((section) => ({
			section: section,
			episodes: undefined,
			isRowLoading: true,
		}));

		setAudioArray(audioModels);

		for (let audioSection of sortedAudioArray) {
			networkManager.getSectionDetail(audioSection.key).then((sectionDetail) => {
				if (sectionDetail.key === "user" && sectionDetail.episodes.length > 0) {
					sessionStorage.removeItem(kLocalStorageKeys.Session.isFirstEpisodeEligible);
				}

				setAudioArray((prevAudioArray) => {
					if (!prevAudioArray) {
						return audioModels;
					}
					const index = prevAudioArray.findIndex((audioModel) => audioModel.section.key === audioSection.key);

					if (index === -1) {
						return prevAudioArray;
					}
					const updatedAudioModel: AudioModel = {
						...prevAudioArray[index],
						episodes: sectionDetail.episodes,
						isRowLoading: false,
					};
					const newAudioArray = [...prevAudioArray];
					newAudioArray[index] = updatedAudioModel;
					console.log(newAudioArray);
					return newAudioArray;
				});
			});
		}
	};

	const determineRowType = (audioItem: AudioModel): AudioRowType => {
		if (audioItem.section.key === "user") {
			if (audioItem.isRowLoading || (audioItem.episodes?.length ?? 0 > 0)) {
				//Row is either loading or we have episode
				return AudioRowType.User;
			}

			if (authUser!.audio_profiles.length > 0) {
				let audioProfile = authUser!.audio_profiles[0];

				if (!audioProfile.is_enabled) {
					if (audioProfile.audio_error && audioProfile.audio_error === AudioProfileError.Unengaged) {
						//We have a profile but it's disabled and ther is error => Activate CTA with personalised message
						return AudioRowType.UserDeactivatedUnengaged;
					}

					if (audioProfile.audio_error && audioProfile.audio_error === AudioProfileError.NotProUser) {
						//We have a profile but it's disabled and ther is error => Activate CTA with personalised message
						return AudioRowType.UserDeactivatedNotPro;
					}

					//We have a profile but it's disabled => Activate CTA
					return AudioRowType.UserDeactivated;
				}

				if (audioProfile.audio_error) {
					// We have a profile and it's enabled but there is an error
					return AudioRowType.UserError;
				}

				let isFirstEpisodeEligible = sessionStorage.getItem(kLocalStorageKeys.Session.isFirstEpisodeEligible);

				if (isFirstEpisodeEligible) {
					//Loading the first episode
					return AudioRowType.UserFirstEpisodeLoading
				}

				//We have a profile and it's enabled but there are no episodes => Your podcast will be here soon
				return AudioRowType.UserCheckBack;
			}

			//We don't have a profile => onboarding
			return AudioRowType.UserOnboarding;
		}

		//List
		return AudioRowType.List;
	};

	const onPlayClick = (audioEpisode: NLAudioEpisode) => {
		setAudioPlayerActive(true);
		if (episode?.id === audioEpisode.id && isPlaying) {
			audioDispatch({ type: "PAUSE" });
			return;
		}

		audioDispatch({ type: 'PLAY_AUDIO', payload: { episode: audioEpisode } });
	}

	const renderRail = (audioItem: AudioModel) => {
		let rowType = determineRowType(audioItem);

		switch (rowType) {
			case AudioRowType.User:
				return <AudioUserCard audioItem={audioItem} onEpisodeClick={(episode) => onPlayClick(episode)} />
			case AudioRowType.UserCheckBack:
			case AudioRowType.UserFirstEpisodeLoading:
				return <AudioEmptyCard audioRowType={rowType} scheduledHour={authUser!.audio_profiles[0].scheduled_hour} />
			case AudioRowType.UserOnboarding:
			case AudioRowType.UserDeactivated:
			case AudioRowType.UserDeactivatedUnengaged:
			case AudioRowType.UserDeactivatedNotPro:
				return <AudioActionCard audioRowType={rowType} settingsOnClick={() => setAudioSettingsState({isShow: true})}/>;
			case AudioRowType.UserError:
				return <AudioErrorBanner audioProfileError={authUser!.audio_profiles[0].audio_error} />
			default:
				return <AudioGeneralRow audioItem={audioItem} onPlayClick={(episode) => onPlayClick(episode)} />;
		}
	};

	return (
		<>
			<AudioSettingsModal audioSettingsState={audioSettingsState} setAudioSettingsState={setAudioSettingsState} />
			<div>
				<Alert alertState={alertState} setAlertState={setAlertState} />

				<div className="flex flex-row w-full items-stretch">
					{isError == true ? (
						<EmptyStateView title="Well this is awkward..." description="We don’t have any podcasts here right now check back later" alertImage={collageDiscover} />
					) : (
						<div className="w-full h-screen border-r border-primary-100 overflow-x-hidden">
							<AudioHeader settingsOnClick={() => setAudioSettingsState({ isShow: true })} />
							<div className="w-full py-4">
								{audioArray &&
									audioArray.map((audioModel) => {
										return renderRail(audioModel);
									})}
							</div>
						</div>
					)}

					<AudioPlayer />
				</div>
			</div>
		</>
	);
};

export default Audio;
