import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@providers/AuthContext";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import LoadingView from "@components/common/LoadingView";

const ProtectedRoute = () => {
	const { authUser } = useAuth();
	
	if (authUser === undefined) {
		return <LoadingView loadingState={{ isLoading: true }} />;
	}

	if (!authUser) {
		let nextPath = window.location.pathname;
		if (nextPath.split("/")[1] === "mail") {
			sessionStorage.setItem(kLocalStorageKeys.Session.nextPath, nextPath);
		}
		return <Navigate to={{ pathname: "/login" }} />;
	}

	return <Outlet />;
};

export default ProtectedRoute;