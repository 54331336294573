import { useEffect, useState } from "react";
import { kStringConstants } from "@utils/constants/StringConstants";
import Alert, { AlertState } from "@components/common/Alert";
import { NLMailboxProfileType, NLProfile, NLUserMailState } from "@models/Models";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import ParticlesBackground from "@components/common/ParticlesBackground";
import NewsletterPickerRow from "./NewsletterPickerRow";
import { GetUserMailStatesService } from "@utils/managers/backendMailManager/mailboxFunctions/GetUserMailStatesService";
import * as Date from "@utils/Date+GetDateFor";
import { NLUserMailStateNames } from "@models/Enums";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { useOnboardingProfile } from "@providers/OnboardingContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import { MailboxIntegrationManager } from "@utils/managers/MailboxIntegrationManager";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { useAuth } from "@providers/AuthContext";

const NewsletterPicker = (props) => {
	const { onboardingProfile, setOnboardingProfile } = useOnboardingProfile();
	const location = useLocation();
	const navigate = useNavigate();
	const isAccount: boolean = location.state.isAccount ?? false;
	const [mailStates, setMailStates] = useState<NLUserMailState[]>(
		onboardingProfile!.persistence.statesArray
			.filter((x) => x.state_id !== NLUserMailStateNames.hidden)
			.sort((a, b) => (a?.sender_name || "").localeCompare(b?.sender_name || ""))
			.sort((a, b) => {
				const whitelistedA = Number(a?.is_whitelisted) || 0;
				const whitelistedB = Number(b?.is_whitelisted) || 0;
				return whitelistedB - whitelistedA;
			})
	);
	const [lookbackArray, setLookbackArray] = useState<number[]>([-7, -14, -21, -30]);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [isShowMoreLoading, setShowMoreLoading] = useState<boolean>(false);
	const [isSelectAll, setIsSelectAll] = useState<boolean>(true);
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});
	const { authUser, setAuthUser } = useAuth();

	useEffect(() => {
		if (!onboardingProfile!.persistence.isNewsletterPickerOnboardingAnimationComplete) {
			animateRowSelect();

			const ratio: number = (mailStates?.filter((state) => state.is_whitelisted ?? false).length || 0) / (mailStates?.length || 1);
			const doubleRatio: number = Math.round(100 * ratio) / 100;
			analyticsManager.recordEvent(kAnalyticsConstants.Onboarding.feedPickerPresented, {
				[kAnalyticsConstants.Onboarding.OnboardingProfileKeys.displayedFeed]: mailStates?.length || 0,
				[kAnalyticsConstants.Onboarding.OnboardingProfileKeys.displayedWhitelistedFeed]: mailStates?.filter((state) => state.is_whitelisted ?? false).length || 0,
				[kAnalyticsConstants.Onboarding.OnboardingProfileKeys.displayedWhitelistedRatio]: doubleRatio,
				is_onboarding: !isAccount,
			});
		}
	}, []);

	const listItemClicked = (e, stateId) => {
		var tempOnboardingProfile = { ...onboardingProfile! };
		var currentFeed = tempOnboardingProfile.persistence.feedArray;

		if (currentFeed.includes(stateId)) {
			currentFeed = currentFeed.filter((x) => x !== stateId);
		} else {
			currentFeed.push(stateId);
		}

		tempOnboardingProfile.persistence.feedArray = currentFeed;
		setOnboardingProfile(tempOnboardingProfile);
		setIsSelectAll(tempOnboardingProfile.persistence.feedArray.length != tempOnboardingProfile.persistence.statesArray.length);
	};

	const animateRowSelect = () => {
		const feedLength = mailStates.length;
		if (feedLength > 0) {
			const rowsToSelect = feedLength > 4 ? 3 : 1;

			var animationCompleteCount = rowsToSelect;

			for (var idx = 1; idx <= rowsToSelect; idx++) {
				let index = idx - 1;

				setTimeout(function () {
					listItemClicked(null, mailStates[index].id);

					setTimeout(function () {
						listItemClicked(null, mailStates[index].id);
						animationCompleteCount -= 1;

						if (animationCompleteCount == 0) {
							var tempOnboardingProfile = { ...onboardingProfile! };
							tempOnboardingProfile.persistence.isNewsletterPickerOnboardingAnimationComplete = true;
							setOnboardingProfile(tempOnboardingProfile);
						}
					}, rowsToSelect * 350);
				}, 1000 + index * 350);
			}
		}
	};

	const loadMoreOnClick = (passedLookbackArray) => {
		if (passedLookbackArray.length < 2) {
			setLookbackArray([]);
			return;
		}

		setShowMoreLoading(true);

		let lookbackBeforeItem = passedLookbackArray[0];
		let lookbackAfterItem = passedLookbackArray[1];

		const mailboxProfile = onboardingProfile!.persistence.mailboxProfile;

		const userMailStateRequests = new GetUserMailStatesService.QueryBuilder(mailboxProfile, [lookbackAfterItem, lookbackBeforeItem], false, onboardingProfile!.persistence.lastGetUserMailStatesDateString);

		const getUserMailStatesService = new GetUserMailStatesService([userMailStateRequests]);

		getUserMailStatesService.getUserMailStates((userMailStates, _mailboxProfileIds, error) => {
			if (!userMailStates || error) {
				setAlertState({ isShow: true, title: kStringConstants.Common.errorAlertTitle, message: kStringConstants.Common.errorAlertMessage, actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
				return;
			}

			var tempLookbackArray = [...passedLookbackArray];
			tempLookbackArray = tempLookbackArray.slice(1);

			appendNewUserMailStates(userMailStates, tempLookbackArray);
		});
	};

	const appendNewUserMailStates = (userMailStates: NLUserMailState[], tempLookbackArray) => {
		let originalMailStates = [...mailStates];
		var originalOnboardingStates = [...onboardingProfile!.persistence.statesArray];
		let originalOnboardingStatesSenderAddressArray = originalMailStates.map((state) => state.sender_address);
		let newUserMailStates = userMailStates.filter((userMailState) => !originalOnboardingStatesSenderAddressArray.includes(userMailState.sender_address));

		let tempOnboardingProfile = onboardingProfile!;
		originalOnboardingStates.push(...newUserMailStates);
		tempOnboardingProfile.persistence.statesArray = originalOnboardingStates;
		tempOnboardingProfile.persistence.lastGetUserMailStatesDateString = Date.backendDateString();

		let existingSenderAddresses = originalMailStates.map((state) => state.sender_address);
		let sortedNewUserMailStates = userMailStates
			.filter((userMailState) => userMailState.state_id !== NLUserMailStateNames.hidden && !existingSenderAddresses.includes(userMailState.sender_address))
			.sort((a, b) => (a?.sender_name || "").localeCompare(b?.sender_name || ""))
			.sort((a, b) => {
				const whitelistedA = Number(a?.is_whitelisted) || 0;
				const whitelistedB = Number(b?.is_whitelisted) || 0;
				return whitelistedB - whitelistedA;
			});

		originalMailStates.push(...sortedNewUserMailStates);

		setMailStates(originalMailStates);
		setOnboardingProfile(tempOnboardingProfile);

		if (sortedNewUserMailStates.length == 0) {
			loadMoreOnClick(tempLookbackArray);
			return;
		}

		setLookbackArray(tempLookbackArray);
		setShowMoreLoading(false);
	};

	const selectAllOnClick = () => {
		var tempOnboardingProfile = { ...onboardingProfile! };
		tempOnboardingProfile.persistence.feedArray = [];

		if (isSelectAll) {
			tempOnboardingProfile.persistence.feedArray = tempOnboardingProfile.persistence.statesArray.map((x) => x.id);
		}

		setIsSelectAll(!isSelectAll);
		setOnboardingProfile(tempOnboardingProfile);
	};

	const selectAllSuggestedOnClick = () => {
		var tempOnboardingProfile = { ...onboardingProfile! };
		tempOnboardingProfile.persistence.feedArray = [];
		tempOnboardingProfile.persistence.feedArray = tempOnboardingProfile.persistence.statesArray.filter((x) => x.is_whitelisted === true).map((x) => x.id);
		setOnboardingProfile(tempOnboardingProfile);
	};

	const movedToNextPage = () => {
		const ratio: number = (mailStates?.filter((state) => state.is_whitelisted ?? false).length || 0) / (mailStates?.length || 1);
		const doubleRatio: number = Math.round(100 * ratio) / 100;
		analyticsManager.recordEvent(kAnalyticsConstants.Onboarding.feedPickerDone, {
			[kAnalyticsConstants.Onboarding.OnboardingProfileKeys.displayedFeed]: mailStates?.length || 0,
			[kAnalyticsConstants.Onboarding.OnboardingProfileKeys.displayedWhitelistedFeed]: mailStates?.filter((state) => state.is_whitelisted ?? false).length || 0,
			[kAnalyticsConstants.Onboarding.OnboardingProfileKeys.displayedWhitelistedRatio]: doubleRatio,
			[kAnalyticsConstants.Onboarding.OnboardingProfileKeys.progressedLookBackDays]: (lookbackArray[0] || 0) * -1,
			[kAnalyticsConstants.Onboarding.OnboardingProfileKeys.newslettersAdded]: onboardingProfile!.persistence.feedArray.length || 0,
			is_onboarding: !isAccount,
		});
	};

	const applyChanges = () => {
		setLoadingState({ ...loadingState, message: "Applying inbox changes", isLoading: true });
		const mailboxIntegrationManager = new MailboxIntegrationManager(onboardingProfile!, authUser!, MailboxIntegrationManager.Reference.signUp);
		mailboxIntegrationManager
			.applyNewsletterChanges()
			.then(([_userMailStatesArray, profile]) => {
				if (profile) {
					const userProfile: NLProfile = profile;
					setAuthUser(userProfile);
				}
				analyticsManager.recordEvent(kAnalyticsConstants.App.appliedNewsletterChanges, { newsletter_changes_count: onboardingProfile!.persistence.feedArray.length, newsletter_changes_error: false, is_onboarding: false });
				navigate("/settings", { replace: true, state: { mailboxIntegrated: onboardingProfile?.persistence.mailboxProfile.type }});
			})
			.catch((error) => {
				analyticsManager.recordEvent(kAnalyticsConstants.App.appliedNewsletterChanges, { newsletter_changes_count: onboardingProfile!.persistence.feedArray.length, newsletter_changes_error: true, is_onboarding: false, error: error.message ?? "" });
				setAlertState({
					isShow: true,
					title: "Oops something went wrong",
					message: "Some of your selected newsletter changes were not applied, please try again within the Settings.",
					actionButton: "OK",
					dismissButton: null,
					actionButtonAction: () => {
						setLoadingState({ ...loadingState, isLoading: false });
					},
				});
			});
	};

	return (
		<div>
			<LoadingView loadingState={loadingState} />
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<ParticlesBackground />
			<div className={`flex min-h-[100dvh] flex-col md:justify-center justify-start py-2 md:py-12 ${onboardingProfile!.persistence.isNewsletterPickerOnboardingAnimationComplete ? "pointer-events-auto" : "pointer-events-none"}`}>
				<div className="fixed top-0 bg-black/50 w-full p-3 z-30 backdrop-blur-lg border-b border-white/10 shadow-2xl">
					<div className="max-w-[1080px] w-full z-30 m-auto">
						<div className="flex justify-end items-center">
							{isAccount ? (
								<button className="text-white font-medium font-primary text-lg focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-success-green/80 hover:bg-success-green/100 transition ease-in-out whitespace-nowrap" onClick={() => applyChanges()}>Done</button>
							) : (
								<Link to="/get-started/interests" onClick={() => movedToNextPage()}>
									<button className="text-white font-medium font-primary text-lg focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-success-green/80 hover:bg-success-green/100 transition ease-in-out whitespace-nowrap">Next</button>
								</Link>
							)}
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-10 items-center justify-center p-3 mt-[50px] md:mt-[80px]">
					<div className="meco_container max-w-[1080px] py-[40px] md:p-[60px] md:pb-[40px]">
						<div className="flex flex-col md:flex-row gap-5 items-start">
							<div className="sticky md:top-[90px] w-full md:w-1/2 flex flex-col gap-2">
								<div className="text-white font-bold font-primary text-4xl leading-10">Add newsletters to Meco</div>
								<div className="text-force-primary-light font-medium font-primary text-base  md:text-xl">Selected newsletters will appear in Meco instead of your Gmail inbox going forward. You can go back to viewing these in your inbox at any time.</div>
							</div>
							<div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-3 max-w-[450px] md:max-w-none m-auto md:min-w-[400px]">
								<div className="flex flex-row justify-end w-full gap-2">
									<button className="text-white font-regular font-primary text-sm focus:outline-none rounded-xl p-2 px-3 py-2 items-center border border-white/10 hover:bg-white/20 transition ease-in-out whitespace-nowrap" onClick={() => selectAllSuggestedOnClick()}>
										Select all suggested
									</button>
									<button className="text-white font-regular font-primary text-sm focus:outline-none rounded-xl p-2 px-3 py-2 items-center border border-white/10 hover:bg-white/20 transition ease-in-out whitespace-nowrap" onClick={() => selectAllOnClick()}>
										{isSelectAll ? "Select all" : "Deselect all"}
									</button>
								</div>
								<div className="text-white font-medium font-primary text-3xl text-center w-full max-w-[600px] mb-5  no-select">
									{mailStates.map((sender, i) => {
										return <NewsletterPickerRow {...sender} key={i} isChecked={onboardingProfile!.persistence.feedArray.includes(sender.id)} checkOnChange={listItemClicked} />;
									})}
									{lookbackArray.length > 0 && (
										<button disabled={isShowMoreLoading} className="flex mt-4 w-full border justify-center h-[50px] items-center rounded-[10px] border-force-primary-light/20 text-base  font-primary font-bold" onClick={() => loadMoreOnClick(lookbackArray)}>
											{isShowMoreLoading ? <LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white" /> : <div>Show more from last {lookbackArray[0] * -1} days</div>}
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsletterPicker;
