import { useEffect, useState } from "react";
import ParticlesBackground from "@components/common/ParticlesBackground";
import { Link, useLocation, useNavigate } from "react-router-dom";
import collageNotifications from "@images/collage_notifications.png";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { useAudioOnboardingProfile } from "@providers/AudioOnboardingContext";
import AudioSchedulePickerRow from "./AudioSchedeluePickerRow";
import { Switch } from "@headlessui/react";
import { AudioOnboardingProfile, NLProfile, NLUserGroup, NLUserSenderGroup } from "@models/Models";
import { kAppConfig } from "@utils/configurations/AppConfig";
import { useAuth } from "@providers/AuthContext";
import { subscribeToPushNotifications } from "@utils/managers/NotificationsManager";
import Alert, { AlertState } from "@components/common/Alert";
import { kStringConstants } from "@utils/constants/StringConstants";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { convertHourFromUTC, convertHourToUTC } from "@utils/Date+GetDateFor";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import { usePremium } from "@providers/PremiumContext";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const AudioSchedulePicker = (props) => {
	const location = useLocation();
	const isUpdate: boolean = location.state?.isUpdate ?? false;
	const { audioOnboardingProfile, setAudioOnboardingProfile } = useAudioOnboardingProfile();
	const { authUser, setAuthUser } = useAuth();
	const navigate = useNavigate();
	const { isPremium } = usePremium();
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});

	let timeOptions = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 5];

	let defaultHour = 9;

	useEffect(() => {
		if (!isPremium() || (!isUpdate && authUser!.audio_profiles.length > 0)) {
			navigateHome(false);
			return;
		}
		if (isUpdate) {
			var onboardingProfile = new AudioOnboardingProfile();
			onboardingProfile.scheduledHour = convertHourFromUTC(authUser!.audio_profiles[0].scheduled_hour);
			onboardingProfile.notifications_enabled = authUser!.audio_profiles[0].is_notifications_enabled;
			setAudioOnboardingProfile(onboardingProfile);
		}
	}, []);

	const hourOnClick = (hour: number) => {
		var tempOnboardingProfile = { ...audioOnboardingProfile! };
		tempOnboardingProfile.scheduledHour = hour;
		setAudioOnboardingProfile(tempOnboardingProfile);
	};

	const notificationsChanged = (isEnabled: boolean) => {
		var tempOnboardingProfile = { ...audioOnboardingProfile! };
		tempOnboardingProfile.notifications_enabled = isEnabled;
		setAudioOnboardingProfile(tempOnboardingProfile);
	};

	const intToHour = (int: number): string => {
		return `${int.toString().padStart(2, "0")}:00`;
	};

	const didComplete = () => {
		setLoadingState({ isLoading: true });

		//Update Scenario
		if (isUpdate) {
			var newAudioProfile = authUser!.audio_profiles[0];
			let scheduledHour = audioOnboardingProfile?.scheduledHour ?? defaultHour;
			let utcScheduledHour = convertHourToUTC(scheduledHour) ?? defaultHour;
			let notificationEnabled = audioOnboardingProfile?.notifications_enabled ?? true;
			newAudioProfile.scheduled_hour = utcScheduledHour;
			newAudioProfile.is_notifications_enabled = notificationEnabled;

			networkManager
				.updateAudioProfile(newAudioProfile)
				.then((profile) => {
					recordEvent(kAnalyticsConstants.Audio.scheduleUpdated, { "schedule_time": scheduledHour, "reminders_on": notificationEnabled });
					setAuthUser(profile);
					navigateHome(false);
				})
				.catch((error) => {
					setLoadingState({ isLoading: false });
					setAlertState({
						isShow: true,
						title: kStringConstants.Common.errorAlertTitle,
						message: kStringConstants.Common.errorAlertMessage,
						actionButton: "OK",
						dismissButton: null,
						actionButtonAction: () => {
							navigateHome(false);
						},
					});
				});
			return;
		}

		//Create Scenario
		if (!(audioOnboardingProfile?.selectedSenders.length ?? 0 > 0)) {
			return;
		}

		var isOnboardingAudioEligible = false;

		createUserGroupOnBackend(generateNewGroup(), audioOnboardingProfile!.selectedSenders)
			.then((userGroup) => {
				return createAudioProfile(userGroup);
			})
			.then(([profile, isOnboardingEligible]) => {
				if (isOnboardingAudioEligible) {
					recordEvent(kAnalyticsConstants.Audio.firstEpisodeLoading)
				}
				setAuthUser(profile);
				isOnboardingAudioEligible = isOnboardingEligible;
				return requestPushNotifications();
			})
			.then((_) => {
				recordEvent(kAnalyticsConstants.Audio.scheduleSelected, { schedule_time: audioOnboardingProfile?.scheduledHour ?? defaultHour, reminders_on: audioOnboardingProfile?.notifications_enabled ?? false });
				navigateHome(isOnboardingAudioEligible);
				setLoadingState({ isLoading: false });
			})
			.catch((_) => {
				setLoadingState({ isLoading: false });
				setAlertState({
					isShow: true,
					title: kStringConstants.Common.errorAlertTitle,
					message: kStringConstants.Common.errorAlertMessage,
					actionButton: "OK",
					dismissButton: null,
					actionButtonAction: () => {
						navigateHome(false);
					},
				});
			});
	};

	const createUserGroupOnBackend = (userGroup: NLUserGroup, senderArray: string[]): Promise<NLUserGroup> => {
		return new Promise((resolve, reject) => {
			networkManager
				.postUserGroups([userGroup])
				.then((createdGroups) => {
					let createdUserGroup = createdGroups[0];
					return createUserSenderGroups(senderArray, createdUserGroup);
				})
				.then((userGroup) => {
					resolve(userGroup);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const createUserSenderGroups = (senderAddressArray: string[], userGroup: NLUserGroup): Promise<NLUserGroup> => {
		return new Promise((resolve, reject) => {
			var tempUserSenderGroups: NLUserSenderGroup[] = [];

			for (let senderAddress of senderAddressArray) {
				let newUserSenderGroup = new NLUserSenderGroup();
				newUserSenderGroup.sender_address = senderAddress;
				newUserSenderGroup.sender_address_index = 0;
				newUserSenderGroup.user_group = userGroup.id;
				tempUserSenderGroups.push(newUserSenderGroup);
			}

			networkManager
				.createUserSenderGroups(tempUserSenderGroups)
				.then((_) => {
					resolve(userGroup);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const createAudioProfile = (userGroup: NLUserGroup): Promise<[NLProfile, boolean]> => {
		return new Promise((resolve, reject) => {
			let scheduledHour = audioOnboardingProfile?.scheduledHour ?? defaultHour;
			let utcScheduledHour = convertHourToUTC(scheduledHour) ?? defaultHour;
			let notificationEnabled = audioOnboardingProfile?.notifications_enabled ?? true;
			let audioProfile = { group_id: userGroup.id, scheduled_hour: utcScheduledHour, is_notifications_enabled: notificationEnabled };

			networkManager
				.createAudioProfile(audioProfile)
				.then(([profile, isOnboardingEligible]) => {
					resolve([profile, isOnboardingEligible]);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const requestPushNotifications = (): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			if (!audioOnboardingProfile?.notifications_enabled ?? true) {
				resolve(true);
				return;
			}

			Notification.requestPermission().then((permission) => {
				if (permission === "granted") {
					subscribeToPushNotifications()
						.then(() => {
							resolve(true);
						})
						.catch((_) => {
							resolve(true);
						});
				} else {
					resolve(false);
				}
			});
		});
	};

	const generateNewGroup = (): NLUserGroup => {
		let newGroup = new NLUserGroup();
		newGroup.group_name = kAppConfig.aiAudioDefaultGroupName;
		newGroup.group_index = 1;
		return newGroup;
	};

	const navigateHome = (isOnboardingAudioEligible: boolean) => {
		sessionStorage.removeItem(kLocalStorageKeys.Session.audioOnboardingProfile);
		navigate("/audio", { replace: true, state: { isOnboardingAudioEligible: isOnboardingAudioEligible } });
	};

	return (
		<div>
			<LoadingView loadingState={loadingState} />
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<ParticlesBackground />
			<div className={`flex min-h-[100dvh] flex-col md:justify-center justify-start py-2 md:py-12`}>
				<div className="fixed top-0 bg-black/50 w-full p-3 z-30 backdrop-blur-lg border-b border-white/10 shadow-2xl">
					<div className="max-w-[1080px] w-full z-30 m-auto">
						<div className="flex justify-between items-center">
							<Link to={isUpdate ? "/audio" : "/audio/pick-senders"}>
								<button className={`text-force-primary-light font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-white/20 hover:bg-brand-blue/80 transition ease-in-out whitespace-nowrap`}>Back</button>
							</Link>
							<button className="text-white font-medium font-primary text-lg focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-success-green/80 transition ease-in-out whitespace-nowrap hover:bg-success-green/100" onClick={didComplete}>
								Done
							</button>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-10 items-center justify-center p-3 mt-[50px] md:mt-[80px]">
					<div className="meco_container max-w-[1080px] py-[40px] md:p-[60px]">
						<div className="flex flex-col md:flex-row gap-5 items-start">
							<div className="sticky md:top-[90px] w-full md:w-1/2 flex flex-col gap-2 items-start">
								<img src={collageNotifications} className="h-[100px] object-contain" />
								<div className="text-white font-bold font-primary text-4xl leading-10">
									Pick a time
									<br />
									that works for you
								</div>
								<div className="text-force-primary-light font-medium font-primary text-base  md:text-xl">Your Daily Brief will be ready and waiting at your selected time! We can send you a reminder when ready.</div>
							</div>
							<div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-5">
								<div className="text-force-primary-light font-medium font-primary text-base">You will receive your Daily Brief at {intToHour(audioOnboardingProfile?.scheduledHour ?? 9)} every day.</div>
								<div className="flex flex-row flex-wrap gap-4 justify-center">
									{timeOptions.map((timeOption) => {
										return <AudioSchedulePickerRow hour={timeOption} displayHour={intToHour(timeOption)} isChecked={audioOnboardingProfile?.scheduledHour == timeOption} didSelect={(hour) => hourOnClick(hour)} />;
									})}
								</div>
								<div className="flex flex-row bg-white/10 transition ease-in-out border border-white/20 p-3 rounded-[10px] gap-2 items-center w-full">
									<div className="flex flex-row justify-between items-center w-full">
										<div className="text-force-primary-light font-regular font-primary text-base">Receive a reminder</div>
										<Switch checked={audioOnboardingProfile?.notifications_enabled} onChange={(checked) => notificationsChanged(checked)} className={`${audioOnboardingProfile?.notifications_enabled ? "bg-success-green" : "bg-white/20"} relative inline-flex h-[30px] w-[55px] items-center rounded-full`}>
											<span className={`${audioOnboardingProfile?.notifications_enabled ? "translate-x-[26px]" : "translate-x-[2px]"} inline-block h-[28px] w-[28px] transform rounded-full bg-white transition`} />
										</Switch>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AudioSchedulePicker;
