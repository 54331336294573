import React, { useRef } from "react";
import { AudioModel, NLAudioEpisode } from "@models/Models";
import { ReactComponent as ChevronRight } from "@images/chevron_right.svg";
import AudioListCard from "./AudioListCard";

interface AudioGeneralRowProps {
	audioItem: AudioModel;
	onPlayClick: (episode: NLAudioEpisode) => void;
}

const AudioGeneralRow: React.FC<AudioGeneralRowProps> = ({ audioItem, onPlayClick }) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const scroll = (direction: "left" | "right") => {
		if (containerRef.current) {
			const { scrollLeft, clientWidth } = containerRef.current;
			const scrollAmount = direction === "left" ? -clientWidth / 2 : clientWidth / 2;
			containerRef.current.scrollTo({ left: scrollLeft + scrollAmount, behavior: "smooth" });
		}
	};

	const renderCards = () => {
		if (audioItem.episodes && audioItem.episodes.length > 0) {
			return (
				<>
					{audioItem.episodes.map((episode) => (
						<AudioListCard key={episode.id} audioEpisode={episode} onClick={() => onPlayClick(episode)} />
					))}
				</>
			);
		}

		return null;
	};

	return (
		<div className="relative group py-3" key={audioItem.section.id}>
			<div className="flex flex-row justify-between px-4 items-center">
				<div className="font-primary font-black text-xl text-primary">{audioItem.section.name}</div>
				<div className="flex flex-row items-center gap-2">
					<div className="bg-surface-200 border border-primary-100 p-3 cursor-pointer opacity-0 transition-opacity duration-300 group-hover:opacity-100 flex items-center justify-center text-primary rounded-full" onClick={() => scroll("left")}>
						<ChevronRight className="fill-primary w-[12px] h-[12px] rotate-180" />
					</div>
					<div className="bg-surface-200 border border-primary-100 p-3 cursor-pointer opacity-0 transition-opacity duration-300 group-hover:opacity-100 flex items-center justify-center text-primary rounded-full" onClick={() => scroll("right")}>
						<ChevronRight className="fill-primary w-[12px] h-[12px]" />
					</div>
				</div>
			</div>
			<div className="grid grid-flow-col auto-cols-max gap-4 pt-3 pb-4 overflow-x-auto px-4 no-scrollbar" ref={containerRef}>
				{renderCards()}
			</div>
		</div>
	);
};

export default AudioGeneralRow;
