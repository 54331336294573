export const CategorySelectionRow = (props) => {
	return (
		<div className="col-md-auto col-centered no-select">
            <div className={`color-white rounded-[6px] border border-white/20 ${!props.isChecked && props.isLimit ? "cursor-not-allowed" : "cursor-pointer"} px-3 py-2 transition ease-in-out ${props.isChecked ? "bg-success-green hover:bg-success-green scale-105" : "bg-white/10 hover:bg-white/20 scale-100"}`} onClick={(e) => props.checkOnChange(e, props)} >
                <div className="font-medium font-primary text-base  text-force-primary-light">{props.emoji}&nbsp;&nbsp;{props.name}</div>
            </div>
		</div>
	);
};

export default CategorySelectionRow;
