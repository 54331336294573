import { FC, useState, useEffect } from "react";
import LoadingView, { LoadingState } from "@components/common/LoadingView";

const OauthCallback: FC<{}> = () => {
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
		message: null,
	});
	
	useEffect(() => {
		const params = window.location.search;
		if (window.opener) {
			window.opener.postMessage(params);
			window.close();
		}
	}, []);

	return (
		<div className="no-select">
			<LoadingView loadingState={{isLoading: true}} />
		</div>
	);
};

export default OauthCallback;
