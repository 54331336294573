import React, { MouseEventHandler } from "react";
import { NLAudioEpisode } from "@models/Models";
import { ReactComponent as PlayIcon } from "@images/audio_play_icon.svg";
import { ReactComponent as PauseIcon } from "@images/audio_pause_icon.svg";

import { useAudio } from "@providers/AudioContext";
import IconButton from "@components/common/IconButton";
import { dateStringToAudioDate } from "@utils/Date+GetDateFor";

interface AudioListCardProps {
	audioEpisode: NLAudioEpisode;
	onClick: MouseEventHandler<HTMLDivElement>;
}

const AudioListCard: React.FC<AudioListCardProps> = ({ audioEpisode, onClick }) => {
	const { episode, isPlaying } = useAudio();

	const getDateAndTime = () => {
		
		let dateString = dateStringToAudioDate(audioEpisode.created_at);
		const minutes = Math.ceil(audioEpisode.duration / 60);
		const minutesString = `${minutes} MIN`;
		if (dateString) {
			return `${dateString} – ${minutesString}`;
		}

        return minutesString
    }

	const calculateWidth = ():number => {
		let fullWidth = 312;
		let ratio = audioEpisode.resume_time / audioEpisode.duration;
		return fullWidth * ratio;
	}

	return (
		<div className="bg-surface flex flex-col rounded-[16px] w-[312px] h-[280px] border border-primary-100 overflow-hidden hover:bg-surface-100 transition ease-in-out duration-[250] cursor-pointer shadow-md" onClick={onClick}>
			<div className="relative w-full bg-primary-500 h-[140px]">
				<img src={audioEpisode?.image_url} className="w-full h-full object-cover" />
				<div className="bg-success-green h-[4px] absolute bottom-0 left-0" style={{width: calculateWidth()}} />
			</div>
			<div className="h-[1px] bg-primary-100"></div>
			<div className="flex flex-col py-[12px] px-[12px] pb-[20px] gap-2 h-[145px]">
				<div className="flex justify-between gap-2 items-center">
					<div className="flex flex-col min-w-0 flex-grow">
						<div className="font-primary font-black text-xs text-primary-500">{getDateAndTime()}</div>
						<div className="font-primary font-medium text-base text-primary line-clamp-1">{audioEpisode?.title}</div>
					</div>
					<div>
						<IconButton Icon={episode?.id === audioEpisode.id && isPlaying ? PauseIcon : PlayIcon} onClick={() => null} className="!w-[32px] !h-[32px] bg-success-green !rounded-full" iconClassName="!fill-white" forcedIconSize={14} />
					</div>
				</div>
				<div className="font-primary font-regular text-sm text-primary-500 line-clamp-3">{audioEpisode?.summary}</div>
			</div>
		</div>
	);
};

export default AudioListCard;
