import React, { createContext, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { PremiumSource, PremiumStatus } from "@models/Enums";
import Qonversion from "@qonversion/web-sdk";

interface PremiumContextProps {
	isPremium: () => boolean;
    setQonversionPremiumStatus: (status: boolean) => void;
    premiumSource: PremiumSource | undefined;
    getPremiumStatus: () => void;
}

const PremiumContext = createContext<PremiumContextProps | undefined>(undefined);

export const PremiumProvider = () => {
    const [qonversionPremiumStatus, setQonversionPremiumStatus] = React.useState<boolean>(false);
    const [premiumSource, setPremiumSource] = React.useState<PremiumSource | undefined>(undefined);
	const { authUser } = useAuth();

    useEffect(() => {
        getPremiumStatus();
    }, [authUser]);

    const isPremium = ():boolean => {
        if (authUser!.premium_status === PremiumStatus.friendsAndFamily || authUser!.premium_status === PremiumStatus.internalTrial) {
            return true;
        }
        return qonversionPremiumStatus;
    }

    const getPremiumStatus = async () => {
        if (!authUser) { return }

        const qonversionInstance = Qonversion.getSharedInstance();
        qonversionInstance.identify(authUser.id)
        .then(() => {
            qonversionInstance.entitlements().then((entitlements) => {
                const mecoEntitlement = entitlements.find((entitlement) => entitlement.id === "meco_premium" && entitlement.active);
                if (mecoEntitlement) {
                    setQonversionPremiumStatus(true);
                    const source = PremiumSource[mecoEntitlement.source as keyof typeof PremiumSource]
                    setPremiumSource(source);
                    return;
                }
                setQonversionPremiumStatus(false);
            }).catch((error) => {
                setQonversionPremiumStatus(false);
                console.log("Error getting premium status", error);
            });
        }).catch((error) => {
            setQonversionPremiumStatus(false);
            console.log("Error identifying q user", error);
        })

    }

	const providerValue: PremiumContextProps = {
		isPremium,
        setQonversionPremiumStatus,
        premiumSource,
        getPremiumStatus,
	};

	return <PremiumContext.Provider value={providerValue}><Outlet /></PremiumContext.Provider>;
};

export const usePremium = () => {
	const context = useContext(PremiumContext);
	if (!context) {
		throw new Error("usePremium must be used within an PremiumProvider");
	}
	return context;
};
