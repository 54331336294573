import moment from "moment-timezone";

export const getUTCDate = (localDay: number, localHour: number, localMinute: number): [number, number, number] => {
	const userTimeZone = moment.tz.guess();
	var tempLocalDay = localDay;
	var userLocalTime;

	if (tempLocalDay === 0) {
		userLocalTime = moment.tz(`${localHour}:${localMinute}`, "HH:mm", userTimeZone);
        const utcTime = userLocalTime.clone().utc();
        const utcHour: number = +utcTime.format("HH");
        const utcMinute: number = +utcTime.format("mm");
        return [0, utcHour, utcMinute];
	} else {
		tempLocalDay = tempLocalDay === 7 ? 0 : tempLocalDay;
		userLocalTime = moment.tz(`${tempLocalDay} ${localHour}:${localMinute}`, "d HH:mm", userTimeZone);

        const utcTime = userLocalTime.clone().utc();

        const utcDay: number = +utcTime.format("d");
        var adjustedUtcDay = utcDay === 0 ? 7 : utcDay;

        const utcHour: number = +utcTime.format("HH");
        const utcMinute: number = +utcTime.format("mm");
    
        return [adjustedUtcDay, utcHour, utcMinute];
	}

};

export const getLocalDate = (utcDay: number, utcHour: number, utcMinute: number): [number, string, string] => {
	const utcTimeZone = "Etc/UTC";
    const userTimeZone = moment.tz.guess();
	var serverUTCDay = utcDay;
	var serverUTCTime;

	if (serverUTCDay === 0) {
		serverUTCTime = moment.tz(`${utcHour}:${utcMinute}`, "HH:mm", utcTimeZone);

        const localTime = serverUTCTime.clone().tz(userTimeZone)

        const localHour: number = +localTime.format("HH");
        const adjustedLocalHour = isSingleDigit(localHour) ? `0${localHour}` : localHour;

        const localMinute: number = +localTime.format("mm");
    
        return [0, adjustedLocalHour.toString(), localMinute.toString()];

	} else {
		serverUTCDay = serverUTCDay === 7 ? 0 : utcDay;
		serverUTCTime = moment.tz(`${serverUTCDay} ${utcHour}:${utcMinute}`, "d HH:mm", utcTimeZone);

        const localTime = serverUTCTime.clone().tz(userTimeZone)

        const localDay: number = +localTime.format("d");
        const adjustedLocalDay = localDay === 0 ? 7 : localDay;

        const localHour: number = +localTime.format("HH");
        const adjustedLocalHour = isSingleDigit(localHour) ? `0${localHour}` : localHour;

        const localMinute: number = +localTime.format("mm");
    
        return [adjustedLocalDay, adjustedLocalHour.toString(), localMinute.toString()];
	}


};

function isSingleDigit(num: number): boolean {
    return num >= 0 && num < 10;
}