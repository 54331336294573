import { NLMailboxProfile, NLUserGroup } from "@models/Models";
import { FC } from "react";
import { Textfit } from "react-textfit";

interface GroupListRowProps {
	group: NLUserGroup;
	isSelected: boolean;
	onSelectedItem: (selectedItem: NLUserGroup) => void;
}

const GroupListRow: FC<GroupListRowProps> = ({ group, isSelected, onSelectedItem }) => {
	return (
		<div key={group.id} className={`flex flex-col ${isSelected ? "bg-success-green scale-[1.02]" : "bg-surface hover:bg-surface-100"} border transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 w-full gap-2 items-start mb-[12px] shadow-md cursor-pointer`} onClick={(e) => onSelectedItem(group)}>
			<div className="flex flex-row items-center justify-between w-full gap-3">
				<div className="flex flex-col items-start text-left flex-grow">
					<Textfit mode="single" max={18} className={`${isSelected ? "text-white" : "text-primary"}  font-medium text-lg font-primary w-full`}>
						{group.group_name}
					</Textfit>
				</div>
				<div className="flex-shrink-0">
					<input readOnly className={`checkbox-round ${isSelected ? "!border-white" : "!border-success-green"}`} type="checkbox" name="selectable-sender" checked={isSelected} value={group.id} />
				</div>
			</div>
		</div>
	);
};

export default GroupListRow;
