import { NLMail, NLMailboxProfile, NLMailboxProfileType } from "@models/Models";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { MailboxError } from "./MailboxError";
import { MailState } from "@providers/MailContext";

export class QueryMessagesService {
	private query: QueryMessagesService.QueryBuilder;

	constructor(query: QueryMessagesService.QueryBuilder) {
		this.query = query;
	}

	queryMessages(completion: (mails: NLMail[] | undefined, error: Error | undefined) => void) {
		const oauthSessionManager = OauthSessionManager.sharedInstance();

		let request: QueryMessagesService.RequestModel | undefined = undefined;
		let response: QueryMessagesService.ResponseModel | undefined = undefined;

		const promise = new Promise<void>((innerResolve, innerReject) => {
			if (this.query.mailboxProfile.type !== NLMailboxProfileType.Internal) {
				oauthSessionManager
					.fetchAccessTokenForMailboxProfile(this.query.mailboxProfile.id, this.query.mailboxProfile.type)
					.then((accessToken) => {
						request = new QueryMessagesService.RequestModel(this.query.mailboxProfile.id, accessToken, this.query.query, this.query.groupId);
						innerResolve();
					})
					.catch((error) => {
						if (error instanceof Error && error.message === "noInternetError") {
							innerReject(error);
						} else {
							response = new QueryMessagesService.ResponseModel(this.query.mailboxProfile.id);
							response.error = oauthSessionManager.generateSignInRequiredError(this.query.mailboxProfile.id, error);
							innerResolve();
						}
					});
			} else {
				request = new QueryMessagesService.RequestModel(this.query.mailboxProfile.id, undefined, this.query.query, this.query.groupId);
				innerResolve();
			}
		});

		promise
			.then(() => {
				if (request) {
					this.makeRequests(request, completion);
					return;
				}
				completion(undefined, undefined);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}

	private makeRequests(request: QueryMessagesService.RequestModel, completion: (mails: NLMail[] | undefined, error: Error | undefined) => void) {
		networkManager
			.queryMessages(request)
			.then((jsonData) => {
				var responseModel = new QueryMessagesService.ResponseModel(jsonData.mailbox_profile_id);

				if (jsonData.error) {
					const mailboxError = MailboxError.returnError(jsonData.error, jsonData.mailbox_profile_id);
					responseModel.error = mailboxError;
					completion(undefined, mailboxError);
					return;
				}

				// if (jsonData.query) {
				//     responseModel.query = jsonData.query;
				// }

				// if (jsonData.group_id) {
				//     responseModel.group_id = jsonData.group_id;
				// }

				var mails: NLMail[] = [];
				if (Array.isArray(jsonData["messages"])) {
					let messages = jsonData["messages"].map((mail: NLMail) => new NLMail(mail));
					mails = messages;
					// responseModel.messages = messages;
				}

				completion(mails, undefined);
			})
			.catch((error) => {
				completion(undefined, error);
			});
	}
}

export namespace QueryMessagesService {
	export class RequestModel {
		access_token: string | undefined;
		mailbox_profile_id!: string;
		query: string;
		group_id: number | undefined;

		constructor(mailboxProfileId: string, accessToken: string | undefined, query: string, groupId: number | undefined) {
			this.mailbox_profile_id = mailboxProfileId;
			this.access_token = accessToken;
			this.query = query;
			this.group_id = groupId;
		}
	}

	export class ResponseModel {
		mailboxProfileId!: string;
		group_id?: number | undefined;
		query?: string | undefined;
		messages?: NLMail[] | undefined;
		error?: Error | undefined;

		constructor(mailboxProfileId: string) {
			this.mailboxProfileId = mailboxProfileId;
		}
	}

	export class QueryBuilder {
		mailboxProfile: NLMailboxProfile;
		mailState: MailState;
		query: string;
		groupId: number | undefined;

		constructor(mailboxProfile: NLMailboxProfile, mailState: MailState, senderAddress: string | undefined, beforeDate: string | undefined, isUnread: boolean | undefined, groupId: number | undefined, searchTerm: string | undefined) {
			this.mailboxProfile = mailboxProfile;
			this.mailState = mailState;
			this.query = QueryBuilder.generateSearchQuery(senderAddress, beforeDate, isUnread, searchTerm) ?? "";
			this.groupId = groupId;
		}

		static generateSearchQuery(senderAddress: string | undefined, beforeDate: string | undefined, isUnread: boolean | undefined, searchTerm: string | undefined): string | undefined {
			let senderAddressQuery: string | undefined = undefined;

			if (senderAddress) {
				senderAddressQuery = `from:(${senderAddress})`;
			}

			let beforeDateQuery: string | undefined = undefined;

			if (beforeDate) {
				beforeDateQuery = `before:${beforeDate}`;
			}

			let isUnreadQuery: string | undefined = undefined;

			if (isUnread) {
				isUnreadQuery = isUnread ? "is:unread" : "is:read";
			}

			let searchTermQuery: string | undefined = undefined;

			if (searchTerm) {
				searchTermQuery = searchTerm;
			}

			const queryArray: (string | undefined)[] = [senderAddressQuery, beforeDateQuery, isUnreadQuery, searchTermQuery];

			let mainQuery: string | undefined = undefined;

			for (const queryString of queryArray) {
				if (queryString !== undefined) {
					mainQuery = mainQuery !== undefined ? `${mainQuery} ${queryString}` : queryString;
				}
			}

			return mainQuery;
		}
	}
}
