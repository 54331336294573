import { MessageIdType } from "@models/Enums";

export {};

declare global {
	interface String {
		messageIdType(): string;
		safeId(): string;
	}
}

const isUUID = (id: String) => {
	return id.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/);
};

String.prototype.messageIdType = function (): MessageIdType {
	if (isUUID(this)) {
		return MessageIdType.Internal;
	}

	if (this.includes("_")) {
		const mailboxProfileId = this.split("_").pop();
		if (mailboxProfileId && isUUID(mailboxProfileId)) {
			return MessageIdType.OAuth;
		}
	}

	return MessageIdType.Legacy;
};

String.prototype.safeId = function (): string {
    const sanitized = this.replace(/[^a-zA-Z0-9-_:.]/g, '_');
    const startsWithLetter = sanitized.match(/^[a-zA-Z]/);
    const id = startsWithLetter ? sanitized : `${sanitized}`;
    return id;
};