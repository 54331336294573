import { FC, useState, useEffect } from "react";
import collageBalloon from "@images/collage_paywall_balloon.png";
import { ReactComponent as MecoLogo } from "@images/meco_logo.svg";
import { ReactComponent as RightArrow } from "@images/right_arrow.svg";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import ParticlesBackground from "@components/common/ParticlesBackground";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { kStringConstants } from "@utils/constants/StringConstants";
import { kErrorConstants } from "@utils/constants/ErrorConstants";
import * as userManager from "@utils/managers/UserManager";
import { Link, useNavigate } from "react-router-dom";
import { generateToast } from "@utils/managers/ToastManager";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const AccountCreationView = () => {
	const [isValidEmail, setValidEmail] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [emailAddress, setEmailAddress] = useState<string | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		userManager.logOutUser();
	}, []);

	const emailInputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
		const emailValue: string = e.currentTarget.value;
		setEmailAddress(emailValue);
		const isValid = checkEmail(emailValue);
		setValidEmail(isValid);
	};

	const goToMagicLink = (emailAddress) => {
		navigate("/magic-link", { state: { email: emailAddress } });
	};

	const continueButtonOnClick = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (!emailAddress || !isValidEmail) {
			return;
		}

		setLoading(true);

		networkManager
			.signUpUser(emailAddress)
			.then((_response) => {
				recordEvent(kAnalyticsConstants.Onboarding.accountSignUpSuccessful, { email: emailAddress });
				recordEvent(kAnalyticsConstants.Onboarding.magicLinkSent, { email: emailAddress, is_login: false });
				goToMagicLink(emailAddress);
			})
			.catch((error) => {
				setLoading(false);
				generateSignUpError(error);
				recordEvent(kAnalyticsConstants.Onboarding.accountSignUpError, { error: error.message, email: emailAddress });
			});
	};

	const generateSignUpError = (error) => {
		var title = kStringConstants.Common.errorAlertTitle;
		var message = kStringConstants.Common.errorAlertMessage;

		if (error == kErrorConstants.signUp.existingAccount) {
			title = "Account already exists!";
			message = "To access this account please log in.";
		}

		generateToast({ status: "error", title: title, message: message });
	};

	function checkEmail(email: string) {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email) && email != "";
	}

	return (
		<div>
			<ParticlesBackground />
			<div className="flex min-h-[100dvh] flex-col md:justify-center justify-start overflow-hidden py-2 md:py-12">
				<div className="flex flex-col md:gap-0 gap-10 items-center justify-center p-3">
					<div className="meco_container text-center max-w-[500px] py-[40px] md:p-[60px]">
						<div className="flex flex-col gap-2">
							<img src={collageBalloon} className="w-[90px] m-auto md:hidden" />
							<div className="text-white font-black font-primary text-3xl">Sign up to Meco</div>
							<div className="text-white/50 font-regular font-primary text-lg">We’ll email you a magic link so we can verify your email address.</div>
							<form className="flex flex-row mt-4 gap-3" onSubmit={continueButtonOnClick}>
								<div className="flex flex-col gap-3 flex-grow">
									<input type="email" disabled={isLoading} autoFocus={true} onChange={emailInputOnChange} className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Enter email address" />
									<div className="text-white/50 font-regular font-primary text-2xs text-center">
										By joining Meco, you consent to our{" "}
										<a href="https://www.meco.app/terms" target="_blank" className="text-white/50 hover:text-success-green transition ease-in-out">
											terms & conditions
										</a>
									</div>
								</div>
								<button type="submit" disabled={!isValidEmail || isLoading} className={`flex items-center justify-center focus:ring-2 focus:border-white focus:ring-white w-[55px] h-[55px] bg-success-green rounded-full font-regular font-primary text-lg ${isValidEmail ? "opacity-100" : "opacity-50"}`}>
									{isLoading ? <LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white" /> : <RightArrow className="fill-white h-[20px]" />}
								</button>
							</form>

							<div className="text-white font-regular font-primary text-lg mt-5">
								Already have an account?{" "}
								<Link to="/login" className="text-success-green hover:text-success-green transition ease-in-out">
									Log In
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AccountCreationView;
