import { useLayout } from "@providers/LayoutContext";
import { useEffect, useState } from "react";
import ConnectedMailboxes from "./sections/ConnectedMailboxes";
import Groups from "./sections/Groups";
import GiveFeedback from "./sections/GiveFeedback";
import Preferences from "./sections/Preferences";
import HelpSupport from "./sections/HelpSupport";
import { NLMailboxProfileType } from "@models/Models";
import { useLocation } from "react-router";
import LottieView from "@utils/LottieView";
import { useData } from "@providers/DataContext";
import { useNavigate } from "react-router-dom";
import { useMailsDispatch } from "@providers/MailContext";
import MecoPro from "./sections/MecoPro";

const Settings = () => {
	const { setSettingsActive } = useLayout();
	const location = useLocation();
	const navigate = useNavigate();
	const integratedMailboxProfileType: NLMailboxProfileType = location.state?.mailboxIntegrated;
	const [successMailboxIntegration, setSuccessMailboxIntegration] = useState<string | undefined>(undefined);
	const { fetchFreshData, setDigestNeedsReloading } = useData();
	const mailsDispatch = useMailsDispatch();

	useEffect(() => {
		setSettingsActive(true);
		navigate(location.pathname, { replace: true, state: {} });
	}, []);

	useEffect(() => {
		if (integratedMailboxProfileType) {
			mailsDispatch({ type: "CLEANUP" });
			fetchFreshData();
			setDigestNeedsReloading(true);

			switch (integratedMailboxProfileType) {
				case NLMailboxProfileType.Gmail:
					setSuccessMailboxIntegration("Gmail account added");
					break;
				case NLMailboxProfileType.Outlook:
					setSuccessMailboxIntegration("Outlook account added");
					break;
				case NLMailboxProfileType.Internal:
					setSuccessMailboxIntegration("Meco inbox created");
					break;
			}
		}
	}, [integratedMailboxProfileType]);

	return (
		<>
			<LottieView isShow={successMailboxIntegration != null} message={successMailboxIntegration} setIsShow={() => setSuccessMailboxIntegration(undefined)} />
			<div className="container mx-auto px-6 py-5">
				<div className="flex flex-col gap-5 max-w-2xl m-auto">
					<MecoPro />
					<ConnectedMailboxes />
					<Groups />
					<GiveFeedback />
					<Preferences />
					<HelpSupport />
				</div>
			</div>
		</>
	);
};

export default Settings;
