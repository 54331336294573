import React, { useState, useEffect, FC, Fragment } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { useHotkeys } from "react-hotkeys-hook";
import IconButton from "@components/common/IconButton";
import { ReactComponent as GmailIcon } from "@images/gmail_icon.svg";
import { ReactComponent as OutlookIcon } from "@images/outlook_logo.svg";
import { ReactComponent as MecoIcon } from "@images/meco_app_logo.svg";
import { ReactComponent as FilledArrow } from "@images/filled_arrow_icon.svg";
import { useAuth } from "@providers/AuthContext";
import { NLMail, NLMailboxProfile, NLMailboxProfileType, NLUserMailState, NLUserMailStateNames } from "@models/Models";
import Alert, { AlertState } from "@components/common/Alert";
import { Dialog, Transition } from "@headlessui/react";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import { LabelHealthService } from "@utils/managers/backendMailManager/mailboxFunctions/LabelHealthService";
import { GetUserMailStatesService } from "@utils/managers/backendMailManager/mailboxFunctions/GetUserMailStatesService";
import { useData } from "@providers/DataContext";
import { FiltersService } from "@utils/managers/backendMailManager/mailboxFunctions/FiltersService";
import { kErrorConstants } from "@utils/constants/ErrorConstants";

interface ResyncMailboxModalProps {
	mailboxProfile: NLMailboxProfile;
	onClose: () => void;
}

const ResyncMailboxModal: FC<ResyncMailboxModalProps> = ({ mailboxProfile, onClose }) => {
	const { userMailStates, setUserMailStates, changeStates, setDigestNeedsReloading } = useData();
	const oauthSessionManager = OauthSessionManager.sharedInstance();
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});

	enum ResyncStep {
		notStarted,
		authorizationStep,
		labelStep,
		fetchStatesBackendStep,
		gmailSyncStep,
		gmailFilterSyncStep,
		complete,
	}

	enum ResyncStatus {
		checking,
		success,
		error,
	}

	interface ResyncModel {
		step: ResyncStep;
		status: ResyncStatus;
		error?: string;
	}

	const [resyncStep, setResyncStep] = useState<ResyncModel>({ step: ResyncStep.authorizationStep, status: ResyncStatus.checking });
	const [authorizationStatus, setAuthorizationStatus] = useState<string | undefined>(undefined);
	const [labelStatus, setLabelStatus] = useState<string | undefined>(undefined);
	const [fetchStatesStatus, setFetchStatesStatus] = useState<string | undefined>(undefined);
	const [syncStatus, setSyncStatus] = useState<string | undefined>(undefined);
	const [filterSyncStatus, setFilterSyncStatus] = useState<string | undefined>(undefined);
	const [completeStatus, setCompleteStatus] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (!mailboxProfile) return;
		startResync();
	}, []);

	useEffect(() => {
		statusUpdated(resyncStep);
	}, [resyncStep]);

	const onModalClose = () => {
		onClose();
	};

	const startResync = () => {
		recordEvent(kAnalyticsConstants.ResyncGmail.resyncStarted);

		checkAuthorizationStatus()
			.then(() => {
				return checkAndCreateNewsletterLabels();
			})
			.then(() => {
				return getFreshUserMailStates();
			})
			.then((userMailStates) => {
				return syncStatesWithGmail(userMailStates);
			})
			.then(() => {
				return syncFilters();
			})
			.then(() => {
				recordEvent(kAnalyticsConstants.ResyncGmail.resyncCompleted);
				setDigestNeedsReloading(true);
				setResyncStep({ step: ResyncStep.complete, status: ResyncStatus.success });
			})
			.catch((error) => {
				if (error === kErrorConstants.mailboxProfiles.oauth.signInRequired) {
					const mailboxTypeName = getMailboxTypeName(mailboxProfile.type);
					setAlertState({ isShow: true, title: "Action required", message: `${mailboxTypeName} address is no longer connected. Please go back and reconnect this ${mailboxTypeName} address to continue to read and receive your newsletters in Meco.`, actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
				}
				recordEvent(kAnalyticsConstants.ResyncGmail.resyncCompletedWErrors, { error: error.message, mailbox_profile_id: mailboxProfile.id, mailbox_profile_email: mailboxProfile.email_address });
			});
	};

	const statusUpdated = (resyncModel: ResyncModel) => {
		const labelOrFolder = mailboxProfile.type == NLMailboxProfileType.Gmail ? "label" : "folder";
		const filtersOrRules = mailboxProfile.type == NLMailboxProfileType.Gmail ? "filters" : "rules";
		const mailboxTypeName = getMailboxTypeName(mailboxProfile.type);

		switch (resyncModel.step) {
			case ResyncStep.notStarted:
				setAuthorizationStatus(undefined);
				setLabelStatus(undefined);
				setFetchStatesStatus(undefined);
				setSyncStatus(undefined);
				setFilterSyncStatus(undefined);
				setCompleteStatus(undefined);
				break;
			case ResyncStep.authorizationStep:
				switch (resyncModel.status) {
					case ResyncStatus.checking:
						setAuthorizationStatus("⏳ Checking authorization status");
						break;
					case ResyncStatus.success:
						setAuthorizationStatus(`✅ Successfully authorized with ${mailboxTypeName}`);
						break;
					case ResyncStatus.error:
						setAuthorizationStatus(`❌ ${resyncModel.error}`);
						break;
				}
				break;
			case ResyncStep.labelStep:
				switch (resyncModel.status) {
					case ResyncStatus.checking:
						setLabelStatus(`⏳ Checking Meco ${labelOrFolder} on ${mailboxTypeName}`);
						break;
					case ResyncStatus.success:
						setLabelStatus(`✅ Checked and validated Meco ${labelOrFolder} on ${mailboxTypeName}`);
						break;
					case ResyncStatus.error:
						setLabelStatus(`❌ ${resyncModel.error}`);
						break;
				}
				break;
			case ResyncStep.fetchStatesBackendStep:
				switch (resyncModel.status) {
					case ResyncStatus.checking:
						setFetchStatesStatus(`⏳ Checking your newsletter preferences`);
						break;
					case ResyncStatus.success:
						setFetchStatesStatus(`✅ Successfully received your newsletter preferences`);
						break;
					case ResyncStatus.error:
						setFetchStatesStatus(`❌ ${resyncModel.error}`);
						break;
				}
				break;
			case ResyncStep.gmailSyncStep:
				switch (resyncModel.status) {
					case ResyncStatus.checking:
						setSyncStatus(`⏳ Syncing changes with ${mailboxTypeName}`);
						break;
					case ResyncStatus.success:
						setSyncStatus(`✅ Successfully synced changes with ${mailboxTypeName}`);
						break;
					case ResyncStatus.error:
						setSyncStatus(`❌ ${resyncModel.error}`);
						break;
				}
				break;
			case ResyncStep.gmailFilterSyncStep:
				switch (resyncModel.status) {
					case ResyncStatus.checking:
						setFilterSyncStatus(`⏳ Syncing ${labelOrFolder}) with ${mailboxTypeName}`);
						break;
					case ResyncStatus.success:
						setFilterSyncStatus(`✅ Successfully synced ${filtersOrRules} with ${mailboxTypeName}`);
						break;
					case ResyncStatus.error:
						setFilterSyncStatus(`❌ ${resyncModel.error}`);
						break;
				}
				break;
			case ResyncStep.complete:
				setCompleteStatus("✅ Successfully completed resyncing");
				break;
		}
	};

	const getMailboxTypeName = (type: string): string => {
		switch (type) {
			case NLMailboxProfileType.Gmail:
				return "Gmail";
			case NLMailboxProfileType.Outlook:
				return "Outlook";
		}

		return "";
	};

	const checkAuthorizationStatus = (): Promise<void> => {
		return new Promise((resolve, reject) => {
			setResyncStep({ step: ResyncStep.authorizationStep, status: ResyncStatus.checking });

			oauthSessionManager
				.fetchAccessTokenForMailboxProfile(mailboxProfile.id, mailboxProfile.type)
				.then((_accessToken) => {
					setResyncStep({ step: ResyncStep.authorizationStep, status: ResyncStatus.success });
					resolve();
				})
				.catch((error) => {
					setResyncStep({ step: ResyncStep.authorizationStep, status: ResyncStatus.error, error: `Failed to authorize with ${getMailboxTypeName(mailboxProfile.type)}. Please go back and sign-in with ${getMailboxTypeName(mailboxProfile.type)}.` });
					reject(error);
				});
		});
	};

	const checkAndCreateNewsletterLabels = (): Promise<void> => {
		return new Promise((resolve, reject) => {
			setResyncStep({ step: ResyncStep.labelStep, status: ResyncStatus.checking });

			const labelHealthService = new LabelHealthService(mailboxProfile);
			labelHealthService.checkLabelHealth((error) => {
				if (error) {
					let labelOrFolder = mailboxProfile.type == NLMailboxProfileType.Gmail ? "label" : "folder";
					setResyncStep({ step: ResyncStep.labelStep, status: ResyncStatus.error, error: `Your Meco ${labelOrFolder} on ${getMailboxTypeName(mailboxProfile.type)} doesn't exist. Please reach us at team@meco.app.` });
					reject(error);
					return;
				}
				setResyncStep({ step: ResyncStep.labelStep, status: ResyncStatus.success });
				resolve();
			});
		});
	};

	const getFreshUserMailStates = (): Promise<NLUserMailState[]> => {
		return new Promise((resolve, reject) => {
			setResyncStep({ step: ResyncStep.fetchStatesBackendStep, status: ResyncStatus.checking });

			const userMailStateRequest = new GetUserMailStatesService.QueryBuilder(mailboxProfile, null, false, null);
			const getUserMailStatesService = new GetUserMailStatesService([userMailStateRequest], [NLUserMailStateNames.new, NLUserMailStateNames.feed]);

			getUserMailStatesService.getUserMailStates((freshStates, _mailboxProfileIds, error) => {
				if (error) {
					setResyncStep({ step: ResyncStep.fetchStatesBackendStep, status: ResyncStatus.error, error: "Couldn't get your newsletter preferences. Please try again later." });
					reject(error);
					return;
				}

				var tempStates = userMailStates ?? [];
				tempStates = tempStates.filter((x) => x.mailbox_profile_id != mailboxProfile.id);
				if (freshStates) {
					tempStates.push(...freshStates);
				}
				setUserMailStates(tempStates);
				setResyncStep({ step: ResyncStep.fetchStatesBackendStep, status: ResyncStatus.success });
				resolve(freshStates ?? []);
			});
		});
	};

	const syncStatesWithGmail = (states: NLUserMailState[] | undefined): Promise<void> => {
		return new Promise((resolve, reject) => {
			setResyncStep({ step: ResyncStep.gmailSyncStep, status: ResyncStatus.checking });

			if (states && states.filter((x) => (x.state_id = NLUserMailStateNames.feed))) {
				changeStates([mailboxProfile], states, NLUserMailStateNames.feed, (error) => {
					if (error) {
						setResyncStep({ step: ResyncStep.gmailSyncStep, status: ResyncStatus.error, error: `We couldn't sync with ${getMailboxTypeName(mailboxProfile.type)}. Please reach us at team@meco.app.` });
						reject(error);
						return;
					}
					setResyncStep({ step: ResyncStep.gmailSyncStep, status: ResyncStatus.success });
					resolve();
				});
			} else {
				setResyncStep({ step: ResyncStep.gmailSyncStep, status: ResyncStatus.success });
				resolve();
			}
		});
	};

	const syncFilters = (): Promise<void> => {
		return new Promise((resolve, reject) => {
			setResyncStep({ step: ResyncStep.gmailFilterSyncStep, status: ResyncStatus.checking });
			const query = new FiltersService.QueryBuilder(mailboxProfile);
			const filterService = new FiltersService([query]);
			filterService.syncFilters((response, error) => {
				if (error) {
					let filtersOrRules = mailboxProfile.type == NLMailboxProfileType.Gmail ? "filters" : "rules";
					setResyncStep({ step: ResyncStep.gmailFilterSyncStep, status: ResyncStatus.error, error: `We couldn't create your ${getMailboxTypeName(mailboxProfile.type)} ${filtersOrRules}. Please reach us at team@meco.app.` });
					reject(error);
					return;
				}
				setResyncStep({ step: ResyncStep.gmailFilterSyncStep, status: ResyncStatus.success });
				resolve();
			});
		});
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={mailboxProfile !== undefined}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child className="w-full h-full" enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="m-auto w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-secondary border border-primary-200 text-left align-middle shadow-xl transition-all">
								<div className="absolute w-full bg-surface backdrop-blur-md top-0 left-0 flex flex-row justify-between items-center p-4 pb-3 border-b border-primary-200 z-20">
									<div className="text-primary font-medium font-primary text-xl">
										Resync with <span className="capitalize">{mailboxProfile.type}</span>
									</div>
									<IconButton Icon={ExitIcon} className="-mr-2.5" onClick={() => onModalClose()} />
								</div>
								<div className="flex flex-col gap-4 text-left h-full p-4 py-[105px] bg-secondary overflow-auto">
									<div className="text-center font-primary font-regular text-sm text-primary-500">Just be aware that this might take a bit of time. If you're still having issues after trying this, please reach out to us at team@meco.app.</div>
									<div className="text-center font-primary font-regular text-sm text-primary">{authorizationStatus}</div>
									<div className="text-center font-primary font-regular text-sm text-primary">{labelStatus}</div>
									<div className="text-center font-primary font-regular text-sm text-primary">{fetchStatesStatus}</div>
									<div className="text-center font-primary font-regular text-sm text-primary">{syncStatus}</div>
									<div className="text-center font-primary font-regular text-sm text-primary">{filterSyncStatus}</div>
									<div className="text-center font-primary font-bold text-lg text-primary">{completeStatus}</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default ResyncMailboxModal;
