import moment from "moment";

export function getDateFor(days) {
    var date = new Date();
    date.toLocaleDateString('en-US')
    date.setDate(date.getDate()+days);
    date.toISOString().slice(0,10)
    let stringDate = date.toISOString().slice(0,10).replaceAll("-", "/");
    return stringDate
}

export function dateToString() {
    var date = new Date();
    date.toLocaleDateString('en-US')
    date.toISOString().slice(0,10)
    let stringDate = date.toISOString().slice(0,10).replaceAll("-", "/");
    return stringDate
}

export function backendDateString() {
    var date = new Date();
    const momentDate = moment.utc(date);
    const formattedDate = momentDate.format('YYYY-MM-DD-HH-mm-ss');
    return formattedDate;
}

export function dateToEpochString(date) {
    const epochMilliseconds = moment(date).valueOf();
    return String(epochMilliseconds);
}

export function dateStringToAudioDate(dateString) {
    if (!dateString) { return undefined }
    const localDate = moment.utc(dateString).local();
    const formattedDate = localDate.format("MMM DD").toUpperCase();
    return formattedDate;
}

export function convertHourToUTC(hour) {
    const now = moment();
    const localDate = now.clone().set({ hour: hour, minute: 0, second: 0 });
    const utcDate = localDate.clone().utc();
    const utcHour = utcDate.hour();
    return utcHour;
}

export function convertHourToLocalTime(utcHour) {
    const now = moment().utc();
    const utcDate = now.clone().set({ hour: utcHour, minute: 0, second: 0 });
    const localDate = utcDate.clone().local();
    const localTimeString = localDate.format('HH:mm');
    return localTimeString;
}

export function convertHourFromUTC(utcHour) {
    const now = moment().utc();
    const utcDate = now.clone().set({ hour: utcHour, minute: 0, second: 0 });
    const localDate = utcDate.clone().local();
    const localHour = localDate.hour();
    return localHour;
}