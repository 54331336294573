import { useEffect, useState } from "react";
import Alert, { AlertState } from "@components/common/Alert";
import { AudioOnboardingProfile, AudioSenderModel, NLMail, NLProfile, NLUserGroup, NLUserMailState, NLUserSenderGroup } from "@models/Models";
import ParticlesBackground from "@components/common/ParticlesBackground";
import { GetUserMailStatesService } from "@utils/managers/backendMailManager/mailboxFunctions/GetUserMailStatesService";
import { NLUserMailStateNames } from "@models/Enums";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@providers/AuthContext";
import pickingFruit from "@images/collage_picking_fruit.png";
import { kErrorConstants } from "@utils/constants/ErrorConstants";
import { SyncMessagesService } from "@utils/managers/backendMailManager/mailboxFunctions/SyncMessagesService";
import AudioPickSendersRow from "./AudioPickSendersRow";
import { useAudioOnboardingProfile } from "@providers/AudioOnboardingContext";
import { usePremium } from "@providers/PremiumContext";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import { kStringConstants } from "@utils/constants/StringConstants";
import { deleteUserSenderGroup, createUserSenderGroups } from "@utils/managers/networking/NetworkManager";
import LoadingView, { LoadingState } from "@components/common/LoadingView";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

const AudioPickSenders = () => {
	const { audioOnboardingProfile, setAudioOnboardingProfile } = useAudioOnboardingProfile();
	const location = useLocation();
	const navigate = useNavigate();
	const alreadySelectedSenders: NLUserSenderGroup[] | undefined = location.state?.alreadySelectedSenders ?? undefined;
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const { authUser, setAuthUser } = useAuth();
	const { isPremium } = usePremium();
	const [isLimitReached, setLimitReached] = useState<boolean>(false);
	const [loadingState, setLoadingState] = useState<LoadingState>({
		isLoading: false,
	});
	
	useEffect(() => {
		if (!isPremium() || (!alreadySelectedSenders && authUser!.audio_profiles.length > 0)) {
			navigate("/audio");
			return;
		}
		if (!audioOnboardingProfile?.senderModelArray) {
			fetchData();
		}
	}, []);

	const fetchData = () => {
		var userMailStates: NLUserMailState[] = [];

		getUserMailStates(authUser!)
			.then((states) => {
				if (!(states.length > 0)) {
					setAlertState({
						isShow: true,
						title: "No newsletters found!",
						message: "You need newsletters to be able to set up your Daily Brief. Come back once you’ve subscribed to some newsletters - checkout our Discover section if you’re looking for a great selection!",
						actionButton: "OK",
						dismissButton: null,
						actionButtonAction: () => {
							navigateHome();
						},
					});
					return;
				}

				userMailStates = states;
				return getMessages(authUser!);
			})
			.then((mails) => {
				return processStates(userMailStates, mails!);
			})
			.then((audioSenders) => {
				var onboardingProfile = new AudioOnboardingProfile();
				onboardingProfile.senderModelArray = audioSenders;
				let selectedSenders = alreadySelectedSenders?.map((x) => x.sender_address);
				onboardingProfile.selectedSenders = selectedSenders ?? [];
				setAudioOnboardingProfile(onboardingProfile);
			})
			.catch((_) => {
				setAlertState({
					isShow: true,
					title: kStringConstants.Common.errorAlertTitle,
					message: kStringConstants.Common.errorAlertMessage,
					actionButton: "OK",
					dismissButton: null,
					actionButtonAction: () => {
						navigateHome();
					},
				});
			});
	};

	const getUserMailStates = (profile: NLProfile): Promise<NLUserMailState[]> => {
		return new Promise((resolve, reject) => {
			var userMailStateRequests: GetUserMailStatesService.QueryBuilder[] = [];

			for (let mailboxProfile of profile.mailbox_profiles) {
				const userMailStateRequest = new GetUserMailStatesService.QueryBuilder(mailboxProfile, null, false, null);
				userMailStateRequests.push(userMailStateRequest);
			}

			if (userMailStateRequests.length > 0) {
				const getUserMailStatesService = new GetUserMailStatesService(userMailStateRequests, [NLUserMailStateNames.feed]);

				getUserMailStatesService.getUserMailStates((userMailStates, _mailboxProfileIds, error) => {
					if (!userMailStates) {
						reject(kErrorConstants.commonBackendError);
						return;
					}

					if (error) {
						reject(error);
						return;
					}

					resolve(userMailStates);
				});
			} else {
				resolve([]);
			}
		});
	};

	const getMessages = (profile: NLProfile): Promise<NLMail[]> => {
		return new Promise((resolve, reject) => {
			var syncMessagesRequests: SyncMessagesService.QueryBuilder[] = [];

			for (let mailboxProfile of profile.mailbox_profiles) {
				const syncMessagesRequest = new SyncMessagesService.QueryBuilder(mailboxProfile);
				syncMessagesRequests.push(syncMessagesRequest);
			}

			if (syncMessagesRequests.length === 0) {
				resolve([]);
				return;
			}

			var syncMessagesService = new SyncMessagesService(syncMessagesRequests);

			syncMessagesService.fullSyncMessages((responseArray, error) => {
				if (error) {
					reject(error);
					return;
				}

				if (!responseArray) {
					reject(kErrorConstants.commonBackendError);
					return;
				}

				var messages: NLMail[] = [];

				for (let response of responseArray) {
					if (!response.message_list) {
						continue;
					}

					messages = messages ?? [];
					messages.push(...response.message_list);
				}

				resolve(messages);
			});
		});
	};

	const processStates = (userMailStates: NLUserMailState[], mails: NLMail[]): Promise<AudioSenderModel[]> => {
		return new Promise((resolve, reject) => {
			var allSenders: AudioSenderModel[] = [];

			if (!(userMailStates.length > 0)) {
				resolve(allSenders);
				return;
			}

			//Exclude Meco email addresses
			userMailStates = userMailStates.filter((x) => !x.sender_address.includes("@meco.app"));

			if (mails.length > 0) {
				mails = mails.sort((a, b) => +b.receive_date - +a.receive_date);

				for (let mail of mails) {
					let state = userMailStates.find((x) => x.sender_address === mail.sender_address);
					if (!state || allSenders.find((x) => x.userMailState.id === state.id)) {
						continue;
					}
					let audioSender: AudioSenderModel = { userMailState: state, mail: mail };
					allSenders.push(audioSender);
				}
			}

			var statesSenders: AudioSenderModel[] = [];
			let senderList = userMailStates.sort((a, b) => (a?.sender_name || "").localeCompare(b?.sender_name || ""));

			for (let userMailState of senderList) {
				if (!statesSenders.find((x) => x.userMailState.id === userMailState.id) && !allSenders.find((x) => x.userMailState.id === userMailState.id)) {
					let audioSender: AudioSenderModel = { userMailState: userMailState, mail: undefined };
					statesSenders.push(audioSender);
				}
			}

			allSenders.push(...statesSenders);

			resolve(allSenders);
		});
	};

	const listItemClicked = (senderAddress: string) => {
		var tempOnboardingProfile = { ...audioOnboardingProfile! };
		var currentArray = tempOnboardingProfile.selectedSenders;

		if (currentArray.includes(senderAddress)) {
			currentArray = currentArray.filter((x) => x !== senderAddress);
		} else {
			if (currentArray.length < 20) {
				currentArray.push(senderAddress);
			}
		}

		setLimitReached(currentArray.length >= 20);
		tempOnboardingProfile.selectedSenders = currentArray;
		setAudioOnboardingProfile(tempOnboardingProfile);
	};

	const navigateHome = () => {
		sessionStorage.removeItem(kLocalStorageKeys.Session.audioOnboardingProfile);
		navigate("/audio");
	};

	const updateOnClick = () => {
		if (!(audioOnboardingProfile?.selectedSenders.length ?? 0 > 0)) {
			return;
		}
		if (!alreadySelectedSenders) {
			return;
		}

		setLoadingState({ isLoading: true });

		const initialSet = new Set(alreadySelectedSenders.map((x) => x.sender_address) ?? []);
		const selectedSet = new Set(audioOnboardingProfile!.selectedSenders);

		const removedSenders = new Set([...initialSet].filter((x) => !selectedSet.has(x)));
		const addedSenders = new Set([...selectedSet].filter((x) => !initialSet.has(x)));

		const dispatchGroup: Promise<void>[] = [];

		if (removedSenders.size > 0) {
			removedSenders.forEach((removedSender) => {
				let userSenderGroup = alreadySelectedSenders.find((x) => x.sender_address === removedSender);
				if (userSenderGroup) {
					const promise = new Promise<void>((resolve) => {
						deleteUserSenderGroup(userSenderGroup.id)
							.then(() => {
								resolve();
							})
							.catch((error) => {
								resolve();
							});
					});
					dispatchGroup.push(promise);
				}
			});
		}

		if (addedSenders.size > 0) {
			const promise = new Promise<void>((resolve) => {
				var tempUserSenderGroups: NLUserSenderGroup[] = [];

				for (let senderAddress of Array.from(addedSenders)) {
					let newUserSenderGroup = new NLUserSenderGroup();
					newUserSenderGroup.sender_address = senderAddress;
					newUserSenderGroup.sender_address_index = 0;
					newUserSenderGroup.user_group = authUser!.audio_profiles[0].group_id;
					tempUserSenderGroups.push(newUserSenderGroup);
				}
	
				createUserSenderGroups(tempUserSenderGroups)
					.then((_) => {
						resolve();
					})
					.catch((error) => {
						resolve();
					});
			});
			dispatchGroup.push(promise);
		}

		Promise.all(dispatchGroup).finally(() => {
			recordEvent(kAnalyticsConstants.Audio.senedersUpdated, { "sender_count": audioOnboardingProfile?.selectedSenders.length ?? 0 })
			setLoadingState({ isLoading: false });
			navigateHome();
		});

	};


	return (
		<div>
			<LoadingView loadingState={loadingState} />
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<ParticlesBackground />
			<div className={`flex min-h-[100dvh] flex-col md:justify-center justify-start py-2 md:py-12`}>
				<div className="fixed top-0 bg-black/50 w-full p-3 z-30 backdrop-blur-lg border-b border-white/10 shadow-2xl">
					<div className="max-w-[1080px] w-full z-30 m-auto">
						<div className="flex justify-between items-center">
							<Link to={alreadySelectedSenders ? "/audio" : "/audio/get-started"} onClick={() => null}>
								<button className={`text-force-primary-light font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-white/20 hover:bg-brand-blue/80 transition ease-in-out whitespace-nowrap`}>Back</button>
							</Link>
							{alreadySelectedSenders ? (
								<button className={`text-white font-medium font-primary text-lg focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-success-green/80 transition ease-in-out whitespace-nowrap ${(audioOnboardingProfile?.selectedSenders?.length ?? 0) === 0 ? "opacity-50" : "opacity-100 hover:bg-success-green/100"}`} disabled={(audioOnboardingProfile?.selectedSenders?.length ?? 0) === 0} onClick={updateOnClick}>
									Done
								</button>
							) : (
								<Link to="/audio/schedule" onClick={() => { recordEvent(kAnalyticsConstants.Audio.sendersSelected, {"sender_count": audioOnboardingProfile?.selectedSenders.length ?? 0}) }}>
									<button className={`text-white font-medium font-primary text-lg focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-success-green/80 transition ease-in-out whitespace-nowrap ${(audioOnboardingProfile?.selectedSenders?.length ?? 0) === 0 ? "opacity-50" : "opacity-100 hover:bg-success-green/100"}`} disabled={(audioOnboardingProfile?.selectedSenders?.length ?? 0) === 0}>
										Next
									</button>
								</Link>
							)}
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-10 items-center justify-center p-3 mt-[50px] md:mt-[80px]">
					<div className="meco_container max-w-[1080px] py-[40px] md:p-[60px]">
						<div className="flex flex-col md:flex-row gap-5 items-start">
							<div className="sticky md:top-[90px] w-full md:w-1/2 flex flex-col gap-2 items-start">
								<img src={pickingFruit} className="h-[100px] object-contain" />
								<div className="text-white font-bold font-primary text-4xl leading-10">
									Pick senders for your
									<br />
									Daily Brief
								</div>
								<div className="text-force-primary-light font-medium font-primary text-base  md:text-xl">For best results select frequent newsletters that cover topics you’re excited to hear about daily.</div>
							</div>
							<div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-3 max-w-[450px] md:max-w-none m-auto md:min-w-[400px]">
								{!audioOnboardingProfile?.senderModelArray ? (
									<LoadingSpinner className="m-auto h-[30px] text-white/20 animate-spin fill-white" />
								) : (
									<div className="text-white font-medium font-primary text-3xl text-center w-full max-w-[600px] mb-5 no-select">
										{audioOnboardingProfile &&
											audioOnboardingProfile.senderModelArray &&
											audioOnboardingProfile.senderModelArray.map((senderModel, i) => {
												return <AudioPickSendersRow audioSenderModel={senderModel} isChecked={audioOnboardingProfile!.selectedSenders.includes(senderModel.userMailState.sender_address)} checkDidChange={(senderAddress) => listItemClicked(senderAddress)} isLimit={isLimitReached} />;
											})}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AudioPickSenders;
