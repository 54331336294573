import { NLAudioEpisode, NLAudioEpisodeChapter } from "@models/Models";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import React, { createContext, Dispatch, useReducer, useContext, ReactNode, useRef, useEffect } from "react";
import { postAudioListenHistory } from "@utils/managers/networking/NetworkManager";
import { generateAudioProperties, recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

type Action = { type: "PLAY_AUDIO"; payload: { episode: NLAudioEpisode } } | { type: "PLAY" } | { type: "PAUSE" } | { type: "SEEK"; payload: { to: number } } | { type: "SET_CURRENT_TIME"; payload: { audioProgress: number } } | { type: "SET_ACTIVE_CHAPTER_ID"; payload: { activeChapterId: string } } | { type: "MOVE_CHAPTER"; payload: { isNext: boolean } } | { type: "SET_PLAYBACK_SPEED"; payload: { playbackSpeed: number } } | { type: "RESET" };

interface AudioState {
	audio: HTMLAudioElement;
	isPlaying: boolean;
	episode: NLAudioEpisode | undefined;
	activeChapterId: string | undefined;
	audioProgress: number;
	volume: number;
	playbackSpeed: number;
	lastPlayedAt?: number;
}

const AudioContext = createContext<AudioState | undefined>(undefined);
const AudioDispatchContext = createContext<Dispatch<Action> | undefined>(undefined);

const audioReducer = (state: AudioState, action: Action) => {
	const moveChapter = (isNext: boolean): number | undefined => {
		let episode = state.episode;
		if (!episode) {
			return undefined;
		}

		let currentIndex: number | null = null;
		const currentDuration: number = Math.floor(state.audio.currentTime);
		const threshold: number = 2;

		for (let index = 0; index < episode.chapters.length; index++) {
			const chapter = episode.chapters[index];
			const startTime = chapter.start_time;
			const endTime = chapter.end_time;
			if (startTime != undefined && endTime != undefined) {
				if (currentDuration >= startTime && currentDuration < endTime) {
					currentIndex = index;
					break;
				}
			}
		}

		if (currentIndex === null) {
			return undefined;
		}

		if (isNext) {
			const nextIndex: number = currentIndex + 1;
			if (episode.chapters[nextIndex] && episode.chapters[nextIndex].start_time) {
				const newChapterStart = episode.chapters[nextIndex].start_time!;
				return newChapterStart;
			}
		} else {
			const currentChapterStart = episode.chapters[currentIndex].start_time;
			if (currentChapterStart != null) {
				if (currentDuration <= currentChapterStart + threshold) {
					const previousIndex: number = currentIndex - 1;
					if (episode.chapters[previousIndex] && episode.chapters[previousIndex].start_time !== undefined) {
						const newChapterStart = episode.chapters[previousIndex].start_time!;
						return newChapterStart;
					}
				} else {
					return currentChapterStart;
				}
			}
		}
	};
	
	const pause = (state: AudioState) => {
		state.audio.pause();
		logListenEvent(state)
	}

	function logListenEvent(state: AudioState) {
		let currentTime = state.audio.currentTime;
		let duration = state.audio.duration;

		if (!state.episode) {
			return;
		}

		if (!state.lastPlayedAt) {
			return;
		}
	
		const effectiveDuration = currentTime > 0 ? currentTime : duration;
	
		const differenceInSeconds = Math.floor((Date.now() - state.lastPlayedAt) / 1000);
		if (differenceInSeconds > 5) {
			var properties = generateAudioProperties(state.episode);
			properties["$duration"] = differenceInSeconds;
			recordEvent(kAnalyticsConstants.Audio.audioListened, properties);
			postAudioListenHistory(
				state.episode.id,
				effectiveDuration
			)
		}
	
		return;
	}

	switch (action.type) {
		case "PLAY_AUDIO":
			state.episode = action.payload.episode;
			state.audio.src = action.payload.episode.streaming_url ?? "";
			state.audio.load();
			state.audio.playbackRate = state.playbackSpeed;
			const completionPercentage = state.episode.resume_time / state.episode.duration;
			if (state.episode.resume_time > 0 && completionPercentage < 0.9) {
				state.audio.currentTime = state.episode.resume_time;
			}
			state.audio.play();
			return { ...state, episode: action.payload.episode, isPlaying: true, lastPlayedAt: Date.now() };
		case "PLAY":
			state.audio.playbackRate = state.playbackSpeed;
			state.audio.play();
			return { ...state, isPlaying: true, lastPlayedAt: Date.now() };
		case "PAUSE":
			pause(state);
			return { ...state, isPlaying: false, lastPlayedAt: undefined };
		case "SEEK":
			state.audio.currentTime = action.payload.to;
			state.audio.playbackRate = state.playbackSpeed;
			state.audio.play();
			return { ...state, isPlaying: true };
		case "MOVE_CHAPTER":
			let isNext = action.payload.isNext;
			let seekSecond = moveChapter(isNext);
			if (seekSecond == undefined) {
				return state;
			}
			state.audio.currentTime = seekSecond;
			state.audio.playbackRate = state.playbackSpeed;
			state.audio.play();
			return { ...state, isPlaying: true };
		case "SET_CURRENT_TIME":
			return { ...state, audioProgress: action.payload.audioProgress };
		case "SET_PLAYBACK_SPEED":
			state.audio.playbackRate = action.payload.playbackSpeed;
			return { ...state, playbackSpeed: action.payload.playbackSpeed };
		case "SET_ACTIVE_CHAPTER_ID":
			return { ...state, activeChapterId: action.payload.activeChapterId };
		case "RESET":
			pause(state);
			state.audio.currentTime = 0;
			state.episode = undefined;
			return { ...state, isPlaying: false, lastPlayedAt: undefined };
		default:
			return state;
	}
};

const AudioProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const audioRef = useRef(new Audio());
	const activeChapterIdRef = useRef<string | undefined>(undefined);

	useEffect(() => {
		return () => {
			audioRef.current.pause();
		};
	}, []);

	const getDefaultPlaybackSpeed = () => {
		let storedSpeed = localStorage.getItem(kLocalStorageKeys.Audio.playbackSpeed);
		if (!storedSpeed) {
			return 1.0;
		}
		return JSON.parse(storedSpeed);
	};

	const [state, dispatch] = useReducer(audioReducer, {
		audio: audioRef.current,
		isPlaying: false,
		episode: undefined,
		activeChapterId: undefined,
		audioProgress: 0,
		volume: 1.0,
		playbackSpeed: getDefaultPlaybackSpeed(),
		lastPlayedAt: undefined,
	});

	useEffect(() => {
		activeChapterIdRef.current = state.activeChapterId;
	}, [state.activeChapterId]);

	useEffect(() => {
		const audio = audioRef.current;

		const handleTimeUpdate = () => {
			updateCurrentChapter(audio.currentTime);
			dispatch({ type: "SET_CURRENT_TIME", payload: { audioProgress: audio.currentTime } });
		};

		audio.addEventListener("timeupdate", handleTimeUpdate);
		audio.addEventListener("durationchange", handleTimeUpdate);

		return () => {
			audio.removeEventListener("timeupdate", handleTimeUpdate);
			audio.removeEventListener("durationchange", handleTimeUpdate);
		};
	}, [state.episode]);

	const updateCurrentChapter = (currentTime: number) => {
		if (state.episode) {
			for (let index = 0; index < state.episode.chapters.length; index++) {
				const chapter = state.episode.chapters[index];
				const { start_time, end_time } = chapter;

				if (start_time !== null && end_time !== null) {
					if (currentTime >= (start_time ?? 0) && currentTime < (end_time ?? 0)) {
						if (activeChapterIdRef.current !== chapter.id) {
							activeChapterIdRef.current = chapter.id;
							dispatch({ type: "SET_ACTIVE_CHAPTER_ID", payload: { activeChapterId: chapter.id } });
						}
						break;
					}
					if (currentTime >= state.audio.duration) {
						dispatch({ type: "PAUSE" });
					}
				}
			}
		}
	};

	return (
		<AudioContext.Provider value={state}>
			<AudioDispatchContext.Provider value={dispatch}>{children}</AudioDispatchContext.Provider>
		</AudioContext.Provider>
	);
};

const useAudio = () => {
	const context = useContext(AudioContext);
	if (context === undefined) {
		throw new Error("useAudio must be used within a AudioProvider");
	}
	return context;
};

const useAudioDispatch = () => {
	const context = useContext(AudioDispatchContext);
	if (context === undefined) {
		throw new Error("useAudio must be used within a AudioProvider");
	}
	return context;
};

export { AudioProvider, useAudio, useAudioDispatch };
