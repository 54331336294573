import { useEffect, useState } from "react";
import Alert, { AlertState } from "@components/common/Alert";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import ParticlesBackground from "@components/common/ParticlesBackground";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "@providers/AuthContext";
import { NLMailboxProfile, NLMailboxProfileType, NLProfile } from "@models/Models";
import ReconnectMailboxView from "./ReconnectMailboxView";
import OauthSessionManager from "@utils/managers/oauth/OauthSessionManager";
import { kErrorConstants } from "@utils/constants/ErrorConstants";
import { kStringConstants } from "@utils/constants/StringConstants";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";

const ReconnectMailboxes = (props) => {
	const navigate = useNavigate();
	const { authUser, setAuthUser } = useAuth();
	const [accountsArray] = useState<NLMailboxProfile[]>(authUser!.mailbox_profiles.filter((x) => x.type != NLMailboxProfileType.Internal));
	const [successfulIntegrationIds, setSuccessfulIntegrationIds] = useState<string[]>([]);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});

	useEffect(() => {
		analyticsManager.recordEvent("OAU - Reconnect Presented", { account_count: accountsArray.length });
	}, []);

	const integrationOnClick = (mailboxProfile) => {
		const mailboxProfileType = NLMailboxProfileType.getByValue(mailboxProfile.type);

		switch (mailboxProfileType) {
			case NLMailboxProfileType.Gmail:
				OauthSessionManager.sharedInstance()
					.integrateWithGmail(mailboxProfile.email_address)
					.then(([profile, _mailboxProfileId]) => {
						onReconnectSuccess(profile, mailboxProfile);
					})
					.catch((error) => {
						analyticsManager.recordEvent("GMI - Reconnect Error", { error: error.message ?? "", is_reconnect_screen: true });
						handleSignInError(error);
					});

				break;
			case NLMailboxProfileType.Outlook:
				OauthSessionManager.sharedInstance()
					.integrateWithOutlook(mailboxProfile.email_address)
					.then(([profile, _mailboxProfileId]) => {
						onReconnectSuccess(profile, mailboxProfile);
					})
					.catch((error) => {
						analyticsManager.recordEvent("OUT - Reconnect Error", { error: error.message ?? "", is_reconnect_screen: true });
						handleSignInError(error);
					});
				break;
			default:
				return;
		}
	};

	const onReconnectSuccess = (profile: NLProfile, mailboxProfile: NLMailboxProfile) => {
		setAuthUser(profile);
		var tempSuccessfulIntegrationIds = [...successfulIntegrationIds];
		tempSuccessfulIntegrationIds.push(mailboxProfile.id);
		setSuccessfulIntegrationIds([...tempSuccessfulIntegrationIds]);

		if (accountsArray.length === tempSuccessfulIntegrationIds.length) {
			analyticsManager.recordEvent("OAU - Reconnect Successful", { account_count: accountsArray.length, is_reconnect_screen: true });
			goToNextPath();
		}
	};

	const handleSignInError = (error) => {
		var alertTitle = kStringConstants.Common.errorAlertTitle;
		var alertMessage = kStringConstants.Common.errorAlertMessage;

		if (error === kErrorConstants.oauthIntegration.permissionError) {
			alertTitle = "Please allow Gmail permissions";
			alertMessage = "We require these permissions to add and manage newsletters with Meco. We designed Meco with your privacy in mind. Your emails remain private and are only accessible by you.";
		}

		if (error === kErrorConstants.oauthIntegration.accountMismatch) {
			alertTitle = "Incorrect Gmail address";
			alertMessage = "The address you selected did not match the email above. Please retry with the correct Gmail.";
		}

		setAlertState({ isShow: true, title: alertTitle, message: alertMessage, actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
	};

	const goToNextPath = () => {
		const nextPath = sessionStorage.getItem(kLocalStorageKeys.Session.nextPath);
		if (nextPath) {
			navigate(nextPath, { replace: true });
			return;
		}
		navigate("/", { replace: true });
	};

	return (
		<div>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<ParticlesBackground />
			<div className="fixed top-0 bg-black/50 w-full p-3 z-30 backdrop-blur-lg border-b border-white/10 shadow-2xl">
				<div className="max-w-[1080px] w-full z-30 m-auto">
					<div className="flex justify-end items-center">
						<button className="text-white font-medium font-primary text-lg focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-white/20 hover:bg-brand-blue/80 transition ease-in-out whitespace-nowrap" onClick={() => goToNextPath()}>Skip</button>
					</div>
				</div>
			</div>
			<div className="flex min-h-[100dvh] flex-col md:justify-center justify-start py-2 md:py-12">
				<div className="flex flex-col gap-10 items-center justify-center p-3 mt-[50px] md:mt-[80px]">
					<div className="meco_container max-w-[1080px] py-[40px] md:p-[60px]">
						<div className="flex flex-col md:flex-row gap-5 items-start">
							<div className="sticky md:top-[90px] w-full md:w-1/2 flex flex-col gap-2">
								<div className="text-white font-bold font-primary text-4xl leading-10">Reconnect Mailboxes</div>
								<div className="text-force-primary-light font-medium font-primary text-base  md:text-xl">One or more of your mailboxes need to be reconnected so that you can continue to receive newsletters in Meco.</div>
							</div>
							<div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-3 max-w-[450px] md:max-w-none m-auto md:min-w-[400px]">
								{accountsArray &&
									accountsArray.map((mailboxProfile) => {
										return <ReconnectMailboxView key={mailboxProfile.id} mailboxProfile={mailboxProfile} isReconnected={successfulIntegrationIds.includes(mailboxProfile.id)} integrationOnClick={(mailboxProfile) => integrationOnClick(mailboxProfile)} />;
									})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReconnectMailboxes;
