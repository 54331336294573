import toast, { Toaster } from "react-hot-toast";

export interface ToastState {
	status?: "success" | "error" | undefined;
	title?: string | undefined;
	message?: string | undefined;
	action?: (data: any) => void;
	actionTitle?: string | undefined;
	position?: "bottom-center" | "bottom-right" | null;
	onDismiss?: () => void;
}

export const generateToast = (toastState: ToastState) => {
    var { status, title, message, position, action, actionTitle, onDismiss } = toastState;

	toast.remove();

	switch (status) {
		case "success":
			let successTimeoutId = setTimeout(() => {
				if (onDismiss) {
					onDismiss();
				}
				toast.dismiss(message);
			}, 4000);
			toast.success(() => getBody(title, message, action, actionTitle, successTimeoutId), getProperties(message, position, status));
			break;
		case "error":
			toast.error(() => getBody(title, message, action, actionTitle, null), getProperties(message, position, status));
			break;
		default:
			let errorTimeoutId = setTimeout(() => {
				if (onDismiss) {
					onDismiss();
				}
				toast.dismiss(message);
			}, 2000);
			toast(() => getBody(title, message, action, actionTitle, errorTimeoutId), getProperties(message, position, status));
			break;
	}
};

const getBody = (title, message, action, actionTitle, timeoutId) => {
	var messageAlpha = "100";
	if (title) {
		messageAlpha = "50";
	}

	return (
		<div className="flex flex-row items-center gap-4">
			<div className="font-primary">
				{title && <p className="font-medium text-lg text-white m-0">{title}</p>}
				{message && <p className={`font-regular text-base  m-0 text-white/${messageAlpha}`}>{message}</p>}
			</div>
			{action && actionTitle && (
				<button onClick={() => actionOnClick(timeoutId, action)} className={`p-[12px] py-1.5 rounded-lg whitespace-nowrap bg-force-primary-light/20 hover:bg-white/40 text-white transition ease-in-out duration-300`}>
					<div className="font-primary font-medium text-xs text-white">{actionTitle}</div>
				</button>
			)}
		</div>
	);
};

const getProperties = (message, position, status) => {
	return { id: message, duration: status === "error" ? 2000 : Infinity, style: { backgroundColor: "#000518", border: "1px rgba(238, 238, 238, 0.2) solid" }, position: position ? position : "top-center" };
};

const actionOnClick = (timeoutId, action) => {
	toast.dismiss();
	if (timeoutId) {
		clearTimeout(timeoutId);
	}
	action();
};
