import { FC, MouseEventHandler, useEffect, useState } from "react";
import { ReactComponent as LockIcon } from "@images/lock.shield.fill.svg";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import * as analyticsManager from "@utils/managers/AnalyticsManager";

export enum OauthPrivacyPopupState {
    hidden,
    visible
}

const OauthPrivacyPopup = (props) => {
	const [isVisible, setVisible] = useState(false);
	const [isLearnMore, setIsLearnMore] = useState(props?.isLearnMore ?? false);

	useEffect(() => {
		if (props.popupState !== OauthPrivacyPopupState.hidden) {
			document.body.style.overflow = "hidden";
			setVisible(true);
		}

		if (props.popupState === OauthPrivacyPopupState.hidden) {
			setTimeout(() => {
				document.body.style.overflow = "unset";
				setVisible(false);
				setIsLearnMore(false);
			}, 451);
		}
	}, [props.popupState]);

	useEffect(() => {
		if (isLearnMore) {
			analyticsManager.recordEvent("GMI - Privacy Details Viewed", { is_onboarding: props.isOnboarding ?? true });
		}
	}, [isLearnMore])

	return (
		<div className={`fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10 transition-all duration-450 ease-in-out z-30 ${props.popupState !== OauthPrivacyPopupState.hidden ? "opacity-1" : "opacity-0"} ${isVisible ? "visible overflow-hidden" : "invisible"}`}>
			<div className={`max-h-full w-full ${isLearnMore ? "max-w-3xl" : "max-w-[450px]"} overflow-y-auto rounded-2xl bg-white m-4`}>
				<div className="w-full">
					{isLearnMore ? (
						<div>
							<div className="flex flex-row justify-between items-center m-4">
								<div className="text-success-green font-bold font-primary text-xl">A little extra info...</div>
								<button onClick={() => props.setIsShow(false)}>
									<ExitIcon className="h-[26px] fill-brand-blue/50 hover:fill-brand-blue/100" />
								</button>
							</div>
							<div className="mx-auto text-left p-4 pt-0">
								<div className="flex flex-col items-center mb-1 gap-3">
									<div>
										<h1 className="text-brand-blue font-medium font-primary text-lg pb-2">How we work with {props.integrationType}</h1>
										<p className="text-brand-blue font-regular font-primary text-base  pb-3">We request some {props.integrationType} permissions to surface your current newsletters in Meco. Added newsletters will skip your {props.integrationType} inbox and be added to a Meco label, instantly decluttering your inbox. You can revert this back to normal at any time.</p>
									</div>
									<div>
										<h1 className="text-brand-blue font-medium font-primary text-lg pb-2">We don't store your emails</h1>
										<p className="text-brand-blue font-regular font-primary text-base  pb-3">We built Meco using industry-leading security practices that ensure your connection with {props.integrationType} is private and secure. Your emails are your business and not ours. Therefore we never store or process your email data.</p>
									</div>
									<div>
										<h1 className="text-brand-blue font-medium font-primary text-lg pb-2">We will never sell your data</h1>
										<p className="text-brand-blue font-regular font-primary text-base  pb-3">Selling data is a big no-no for us. Plus we collect as little data as possible - only some usage data and publishers you are subscribed to. This helps us ensure a great overall experience for you.</p>
									</div>
									<button className="cta-button w-full" onClick={() => props.agreeOnClick()}>
										Connect with {props.integrationType}
									</button>
								</div>
							</div>
						</div>
					) : (
						<div>
							<div className="flex flex-row justify-end m-4 mb-0">
								<button onClick={() => props.setIsShow(false)}>
									<ExitIcon className="h-[26px] fill-brand-blue/50 hover:fill-brand-blue/100" />
								</button>
							</div>
							<div className="mx-auto text-center p-4 pt-0">
								<div className="flex flex-col items-center mb-3 gap-3">
									<LockIcon className="w-[45px] fill-success-green" />
									<h1 className="text-brand-blue font-medium font-primary text-xl pb-2 leading-[1.2]">Your emails and data are private</h1>
									<p className="text-brand-blue font-regular font-primary text-base  pb-2">
										We require some {props.integrationType} permissions to add your newsletters to Meco. Your emails are stored securely on your device and only accessible by you. We never sell or share your data and you can remove this connection at any time. <div className="text-success-green inline cursor-pointer font-medium" onClick={() => setIsLearnMore(true)}>Learn more</div>.
									</p>
									<button className="cta-button w-full" onClick={() => props.agreeOnClick()}>
										OK
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default OauthPrivacyPopup;
