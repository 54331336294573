import { MouseEventHandler } from "react";

interface EmptyStateProps {
	alertImage?: string | undefined;
	title: string;
	description: string;
	isDisplayCta?: "Show More" | "Add mailbox" | undefined;
	ctaAction?: MouseEventHandler<HTMLButtonElement> | undefined;
	centerInParent?: boolean;
}

const EmptyStateView: React.FC<EmptyStateProps> = ({alertImage, title, description, isDisplayCta, ctaAction, centerInParent}) => {
	return (
		<div className={`w-full flex flex-col ${centerInParent ? "h-full" : "h-screen"} p-3 items-center justify-center gap-2 text-center`}>
            <img src={alertImage} className="max-w-[80px] object-contain"/>
            <div className="text-base  font-primary font-black text-primary">{title}</div>
            <div className="text-sm font-primary font-regular text-primary">{description}</div>
			{isDisplayCta && <button className="bg-primary-100 hover:bg-primary-200 transition ease-in-out duration-300 text-primary font-primary font-medium text-sm p-3 py-2 rounded-md mt-2" onClick={ctaAction}>{isDisplayCta}</button>}
		</div>
	);
};

export default EmptyStateView;
