import * as networkManager from "@utils/managers/networking/NetworkManager";
import { NLMailboxProfile, NLMailboxProfileType, NLProfile, NLUserMailState, NLUserMailStateNames, OnboardingProfile } from "../../models/Models";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import { ChangeUserMailStatesService } from "@utils/managers/backendMailManager/mailboxFunctions/ChangeUserMailStatesService";
import * as mailManager from "@utils/managers/MailManager";
import { FiltersService } from "@utils/managers/backendMailManager/mailboxFunctions/FiltersService";

export class MailboxIntegrationManager {
	onboardingProfile: OnboardingProfile;
	user: NLProfile;
	reference: MailboxIntegrationManager.Reference;

	constructor(onboardingProfile: OnboardingProfile, user: NLProfile, reference: MailboxIntegrationManager.Reference) {
		this.onboardingProfile = onboardingProfile;
		this.user = user;
		this.reference = reference;
	}

	applyNewsletterChanges = (): Promise<any[]> => {
		return new Promise((resolve, reject) => {
			var promises: Promise<any>[] = [];
			const oneClickSubscribePromise = this.postOneClickSubscribes(this.onboardingProfile);
			const feedArrayPromise = this.postFeedArray(this.onboardingProfile);
			const inboxArrayPromise = this.postInboxArray(this.onboardingProfile);
			promises = [oneClickSubscribePromise, feedArrayPromise, inboxArrayPromise];

			var resultsArray: any[] = [];

			Promise.all(promises)
				.then((results) => {
					resultsArray.push([...results]);
					return this.syncFilters(this.onboardingProfile);
				})
				.finally(() => {
					this.markProfileAsSetup(this.onboardingProfile, this.user, this.reference).then((profile) => {
						resultsArray.push(profile);
						resolve(resultsArray);
					});
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	postOneClickSubscribes = (onboardingProfile: OnboardingProfile): Promise<NLUserMailState[] | undefined> => {
		return new Promise((resolve, reject) => {
			const oneClickSubscribeArray: number[] = onboardingProfile.persistence.oneClickSubscribeArray.map((x) => x.id);

			if (oneClickSubscribeArray.length == 0) {
				resolve(undefined);
				return;
			}

			networkManager
				.postOneClickSubscribeNewsletters(oneClickSubscribeArray, kAnalyticsConstants.Reference.onboarding, false)
				.then((userMailStates) => {
					if (userMailStates.length > 0) {
						for (let userMailState of userMailStates) {
							analyticsManager.recordEvent(kAnalyticsConstants.App.oneClickSubscribeConversion, { sender_name: userMailState.sender_name ?? "", sender_address: userMailState.sender_address, reference: kAnalyticsConstants.Reference.onboarding });
						}
					}

					resolve(userMailStates);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	postFeedArray = (onboardingProfile: OnboardingProfile): Promise<NLUserMailState[] | undefined> => {
		return new Promise((resolve, reject) => {
			const feedArray: number[] = onboardingProfile.persistence.feedArray;

			if (feedArray.length == 0) {
				resolve(undefined);
				return;
			}

			const userMailStateRequests = new ChangeUserMailStatesService.QueryBuilder(onboardingProfile.persistence.mailboxProfile, NLUserMailStateNames.feed, onboardingProfile.persistence.feedArray);

			mailManager.changeState([userMailStateRequests], false, (userMailStates, error) => {
				if (error) {
					reject(error);
					return;
				}
				resolve(userMailStates);
			});
		});
	};

	postInboxArray = (onboardingProfile: OnboardingProfile): Promise<NLUserMailState[] | undefined> => {
		return new Promise((resolve, reject) => {
			const userMailStates: NLUserMailState[] = onboardingProfile.persistence.statesArray;

			if (userMailStates.length == 0) {
				resolve(undefined);
				return;
			}

			const feedArray: number[] = onboardingProfile.persistence.feedArray;

			const inboxArray = userMailStates.filter((x) => !feedArray.includes(x.id) && x.state_id === NLUserMailStateNames.new);
			const inboxSendersArray = inboxArray.map((x) => x.id) ?? [];

			if (inboxSendersArray.length == 0) {
				resolve(undefined);
				return;
			}

			const inboxRequestModel = new ChangeUserMailStatesService.QueryBuilder(onboardingProfile.persistence.mailboxProfile, NLUserMailStateNames.inbox, inboxSendersArray);

			mailManager.changeState([inboxRequestModel], false, (userMailStates, error) => {
				if (error) {
					reject(error);
					return;
				}
				resolve(userMailStates);
			});
		});
	};

	syncFilters = (onboardingProfile: OnboardingProfile): Promise<void> => {
		return new Promise((resolve, reject) => {
			const mailboxProfile = onboardingProfile.persistence.mailboxProfile;
			if (mailboxProfile.type == NLMailboxProfileType.Internal) {
				resolve();
				return;
			}

			const filterRequestModels = [new FiltersService.QueryBuilder(onboardingProfile.persistence.mailboxProfile)];
			const filterService = new FiltersService(filterRequestModels);

			filterService.syncFilters((syncFiltersResponse, error) => {
				if (!syncFiltersResponse || error) {
					reject(error);
					return;
				}

				const syncResponse = syncFiltersResponse.filter((x) => x.mailboxProfileId === mailboxProfile.id);

				if (syncResponse[0].error) {
					reject(syncResponse[0].error);
					return;
				}

				resolve();
			});
		});
	};

	markProfileAsSetup = (onboardingProfile: OnboardingProfile, user: NLProfile, reference: MailboxIntegrationManager.Reference): Promise<NLProfile | undefined> => {
		return new Promise((resolve, _reject) => {
			switch (reference) {
				case MailboxIntegrationManager.Reference.signUp:
					networkManager
						.updateUserProfile({ is_profile_setup: true })
						.then((updatedProfile) => {
							var modifiedMailboxProfile = new NLMailboxProfile(onboardingProfile.persistence.mailboxProfile);
							modifiedMailboxProfile.is_profile_setup = true;
							networkManager
								.updateMailboxProfile(modifiedMailboxProfile)
								.then((updatedProfile) => {
									resolve(updatedProfile);
								})
								.catch((_error) => {
									resolve(undefined);
								});
						})
						.catch((_error) => {
							resolve(undefined);
						});
					break;
				case MailboxIntegrationManager.Reference.nonSignUp:
					const mailboxProfile = onboardingProfile.persistence.mailboxProfile;
					if (mailboxProfile.type == NLMailboxProfileType.Internal) {
						resolve(user);
						return;
					}

					var modifiedMailboxProfile = new NLMailboxProfile(mailboxProfile);
					modifiedMailboxProfile.is_profile_setup = true;
					networkManager
						.updateMailboxProfile(modifiedMailboxProfile)
						.then((updatedProfile) => {
							resolve(updatedProfile);
						})
						.catch((_error) => {
							resolve(undefined);
						});
					break;
			}
		});
	};
}

export namespace MailboxIntegrationManager {
	export enum Reference {
		signUp,
		nonSignUp,
	}
}
