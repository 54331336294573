import React, { MouseEventHandler } from "react";
import { AudioModel, AudioProfileError, NLAudioEpisode } from "@models/Models";
import { ReactComponent as PlayIcon } from "@images/audio_play_icon.svg";
import { ReactComponent as PauseIcon } from "@images/audio_pause_icon.svg";

import { useAudio, useAudioDispatch } from "@providers/AudioContext";
import IconButton from "@components/common/IconButton";
import { dateStringToAudioDate } from "@utils/Date+GetDateFor";

interface AudioErrorBannerProps {
	audioProfileError?: string;
}

const AudioErrorBanner: React.FC<AudioErrorBannerProps> = ({ audioProfileError }) => {
	const generateAudioError = () => {
		switch (audioProfileError) {
			case AudioProfileError.NoSendersFound:
				return "No senders, no podcast! Please add some senders in Settings to get your Daily Brief.";
			case AudioProfileError.NoMesagesFound:
				return "There were not enough newsletters available to create your Daily Brief today! Try adding more senders via Settings.";
			default:
				return "We had an issue generating your Daily Brief. We will try again.";
		}
	};

	if (!audioProfileError) {
		return null;
	}

	return (
		<div className="w-full px-4">
			<div className="w-full border border-yellow-500 rounded-lg p-3">
				<div className="font-primary font-regular text-base text-primary">⚠️ {generateAudioError()}</div>
			</div>
		</div>
	);
};

export default AudioErrorBanner;
