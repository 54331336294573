import React, { createContext, useContext, ReactNode, useEffect } from "react";
import { NLMail, NLMailboxProfile, NLProfile, NLReaderMode, NLUserGroup, NLUserMailState, NLUserMailStateNames, NLUserSenderGroup } from "@models/Models";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { useAuth } from "./AuthContext";
import { kErrorConstants } from "@utils/constants/ErrorConstants";
import { GetUserMailStatesService } from "@utils/managers/backendMailManager/mailboxFunctions/GetUserMailStatesService";
import createPersistedState from "use-persisted-state";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import { TimeState, UnreadState } from "@models/Enums";
import { useBookmarksDispatch } from "./BookmarkContext";
import { ChangeUserMailStatesService } from "@utils/managers/backendMailManager/mailboxFunctions/ChangeUserMailStatesService";
import { changeState } from "@utils/managers/MailManager";
import { ReaderModeChoices } from "@utils/ReaderModeChoices";
import { recordEvent, refreshSuperProperties } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";

interface DataContextProps {
	digestIsUnread: UnreadState;
	setDigestUnread: (unreadState: UnreadState) => void;
	userGroups: NLUserGroup[] | undefined;
	setUserSenderGroups: (userSenderGroups: NLUserSenderGroup[] | undefined) => void;
	userSenderGroups: NLUserSenderGroup[] | undefined;
	setUserGroups: (userGroups: NLUserGroup[] | undefined) => void;
	activeGroup: NLUserGroup | undefined;
	setActiveGroup: (group: NLUserGroup | undefined) => void;
	isLoadingGroups?: boolean;
	setLoadingGroups: (isLoading: boolean | undefined) => void;
	isLoadingStates?: boolean;
	setLoadingStates: (isLoading: boolean | undefined) => void;
	userMailStates?: NLUserMailState[];
	setUserMailStates: (userMailStates: NLUserMailState[] | undefined) => void;
	activeUserMailState: NLUserMailState | undefined;
	setActiveUserMailState: (userMailState: NLUserMailState | undefined) => void;
	activeTimeFilter: TimeState;
	setActiveTimeFilter: (timeState: TimeState) => void;
	isLoading: boolean | undefined;
	activeMail: NLMail | undefined;
	setActiveMail: (activeMail: NLMail | undefined) => void;
	startingPointMail: NLMail | undefined;
	setStartingPointMail: (activeMail: NLMail | undefined) => void;
	isLoadingBookmarks: boolean | undefined;
	digestNeedsReloading: boolean;
	setDigestNeedsReloading: (digestNeedsReloading: boolean) => void;
	fetchFreshData: () => void;
	changeStates: (mailboxProfiles: NLMailboxProfile[], statesToBeChanged: NLUserMailState[], destinationAction: NLUserMailStateNames, completion: (error: Error | undefined) => void) => void;
	readerMode: NLReaderMode,
	setReaderMode: (readerMode: NLReaderMode) => void;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);
const useUnreadState = createPersistedState(kLocalStorageKeys.DigestFilters.unreadFilter);
const useTimeFilterState = createPersistedState(kLocalStorageKeys.DigestFilters.timeFilter);
const useReaderMode = createPersistedState(kLocalStorageKeys.App.readerMode);

export const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [isLoading, setLoading] = React.useState<boolean | undefined>(undefined);
	
	const [digestIsUnread, setDigestUnread] = useUnreadState(UnreadState.unread);
	const [digestNeedsReloading, setDigestNeedsReloading] = React.useState<boolean>(false);

	//Bookmarks
	const [isLoadingBookmarks, setLoadingBookmarks] = React.useState<boolean | undefined>(undefined);
	const bookmarksDispatch = useBookmarksDispatch();

	//Groups
	const [isLoadingGroups, setLoadingGroups] = React.useState<boolean | undefined>(undefined);
	const [userGroups, setUserGroups] = React.useState<NLUserGroup[] | undefined>(undefined);
	const [userSenderGroups, setUserSenderGroups] = React.useState<NLUserSenderGroup[] | undefined>(undefined);
	const [activeGroup, setActiveGroup] = React.useState<NLUserGroup | undefined>(undefined);

	//States
	const [isLoadingStates, setLoadingStates] = React.useState<boolean | undefined>(undefined);
	const [userMailStates, setUserMailStates] = React.useState<NLUserMailState[] | undefined>(undefined);
	const [activeUserMailState, setActiveUserMailState] = React.useState<NLUserMailState | undefined>(undefined);

	//Mails
	const [activeTimeFilter, setActiveTimeFilter] = useTimeFilterState(TimeState.last7days);
	const [activeMail, setActiveMail] = React.useState<NLMail | undefined>(undefined);
	const [startingPointMail, setStartingPointMail] = React.useState<NLMail | undefined>(undefined);

	//Reader Mode
	const [readerMode, setReaderMode] = useReaderMode<NLReaderMode>(ReaderModeChoices.getDefault());
	
	const { setAuthUser } = useAuth();

	useEffect(() => {
		fetchFreshData();
	}, []);

	const fetchFreshData = () => {
		setLoading(true);
		setLoadingBookmarks(true);
		setLoadingGroups(true);
		setLoadingStates(true);

		networkManager
			.getUserProfile(false)
			.then((profile) => {
				setAuthUser(profile);
				return Promise.allSettled([networkManager.getBookmarks(), networkManager.getUserGroups(), networkManager.getUserSenderGroups(), getUserMailStates(profile)]);
			})
			.then(([bookmarksResult, userGroupsResult, userSenderGroupsResult, userMailStatesResult]) => {
				if (bookmarksResult.status === "fulfilled") {
					bookmarksDispatch({ type: "SET_BOOKMARKS", payload: { bookmarks: bookmarksResult.value } });
					setLoadingBookmarks(false);
				}

				if (userGroupsResult.status === "fulfilled") {
					setUserGroups(userGroupsResult.value);
				}

				if (userSenderGroupsResult.status === "fulfilled") {
					setUserSenderGroups(userSenderGroupsResult.value);
					setLoadingGroups(false);
				}

				if (userMailStatesResult.status === "fulfilled") {
					setUserMailStates(userMailStatesResult.value);
					setLoadingStates(false);
				}
			})
			.catch(() => {
				setLoadingBookmarks(false);
				setLoadingGroups(false);
				setLoadingStates(false);
				setLoading(false);
			});
	};

	const getUserMailStates = (profile: NLProfile): Promise<NLUserMailState[]> => {
		return new Promise((resolve, reject) => {
			var userMailStateRequests: GetUserMailStatesService.QueryBuilder[] = [];

			for (let mailboxProfile of profile.mailbox_profiles) {
				const userMailStateRequest = new GetUserMailStatesService.QueryBuilder(mailboxProfile, null, false, null);
				userMailStateRequests.push(userMailStateRequest);
			}

			if (userMailStateRequests.length > 0) {
				const getUserMailStatesService = new GetUserMailStatesService(userMailStateRequests, [NLUserMailStateNames.new, NLUserMailStateNames.feed]);

				getUserMailStatesService.getUserMailStates((userMailStates, _mailboxProfileIds, error) => {
					if (!userMailStates) {
						reject(kErrorConstants.commonBackendError);
						return;
					}

					if (error) {
						reject(error);
						return;
					}

					refreshSuperProperties(profile, userMailStates);
					resolve(userMailStates);
				});
			} else {
				resolve([]);
			}
		});
	};

	const changeStates = (mailboxProfiles: NLMailboxProfile[], statesToBeChanged: NLUserMailState[], destinationAction: NLUserMailStateNames, completion: (error: Error | undefined) => void) => {
		var tempUserMailStates = [...(userMailStates ?? [])];
		const selectedStateIds = statesToBeChanged.map((x) => x.id);
		var newUserMailStates: NLUserMailState[] = [];

		for (const userMailState of tempUserMailStates) {
			if (selectedStateIds.includes(userMailState.id)) {
				userMailState.state_id = destinationAction;
			}
			newUserMailStates.push(userMailState);
		}

		setUserMailStates(newUserMailStates);

		const groupByMailboxId: { [mailboxProfileId: string]: NLUserMailState[] } = {};
		statesToBeChanged.forEach((state) => {
			if (!groupByMailboxId[state.mailbox_profile_id]) {
				groupByMailboxId[state.mailbox_profile_id] = [];
			}
			groupByMailboxId[state.mailbox_profile_id].push(state);
			recordEvent(kAnalyticsConstants.App.stateChanged, { [kAnalyticsConstants.App.NewsletterKeys.newsletterSenderAddress] : state.sender_address, [kAnalyticsConstants.App.NewsletterKeys.newsletterSenderName] : state.sender_name, "new_state" : destinationAction })
		});

		var userMailStateRequests: ChangeUserMailStatesService.QueryBuilder[] = [];

		for (const mailboxProfileId in groupByMailboxId) {
			const mailboxProfile = mailboxProfiles.find((x) => x.id === mailboxProfileId);
			if (mailboxProfile) {
				const userMailStateRequest = new ChangeUserMailStatesService.QueryBuilder(
					mailboxProfile,
					destinationAction,
					groupByMailboxId[mailboxProfileId].map((x) => x.id)
				);
				userMailStateRequests.push(userMailStateRequest);
			}
		}

		if (userMailStateRequests.length > 0) {
			changeState(userMailStateRequests, destinationAction === NLUserMailStateNames.feed, (userMailStates, error) => {
				completion(error);
			});
		}
	};

	const providerValue: DataContextProps = {
		digestIsUnread,
		setDigestUnread,
		userGroups,
		setUserGroups,
		userSenderGroups,
		setUserSenderGroups,
		activeGroup,
		setActiveGroup,
		isLoadingGroups,
		setLoadingGroups,
		isLoadingStates,
		setLoadingStates,
		userMailStates,
		setUserMailStates,
		activeUserMailState,
		setActiveUserMailState,
		activeTimeFilter,
		setActiveTimeFilter,
		isLoading,
		activeMail,
		setActiveMail,
		startingPointMail,
		setStartingPointMail,
		isLoadingBookmarks,
		digestNeedsReloading,
		setDigestNeedsReloading,
		fetchFreshData,
		changeStates,
		readerMode,
		setReaderMode,
	};

	return <DataContext.Provider value={providerValue}>{children}</DataContext.Provider>;
};

export const useData = () => {
	const context = useContext(DataContext);
	if (!context) {
		throw new Error("useMails must be used within an AuthProvider");
	}
	return context;
};
