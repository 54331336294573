import { NLMailboxProfile, NLUserMailState } from "@models/Models";
import { FC } from "react";
import { Textfit } from "react-textfit"

interface SenderListRowProps {
	userMailState: NLUserMailState;
	mailboxProfiles?: NLMailboxProfile[] | undefined;
	isSelected: boolean;
	onSelectedItem: (selectedItem: NLUserMailState) => void;
	isFlash: boolean;
}

const SenderListRow: FC<SenderListRowProps> = ({ userMailState, mailboxProfiles, isSelected, onSelectedItem, isFlash }) => {
	return (
		<div key={userMailState.id} className={`flex flex-col ${isSelected ? "bg-success-green scale-[1.02]" : "bg-surface hover:bg-surface-100"} border transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 w-full gap-2 items-start mb-[12px] shadow-md cursor-pointer ${isFlash ? "animate-flash" : ""}`} onClick={(e) => onSelectedItem(userMailState)}>
			<div className="flex flex-row items-center justify-between w-full gap-3">
				<div className="flex flex-col items-start text-left flex-grow">
					{ mailboxProfiles && mailboxProfiles.length > 1 && <Textfit mode="single" max={12} className={`${isSelected ? "text-white" : "text-primary"}  font-regular text-xs font-primary w-full`}>{mailboxProfiles.find((x) => x.id === userMailState.mailbox_profile_id)?.name}</Textfit> }
					<Textfit mode="single" max={18} className={`${isSelected ? "text-white" : "text-primary"}  font-medium text-lg font-primary w-full`}>{userMailState.sender_name}</Textfit>
					<Textfit mode="single" max={12} className={`${isSelected ? "text-white/50" : "text-primary-500"}  font-regular text-xs font-primary w-full`}>{userMailState.sender_address}</Textfit>
				</div>
                <div className="flex-shrink-0">
                    <input readOnly className={`checkbox-round ${isSelected ? "!border-white" : "!border-success-green"}`} type="checkbox" name="selectable-sender" checked={isSelected} value={userMailState.id} />
                </div>
			</div>
		</div>
	);
};

export default SenderListRow;
