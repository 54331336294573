import SectionLayout from "../../../layouts/SectionLayout";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { getCheckoutUrl } from "@utils/managers/networking/NetworkManager";
import { NLCreateCheckout, NLPurchaseProduct } from "@models/Models";
import Alert, { AlertState } from "@components/common/Alert";
import { ReactNode, useState } from "react";
import { usePremium } from "@providers/PremiumContext";
import { kAppConstants } from "@utils/constants/AppConstants";
import { yearlyPlan, monthlyPlan } from "@utils/constants/PaywallConstants";
import { useAuth } from "@providers/AuthContext";
import { PremiumStatus } from "@models/Enums";

const MecoPro = () => {
	const { isPremium } = usePremium();
	const { authUser } = useAuth();
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});

	const [selectedProduct, setSelectedProduct] = useState<NLPurchaseProduct>(yearlyPlan);
	const [isCreatingCheckout, setIsCreatingCheckout] = useState<boolean>(false);

	const purchaseProduct: NLPurchaseProduct[] = [yearlyPlan, monthlyPlan];

	const productOnSelect = (product: NLPurchaseProduct) => {
		setSelectedProduct(product);
	};

	const subscribeOnClick = () => {
		setIsCreatingCheckout(true);
		const createCheckout = new NLCreateCheckout(selectedProduct.price_id, window.location.href, window.location.href);
		getCheckoutUrl(createCheckout)
			.then((checkout) => {
				window.location.href = checkout.checkout_url;
			})
			.catch((_error) => {
				setIsCreatingCheckout(false);
				setAlertState({ isShow: true, title: "Uh oh! Something went wrong", message: "Please try again and contact us if the problem persists", actionButton: "OK", dismissButton: null, actionButtonAction: undefined });
			});
	};

	const renderPaywall = (isInternalTrial: boolean = false): ReactNode => {
		return (
			<div className="flex flex-row gap-4.5 flex-wrap">
				<div className="font-primary font-regular text-base text-primary">{isInternalTrial ? "We gifted you 7 days of PRO to give you a chance to try the full experience. If you are now ready to upgrade and want access going forward, please pick a plan below." : "Connect your Gmail and Outlook inboxes to move your newsletters to Meco while decluttering your inboxes. PRO includes features to help you read and learn more, including custom themes, fonts, dark mode and more."}</div>
				<div className="flex flex-row gap-2 w-full">
					{purchaseProduct.map((product) => {
						return (
							<button key={product.price_id} className={`relative flex flex-col ${product.price_id === selectedProduct.price_id ? "border-success-green" : "border-primary-100"} bg-surface hover:bg-surface-100 border duration-300 transition ease-in-out w-full item rounded-[15px] p-3 pt-4 gap-3`} onClick={() => productOnSelect(product)}>
								{product.badge && <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 px-3 py-2 bg-success-green text-white font-medium font-primary text-xs rounded-full">{product.badge}</div>}
								<div className="flex flex-col items-start justify-between w-full h-full gap-3">
									<div className="flex flex-col gap-0 w-full">
										<div className="flex flex-row gap-2 items-center justify-between w-full">
											<div className="text-left font-medium font-primary text-lg text-primary">{product.product_name}</div>
											<input readOnly className={`checkbox-round green`} type="checkbox" name="selectable-sender" checked={product.price_id === selectedProduct.price_id} value={product.price_id} />
										</div>
										{product.trial_days && <div className="flex flex-col items-start text-left flex-grow font-medium font-primary text-md text-success-green">7-day trial</div>}
									</div>
									<div className="flex flex-row gap-2 items-center">
										<div className="font-medium font-primary text-base text-primary">
											{product.monthly_price}
											<div className="inline font-regular text-xs pl-1">/MO</div>
										</div>
									</div>
								</div>
							</button>
						);
					})}
				</div>
				<div className="flex flex-col items-center justify-center gap-2 w-full">
					<button onClick={() => subscribeOnClick()} className="flex cta-button w-full">
						{isCreatingCheckout ? <LoadingSpinner className="h-[26px] text-white/20 animate-spin fill-white" /> : <div className="font-primary font-medium text-base text-white">{selectedProduct.trial_days ? "TRY " + selectedProduct.trial_days + " DAYS FOR FREE" : "Subscribe to unlock"}</div>}
					</button>
					<div className={`font-regular font-primary text-sm text-primary/50 ${selectedProduct.trial_days ? "opacity-100" : "opacity-0"}`}>
						Free for {selectedProduct.trial_days} days, then {selectedProduct.total_price}/{selectedProduct.term}. Cancel anytime.
					</div>
				</div>
			</div>
		);
	};

	return (
		<SectionLayout id="meco_pro" sectionTitle="Meco PRO - Unlock the smartest version of you">
			<Alert alertState={alertState} setAlertState={setAlertState} />
			{isPremium() ? authUser!.premium_status === PremiumStatus.internalTrial ? renderPaywall(true) : <div className="font-primary font-bold text-lg text-primary">You are a PRO user ✨</div> : renderPaywall()}
		</SectionLayout>
	);
};

export default MecoPro;
