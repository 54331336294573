import { useState, useEffect, useRef } from "react";
import { ReactComponent as BenefitHeart } from "@images/audio_onboarding_heart_icon.svg";
import { ReactComponent as BenefitWand } from "@images/gmail_benefit_wand.svg";
import { ReactComponent as BenefitHeadphones } from "@images/audio_onboarding_headphone_icon.svg";
import BenefitView, { BenefitViewState } from "../../common/integration-common/BenefitView";
import * as analyticsManager from "@utils/managers/AnalyticsManager";
import ParticlesBackground from "@components/common/ParticlesBackground";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { usePremium } from "@providers/PremiumContext";
import PaywallModal from "@components/common/PaywallModal";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import animation from "@images/soundwave_animation.json";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import { useAudioOnboardingProfile } from "@providers/AudioOnboardingContext";

const AudioIntro = () => {
	const navigate = useNavigate();
	const { audioOnboardingProfile, setAudioOnboardingProfile } = useAudioOnboardingProfile();
	const { isPremium } = usePremium();
	const [showPaywall, setShowPaywall] = useState<boolean>(false);
	const playerRef = useRef<LottieRefCurrentProps>(null);

	const benefits: BenefitViewState[] = [
		{
			icon: <BenefitHeart className="fill-force-primary-light w-[30px]" />,
			title: "Personalized to you",
			description: "Select which of your newsletters to include and we’ll package the highlights into a 5-10 minute daily podcast.",
		},
		{
			icon: <BenefitWand className="fill-force-primary-light w-[30px]" />,
			title: "No fluff or duplicate stories",
			description: "We remove needless fluff and combine repeated stories across newsletters to give you the key details fast.",
		},
		{
			icon: <BenefitHeadphones className="fill-force-primary-light w-[30px]" />,
			title: "Get your news, on the move",
			description: "Listen to the highlights from the key stories mentioned in your favorite newsletters with a coffee or while you’re on the move.",
		},
	];

	useEffect(() => {
		playerRef.current?.setSpeed(0.3);
	}, []);

	const getStartedOnClick = () => {
		sessionStorage.removeItem(kLocalStorageKeys.Session.audioOnboardingProfile);
		setAudioOnboardingProfile(undefined);
		if (isPremium()) {
			navigate("/audio/pick-senders");
		} else {
			setShowPaywall(true);
		}
	};

	return (
		<div>
			<PaywallModal isShow={showPaywall} onClose={() => setShowPaywall(false)} analyticsReference={kAnalyticsConstants.Reference.Paywall.audio} />
			<ParticlesBackground />
			<div className="flex min-h-[100dvh] flex-col md:justify-center justify-start overflow-hidden py-2 md:py-12">
				<div className="flex flex-col gap-10 items-center justify-center p-3">
					<div className="meco_container text-center max-w-[1080px] py-[40px] md:p-[60px] md:pb-[40px]">
						<div className="flex flex-col-reverse md:flex-row gap-5 max-w-[400px] md:max-w-none">
							<div className="w-full md:w-1/2 flex flex-col">
								<div className="flex flex-col gap-3">
									{benefits.map((benefit) => (
										<BenefitView key={benefit.title} benefit={benefit} />
									))}
									<div className="flex flex-col gap-0 md:hidden">
										<button className="flex m-auto cta-button w-full shadow-2xl mt-4" onClick={getStartedOnClick}>
											Get started
										</button>
										<Link to="/settings">
											<button className="text-force-primary-light font-medium font-primary text-base focus:outline-none whitespace-nowrap mt-3">Back</button>
										</Link>
									</div>
								</div>
							</div>
							<div className="w-full md:w-1/2 flex flex-col items-center justify-center gap-4 relative">
								<div>
									<Lottie className="w-full h-full absolute bottom-0 left-0 -z-10" animationData={animation} lottieRef={playerRef} loop={true} autoPlay={true} />
									<div className="text-success-green font-black font-primary text-base text-center">– DAILY BRIEF –</div>
									<div className="text-white font-medium font-primary text-3xl text-center md:leading-10 leading-9">A daily AI podcast made from your newsletters</div>
								</div>
								<button className="hidden md:flex cta-button w-full shadow-2xl" onClick={getStartedOnClick}>
									Get started
								</button>
							</div>
						</div>
						<div className="w-full hidden md:block">
							<div className="h-[1px] w-full bg-force-primary-light/20 mt-5"></div>
							<div className={`flex justify-start`}>
								<Link to="/audio">
									<button className="text-force-primary-light font-medium font-primary text-base focus:outline-none rounded-xl p-2 px-3 py-2 items-center bg-white/20 hover:bg-brand-blue/80 transition ease-in-out whitespace-nowrap mt-3">Back</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AudioIntro;
