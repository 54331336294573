import axios from "axios";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import * as networkConfig from "./NetworkConfig";
import * as Sentry from "@sentry/react";
import { kErrorConstants } from "@utils/constants/ErrorConstants";
import { NLOauthAccessToken, NLNewsletterCategory, NLProfile, NLUserGroup, NLUserMailState, NLRecommendedNewsletters, NLMailboxProfile, NLUserSenderGroup, NLBookmark, NLConsent, NLMail, NLCheckout, NLCreateCheckout, NLUserMailStateNames, NLNotificationSetTimeType, NLNotificationAsArrivedType, NLAudioSection, NLAudioSectionDetail, NLAudioProfile } from "../../../models/Models";
import { GetUserMailStatesService } from "../backendMailManager/mailboxFunctions/GetUserMailStatesService";
import { ChangeUserMailStatesService } from "../backendMailManager/mailboxFunctions/ChangeUserMailStatesService";
import { VerifyMecoStatusCode } from "../../../components/meco-integration/MecoInboxIntegration";
import { FiltersService } from "../backendMailManager/mailboxFunctions/FiltersService";
import { SyncMessagesService } from "../backendMailManager/mailboxFunctions/SyncMessagesService";
import { PartialSyncMessagesService } from "../backendMailManager/mailboxFunctions/PartialSyncMessagesService";
import { GetMessagesService } from "../backendMailManager/mailboxFunctions/GetMessagesService";
import { QueryMessagesService } from "../backendMailManager/mailboxFunctions/QueryMessagesService";
import { DeleteUndeleteMessagesService } from "../backendMailManager/mailboxFunctions/DeleteUndeleteMessagesService";
import { ModifyMessagesService } from "../backendMailManager/mailboxFunctions/ModifyMessagesService";
import { SearchSenderService } from "../backendMailManager/mailboxFunctions/SearchSenderService";
import { CreateSenderService } from "../backendMailManager/mailboxFunctions/CreateSenderService";
import { MxResult, NLNotificationPreferenceType } from "@models/Enums";
import { ReaderModeService } from "../backendMailManager/mailboxFunctions/ReaderModeService";
import { kAppConstants } from "@utils/constants/AppConstants";
import { LabelHealthService } from "../backendMailManager/mailboxFunctions/LabelHealthService";
import { ReplyMessageService } from "../backendMailManager/mailboxFunctions/ReplyMessageService";
import { MessageSummaryService } from "../backendMailManager/mailboxFunctions/MessageSummaryService";

function headers() {
	let header = { "Content-Type": "application/json" };
	return header;
}

function paramsAppended(url: string) {
	if (url.includes("?")) {
		return url + "&platform=web";
	}

	return url + "?platform=web";
}

const api = axios.create({
	withCredentials: true,
	baseURL: networkConfig.baseURL(),
});

api.interceptors.request.use((request) => {
	if (kAppConstants.appStage !== "production") {
		console.log("Starting Request", JSON.stringify(request, null, 2));
	}
	return request;
});

api.interceptors.response.use((response) => {
	if (kAppConstants.appStage !== "production") {
		console.log("Response:", JSON.stringify(response, null, 2));
	}
	return response;
});

function isUserAuthenticated(error: any) {
	if ((error.response?.status ?? 0) === 403) {
		window.location.href = "/login?expired=true";
	}
}

export function getUserProfile(logOutUserIfUnauthorized: boolean = true): Promise<NLProfile> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.userProfile), { headers: headers() })
			.then((response) => {
				try {
					const userProfile = new NLProfile(response["data"]);
					Sentry.setUser({ id: userProfile.id, email: userProfile.email });
					resolve(userProfile);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				if (logOutUserIfUnauthorized) {
					isUserAuthenticated(error);
				}
				reject(error);
			});
	});
}

export function updateUserProfile(params): Promise<NLProfile> {
	return new Promise((resolve, reject) => {
		api
			.put(paramsAppended(networkConfig.Endpoints.userProfile), params, { headers: headers() })
			.then((response) => {
				try {
					var userProfile = new NLProfile(response["data"]);
					resolve(userProfile);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function postAnalyticsEvents(eventName: string, properties: Record<string, any> = {}): Promise<void> {
	return new Promise((resolve, reject) => {
		var data = { event_name: eventName };

		if (properties) {
			for (var key in properties) {
				data[key] = properties[key];
			}
		}

		api
			.post(paramsAppended(networkConfig.Endpoints.postEvents), data, { headers: headers() })
			.then((_response) => {
				resolve();
			})
			.catch((error) => {
				resolve();
			});
	});
}

export function postShareMail(mail: NLMail): Promise<string> {
	return new Promise((resolve, reject) => {
		var data = { sender_name: mail.sender_name, sender_address: mail.sender_address };

		if (mail.subject && mail.subject?.length > 0) {
			data["mail_subject"] = mail.subject;
		}

		if (mail.body_html && mail.body_html?.length > 0) {
			data["mail_body"] = mail.body_html;
		}

		if (mail.snippet && mail.snippet?.length > 0) {
			data["mail_snippet"] = mail.snippet;
		}

		api
			.post(paramsAppended(networkConfig.Endpoints.postShareMail), data, { headers: headers() })
			.then((response) => {
				let sharedNewsletterUrl = response.data["shared_newsletter_url"];
				resolve(sharedNewsletterUrl);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getNewsletterCategories(): Promise<NLNewsletterCategory[]> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.getNewsletterCategories), { headers: headers() })
			.then((response) => {
				let categories = response["data"]["newsletter_categories"];
				let newsletterCategories = categories.map((category) => new NLNewsletterCategory(category));
				resolve(newsletterCategories);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function postNewsletterCategories(categoryIds: string[]): Promise<NLNewsletterCategory[]> {
	return new Promise((resolve, reject) => {
		let data = { user_categories: categoryIds };

		api
			.post(paramsAppended(networkConfig.Endpoints.getNewsletterCategories), data, { headers: headers() })
			.then((response) => {
				let categories = response["data"]["newsletter_categories"];
				let newsletterCategories = categories.map((category) => new NLNewsletterCategory(category));
				resolve(newsletterCategories);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getOnboardingRecommendedNewsletters(maxRecommendation): Promise<NLRecommendedNewsletters> {
	return new Promise((resolve, reject) => {
		api
			.get(networkConfig.Endpoints.getOnboardingRecommendedNewsletters + "?max_recommended_newsletter_count=" + maxRecommendation, { headers: headers() })
			.then((response) => {
				let recommendedNewsletters = new NLRecommendedNewsletters(response["data"]);
				resolve(recommendedNewsletters);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function postOneClickSubscribeNewsletters(newsletterIdArray: number[], reference: string, syncGmailFilters: boolean = true): Promise<NLUserMailState[]> {
	return new Promise((resolve, reject) => {
		var data = { one_click_subscribe_newsletter_ids: newsletterIdArray, reference: reference };

		if (syncGmailFilters) {
			data["sync_gmail_filters"] = true;
		}

		api
			.post(paramsAppended(networkConfig.Endpoints.postOneClickSubscribeNewsletters), data, { headers: headers() })
			.then((response) => {
				let userMailStates = response["data"]["user_mail_states"].map((userMailState) => new NLUserMailState(userMailState));
				resolve(userMailStates);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getConsents(): Promise<NLConsent[]> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.getConsents), { headers: headers() })
			.then((response) => {
				let consents = response["data"]["consents"].map((consent) => new NLConsent(consent));
				resolve(consents);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function postConsent(consentId: number): Promise<NLConsent[]> {
	return new Promise((resolve, reject) => {
		let data = { id: consentId };
		api
			.post(paramsAppended(networkConfig.Endpoints.getConsents), data, { headers: headers() })
			.then((response) => {
				let consents = response["data"]["consents"].map((consent) => new NLConsent(consent));
				resolve(consents);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function deleteConsent(consentId: number): Promise<NLConsent[]> {
	return new Promise((resolve, reject) => {
		let data = { id: consentId };
		api
			.delete(paramsAppended(networkConfig.Endpoints.getConsents), { headers: headers(), data: data })
			.then((response) => {
				let consents = response["data"]["consents"].map((consent) => new NLConsent(consent));
				resolve(consents);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getAppConfig(completionHandler) {
	api
		.get(paramsAppended(networkConfig.Endpoints.getAppConfig), { headers: null })
		.then((response) => {
			completionHandler(response, null);
		})
		.catch((error) => {
			completionHandler(null, error);
		});
}

export function getUserGroups(): Promise<NLUserGroup[]> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.getUserGroups), { headers: headers() })
			.then((response) => {
				let parsedResult = response["data"];
				let groups = parsedResult["user_groups"];
				if (Array.isArray(groups)) {
					groups = groups.filter((group) => group["group_name"] !== "$all");
				}

				let userGroups: NLUserGroup[] = groups.map((x) => new NLUserGroup(x));
				resolve(userGroups);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getUserSenderGroups(skip: number = 0): Promise<NLUserSenderGroup[]> {
	return new Promise((resolve, reject) => {
		const pageSize = 20;
		api
			.get(paramsAppended(networkConfig.Endpoints.getUserSenderGroups) + "&pageSize=" + pageSize + "&skip=" + skip, { headers: headers() })
			.then((response) => {
				let parsedResult = response["data"];
				let groups = parsedResult["user_sender_groups"];
				let userSenderGroups: NLUserSenderGroup[] = groups.map((x) => new NLUserSenderGroup(x));
				if (parsedResult["has_next"]) {
					getUserSenderGroups(pageSize + skip).then((moreUserSenderGroups) => {
						resolve(userSenderGroups.concat(moreUserSenderGroups));
					});
				} else {
					resolve(userSenderGroups);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function createUserSenderGroups(senderGroupArray): Promise<NLUserSenderGroup[]> {
	return new Promise((resolve, reject) => {
		let data = { user_sender_groups: senderGroupArray };
		api
			.post(paramsAppended(networkConfig.Endpoints.getUserSenderGroups), data, { headers: headers() })
			.then((response) => {
				let parsedResult = response["data"];
				let groups = parsedResult["user_sender_groups"];
				let userSenderGroups: NLUserSenderGroup[] = groups.map((x) => new NLUserSenderGroup(x));
				resolve(userSenderGroups);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function deleteUserSenderGroup(userSenderGroupId: number): Promise<void> {
	return new Promise((resolve, reject) => {
		let data = { id: userSenderGroupId };
		api
			.delete(paramsAppended(networkConfig.Endpoints.getUserSenderGroups), { headers: headers(), data: data })
			.then((_response) => {
				resolve();
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function postUserGroups(userGroupsArray: NLUserGroup[]): Promise<NLUserGroup[]> {
	return new Promise((resolve, reject) => {
		let data = { user_groups: userGroupsArray };

		api
			.post(paramsAppended(networkConfig.Endpoints.getUserGroups), data, { headers: headers() })
			.then((response) => {
				let parsedResult = response["data"];
				let groups = parsedResult["user_groups"];
				if (Array.isArray(groups)) {
					groups = groups.filter((group) => group["group_name"] !== "$all");
				}
				let userGroups: NLUserGroup[] = groups.map((x) => new NLUserGroup(x));
				userGroups.sort((a, b) => (b.group_index ?? 0) - (a.group_index ?? 0));
				resolve(userGroups);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function deleteUserGroup(userGroupId) {
	return new Promise((resolve, reject) => {
		let data = { id: userGroupId };

		api
			.delete(paramsAppended(networkConfig.Endpoints.getUserGroups), { headers: headers(), data: data })
			.then((_response) => {
				resolve(null);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function signUpUser(emailAddress: string) {
	return new Promise((resolve, reject) => {
		
		let campaignId = sessionStorage.getItem(kLocalStorageKeys.Session.utmCampaign);
		let data = { email: emailAddress };

		if (campaignId) {
			data["campaign_id"] = campaignId;
		}

		api
			.post(paramsAppended(networkConfig.Endpoints.signUpUser), data, { headers: headers() })
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				var internalError = error;
				if (error.response.status == 409) {
					internalError = kErrorConstants.signUp.existingAccount;
				}
				reject(internalError);
			});
	});
}

export function logInUser(emailAddress) {
	return new Promise((resolve, reject) => {
		let data = { email: emailAddress };

		api
			.post(paramsAppended(networkConfig.Endpoints.logInUser), data, { headers: headers() })
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				var internalError = error;
				if (error.response.status == 404) {
					internalError = kErrorConstants.logIn.nonExistantAccount;
				}
				reject(internalError);
			});
	});
}

export function validateMagicLinkToken(magicLinktoken): Promise<void> {
	return new Promise((resolve, reject) => {

		let data = { magic_link_token: magicLinktoken };

		api
			.post(paramsAppended(networkConfig.Endpoints.validateMagicLink), data, { headers: headers() })
			.then((_response) => {
				sessionStorage.removeItem(kLocalStorageKeys.Session.utmCampaign);
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function validateMagicCode(code): Promise<Record<string, any>> {
	return new Promise((resolve, reject) => {
		let data = { magic_link_code: code };

		api
			.post(paramsAppended(networkConfig.Endpoints.validateMagicCode), data, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getDomainMXRecord(emailAddress): Promise<MxResult | undefined> {
	return new Promise((resolve, reject) => {
		let data = { email_address: emailAddress };

		api
			.post(paramsAppended(networkConfig.Endpoints.getDomainMXRecord), data, { headers: headers() })
			.then((response) => {
				try {
					var result = response && response["data"]["mx_result"];
					let mxResult = MxResult[result as keyof typeof MxResult];
					resolve(mxResult);
				} catch {
					resolve(undefined);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				resolve(undefined);
			});
	});
}

export function createGmailMailboxProfile(serverAuthCode: string, redirectUri: string, loginHint?: string): Promise<[NLProfile, string]> {
	return new Promise((resolve, reject) => {
		let data = { server_auth_code: serverAuthCode, redirect_uri: redirectUri, login_hint: loginHint };
		api
			.post(paramsAppended(networkConfig.Endpoints.gmailMailboxProfile), data, { headers: headers() })
			.then((response) => {
				try {
					const userProfile = new NLProfile(response["data"]);
					const mailboxProfileId = response.headers["mailbox_profile_id"];
					resolve([userProfile, mailboxProfileId]);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				var internalError = error;
				if (error.response.status === 409) {
					internalError = kErrorConstants.oauthIntegration.alreadyIntegratedAccount;
				}
				if (error.response.status === 406) {
					internalError = kErrorConstants.oauthIntegration.accountMismatch;
				}
				reject(internalError);
			});
	});
}

export function createOutlookMailboxProfile(serverAuthCode: string, redirectUri: string, loginHint?: string): Promise<[NLProfile, string]> {
	return new Promise((resolve, reject) => {
		let data = { server_auth_code: serverAuthCode, redirect_uri: redirectUri, login_hint: loginHint };
		api
			.post(paramsAppended(networkConfig.Endpoints.outlookMailboxProfile), data, { headers: headers() })
			.then((response) => {
				try {
					const userProfile = new NLProfile(response["data"]);
					const mailboxProfileId = response.headers["mailbox_profile_id"];
					resolve([userProfile, mailboxProfileId]);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				var internalError = error;
				if (error.response.status === 409) {
					internalError = kErrorConstants.oauthIntegration.alreadyIntegratedAccount;
				}
				if (error.response.status === 406) {
					internalError = kErrorConstants.oauthIntegration.accountMismatch;
				}
				reject(internalError);
			});
	});
}

export function getGoogleAccessToken(mailboxProfileId: string): Promise<NLOauthAccessToken> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.getGoogleAccessToken + "/" + mailboxProfileId), { headers: headers() })
			.then((response) => {
				try {
					const accessToken = response["data"]["google_auth_access_token"];
					const expiresIn = response["data"]["expires_in"];
					const gmailAccessToken = new NLOauthAccessToken(mailboxProfileId, accessToken, expiresIn);
					resolve(gmailAccessToken);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getOutlookAccessToken(mailboxProfileId: string): Promise<NLOauthAccessToken> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.getOutlookAccessToken + "/" + mailboxProfileId), { headers: headers() })
			.then((response) => {
				try {
					const accessToken = response["data"]["outlook_auth_access_token"];
					const expiresIn = response["data"]["expires_in"];
					const outlookAccessToken = new NLOauthAccessToken(mailboxProfileId, accessToken, expiresIn);
					resolve(outlookAccessToken);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function logOutUser(): Promise<void> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.logOutUser), { headers: headers() })
			.then((_response) => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getDiscoverRecommendations(categoryId): Promise<NLRecommendedNewsletters> {
	return new Promise((resolve, reject) => {
		var urlParameter = "?trending=true";

		if (categoryId) {
			urlParameter = "?category_id=" + categoryId;
		}

		api
			.get(paramsAppended(networkConfig.Endpoints.getDiscoverRecommendedNewsletters + urlParameter), { headers: headers() })
			.then((response) => {
				let recommendedNewsletters = new NLRecommendedNewsletters(response["data"]);
				resolve(recommendedNewsletters);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function verifyMecoMailboxUsername(username: string): Promise<number> {
	return new Promise<VerifyMecoStatusCode>((resolve, reject) => {
		let data = { username: username };
		api
			.post(paramsAppended(networkConfig.Endpoints.verifyMecoMailboxUsername), data, { headers: headers() })
			.then((response) => {
				resolve(response.status);
			})
			.catch((error) => {
				resolve(error.response?.status);
			});
	});
}

export function createMecoMailboxProfile(username: string, fullName: string | null): Promise<NLProfile> {
	return new Promise<NLProfile>((resolve, reject) => {
		var data = { username: username };

		if (fullName) {
			data["full_name"] = fullName;
		}

		api
			.post(paramsAppended(networkConfig.Endpoints.mecoMailboxProfile), data, { headers: headers() })
			.then((response) => {
				try {
					var userProfile = new NLProfile(response["data"]);
					resolve(userProfile);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getUserMailStates(requests: GetUserMailStatesService.RequestModel[], stateTypes: NLUserMailStateNames[] | undefined): Promise<Record<string, any>> {
	const bodyParameters = {
		user_mail_states: requests,
	};

	var queryParameters = "";
	if (stateTypes) {
		for (let stateType of stateTypes) {
			queryParameters += "&state_type=" + stateType;
		}
	}

	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxGetUserMailStates) + queryParameters, bodyParameters, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function changeUserMailStates(requests: ChangeUserMailStatesService.RequestModel[]): Promise<Record<string, any>> {
	const bodyParameters = {
		change_states: requests,
	};

	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxChangeUserMailStates), bodyParameters, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function updateMailboxProfile(mailboxProfile: NLMailboxProfile): Promise<NLProfile> {
	return new Promise((resolve, reject) => {
		api
			.put(paramsAppended(networkConfig.Endpoints.updateMailboxProfile), mailboxProfile, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function syncFilters(requests: FiltersService.RequestModel[]): Promise<Record<string, any>> {
	const bodyParameters = {
		sync_filters: requests,
	};

	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxSyncGmailFilters), bodyParameters, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function syncMessages(requests: SyncMessagesService.RequestModel[]): Promise<Record<string, any>> {
	const bodyParameters = {
		sync: requests,
	};

	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxSyncMessages), bodyParameters, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function partialSyncMessages(requests: PartialSyncMessagesService.RequestModel[]): Promise<Record<string, any>> {
	const bodyParameters = {
		sync: requests,
	};

	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxPartialSyncMessages), bodyParameters, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getMessages(requests: GetMessagesService.RequestModel[]): Promise<Record<string, any>> {
	const bodyParameters = {
		get_messages: requests,
	};

	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxGetMessages), bodyParameters, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function queryMessages(request: QueryMessagesService.RequestModel): Promise<Record<string, any>> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxQueryMessages), request, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function deleteMessages(requests: DeleteUndeleteMessagesService.RequestModel[]): Promise<Record<string, any>> {
	return new Promise((resolve, reject) => {
		var requestArray: Record<string, any>[] = [];

		var action = "delete";

		for (let request of requests) {
			action = request.action;

			const requestDict: Record<string, any> = {};
			if (request.access_token) {
				requestDict["access_token"] = request.access_token;
			}
			requestDict["mailbox_profile_id"] = request.mailbox_profile_id;
			requestDict["message_ids"] = request.message_ids;
			requestArray.push(requestDict);
		}

		var bodyParameters = {};
		bodyParameters[action] = requestArray;

		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxDeleteMessages), bodyParameters, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function modifyMessages(requests: ModifyMessagesService.RequestModel[]): Promise<Record<string, any>> {
	return new Promise((resolve, reject) => {
		var requestArray: Record<string, any>[] = [];

		var action = "read";

		for (let request of requests) {
			action = request.action;

			const requestDict: Record<string, any> = {};
			if (request.access_token) {
				requestDict["access_token"] = request.access_token;
			}
			requestDict["mailbox_profile_id"] = request.mailbox_profile_id;
			requestDict["message_ids"] = request.message_ids;
			requestArray.push(requestDict);
		}

		var bodyParameters = {};
		bodyParameters[action] = requestArray;

		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxModifyMessages), bodyParameters, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getBookmarks(skip: number = 0): Promise<NLBookmark[]> {
	return new Promise((resolve, reject) => {
		const pageSize = 20;
		api
			.get(paramsAppended(networkConfig.Endpoints.getBookmarks) + "&pageSize=" + pageSize + "&skip=" + skip, { headers: headers() })
			.then((response) => {
				let parsedResult = response["data"];
				let bookmarks = parsedResult["bookmarks"];
				let newBookmarks = bookmarks.map((x) => new NLBookmark(x));

				if (parsedResult["has_next"]) {
					getBookmarks(pageSize + skip).then((moreBookmarks) => {
						resolve(newBookmarks.concat(moreBookmarks));
					});
				} else {
					resolve(newBookmarks);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function updateBookmark(bookmark: NLBookmark): Promise<NLBookmark> {
	return new Promise((resolve, reject) => {
		api
			.put(paramsAppended(networkConfig.Endpoints.getBookmarks), bookmark, { headers: headers() })
			.then((response) => {
				let bookmark = response["data"];
				let bookmarkObject: NLBookmark = new NLBookmark(bookmark);
				resolve(bookmarkObject);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function postBookmarks(bookmarks: [NLBookmark]): Promise<NLBookmark[]> {
	const bodyParameters = {
		bookmarks: bookmarks,
	};

	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.getBookmarks), bodyParameters, { headers: headers() })
			.then((response) => {
				let parsedResult = response["data"];
				let fetchedBookmarks = parsedResult["bookmarks"];
				let bookmarkObjects: NLBookmark[] = fetchedBookmarks.filter((x) => x.is_deleted === false).map((x) => new NLBookmark(x));
				resolve(bookmarkObjects);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function searchSenders(request: SearchSenderService.RequestModel): Promise<Record<string, any>> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxSearchSenders), request, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function createSender(request: CreateSenderService.RequestModel): Promise<NLUserMailState> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxCreateSender), request, { headers: headers() })
			.then((response) => {
				let userMailState = new NLUserMailState(response["data"]);
				resolve(userMailState);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function deleteMailboxProfile(mailboxProfileId: string, accessToken?: string): Promise<NLProfile> {
	return new Promise<NLProfile>((resolve, reject) => {
		var data = { id: mailboxProfileId };

		if (accessToken) {
			data["access_token"] = accessToken;
		}

		api
			.delete(paramsAppended(networkConfig.Endpoints.updateMailboxProfile), { headers: headers(), data: data })
			.then((response) => {
				try {
					const userProfile = new NLProfile(response["data"]);
					resolve(userProfile);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getCheckoutUrl(checkout: NLCreateCheckout): Promise<NLCheckout> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.getCheckOutUrl), checkout, { headers: headers() })
			.then((response) => {
				let checkout = new NLCheckout(response["data"]);
				resolve(checkout);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getMessageWithReaderMode(request: ReaderModeService.RequestModel): Promise<Record<string, any>> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxMessageReaderMode), request, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function changeNotificationPreferenceSetTime(request: NLNotificationSetTimeType): Promise<NLNotificationSetTimeType> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.changeNotificationPreference), request, { headers: headers() })
			.then((response) => {
				const notificationPreference = new NLNotificationSetTimeType(response["data"]);
				resolve(notificationPreference);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function changeNotificationPreferenceAsArrived(request: NLNotificationAsArrivedType): Promise<NLNotificationAsArrivedType> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.changeNotificationPreference), request, { headers: headers() })
			.then((response) => {
				const notificationPreference = new NLNotificationAsArrivedType(response["data"]);
				resolve(notificationPreference);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function deleteNotificationPreference(type: NLNotificationPreferenceType): Promise<void> {
	return new Promise((resolve, reject) => {
		let data = { type: type };

		api
			.delete(paramsAppended(networkConfig.Endpoints.changeNotificationPreference), { headers: headers(), data: data })
			.then((_response) => {
				resolve();
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function registerPush(data: Record<string, any>): Promise<void> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.registerPush), data, { headers: headers() })
			.then((response) => {
				console.log(response);
				resolve();
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function checkLabelHealth(request: LabelHealthService.RequestModel): Promise<void> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxLabelHealth), request, { headers: headers() })
			.then((_response) => {
				resolve();
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function activateInternalTrial(): Promise<NLProfile> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.activateInternalTrial), { headers: headers() })
			.then((response) => {
				try {
					var userProfile = new NLProfile(response["data"]);
					resolve(userProfile);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getAudioSections(): Promise<NLAudioSection[]> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.getAudioSections), { headers: headers() })
			.then((response) => {
				try {
					let sections = response["data"]["audio_sections"]
					let audioSections: NLAudioSection[] = sections.map((x) => new NLAudioSection(x));
					resolve(audioSections);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getSectionDetail(sectionKey: string): Promise<NLAudioSectionDetail> {
	return new Promise((resolve, reject) => {
		api
			.get(paramsAppended(networkConfig.Endpoints.getAudioSectionDetail + sectionKey), { headers: headers() })
			.then((response) => {
				try {
					let sectionDetail = new NLAudioSectionDetail(response["data"]);
					resolve(sectionDetail);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function postShareAudio(audioId: string): Promise<string> {
	return new Promise((resolve, reject) => {
		var data = { user_audio_id: audioId };

		api
			.post(paramsAppended(networkConfig.Endpoints.postShareAudio), data, { headers: headers() })
			.then((response) => {
				let shareAudioUrl = response.data["share_url"];
				resolve(shareAudioUrl);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function postAudioRating(audioId: string, isThumbsUp: boolean): Promise<void> {
	return new Promise((resolve, reject) => {
		var data = { user_audio_id: audioId, is_thumbs_up: isThumbsUp };

		api
			.post(paramsAppended(networkConfig.Endpoints.postAudioRating), data, { headers: headers() })
			.then((_) => {
				resolve()
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function createAudioProfile(audioProfile: any): Promise<[NLProfile, boolean]> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.createAudioProfile), audioProfile, { headers: headers() })
			.then((response) => {
				try {
					const userProfile = new NLProfile(response["data"]);
					const isOnboardingAudioEligible = response.headers["is_onboarding_audio_eligible"].toLowerCase() === "true";
					resolve([userProfile, isOnboardingAudioEligible]);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				isUserAuthenticated(error);
				var internalError = error;
				if (error.response.status === 409) {
					internalError = kErrorConstants.oauthIntegration.alreadyIntegratedAccount;
				}
				if (error.response.status === 406) {
					internalError = kErrorConstants.oauthIntegration.accountMismatch;
				}
				reject(internalError);
			});
	});
}

export function updateAudioProfile(audioProfile: NLAudioProfile): Promise<NLProfile> {
	return new Promise((resolve, reject) => {
		api
			.put(paramsAppended(networkConfig.Endpoints.createAudioProfile), audioProfile, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function postAudioListenHistory(episodeId: string, duration: number): Promise<void> {
	return new Promise((resolve, reject) => {
		var data = { user_audio_id: episodeId, duration: duration };

		api
			.post(paramsAppended(networkConfig.Endpoints.postAudioHistory), data, { headers: headers() })
			.then((_) => {
				resolve()
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function replyMessage(request: ReplyMessageService.RequestModel): Promise<Record<string, any>> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxReplyMessage), request, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}

export function getMessageSummary(request: MessageSummaryService.RequestModel): Promise<Record<string, any>> {
	return new Promise((resolve, reject) => {
		api
			.post(paramsAppended(networkConfig.Endpoints.mailboxMessageSummary), request, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				isUserAuthenticated(error);
				reject(error);
			});
	});
}