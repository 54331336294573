import React, { useState, useEffect, FC, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";

export interface LoadingState {
	isLoading: boolean;
	message?: string | null;
	noBackground?: boolean;
	withSuccessAnimation?: (animationComplete: boolean) => void;
}

interface LoadingProps {
	loadingState: LoadingState;
}

const LoadingView: FC<LoadingProps> = ({ loadingState }) => {
	const { isLoading, message, noBackground } = loadingState;

	return (
		<>
			<Transition as={Fragment} show={isLoading}>
				<Dialog static onClose={() => null} className="relative z-40">
					{noBackground ? (
						<></>
					) : (
						<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
							<div className="fixed inset-0 bg-brand-blue bg-opacity-50" aria-hidden="true" />
						</Transition.Child>
					)}
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<div className="bg-force-primary-light rounded-2xl p-4 m-6 shadow-2xl max-w-[280px]">
								<div className="flex flex-col gap-3 text-center">
									<LoadingSpinner className="h-[50px] text-brand-blue/20 animate-spin fill-brand-blue" />
									<div className={`${message ? "block" : "hidden"} text-brand-blue/80 font-medium font-primary text-base  md:text-base  leading-6`}>{message}</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default LoadingView;
