export const kAnalyticsConstants = {
	App: {
		sessionStarted: "MCO - Session Started",
		sessionEnded: "MCO - Session Ended",
		launchError: "MCO - Launch Error",
		fetchError: "MCO - Fetch Error",
		appTerminated: "MCO - App Terminated",
		appliedNewsletterChanges: "MCO - Applied Newsletter Changes",
		stoppedUsingMeco: "MCO - Stopped Using Meco",
		newsletterRead: "MCO - Newsletter Read",
		summaryTapped: "MCO - AI Summary Tapped",
		summaryLoaded: "MCO - AI Summary Loaded",
		summaryError: "MCO - AI Summary Error",
		newsletterDeleted: "MCO - Newsletter Deleted",
		newsletterForwarded: "MCO - Newsletter Forwarded",
		stateChanged: "MCO - State Changed",
		newsletterAdded: "MCO - Newsletter Added",
		newsletterRenamed: "MCO - Newsletter Renamed",
		newsletterStarred: "MCO - Newsletter Starred",
		newsletterUnstarred: "MCO - Newsletter Unstarred",
		toastUndoTapped: "MCO - Toast Undo Tapped",
		discoverPageView: "MCO - Discover Page View",
		settingsViewed: "MCO - Settings Viewed",
		universalLinkOpened: "MCO - Universal Link Opened",
		oneClickSubscribeConversion: "MCO - One Click Subscribe Conversion",
		notifications: "MCO - Notifications Pop up",
		fetchEmailsError: "MCO - Fetch emails error",
		newsletterStarred: "MCO - Newsletter Starred",
		newsletterUnstarred: "MCO - Newsletter Unstarred",
		digestTapped: "MCO - Digest Tapped",
		digestTapped: "MCO - Audio Tapped",
		discoverTapped: "MCO - Discover Tapped",
		settingsTapped: "MCO - Settings Tapped",
		bookmarksTapped: "MCO - Bookmarks Tapped",
		sendersTapped: "MCO - Senders Tapped",
		searchTapped: "MCO - Search Tapped",
		groupTapped: "MCO - Group Tapped",
		senderTapped: "MCO - Sender Tapped",
		newsletterUnsubscribed: "MCO - Newsletter Unsubscribed",
		
		NewsletterAddedVia: {
			newsletterAddedViaKey: "newsletter_added_via",
			gmailSearch: "gmail_search",
			userInput: "user_input",
		},

		NewsletterKeys: {
			newsletterSenderAddress: "newsletter_sender_address",
			newsletterSenderName: "newsletter_sender_name",
			newsletterReceiveDate: "newsletter_receive_date",
			newsletterSubject: "newsletter_subject",
			newsletterReadVia: "newsletter_read_via",
			newsletterNewSenderName: "newsletter_new_sender_name",
			undoAction: "undo_action",
		},

		NewsletterReadViaValues: {
			digest: "digest",
			newsflash: "newsflash",
			notRead: "not_read",
			universalLink: "universal_link",
		},
		AudioKeys: {
			audioEpisodeId: "audio_episode_id",
            audioEpisodeTitle: "audio_episode_title",
            audioIsPublic: "audio_is_public",
            audioCreationDate: "audio_creation_date",
            audioDuration: "audio_duration",
            audioChapterCount: "audio_chapter_count",
		}
	},
	SuperProperties: {
		firstSession: "first_session",
		userId: "user_id",
		signUpDate: "sign_up_date",
		premiumEnabled: "premium_enabled",
		premiumStatus: "premium_status",
		newsletters_added: "newsletters_added",
		newsletters_paused: "newsletters_paused",
		newsletters_hidden: "newsletters_hidden",
		newsletters_inbox: "newsletters_inbox",
		newsletters_new: "newsletters_new",
		defaultGroupId: "default_group_id",
		defaultGroupName: "default_group_name",
		experimentId: "experiment_id",
		variantId: "variant_id",
		default_view_filter: "default_view_filter",
		default_filter: "default_filter",
		default_group_filter: "default_group_filter",
		reader_mode_font_size: "reader_mode_font_size",
		reader_mode_font: "reader_mode_font",
		reader_mode_theme: "reader_mode_theme",
		reader_mode_on: "reader_mode_on",
	},
	Onboarding: {
		accountLoginError: "ONB - Account - Login Error",
		accountLoginSuccessful: "ONB - Account - Login Successful",
		accountSignUpError: "ONB - Account - Sign up Error",
		accountSignUpSuccessful: "ONB - Account - Sign up Successful",
		magicLinkSent: "ONB - Magic Link Sent",
		resentLink: "ONB - Magic Link - Resent Link",
		resendTapped: "ONB - Magic Link - Resend Tapped",
		magicCodeEntered: "ONB - Magic Link - Magic Code Entered",
		magicCodeError: "ONB - Magic Link - Magic Code Error",
		magicCodeValidated: "ONB - Magic Link - Magic Code Validated",
		magicLinkValidated: "ONB - Magic Link - Validated",
		magicLinkValidationError: "ONB - Magic Link - Validation Error",
		loginSuccessful: "ONB - Login Successful",

		getStartedPresented: "ONB - Get Started Presented",
		loginTapped: "ONB - Login Tapped",
		loginError: "ONB - Login Error",
		loginSuccessful: "ONB - Login Successful",
		fetchedProspects: "ONB - Fetched Prospects",

		feedPickerPresented: "ONB - Feed Picker Presented",
		feedPickerDone: "ONB - Feed Picker Completed",

		personalisedDiscoverPresented: "ONB - DIS - Presented",
		recommendationDisplayed: "ONB - DIS - Recommendation Displayed",
		personalisedDiscoverError: "ONB - DIS - Error",
		personalisedDiscoverTapped: "ONB - DIS - Newsletter Tapped",
		personalisedDiscoverShowMore: "ONB - DIS - Show More Tapped",
		personalisedDiscoverOCSTapped: "ONB - DIS - One Click Subscribe Tapped",
		personalisedDiscoverCompleted: "ONB - DIS - Completed",

		personalisedDiscoverKeys: {
			selectedCategoryCount: "onb_selected_category_count",
			selectedCategoriesIds: "onb_selected_category_ids",
			selectedCategoriesNames: "onb_selected_category_names",

			returnNewsletterCount: "onb_dis_returned_newsletter_count",
			returnNewsletterLimit: "onb_dis_return_newsletter_limit",
			returnedNewsletterIds: "onb_dis_returned_newsletter_ids",
			returnedNewsletterNames: "onb_dis_returned_newsletter_names",

			returnedOCSCount: "onb_dis_returned_ocs_newsletter_count",
			returnedOCSNewsletterIds: "onb_dis_returned_ocs_newsletter_ids",
			returnedOCSNewsletterNames: "onb_dis_returned_ocs_newsletter_names",

			returnedNOCSCount: "onb_dis_returned_nocs_newsletter_count",
			returnedNOCSNewsletterIds: "onb_dis_returned_nocs_newsletter_ids",
			returnedNOCSNewsletterNames: "onb_dis_returned_nocs_newsletter_names",

			subscribedNewsletterCount: "onb_dis_ocs_subscribed_newsletter_count",
			subscribedNewsletterIds: "onb_dis_ocs_subscribed_newsletter_ids",
			subscribedNewsletterNames: "onb_dis_ocs_subscribed_newsletter_names",

			rec_newsletter_id: "rec_newsletter_id",
			rec_newsletter_name: "rec_newsletter_name",
			rec_engine_cat: "rec_engine_cat",
			rec_engine_cat_score: "rec_engine_cat_score",
			rec_engine_subcat: "rec_engine_subcat",
			rec_engine_subcat_w_score: "rec_engine_subcat_w_score",
			rec_eng_score: "rec_eng_score",
			rec_eng_w_score: "rec_eng_w_score",
			rec_campaign_priority_level: "rec_campaign_priority_level",
			rec_force_priority_level: "rec_force_priority_level",
			rec_combined_score: "rec_combined_score",

			recommendationEngineVersion: "onb_dis_recommendation_engine_version",
		},

		unsubscribePickerPresented: "ONB - Unsubscribe Picker Presented",
		discoverPresented: "ONB - Discover Presented",
		notificatinsPresented: "ONB - Notifications Presented",
		paywallPresented: "ONB - Paywall Presented",
		welcomePresented: "ONB - Welcome Presented",
		completed: "ONB - Completed",

		OnboardingProfileKeys: {
			displayedUnsubscribe: "onb_displayed_unsubscribe",
			newslettersPaused: "onb_newsletters_paused",
			displayedFeed: "onb_displayed_feed",
			displayedWhitelistedFeed: "onb_whitelisted_displayed_feed",
			displayedWhitelistedRatio: "onb_whitelisted_displayed_ratio",
			progressedLookBackDays: "progressed_look_back_days",
			newslettersAdded: "onb_newsletters_added",
			displayedDiscover: "onb_displayed_discover",
			discoveredItems: "onb_discovered_items",
			isTrial: "onb_is_trial",
			isInternalTrial: "onb_is_internal_trial",
			isFriendsFamily: "onb_is_friends_family",
			isFreeForAll: "onb_is_free_for_all",
			isPremium: "onb_is_premium",
			notificationOptionPicked: "onb_notification_option_picked",
			configDiscoverThreshold: "onb_conf_disover_threshold",
			configFirstPartInitialStep: "onb_conf_first_part_initial_step",
			configSecondPartInitialStep: "onb_conf_second_part_initial_step",
		},
	},
	Settings: {
		downloadApp: "SET - Download iOS Tapped",
	},
	Digest: {
		filtersChanged: "DGT - Web-Filters Changed",
	},
	Discover: {
		newsletterTapped: "DIS - Newsletter Tapped",
		categorySelected: "DIS - Category Selected",
		alreadySubscribedTapped: "DIS - Already Subscribed Tapped",
		personalisedDiscoverOCSTapped: "DIS - One Click Subscribe Tapped",
	},
	Groups: {
		groupCreated: "MCO - Group Created",
		groupModified: "MCO - Group Modified",
		groupDeleted: "MCO - Group Deleted",
		groupRenamed: "MCO - Group Renamed",
		groupsReordered: "MCO - Groups Reordered",
		GroupsKeys: {
			groupCreatedVia: "group_created_via",
			groupId: "group_id",
			groupName: "group_name",
			groupSenderCount: "group_sender_count",
			groupNewSenderCount: "group_new_sender_count",
			groupModifiedVia: "group_modified_via",
			groupNewName: "group_new_name",
		},
	},
	EmailViewer: {
		newsletterLinkShared: "MCO - Newsletter Link Shared",
	},
	SwipeAway: {
		swipeAwayViewed: "SWA - Viewed",
		swipeAwaySkipTapped: "SWA - Skip Tapped",
		swipeAwaySkipped: "SWA - Newsletter Skipped",
	},
	ReaderMode: {
		turnedOn: "REM - Turned On",
		turnedOff: "REM - Turned Off",
		issueReported: "REM - Issue Reported",
	},
	Paywall: {
		paywallPresented: "PWA - Presented",
		subscribeButtonTapped: "PWA - Subscribe Button Tapped",
		checkoutGenerationError: "PWA - Checkout Generation Error",
		iapPurchaseSuccessful: "PWA - Purchase Successful",
		iapPurchaseError: "PWA - Purchase Error",
		paywallDismissed: "PWA - Dismissed",
		internalTrialStarted: "PWA - Internal Trial Started",
	},
	ResyncGmail: {
		resyncStarted: "SET - Gmail Resync Started",
		resyncCompleted: "SET - Gmail Resync Completed",
		resyncCompletedWErrors: "SET - Gmail Resync Completed with Errors",
	},
	Reference: {
		feed: "feed",
		settings: "settings",
		onboarding: "onboarding",
		discover: "discover",
		digest: "digest",
		bookmarks: "bookmarks",
		emailViewer: "email_viewer",
		linkViewer: "link_viewer",
		search: "search",
		Paywall: {
			readerMode: "reader_mode",
			gmailIntegration: "gmail_integration",
			outlookIntegration: "outlook_integration",
			audio: "audio",
			aiSummaru: "ai_summary",
		},
	},
	Audio: {
		onboardingStarted: "AUD - Onboarding - Started",
        sendersSelected: "AUD - Onboarding - Senders Selected",
        scheduleSelected: "AUD - Onboarding - Schedule Selected",
        firstEpisodeLoading: "AUD - Onboarding - First Episode Loading",
        senedersUpdated: "AUD - Senders Updated",
        senderPreviewed: "AUD - Sender Previewed",
        scheduleUpdated: "AUD - Schedule Updated",
        playbackSpeedChanged: "AUD - Playback Speed Changed",
        audioLinkShared: "MCO - Audio Link Shared",
        audioListened: "MCO - Audio Listened",
        thumbsUp: "AUD - Thumbs Up",
        thumbsDown: "AUD - Thumbs Down",
	},
	ReplyMessage: {
		presented: "REP - Reply Pressented",
		replied: "REP - Newsletter Replied",
	}
};
