import React, { createContext, useContext, ReactNode, useEffect } from "react";
import { AudioOnboardingProfile } from "@models/Models";
import { kLocalStorageKeys } from "@utils/constants/kLocalStorageKeys";
import { Outlet } from "react-router-dom";

interface AudioOnboardingContextProps {
	audioOnboardingProfile: AudioOnboardingProfile | undefined;
	setAudioOnboardingProfile: (audioOnboardingProfile?: AudioOnboardingProfile) => void;
}

const AudioOnboardingContext = createContext<AudioOnboardingContextProps | undefined>(undefined);

export const AudioOnboardingProvider = () => {
	const [audioOnboardingProfile, setAudioOnboardingProfile] = React.useState<AudioOnboardingProfile | undefined>(() => {
		const storedAudioOnboardingProfile = sessionStorage.getItem(kLocalStorageKeys.Session.audioOnboardingProfile);
		if (storedAudioOnboardingProfile) {
			const jsonAudioOnboardingProfile = JSON.parse(storedAudioOnboardingProfile);
			return Object.assign(new AudioOnboardingProfile(), jsonAudioOnboardingProfile);
		}
		return undefined;
	});

	useEffect(() => {
		if (audioOnboardingProfile) {
			sessionStorage.setItem(kLocalStorageKeys.Session.audioOnboardingProfile, JSON.stringify(audioOnboardingProfile));
		}
	}, [audioOnboardingProfile]);

	const providerValue: AudioOnboardingContextProps = {
		audioOnboardingProfile,
		setAudioOnboardingProfile,
	};

	return (
		<AudioOnboardingContext.Provider value={providerValue}>
			<Outlet />
		</AudioOnboardingContext.Provider>
	);
};

export const useAudioOnboardingProfile = () => {
	const context = useContext(AudioOnboardingContext);
	if (!context) {
		throw new Error("useAudioOnboardingProfile must be used within an AudioOnboardingProvider");
	}
	return context;
};
