import { NLReaderMode } from "@models/Models";

export class ReaderModeChoices {
    static Font = {
        Literata: "Literata",
        OpenSans: "Open Sans",
        Lora: "Lora",
        Atkinson: "Atkinson Hyperlegible",
        Gelasio: "Gelasio",
        Helvetica: "Helvetica",
        TimesNewRoman: "Times New Roman",
    } as const;

    static Theme = {
        Light: "light",
        Sepia: "sepia",
        Gray: "gray",
        Dark: "dark",
    } as const;

    static fontDisplayName(font: typeof ReaderModeChoices.Font[keyof typeof ReaderModeChoices.Font]): string {
        switch (font) {
            case this.Font.Literata:
                return "Literata";
            case this.Font.OpenSans:
                return "Open Sans";
            case this.Font.Lora:
                return "Lora";
            case this.Font.Atkinson:
                return "Atkinson";
            case this.Font.Gelasio:
                return "Gelasio";
            case this.Font.Helvetica:
                return "Helvetica";
            case this.Font.TimesNewRoman:
                return "Times New Roman";
        }
    }

    static themeBackgroundColor(theme: typeof ReaderModeChoices.Theme[keyof typeof ReaderModeChoices.Theme]): string {
        switch (theme) {
            case this.Theme.Light:
                return "#FFFFFF";
            case this.Theme.Sepia:
                return "#f4ecd8";
            case this.Theme.Gray:
                return "#1c1b22";
            case this.Theme.Dark:
                return "#000000";
        }
    }

    static themeForegroundColor(theme: typeof ReaderModeChoices.Theme[keyof typeof ReaderModeChoices.Theme]): string {
        switch (theme) {
            case this.Theme.Light:
                return "#15141a";
            case this.Theme.Sepia:
                return "#5b4636";
            case this.Theme.Gray:
                return "#e0e0e0";
            case this.Theme.Dark:
                return "#DFDFDF";
        }
    }

    static themeDisplayName(theme: typeof ReaderModeChoices.Theme[keyof typeof ReaderModeChoices.Theme]): string {
        switch (theme) {
            case this.Theme.Light:
                return "Wolf";
            case this.Theme.Sepia:
                return "Osprey";
            case this.Theme.Gray:
                return "Rhino";
            case this.Theme.Dark:
                return "Panther";
        }
    }

    static isDarkTheme(theme: typeof ReaderModeChoices.Theme[keyof typeof ReaderModeChoices.Theme]): boolean {
        switch (theme) {
            case this.Theme.Light:
            case this.Theme.Sepia:
                return false;
            case this.Theme.Gray:
            case this.Theme.Dark:
                return true;
        }
    }

    static getDefault(): NLReaderMode {
        return { state: false, font: this.Font.Gelasio, fontSize: 20, theme: this.Theme.Gray };
    }
}
